import styled, { css } from 'styled-components';
import { TextProps } from './Text';

export const TextComponent = styled.p<Omit<TextProps, 'as'>>`
  font: inherit;
  text-align: ${({ textAlign }) => textAlign && textAlign};
  font-size: ${({ fontSize, theme }) => (fontSize ? theme.fontSizes[fontSize] : theme.fontSizes.medium)};
  font-weight: ${({ fontWeight }) => fontWeight};
  color: ${({ theme }) => theme.colors.black};
  text-transform: ${({ textTransform }) => textTransform && textTransform};
  text-decoration: ${({ textDecoration }) => textDecoration && textDecoration};
  letter-spacing: ${({ letterSpacing, theme }) =>
    letterSpacing ? theme.letterSpacing[letterSpacing] : theme.letterSpacing.default};

  ${({ color, theme }) =>
    color &&
    css`
      color: ${color in theme.colors ? theme.colors[color as keyof typeof theme.colors] : color};
    `}
`;
