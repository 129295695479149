import React from 'react';
import { LocationChangeSessionRecord } from 'models/db/db_SessionRecord';
import { useQuery } from '@tanstack/react-query';
import { useGameplaySelector } from 'stores/gameplay.slice';
import { useTranslation } from 'react-i18next';
import { LocationsService } from 'services/LocationsService';
import { Text } from 'components/generics/Text/Text';
import { QUERY_KEYS } from 'utils/consts';
import { LocationImage, Record, RecordContentWrapper } from '../RecordsView.styles';

interface LocationChangeRecordProps {
  isGm?: boolean;
  record: LocationChangeSessionRecord;
}

export const LocationChangeRecord = ({ record, isGm }: LocationChangeRecordProps) => {
  const { t } = useTranslation('gameplay', { keyPrefix: 'session_records_view.location' });
  const { t: tLocationPacks } = useTranslation('locationPacks' as 'resource');
  const { t: tLocation } = useTranslation('locations' as 'resource');
  const { activeSession } = useGameplaySelector();

  const { data: locationPack } = useQuery(
    [QUERY_KEYS.gameplay.getLocationPackResourceData, record.content.location_pack_id],
    () => LocationsService.getLocationPack(record.content.location_pack_id ?? ''),
    { enabled: !!record.content.location_pack_id },
  );

  return (
    <Record isGm={isGm}>
      <RecordContentWrapper>
        <Text color="highlightYellow" fontWeight={700} textTransform="uppercase">
          {t('arrived_at_label')}
        </Text>
        <LocationImage src={record.content.cover_img_url} loading="lazy" />
        <div>
          <Text fontSize="small" fontWeight={700}>
            {tLocation(
              `${activeSession?.theme_id}.${locationPack?.data[0]?.t_key}.${record.content.t_key}.title` as any,
            )}
          </Text>
          <Text fontSize="smaller" fontWeight={700}>
            {tLocationPacks(`${activeSession?.theme_id}.${locationPack?.data[0]?.t_key}.name` as any, '', 'Loading...')}
          </Text>
        </div>
      </RecordContentWrapper>
    </Record>
  );
};
