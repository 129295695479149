import { SupabaseService } from 'services/SupabaseService';
import { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { useUserSelector } from 'stores/user.slice';
import { dynamicRoutes } from 'components/MainRouter/MainRouter';
import { QUERY_KEYS, DB_TABLE_NAMES } from 'utils/consts';
import { OmitAutoGenerated } from 'models/Api';
import { SessionPlayer } from 'models/db/instance_SessionPlayer';
import { Session } from 'models/db/db_Session';
import { CharacterSchema } from 'models/db/db_PlayerCharacter';
import { Health } from 'engine/health';

interface ExtendedSessionRow extends Session {
  instance_SessionPlayer: SessionPlayer[];
}

export const useJoinSessionView = () => {
  const queryClient = useQueryClient();

  const { user } = useUserSelector();
  const [sessionCode, setSessionCode] = useState('');
  const [selectedCharacterId, setSelectedCharacterId] = useState<string | undefined>(undefined);
  const [foundSessionId, setFoundSessionId] = useState<string | undefined>(undefined);

  const [sessionNotFoundError, setSessionNotFound] = useState(false);
  const [ownSessionError, setOwnSessionError] = useState(false);
  const [alreadyHaveCharacterError, setAlreadyHaveCharacterError] = useState(false);
  const [noCompatibleCharactersError, setNoCompatibleCharactersError] = useState(false);

  const [compatibleCharacters, setCompatibleCharacters] = useState<
    {
      id: string;
      name: string;
      portrait: string;
      theme_id: string;
      key: string;
      base_schema: CharacterSchema;
      base_health: {
        head: number;
        torso: number;
        arms: number;
        legs: number;
      };
      base_money: number;
    }[]
  >([]);

  const navigate = useNavigate();

  const handleSessionCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSessionCode(event.target.value);
  };

  const handleSelectedCharacterChange = (selectedOption: any) => setSelectedCharacterId(selectedOption.value);

  const onFindSession = async () => {
    setSessionNotFound(false);
    setOwnSessionError(false);
    setAlreadyHaveCharacterError(false);

    const { data: sessionsDataNotTyped, error } = await SupabaseService.from(DB_TABLE_NAMES.dbSession)
      .select(
        `*,
        instance_SessionPlayer (
          user_id
        )
      `,
      )
      .eq('access_code', sessionCode);

    const sessionData = sessionsDataNotTyped as ExtendedSessionRow[];

    if (error || sessionData.length === 0) {
      setSessionNotFound(true);
      return;
    }

    setFoundSessionId(sessionData[0].id);

    if (sessionData[0].owner_id === user?.id) {
      setOwnSessionError(true);
    } else if (
      sessionData[0].instance_SessionPlayer.find((player: { user_id: string }) => player.user_id === user?.id)
    ) {
      setAlreadyHaveCharacterError(true);
    } else {
      const { data: characters, error } = await SupabaseService.rpc('get_compatible_characters', {
        th_id: sessionData[0].theme_id,
        u_id: user?.id,
      });

      if (error || characters.length === 0) {
        setNoCompatibleCharactersError(true);
        return;
      }

      setCompatibleCharacters(characters);
    }
  };

  const onJoinSession = async () => {
    if (!user) return;

    const selectedCharacter = compatibleCharacters.find((character) => character.id === selectedCharacterId);

    if (foundSessionId && selectedCharacterId && selectedCharacter) {
      const { error } = await SupabaseService.from(DB_TABLE_NAMES.instanceSessionPlayer).insert<
        OmitAutoGenerated<SessionPlayer>
      >([
        {
          session_id: foundSessionId,
          user_id: user?.id,
          player_character_id: selectedCharacterId,
          character_schema: selectedCharacter.base_schema,
          health: {
            head: {
              maxHp: selectedCharacter.base_health.head,
              currentHp: selectedCharacter.base_health.head,
            },
            torso: {
              maxHp: selectedCharacter.base_health.torso,
              currentHp: selectedCharacter.base_health.torso,
            },
            arms: {
              maxHp: selectedCharacter.base_health.arms,
              currentHp: selectedCharacter.base_health.arms,
            },
            legs: {
              maxHp: selectedCharacter.base_health.legs,
              currentHp: selectedCharacter.base_health.legs,
            },
          },
          money: selectedCharacter.base_money,
        },
      ]);

      if (error) {
        return;
      }

      queryClient.refetchQueries({ queryKey: [QUERY_KEYS.launcher.sessionsList] });
      navigate(dynamicRoutes.session(foundSessionId));
    }
  };

  return {
    sessionCode,
    selectedCharacterId,
    compatibleCharacters: compatibleCharacters
      ? compatibleCharacters.map((character) => ({
          value: character.id,
          label: character.name,
        }))
      : [],
    handleSessionCodeChange,
    handleSelectedCharacterChange,
    onFindSession,
    onJoinSession,
    sessionNotFoundError,
    ownSessionError,
    alreadyHaveCharacterError,
    noCompatibleCharactersError,
  } as const;
};
