import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MultiTabSection } from 'components/MultiTabSection/MultiTabSection';
import { TabButtons } from 'components/MultiTabSection/TabButtons';
import { Text } from 'components/generics/Text/Text';
import { CurrentLocationSubsection } from './subsections/CurrentLocationSubsection';
import { StoryCardsSubsection } from './subsections/StoryCardsSubsection';

export const HandbookStorySection = () => {
  const { t } = useTranslation('handbook', { keyPrefix: 'story_view' });
  const [activeTabId, setActiveTabId] = useState('');

  return (
    <>
      <Text fontSize="small" color="opaqueWhite75" fontWeight={500}>
        {t('paragraphs.p1')}
      </Text>
      <Text fontWeight={500}> {t('subsections_prompt')}.</Text>
      <TabButtons
        tabs={[
          {
            id: 'location',
            label: t('subsections.locations.button_label'),
            active: activeTabId === 'location',
            onClick: () => setActiveTabId((prev) => (prev === 'location' ? '' : 'location')),
          },
          {
            id: 'story_cards',
            label: t('subsections.story_cards.button_label'),
            active: activeTabId === 'story_cards',
            onClick: () => setActiveTabId((prev) => (prev === 'story_cards' ? '' : 'story_cards')),
          },
        ]}
      />
      <MultiTabSection
        activeTabId={activeTabId}
        tabs={[
          {
            id: 'location',
            component: <CurrentLocationSubsection />,
          },
          {
            id: 'story_cards',
            component: <StoryCardsSubsection />,
          },
        ]}
      />
    </>
  );
};
