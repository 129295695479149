import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { QueryClient, QueryClientConfig, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { GlobalStyle } from 'assets/styles/globalStyle';
import { mainTheme } from 'assets/styles/theme';
import { ErrorBoundary } from 'components/ErrorBoundary/ErrorBoundary';
import { AuthBoundary } from 'components/AuthBoundary/AuthBoundary';
import { MainRoutes } from 'components/MainRouter/MainRouter';
import { Modal } from 'components/Modal/Modal';
import { store } from 'stores/store';

const QUERY_CLIENT_CONFIG: QueryClientConfig = {
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  },
};

export const AppWrapper = () => {
  const { i18n } = useTranslation();
  const queryClient = new QueryClient(QUERY_CLIENT_CONFIG);

  useEffect(() => {
    const userLanguage = localStorage.getItem('RD@userLanguage');

    if (!userLanguage) {
      localStorage.setItem('RD@userLanguage', navigator.language);
      i18n.changeLanguage(navigator.language);
    } else {
      i18n.changeLanguage(userLanguage);
    }
  }, [i18n]);

  return (
    <ThemeProvider theme={mainTheme}>
      <GlobalStyle />
      <ErrorBoundary>
        <QueryClientProvider client={queryClient}>
          <ReduxProvider store={store}>
            <AuthBoundary>
              <Router>
                <Modal />
                <MainRoutes />
              </Router>
            </AuthBoundary>
          </ReduxProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </ErrorBoundary>
    </ThemeProvider>
  );
};
