import React from 'react';
import { ToastContainer } from 'react-toastify';
import { LauncherNavMenu, menuItems } from 'components/LauncherNavMenu/LauncherNavMenu';
import { Container, ContentContainer } from './LauncherLayout.styles';

interface LauncherLayoutProps {
  children: React.ReactNode;
  activeItem?: keyof typeof menuItems;
}

const LauncherLayout = ({ children, activeItem }: LauncherLayoutProps) => (
  <Container>
    <ToastContainer
      position="top-center"
      autoClose={3000}
      hideProgressBar
      newestOnTop
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="dark"
    />
    <ContentContainer>{children}</ContentContainer>
    <LauncherNavMenu activeItem={activeItem} />
  </Container>
);
export default LauncherLayout;
