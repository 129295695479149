import { PropsWithChildren, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useIsLoggedIn } from 'hooks/useIsLoggedIn';
import { dynamicRoutes, routes } from 'components/MainRouter/MainRouter';

export const Protected = ({ children }: PropsWithChildren) => {
  const isLoggedIn = useIsLoggedIn();
  const navigate = useNavigate();

  useEffect(() => {
    // Extra hacky check for Supabase account email confirmation
    if (window.location.pathname === routes.home && window.location.href.includes('&type=signup')) {
      window.location.href = routes.emailConfirmed;
    }

    if (!isLoggedIn && window.location.pathname !== routes.login) {
      const nextPath = window.location.pathname + window.location.search;
      navigate(dynamicRoutes.loginWithRedirect(nextPath));
    }
  }, [isLoggedIn, navigate]);

  return children as JSX.Element;
};
