import { createGlobalStyle } from 'styled-components';
import Quicksand from 'assets/fonts/Quicksand-VariableFont_wght.ttf';
import { pxToRem } from 'utils/styleUtils';

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Quicksand';
    src: url(${Quicksand}) format('truetype');
    font-weight: 300 700;
  }

  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html {
    font-size: 100%;
    background-color:  ${({ theme }) => theme.colors.bgDarkNavy};
  }
  
  body {
    min-height: 100%;
    font-family: ${({ theme }) => theme.fonts.main};
    color: ${({ theme }) => theme.colors.white};
    background: ${({ theme }) => `linear-gradient(${theme.colors.bgDarkNavy}, ${theme.colors.bgDarkViolet});`};
    background-repeat: no-repeat;
    background-attachment:  fixed;
    width:100%;
    overflow: hidden;
  }

  button {
    border: none;
    background: none;
    font-family: ${({ theme }) => theme.fonts.main};
  }

  #root {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  input:-webkit-autofill, input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
  }

  input[data-autocompleted] {
    background-color: ${({ theme }) => theme.colors.opaqueBlack25} !important;
  }

  ::-webkit-scrollbar {
    width: 0.25rem;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.opaqueWhite5};
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.opaqueWhite50};
    border-radius: 1rem;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;
