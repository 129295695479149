import { useEffect, useState, useMemo } from 'react';
import {
  ATTRIBUTE_LEVEL_ROLL_SCHEMA,
  SKILL_LEVEL_ROLL_SCHEMA,
  DIFFICULTY_LEVEL_ROLL_SCHEMA,
  DiceType,
} from 'engine/rolls';
import { useSessionPlayersAPI } from 'hooks/sessionApi/useSessionPlayersAPI';
import { useGameplaySelector, SESSION_VIEW_TYPE, setCurrentView } from 'stores/gameplay.slice';
import { useAppDispatch } from 'stores/store';
import { OmitAutoGenerated } from 'models/Api';
import { SupabaseService } from 'services/SupabaseService';
import { DB_TABLE_NAMES } from 'utils/consts';
import { PendingAction } from 'models/db/instance_PendingAction';
import { HEALTH_STATE_ROLL_MODIFIERS, calculateCharacterHealthState } from 'engine/health';

export const useCallRoll = () => {
  const { sessionPlayersQuery } = useSessionPlayersAPI();
  const sessionPlayers = sessionPlayersQuery.data?.data ?? [];
  const { activeSession, activePlayer } = useGameplaySelector();
  const dispatch = useAppDispatch();

  // Roll Base - this data is required to call a roll
  const [selectedPlayerId, setSelectedPlayerId] = useState('');
  const [selectedAttributeId, setSelectedAttributeId] = useState('');
  const [selectedSkillId, setSelectedSkillId] = useState('');
  const [selectedDifficultyLevel, setSelectedDifficultyLevel] = useState(0);

  // Roll extras - this data is optional
  const [selectedInventoryModId, setSelectedInventoryModId] = useState('');
  const [rpBonusGranted, setRpBonusGranted] = useState(false);

  // Roll state - this data is recalculated on every change
  const [actionRollSchema, setActionRollSchema] = useState<DiceType[]>([]);
  const [difficultyRollSchema, setDifficultyRollSchema] = useState<DiceType[]>([]);

  // Handlers
  const handleSelectPlayer = (playerId: string) => setSelectedPlayerId((prev) => (prev === playerId ? '' : playerId));
  const handleSelectAttribute = (attributeId: string) =>
    setSelectedAttributeId((prev) => (prev === attributeId ? '' : attributeId));
  const handleSelectSkill = (skillId: string) => setSelectedSkillId((prev) => (prev === skillId ? '' : skillId));
  const handleSelectInventoryMod = (inventoryModId: string) =>
    setSelectedInventoryModId((prev) => (prev === inventoryModId ? '' : inventoryModId));
  const handleToggleRpBonusGranted = () => setRpBonusGranted((prev) => !prev);
  const handleSelectDifficultyLevel = (difficultyLevel: number) =>
    setSelectedDifficultyLevel((prev) => (prev === difficultyLevel ? 0 : difficultyLevel));

  const selectedPlayer = sessionPlayers.find((player) => player.character_id === selectedPlayerId);

  // Recalculate roll schema
  useEffect(() => {
    const attributeLevel = selectedPlayer?.character_schema?.attributes[selectedAttributeId] ?? 0;
    const skillLevel = selectedPlayer?.character_schema?.skills[selectedSkillId] ?? 0;
    let rollSchema = [...ATTRIBUTE_LEVEL_ROLL_SCHEMA[attributeLevel], ...SKILL_LEVEL_ROLL_SCHEMA[skillLevel]];

    if (rpBonusGranted) {
      rollSchema = [...rollSchema, 'D6'];
    }

    setActionRollSchema(rollSchema);
  }, [
    selectedAttributeId,
    selectedSkillId,
    rpBonusGranted,
    selectedPlayer?.character_schema?.attributes,
    selectedPlayer?.character_schema?.skills,
  ]);

  const inventoryMod = useMemo(
    () => selectedPlayer?.items.find((item) => item.id === selectedInventoryModId)?.content?.roll_modifier ?? 0,
    [selectedInventoryModId, selectedPlayer?.items],
  );

  // Recalculate difficulty schema
  useEffect(() => {
    const difficultySchema = DIFFICULTY_LEVEL_ROLL_SCHEMA[selectedDifficultyLevel];
    setDifficultyRollSchema(difficultySchema);
  }, [selectedDifficultyLevel]);

  const rollMeta = useMemo(() => {
    if (actionRollSchema.length > 0 && difficultyRollSchema.length > 0) {
      let actionRollMaximumValue = actionRollSchema
        .map((dice) => parseInt(dice.slice(1), 10))
        .reduce((acc, curr) => acc + curr, 0);

      if (inventoryMod > 0) {
        actionRollMaximumValue += inventoryMod;
      }

      const difficultyRollMaximumValue = difficultyRollSchema
        .map((dice) => parseInt(dice.slice(1), 10))
        .reduce((acc, curr) => acc + curr, 0);

      const absoluteDifference = Math.abs(difficultyRollMaximumValue - actionRollMaximumValue);

      let percentageOfDiffMax = (absoluteDifference / difficultyRollMaximumValue) * 100;

      if (difficultyRollMaximumValue > actionRollMaximumValue) {
        percentageOfDiffMax = -percentageOfDiffMax;
      }

      return {
        actionRollMaximumValue,
        difficultyRollMaximumValue,
        absoluteDifference,
        percentageOfDiffMax,
      };
    }
  }, [actionRollSchema, difficultyRollSchema, inventoryMod]);

  const handleCallRoll = async () => {
    if (selectedPlayerId && selectedPlayer) {
      await SupabaseService.from(DB_TABLE_NAMES.instancePendingAction).insert<OmitAutoGenerated<PendingAction>>([
        {
          recipient: activePlayer?.user_id ?? '',
          session_id: activeSession?.id ?? '',
          type: 'difficulty_roll',
          content: {
            attribute: selectedAttributeId,
            skill: selectedSkillId ?? null,
            player_res_modifier: inventoryMod,
            player_health_multiplier: selectedPlayer.health
              ? HEALTH_STATE_ROLL_MODIFIERS[calculateCharacterHealthState(selectedPlayer.health)]
              : 1,
            difficulty_level: selectedDifficultyLevel,
            player_character_id: selectedPlayer.character_id,
            difficulty_roll_schema: difficultyRollSchema,
            action_roll_schema: actionRollSchema,
          },
        },
      ]);

      dispatch(setCurrentView({ newView: SESSION_VIEW_TYPE.ROLL_DICE }));
    }
  };

  return {
    sessionPlayers,
    selectedPlayerAPI: {
      selectedPlayerId,
      handleSelectPlayer,
      selectedPlayer,
    },
    rollBaseAPI: {
      selectedAttributeId,
      handleSelectAttribute,
      selectedSkillId,
      handleSelectSkill,
      selectedDifficultyLevel,
      handleSelectDifficultyLevel,
    },
    rollExtrasAPI: {
      selectedInventoryModId,
      handleSelectInventoryMod,
      rpBonusGranted,
      handleToggleRpBonusGranted,
    },
    rollState: {
      actionRollSchema,
      difficultyRollSchema,
      inventoryMod,
      rollMeta,
    },
    isSubmitAllowed: !!selectedPlayerId && actionRollSchema.length > 0 && difficultyRollSchema.length > 0,
    handleCallRoll,
  } as const;
};
