import { useGameplaySelector } from 'stores/gameplay.slice';
import { getThemeConfigForThemeId } from 'engine/themesConfig';

export const useThemeConfig = () => {
  const { activeSession } = useGameplaySelector();

  return {
    themeConfig: getThemeConfigForThemeId(activeSession?.theme_id ?? ''),
  } as const;
};
