import { useState, useCallback } from 'react';
import { SupabaseService } from 'services/SupabaseService';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { RegisterBody, UsersService } from 'services/UsersService';
import { SSOProvider } from 'models/Api';
import { getEnv } from 'utils/getEnv';
import { LOWERCASE_REGEX, NUMERIC_REGEX, SPECIAL_CHARACTER_REGEX, UPPERCASE_REGEX } from 'utils/regex';

export const enum REGISTER_FORM_STEP {
  FORM,
  SUCCESS,
}

export const useRegisterView = () => {
  const { t } = useTranslation('launcher', { keyPrefix: 'form_errors' });

  const [formStep, setFormStep] = useState<REGISTER_FORM_STEP>(REGISTER_FORM_STEP.FORM);

  const formMutation = useMutation({
    mutationFn: (body: RegisterBody) =>
      UsersService.register(body).then(() => {
        setFormStep(REGISTER_FORM_STEP.SUCCESS);
      }),
  });

  const validationSchema = Yup.object().shape({
    displayName: Yup.string().required(t('field_required')),
    email: Yup.string().email(t('email_invalid')).required(t('field_required')),
    password: Yup.string()
      .required(t('field_required'))
      .matches(LOWERCASE_REGEX, t('password_lowercase_missing'))
      .matches(UPPERCASE_REGEX, t('password_uppercase_missing'))
      .matches(NUMERIC_REGEX, t('password_number_missing'))
      .matches(SPECIAL_CHARACTER_REGEX, t('password_special_character_missing'))
      .min(8, t('password_too_short')),
    confirmPassword: Yup.string()
      .required(t('field_required'))
      .oneOf([Yup.ref('password'), null], t('passwords_not_match')),
  });

  const formik = useFormik({
    initialValues: {
      displayName: '',
      email: '',
      password: '',
      confirmPassword: '',
    },
    validationSchema,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onSubmit: ({ confirmPassword, ...values }) =>
      formMutation.mutate({
        email: values.email,
        password: values.password,
        data: {
          displayName: values.displayName,
        },
      }),
  });

  const registerViaSSO = useCallback(
    (method: SSOProvider) =>
      SupabaseService.auth.signInWithOAuth({
        provider: method,
        options: {
          redirectTo: `${getEnv('REACT_APP_BASE_URL')}/oauth/success`,
        },
      }),
    [],
  );

  return { formik, formMutation, registerViaSSO, formStep } as const;
};
