import styled, { css } from 'styled-components';
import { pxToRem } from 'utils/styleUtils';

export const CardButton = styled.button<{ isManaging: boolean }>`
  text-align: left;
  cursor: pointer;
  position: relative;

  ${({ isManaging }) =>
    isManaging &&
    css`
      & > div > * {
        opacity: 0;
      }
    `}
`;

export const ManagingOverlay = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  transform: translateY(-50%);
  display: flex;
  padding: 0 1rem;
  justify-content: space-evenly;
  align-items: center;
  gap: 0.5rem;
  z-index: 10;

  & > * {
    opacity: 1 !important;
  }
`;

export const CloseButtonWrapper = styled.div`
  position: absolute;
  top: ${pxToRem(12)};
  right: ${pxToRem(12)};
  width: ${pxToRem(14)};
  z-index: 15;

  & > * {
    opacity: 1 !important;
  }
`;
