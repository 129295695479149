import React from 'react';
import { Text } from 'components/generics/Text/Text';
import { CardContent, CardHeader, CardWrapper } from './ResourceCard.styles';

interface ResourceCardProps {
  heading: string;
  children: React.ReactNode;
  onClick?: () => any;
  noPadding?: boolean;
}

export const ResourceCard = ({ heading, children, onClick, noPadding }: ResourceCardProps) => (
  <CardWrapper as={onClick ? 'button' : 'div'} onClick={onClick}>
    <CardHeader>
      <Text fontSize="tiny" fontWeight={700} textAlign="center" textTransform="uppercase" letterSpacing="wide">
        {heading}
      </Text>
    </CardHeader>
    <CardContent noPadding={noPadding}>{children}</CardContent>
  </CardWrapper>
);
