/* eslint-disable max-len */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SESSION_VIEW_TYPE } from 'stores/gameplay.slice';
import { Text } from 'components/generics/Text/Text';
import { BlandButton } from 'components/generics/Button/BlandButton';
import { LocationSelectOverlay } from 'views/CreateSessionView/LocationSelectOverlay/LocationSelectOverlay';
import { useGameplayNavigation } from 'hooks/useGameplayNavigation';
import { ResourceCard } from 'components/ResourceCard/ResourceCard';
import {
  ButtonsWrapper,
  ExpandedWrapper,
  FilterTab,
  FiltersWrapper,
  LocationContainer,
  LocationImage,
  NameWrapper,
  NoLocationWrapper,
  UnexpandedWrapper,
  Wrapper,
} from './StoryView.styles';
import { StoryCard } from '../DrawStoryCardView/components/StoryCard/StoryCard';
import { useSessionStoryView } from './StoryView.hooks';

export const StoryView = () => {
  const [isActiveLocationCardExpanded, setIsActiveLocationCardExpanded] = useState(false);
  const [currentlyManagedStoryCardId, setCurrentlyManagedStoryCardId] = useState<string | undefined>(undefined);

  const { t } = useTranslation('gameplay', { keyPrefix: 'story_view' });
  const { t: tLocationPacks } = useTranslation('locationPacks' as 'resource');
  const { t: tLocation } = useTranslation('locations' as 'resource');
  const { goToView } = useGameplayNavigation();

  const {
    isChangingLocation,
    activeSession,
    activeLocation,
    setIsChangingLocation,
    selectedLocation,
    selectedLocationPack,
    handleLocationChange,
    handleLocationSelection,
    handleLocationPackSelection,
    locationPack,
    storyCardFilter,
    setStoryCardFilter,
    filteredStoryCards,
    storyCards,
  } = useSessionStoryView();

  return (
    <Wrapper>
      {isChangingLocation ? (
        <LocationSelectOverlay
          themeId={activeSession?.theme_id ?? ''}
          isOverlayActive={isChangingLocation}
          toggleOverlay={() => setIsChangingLocation((prev) => !prev)}
          selectedLocation={selectedLocation}
          selectedLocationPack={selectedLocationPack}
          handleLocationSelection={handleLocationSelection}
          handleLocationPackSelection={handleLocationPackSelection}
          handleConfirmAction={handleLocationChange}
        />
      ) : (
        <>
          <Text fontSize="bigger" fontWeight={500} letterSpacing="wide">
            {t('label')}
          </Text>
          <LocationContainer>
            {activeLocation ? (
              <ResourceCard
                heading={t('location_card.location_tag')}
                onClick={() => setIsActiveLocationCardExpanded((prev) => !prev)}
                noPadding
              >
                <UnexpandedWrapper>
                  <LocationImage src={activeLocation?.cover_img_url} loading="lazy" />
                  <NameWrapper>
                    <Text fontSize="smaller" fontWeight={700} color="opaqueWhite50" textTransform="uppercase">
                      {`${tLocationPacks(`${activeSession?.theme_id}.${locationPack?.t_key}.name` as any, '')}`}
                    </Text>
                    <Text fontSize="small" fontWeight={700} textTransform="uppercase">
                      {tLocation(
                        `${activeSession?.theme_id}.${locationPack?.t_key}.${activeLocation?.t_key}.title` as any,
                      )}
                    </Text>
                  </NameWrapper>
                </UnexpandedWrapper>
                {isActiveLocationCardExpanded && (
                  <ExpandedWrapper>
                    <Text fontSize="smaller">
                      {tLocation(
                        `${activeSession?.theme_id}.${locationPack?.t_key}.${activeLocation?.t_key}.description` as any,
                      )}
                    </Text>

                    {isActiveLocationCardExpanded && activeLocation.content && (
                      <>
                        <br />
                        <Text fontSize="small" fontWeight={600}>
                          {t('location_card.visuals_label')}
                        </Text>
                        {activeLocation.content.visuals_len &&
                          Array.from({ length: activeLocation.content.visuals_len }, (x, i) => i).map((numkey) => (
                            <Text key={`active-card-visuals-${numkey}`} fontSize="smaller" fontWeight={400}>
                              {tLocation(
                                `${activeSession?.theme_id}.${locationPack?.t_key}.${activeLocation?.t_key}.visuals.${numkey}` as any,
                              )}
                            </Text>
                          ))}
                      </>
                    )}
                    {isActiveLocationCardExpanded && activeLocation.content && (
                      <>
                        <br />
                        <Text fontSize="small" fontWeight={600}>
                          {t('location_card.dangers_label')}
                        </Text>
                        {activeLocation.content.dangers_len &&
                          Array.from({ length: activeLocation.content.dangers_len }, (x, i) => i).map((numkey) => (
                            <Text key={`active-card-visuals-${numkey}`} fontSize="smaller" fontWeight={400}>
                              {tLocation(
                                `${activeSession?.theme_id}.${locationPack?.t_key}.${activeLocation?.t_key}.dangers.${numkey}` as any,
                              )}
                            </Text>
                          ))}
                      </>
                    )}
                    {isActiveLocationCardExpanded && activeLocation.content && (
                      <>
                        <br />
                        <Text fontSize="small" fontWeight={600}>
                          {t('location_card.characters_and_encounters_label')}
                        </Text>
                        {activeLocation.content.encounters_len &&
                          Array.from({ length: activeLocation.content.encounters_len }, (x, i) => i).map((numkey) => (
                            <Text key={`active-card-visuals-${numkey}`} fontSize="smaller" fontWeight={400}>
                              {tLocation(
                                `${activeSession?.theme_id}.${locationPack?.t_key}.${activeLocation?.t_key}.encounters.${numkey}` as any,
                              )}
                            </Text>
                          ))}
                      </>
                    )}
                  </ExpandedWrapper>
                )}
              </ResourceCard>
            ) : (
              <NoLocationWrapper>
                <Text color="opaqueWhite50" fontSize="small" fontWeight={600}>
                  {t('location_card.missing_location')}
                </Text>
              </NoLocationWrapper>
            )}
          </LocationContainer>
          <ButtonsWrapper>
            <BlandButton onClick={() => setIsChangingLocation((prev) => !prev)}>
              {t('change_location_button')}
            </BlandButton>
            {activeLocation && (
              <BlandButton onClick={() => goToView(SESSION_VIEW_TYPE.DRAW_STORY_CARD)}>
                {t('draw_story_card_button')}
              </BlandButton>
            )}
          </ButtonsWrapper>
        </>
      )}
      {!isChangingLocation && storyCards && storyCards.length > 0 && (
        <>
          <Text
            fontWeight={500}
            color="opaqueWhite50"
            fontSize="smaller"
            letterSpacing="superWide"
            textTransform="uppercase"
          >
            {t('active_story_cards_label')}
          </Text>
          <FiltersWrapper>
            <FilterTab isSelected={!storyCardFilter} onClick={() => setStoryCardFilter(undefined)}>
              {t('story_cards_options.all')}
            </FilterTab>
            <FilterTab isSelected={storyCardFilter === 'event'} onClick={() => setStoryCardFilter('event')}>
              {t('story_cards_options.events')}
            </FilterTab>
            <FilterTab isSelected={storyCardFilter === 'task'} onClick={() => setStoryCardFilter('task')}>
              {t('story_cards_options.tasks')}
            </FilterTab>
            <FilterTab isSelected={storyCardFilter === 'encounter'} onClick={() => setStoryCardFilter('encounter')}>
              {t('story_cards_options.encounters')}
            </FilterTab>
            <FilterTab isSelected={storyCardFilter === 'threat'} onClick={() => setStoryCardFilter('threat')}>
              {t('story_cards_options.threats')}
            </FilterTab>
          </FiltersWrapper>
          {filteredStoryCards.map((card) => (
            <StoryCard
              key={card.id}
              storyCard={card}
              isManagable
              isManaging={card.id === currentlyManagedStoryCardId}
              toggleManaging={() => setCurrentlyManagedStoryCardId((prev) => (prev === card.id ? undefined : card.id))}
            />
          ))}
        </>
      )}
    </Wrapper>
  );
};
