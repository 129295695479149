import React from 'react';
import { Text } from 'components/generics/Text/Text';
import { useTranslation } from 'react-i18next';
import { ReactComponent as RerollIcon } from 'assets/images/svg/icon_dice.svg';
import { ReactComponent as ClearIcon } from 'assets/images/svg/icon_clear.svg';
import { Container, ButtonGroup, Button } from './Randomizer.styles';

interface RandomizerProps {
  label: string;
  value: string;
  onClear?: () => any;
  onReroll: () => any;
}

export const Randomizer = ({ label, value, onClear, onReroll }: RandomizerProps) => {
  const { t } = useTranslation('gameplay', { keyPrefix: 'randomizer' });

  return (
    <Container>
      <Text color="opaqueWhite50">{label}</Text>
      <Text fontSize="small">{value || t('no_value_provided')}</Text>
      <ButtonGroup>
        {onClear && (
          <Button onClick={onClear}>
            <ClearIcon /> {t('clear_button')}
          </Button>
        )}
        <Button onClick={onReroll}>
          <RerollIcon />
          {value ? t('reroll_button') : t('roll_button')}
        </Button>
      </ButtonGroup>
    </Container>
  );
};
