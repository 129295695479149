export const DB_TABLE_NAMES = {
  dbPlayerCharacter: 'db_PlayerCharacter',
  dbProfile: 'db_Profile',
  dbSession: 'db_Session',
  dbSessionRecord: 'db_SessionRecord',
  dbPlayerCharacterPerk: 'db_PlayerCharacterPerk',
  dbPlayerCharacterItem: 'db_PlayerCharacterItem',
  dbPlayerCharacterSkill: 'db_PlayerCharacterSkill',
  instancePendingAction: 'instance_PendingAction',
  instanceSessionPlayer: 'instance_SessionPlayer',
  instancePlayerItem: 'instance_PlayerItem',
  instanceStoryCard: 'instance_StoryCard',
  resourceTheme: 'resource_Theme',
  resourceCharacterClass: 'resource_CharacterClass',
  resourceCharacterPerk: 'resource_CharacterPerk',
  resourceItem: 'resource_Item',
  resourceSkill: 'resource_Skill',
  resourceStoryCard: 'resource_StoryCard',
  resourceLocationPack: 'resource_LocationPack',
  resourceLocation: 'resource_Location',
  resourcePortraitPack: 'resource_PortraitPack',
} as const;

export const QUERY_KEYS = {
  gameplay: {
    sessionData: 'sessionData',
    activeLocation: 'activeLocation',
    activeLocationPack: 'activeLocationPack',
    getLocationPackResourceData: 'getLocationPackResourceData',
    sessionPlayers: 'sessionPlayers',
    sessionRecords: 'sessionRecords',
    sessionPendingActions: 'sessionPendingActions',
    storyCards: 'storyCards',
  },
  launcher: {
    sessionsList: 'sessionsList',
    charactersList: 'charactersList',
    themes: 'getThemes',
    characterClassesForTheme: 'getCharacterClassesForTheme',
    characterPerksForTheme: 'getCharacterPerksForTheme',
    itemsForTheme: 'getItemsForTheme',
    portraitPacksForTheme: 'getPortraitPacksForTheme',
  },
  channels: {
    sessionRecords: (sessionId: string) => `session-log:${sessionId}`,
    pendingActions: (sessionId: string) => `pending-actions:${sessionId}`,
    sessionPlayers: (sessionId: string) => `session-players:${sessionId}`,
  },
} as const;
