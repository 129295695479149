import { NAVMENU_HEIGHT } from 'assets/styles/theme';
import styled, { css } from 'styled-components';
import { pxToRem } from 'utils/styleUtils';

export const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 0.25rem;
  width: 100%;
`;

export const Selector = styled.button`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  border-radius: ${({ theme }) => theme.border.radiusBase};
  border: ${({ theme }) => `${theme.border.base} ${theme.colors.opaqueWhite25}`};
  background-color: ${({ theme }) => theme.colors.opaqueBlack25};
  color: ${({ theme }) => theme.colors.white};
  font: inherit;
  font-size: ${({ theme }) => theme.fontSizes.medium};
  padding: 0.8rem;
  cursor: pointer;

  svg {
    stroke: ${({ theme }) => theme.colors.opaqueWhite50};
  }

  &:hover {
    svg {
      stroke: ${({ theme }) => theme.colors.white};
    }
  }
`;

export const Overlay = styled.div`
  margin-left: -1rem;
  margin-top: -1rem;
  width: calc(100% + 2rem);
  height: calc(100vh - ${NAVMENU_HEIGHT});
  overflow-y: scroll;
  background: ${({ theme }) => `linear-gradient(${theme.colors.bgDarkNavy}, ${theme.colors.bgDarkViolet})`};
  padding: 1.5rem;
  padding-bottom: ${NAVMENU_HEIGHT};
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;
`;

export const OptionVerticalLayout = styled.button<{ isSelected?: boolean }>`
  display: flex;
  flex-flow: column nowrap;
  gap: 0.75rem;
  background-color: ${({ theme }) => theme.colors.opaqueWhite5};
  padding: 0.75rem;
  border-radius: ${({ theme }) => theme.border.radiusBase};
  cursor: pointer;

  ${({ isSelected }) =>
    isSelected &&
    css`
      outline: ${({ theme }) => `${theme.border.base} ${theme.colors.primary}`};
    `}
`;

export const CoverImageVerticalLayout = styled.img`
  width: 100%;
  border-radius: ${pxToRem(16)};
`;

export const OptionHorizontalLayout = styled(OptionVerticalLayout)`
  flex-flow: row nowrap;
  align-items: center;
  text-align: left;
`;

export const CoverImageHorizontalLayout = styled.img`
  min-width: ${pxToRem(100)};
  width: ${pxToRem(100)};
  height: ${pxToRem(100)};
  border-radius: ${pxToRem(16)};
`;

export const ConfirmButtonWrapper = styled.div`
  position: absolute;
  bottom: 6rem;
  left: 50%;
  transform: translateX(-50%);

  button {
    text-transform: uppercase;
  }
`;
