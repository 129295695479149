import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';
import { useSessionPlayersAPI } from 'hooks/sessionApi/useSessionPlayersAPI';
import { DB_TABLE_NAMES } from 'utils/consts';
import { SupabaseService } from 'services/SupabaseService';
import { useGameplaySelector } from 'stores/gameplay.slice';
import { DICE_ROLL_SCHEMA, DiceType, rollDice } from 'engine/rolls';
import { OmitAutoGenerated } from 'models/Api';
import {
  PendingAction,
  PendingActionRoll,
  PendingActionType,
  PendingDifficultyRoll,
} from 'models/db/instance_PendingAction';
import { SessionRecord } from 'models/db/db_SessionRecord';
import { usePendingActionsAPI } from 'hooks/sessionApi/usePendingActionsAPI';
import { useIsGameMaster } from 'hooks/useIsGameMaster';

export type SelectableRoll = {
  value: string;
  label: string;
  rollScheme?: number[][];
  type: 'difficulty_roll' | 'action_roll' | 'free_roll';
  createdAt: string;
};

// TODO REFACTOR: Remove 'as any', fix types
export const calculateDiceCount = (rollScheme: number[][]) => {
  const diceCount = {} as any;

  rollScheme.forEach(([count, sides]) => {
    const diceType = Object.entries(DICE_ROLL_SCHEMA).find(([, [min, max]]) => min === 1 && max === sides);

    if (diceType) {
      const [type] = diceType;
      diceCount[type] = diceCount[type] ? diceCount[type] + count : count;
    }
  });

  const diceString = Object.entries(diceCount)
    .sort(([typeA], [typeB]) => {
      const sidesA = parseInt(typeA.slice(1), 10);
      const sidesB = parseInt(typeB.slice(1), 10);
      return sidesA - sidesB;
    })
    .map(([type, count]) => `${(count as number) > 1 ? count : ''}d${parseInt(type.slice(1), 10)}`)
    .join(' + ');

  return diceString;
};

export const useRollDice = () => {
  const { t } = useTranslation('gameplay', { keyPrefix: 'roll_dice_view' });
  const { t: tThemesIndex } = useTranslation('themesIndex');

  // Redux state and dispatch
  const { activeSession, activePlayer, rollIdToSelect } = useGameplaySelector();
  const { sessionPlayersQuery, getters } = useSessionPlayersAPI();
  const sessionPlayers = useMemo(() => sessionPlayersQuery.data?.data ?? [], [sessionPlayersQuery.data]);
  const { pendingActionsQuery } = usePendingActionsAPI();
  const pendingActions = pendingActionsQuery.data?.data ?? [];
  const { isGameMaster } = useIsGameMaster();

  // State variables
  const [selectedRoll, setSelectedRoll] = useState<SelectableRoll | null>(null);
  const [selectedRollMeta, setSelectedRollMeta] = useState<
    (PendingActionRoll & PendingDifficultyRoll & { rollType: PendingActionType }) | null
  >(null);
  const [dicesOnBoard, setDicesOnBoard] = useState<{ id: string; type: DiceType }[]>([]);
  const [dicesAreCast, setDicesAreCast] = useState(false);
  const [rollResults, setRollResults] = useState<{ id: string; diceType: DiceType; result: number }[]>([]);
  const [resultsSum, setResultsSum] = useState(0);

  const calledRolls = pendingActions.filter(
    (action) => action.type === 'difficulty_roll' && action.recipient === activePlayer?.user_id,
  );
  const pendingRolls = pendingActions.filter(
    (action) => action.type === 'action_roll' && action.recipient === activePlayer?.user_id,
  );

  useEffect(() => {
    if (!selectedRollMeta) {
      const matchedRoll = [...calledRolls, ...pendingRolls].find((roll) => roll.id === selectedRoll?.value);

      if (!matchedRoll) return;

      setSelectedRollMeta({ ...matchedRoll.content, rollType: matchedRoll.type } as any);
    }
  }, [calledRolls, pendingRolls, selectedRoll?.value, selectedRollMeta]);

  // Handle selection of a roll
  // TODO REFACTOR: Remove 'as any'
  const handleRollSelect = (roll: any) => {
    setSelectedRollMeta(null);
    setSelectedRoll(roll);
  };

  // Add a dice to the board
  const addDice = (type: DiceType) => {
    if (dicesOnBoard.length >= 24) return;
    setDicesOnBoard((dices) => [
      ...dices,
      {
        id: v4(),
        type,
      },
    ]);
  };

  // Remove a dice from the board
  const removeDice = (id: string) => {
    if (dicesOnBoard.length <= 0) return;
    setDicesOnBoard((dices) => dices.filter((dice) => dice.id !== id));
  };

  // Roll the dices on the board
  const rollDices = async () => {
    if (!activeSession || !selectedRoll) return;

    const results = dicesOnBoard.map((dice) => ({
      diceType: dice.type,
      result: rollDice(dice.type),
    }));

    const sum = results.reduce((acc, curr) => acc + curr.result, 0);

    setDicesAreCast(true);
    setRollResults(results.map((result) => ({ ...result, id: v4() })));
    setResultsSum(sum);

    switch (selectedRoll.type) {
      case 'difficulty_roll': {
        const calledRoll = calledRolls.find((roll) => roll.id === selectedRoll?.value);

        if (calledRoll) {
          const calledPlayer = getters.getPlayerCharacterById(calledRoll?.content.player_character_id);

          if (calledPlayer && calledPlayer.health && calledPlayer.character_schema) {
            await SupabaseService.from(DB_TABLE_NAMES.instancePendingAction).insert<OmitAutoGenerated<PendingAction>>([
              {
                recipient: calledPlayer.owner_id,
                session_id: activeSession?.id,
                type: 'action_roll',
                content: {
                  roll_identifier: calledRoll?.id,
                  player_character_id: calledRoll?.content.player_character_id,
                  skill: calledRoll?.content.skill,
                  attribute: calledRoll?.content.attribute,
                  difficulty_value: sum,
                  difficulty_level: Number(calledRoll?.content.difficulty_level),
                  action_roll_schema: calledRoll.content.action_roll_schema,
                  player_res_modifier: calledRoll.content.player_res_modifier,
                  player_health_multiplier: calledRoll.content.player_health_multiplier,
                },
              },
            ]);

            await SupabaseService.from(DB_TABLE_NAMES.dbSessionRecord).insert<OmitAutoGenerated<SessionRecord>>([
              {
                player_character_id: undefined,
                session_id: activeSession?.id,
                record_type: 'call_roll',
                content: {
                  rollId: calledRoll?.id,
                  playerCharacterId: calledRoll.content.player_character_id ?? '',
                  skill: calledRoll?.content.skill,
                  attribute: calledRoll?.content.attribute,
                  difficultyLevel: Number(calledRoll?.content.difficulty_level),
                  resultsSum: sum,
                  results,
                },
              },
            ]);
          }

          await SupabaseService.from(DB_TABLE_NAMES.instancePendingAction).delete().eq('id', calledRoll?.id);
        }
        break;
      }
      case 'action_roll': {
        // Handle action roll
        const actionRoll = pendingRolls.find((roll) => roll.id === selectedRoll?.value);
        if (!actionRoll || !actionRoll.content) return;

        let finalSum = sum;

        if (actionRoll.content.player_res_modifier) {
          finalSum += actionRoll.content.player_res_modifier;
        }

        if (actionRoll.content.player_health_multiplier) {
          finalSum = Math.round(finalSum * actionRoll.content.player_health_multiplier);
        }

        if (actionRoll.content.player_health_multiplier === 0) {
          finalSum = 0;
        }

        setResultsSum(finalSum);

        const activePlayerCharacter = getters.getActivePlayerCharacter();
        if (
          activePlayer &&
          !isGameMaster &&
          activePlayerCharacter &&
          activePlayerCharacter.health &&
          activePlayerCharacter.character_schema
        ) {
          const content: PendingActionRoll = actionRoll.content as PendingActionRoll;

          await SupabaseService.from(DB_TABLE_NAMES.dbSessionRecord).insert<OmitAutoGenerated<SessionRecord>>([
            {
              player_character_id: activePlayer?.character_id ?? undefined,
              session_id: activeSession?.id,
              record_type: 'action_roll',
              content: {
                rollId: actionRoll.id,
                attribute: content.attribute,
                skill: content.skill,
                difficultyLevel: Number(content.difficulty_level),
                difficultyValue: content.difficulty_value,
                resultsSum: finalSum,
                results,
                modifier: content.player_res_modifier,
                multiplier: content.player_health_multiplier,
              },
            },
          ]);
        }

        await SupabaseService.from(DB_TABLE_NAMES.instancePendingAction).delete().eq('id', actionRoll?.id);
        break;
      }
      default: {
        // Handle free roll
        await SupabaseService.from(DB_TABLE_NAMES.dbSessionRecord).insert<OmitAutoGenerated<SessionRecord>>(
          isGameMaster
            ? [
                {
                  session_id: activeSession?.id,
                  record_type: 'free_roll',
                  content: { results },
                },
              ]
            : [
                {
                  player_character_id: activePlayer?.character_id,
                  session_id: activeSession?.id,
                  record_type: 'free_roll',
                  content: { results },
                },
              ],
        );
      }
    }
  };

  const calledRollsTransformed = useMemo(
    () =>
      calledRolls.map((roll) => {
        const content: PendingDifficultyRoll = roll.content as PendingDifficultyRoll;
        const playerCharacter = sessionPlayers.find((player) => player.character_id === content.player_character_id);

        return {
          value: roll.id,
          type: roll.type,
          createdAt: roll.created_at,
          label: `${t('roll_types.difficulty_roll')}: ${playerCharacter?.name} | ${
            content.skill
              ? tThemesIndex(`${activeSession?.theme_id}.character_skills.${content.skill}.title` as any)
              : tThemesIndex(`${activeSession?.theme_id}.character_attributes.${content.attribute}.title` as any)
          }`,
          rollScheme: content.difficulty_roll_schema.map((dice) => DICE_ROLL_SCHEMA[dice]),
        };
      }),
    [calledRolls, activeSession?.theme_id, sessionPlayers, t, tThemesIndex],
  );

  const pendingRollsTransformed = useMemo(
    () =>
      pendingRolls
        .filter((roll) => roll.content.player_character_id === activePlayer?.character_id)
        .map((roll) => {
          const content: PendingActionRoll = roll.content as PendingActionRoll;

          return {
            value: roll.id,
            type: roll.type,
            createdAt: roll.created_at,
            label: `${t('roll_types.action_roll')}: ${
              content.skill
                ? tThemesIndex(`${activeSession?.theme_id}.character_skills.${content.skill}.title` as any)
                : tThemesIndex(`${activeSession?.theme_id}.character_attributes.${content.attribute}.title` as any)
            }`,
            rollScheme: content.action_roll_schema.map((dice) => DICE_ROLL_SCHEMA[dice]),
          };
        }),
    [activePlayer?.character_id, pendingRolls, activeSession?.theme_id, t, tThemesIndex],
  );

  const selectRollFromRedirection = () => {
    if (rollIdToSelect && !selectedRoll) {
      const rollId = pendingRolls.find((roll) => roll.content.roll_identifier === rollIdToSelect)?.id;
      if (rollId) {
        const roll = pendingRollsTransformed.find((roll) => roll.value === rollId);
        if (roll) {
          handleRollSelect(roll);
        }
      }
    }
  };

  const selectDiceFromSelectedRollSchema = () => {
    setDicesOnBoard([]);
    const rollSchema = selectedRoll?.rollScheme;

    rollSchema?.forEach(([count, sides]) => {
      const diceType = Object.entries(DICE_ROLL_SCHEMA).find(([, [min, max]]) => min === 1 && max === sides);

      if (diceType) {
        const [type] = diceType;
        addDice(type as DiceType);
      }
    });
  };

  useEffect(selectRollFromRedirection, [
    activePlayer?.character_id,
    selectedRoll,
    pendingRolls,
    pendingRollsTransformed,
    rollIdToSelect,
  ]);

  return {
    // State variables
    dicesOnBoard,
    dicesAreCast,
    rollResults,
    resultsSum,
    selectedRoll,
    setSelectedRoll,
    selectedRollMeta,

    // Event handlers
    handleRollSelect,
    addDice,
    removeDice,
    rollDices,
    selectDiceFromSelectedRollSchema,

    // Roll options for dropdowns
    calledRolls: calledRollsTransformed,
    pendingRolls: pendingRollsTransformed,
  };
};
