import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { SessionService } from 'services/SessionService';
import { useGameplaySelector } from 'stores/gameplay.slice';
import { QUERY_KEYS } from 'utils/consts';

const PER_PAGE = 15;

export const useSessionRecordsAPI = ({ handleOnSuccess }: { handleOnSuccess?: () => any }) => {
  const { activeSession } = useGameplaySelector();
  const queryClient = useQueryClient();

  const [isFetchingMoreRecords, setIsFetchingMoreRecords] = useState(false);
  const [canFetchMoreRecords, setCanFetchMoreRecords] = useState(false);

  const sessionId = activeSession?.id;

  const sessionRecordsQuery = useQuery(
    [QUERY_KEYS.gameplay.sessionRecords, sessionId],
    () => SessionService.getSessionRecords(sessionId ?? '', 0, PER_PAGE - 1),
    {
      onSuccess: (data) => {
        if (handleOnSuccess) handleOnSuccess();
        if (data.data.length === PER_PAGE) {
          setCanFetchMoreRecords(true);
        }
      },
      enabled: !!sessionId,
    },
  );

  const fetchMoreSessionRecords = async (offset: number) => {
    setIsFetchingMoreRecords(true);
    const from = offset;
    const to = from + PER_PAGE - 1;

    const { data } = await SessionService.getSessionRecords(sessionId ?? '', from, to);

    queryClient.setQueryData([QUERY_KEYS.gameplay.sessionRecords, sessionId], (oldData: any) => ({
      data: [...data, ...oldData.data],
    }));

    if (data.length === 0) {
      setCanFetchMoreRecords(false);
    }

    setIsFetchingMoreRecords(false);
  };

  return {
    sessionRecordsQuery,
    isFetchingMoreRecords,
    canFetchMoreRecords,
    getters: {
      fetchMoreSessionRecords,
    },
  } as const;
};
