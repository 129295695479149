import React from 'react';
import { Text } from 'components/generics/Text/Text';
import { Selector, SelectedPortrait, PortraitHintWrapper, SelectorPromptIcon } from './PortraitSelector.styles';

interface PortraitSelectorProps {
  onSelect?: () => any;
  imageSrc?: string;
}

export const PortraitSelector = ({ imageSrc, onSelect }: PortraitSelectorProps) => (
  <Selector onClick={onSelect}>
    <SelectedPortrait src={imageSrc} isSelected={!!imageSrc} loading="lazy" />
    {!imageSrc && (
      <PortraitHintWrapper>
        <Text fontSize="large" fontWeight={700} color="opaqueWhite50">
          N/A
        </Text>
      </PortraitHintWrapper>
    )}
    {onSelect && <SelectorPromptIcon />}
  </Selector>
);
