import React, { useState, useRef, useEffect } from 'react';
import { Text } from 'components/generics/Text/Text';
import { ColorButton } from 'components/generics/Button/ColorButton';
import { Container, OptionsList, Option, ConfirmButtonWrapper, SubheadingWrapper } from './PortraitSelect.styles';

interface OptionWithPreviewProps {
  optionId: string;
  optionCoverImg: string;
  taggedForSelectionId: string;
  tagForSelection: (optionId: string) => any;
}

const OptionWithPreview = ({
  optionId,
  optionCoverImg,
  taggedForSelectionId,
  tagForSelection,
}: OptionWithPreviewProps) => {
  const previewRef = useRef<HTMLButtonElement>(null);

  return (
    <Option
      ref={previewRef}
      key={optionId}
      isSelected={taggedForSelectionId === optionId}
      onClick={() => tagForSelection(optionId)}
      previewPositionX={() => {
        const viewportHalfWidth = window.innerWidth / 2;
        const previewWidth = previewRef.current?.getBoundingClientRect().left || 0;

        return previewWidth > viewportHalfWidth ? -1.5 : 1.5;
      }}
    >
      <img src={optionCoverImg} alt={optionId} loading="lazy" />
    </Option>
  );
};

interface PortraitSelectProps {
  selectedOptionId?: string;
  onSelect: (optionId: string) => any;
  toggleOverlay: () => any;
  heading?: string;
  subheading?: React.ReactNode;
  confirmButtonText: string;
  options: {
    id: string;
    coverImg: string;
  }[];
}

export const PortraitSelect = ({
  selectedOptionId,
  onSelect,
  toggleOverlay,
  heading,
  subheading,
  confirmButtonText,
  options,
}: PortraitSelectProps) => {
  const [taggedForSelectionId, setTaggedForSelectionId] = useState<string>('');

  const tagForSelection = (optionId: string) =>
    setTaggedForSelectionId((current) => (current === optionId ? '' : optionId));

  const confirmSelection = () => {
    onSelect(taggedForSelectionId);
    toggleOverlay();
  };

  useEffect(() => {
    if (selectedOptionId) {
      setTaggedForSelectionId(selectedOptionId);
    }
  }, [selectedOptionId]);

  return (
    <Container>
      {heading && (
        <Text fontSize="bigger" fontWeight={500}>
          {heading}
        </Text>
      )}
      {subheading && <SubheadingWrapper>{subheading}</SubheadingWrapper>}
      <OptionsList>
        {options.map((option) => (
          <OptionWithPreview
            key={option.id}
            optionId={option.id}
            optionCoverImg={option.coverImg}
            tagForSelection={tagForSelection}
            taggedForSelectionId={taggedForSelectionId}
          />
        ))}
      </OptionsList>
      <ConfirmButtonWrapper>
        <ColorButton isDisabled={!taggedForSelectionId} onClick={confirmSelection}>
          {confirmButtonText}
        </ColorButton>
      </ConfirmButtonWrapper>
    </Container>
  );
};
