import React from 'react';
import { Text } from 'components/generics/Text/Text';
import { OmitAutoGenerated } from 'models/Api';
import { Item } from 'models/db/resource_Item';
import { useTranslation } from 'react-i18next';

interface Props {
  selectedItem: OmitAutoGenerated<Item>;
  themeId: string;
}

export const ItemContent = ({ selectedItem, themeId }: Props) => {
  const { t } = useTranslation('items' as 'resource');
  const { type, content, t_key } = selectedItem;

  return (
    <div style={{ width: '90%' }}>
      <Text fontSize="tiny" fontWeight={600} color="opaqueWhite50" textTransform="uppercase" letterSpacing="superWide">
        {t(`${themeId}.${type}.${content?.subtype}.label` as any)}
      </Text>
      <br />
      <Text fontWeight={600}>{t(`${themeId}.${type}.${content?.subtype}.${t_key}.name` as any)}</Text>
      <Text fontSize="small" color="opaqueWhite75">
        {t(`${themeId}.${type}.${content?.subtype}.${t_key}.description` as any)}
      </Text>
      <br />

      {content?.roll_modifier && <Text fontWeight={600}>{`RES +${content.roll_modifier}`}</Text>}
    </div>
  );
};
