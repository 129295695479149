import React from 'react';
import { TabButtonsGroup, TabButton } from './TabButtons.styles';

export interface TabButton {
  id: string;
  label: string;
  active: boolean;
  onClick: () => any;
}

export const TabButtons = ({ tabs }: { tabs: TabButton[] }) => (
  <TabButtonsGroup>
    {tabs.map((tab) => (
      <TabButton key={tab.id} active={tab.active} onClick={tab.onClick}>
        {tab.label}
      </TabButton>
    ))}
  </TabButtonsGroup>
);
