import React from 'react';
import { useEventListener } from 'hooks/useEventListener';
import { MessageContainer, MessageEditable } from './MessageInput.styles';

export interface MessageInputProps {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => any;
  onEnterPress?: (event: KeyboardEvent) => any;
  toggleFocus?: () => any;
}

export const MessageInput = ({ value, onChange, toggleFocus, onEnterPress }: MessageInputProps) => {
  useEventListener('keydown', (event: KeyboardEvent) => {
    const { key, shiftKey } = event;

    if (onEnterPress && key === 'Enter' && !shiftKey) {
      event.preventDefault();
      onEnterPress(event);
    }
  });

  return (
    <MessageContainer>
      <MessageEditable value={value} onChange={onChange} onFocus={toggleFocus} onBlur={toggleFocus} />
    </MessageContainer>
  );
};
