import React, { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from 'components/generics/Text/Text';
import { PortraitSelector } from 'components/PortraitSelector/PortraitSelector';
import { TextInput } from 'components/generics/Form/TextInput/TextInput';
import { Select } from 'components/generics/Select/Select';
import { ReactComponent as IconSwap } from 'assets/images/svg/icon_swap_horizontal.svg';
import { ColorButton } from 'components/generics/Button/ColorButton';
import { FullScreenSelector } from 'components/FullScreenSelector/FullScreenSelector';
import { Option } from 'views/Gameplay/ManagementView/ManagementView';
import { Container, PerkSelectionGroup, PerkSelector, SubmitButtonWrapper } from '../CharacterCreatorView.styles';
import { useCharacterCreator } from '../CharacterCreator.hooks';
import { CharacterPerkContent } from '../components/CharacterPerkContent';

interface MainFormProps
  // TODO REFACTOR: Rework this type
  extends Pick<
    ReturnType<typeof useCharacterCreator>,
    | 'themeApi'
    | 'characterClassApi'
    | 'portraitApi'
    | 'characterNameApi'
    | 'characterPerksApi'
    | 'characterAgeApi'
    | 'isSubmitAllowed'
  > {
  setActiveTabId: (id: string) => any;
}

const MainForm = ({
  themeApi,
  characterClassApi,
  portraitApi,
  characterNameApi,
  characterPerksApi,
  characterAgeApi,
  setActiveTabId,
  isSubmitAllowed,
}: MainFormProps) => {
  const { t } = useTranslation('launcher', { keyPrefix: 'character_creator_view' });
  const submitButtonRef = useRef<HTMLDivElement>(null);

  const { themesAsOptions, selectedThemeId } = themeApi;
  const { classesAsOptions, selectedClassId } = characterClassApi;
  const { portraitPacksAsOptions, selectablePortraits, selectedPortraitId } = portraitApi;
  const { name, handleNameChange, isCharacterNameAllowed } = characterNameApi;
  const { characterAge, handleCharacterAgeSelection } = characterAgeApi;
  const {
    selectedOriginPerk,
    selectedExperiencePerks,
    selectedPersonalityPerks,
    selectedFlawPerk,
    handleCurrentlyChangedPerkId,
  } = characterPerksApi;

  // Scroll to bottom on mount
  // it's because subsequent elements of the form are rendered gradually
  // We assume that user is always interested in the bottom of the form at the moment
  useEffect(() => {
    if (submitButtonRef.current) {
      submitButtonRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  const AGE_OPTIONS = useMemo(
    () => [
      {
        value: 'teenager',
        label: t('age_select.teenager'),
      },
      {
        value: 'adult',
        label: t('age_select.adult'),
      },
      {
        value: 'elder',
        label: t('age_select.elder'),
      },
    ],
    [t],
  );

  return (
    <Container>
      <Text fontSize="bigger" fontWeight={500} letterSpacing="wide">
        {t('heading')}
      </Text>
      {themesAsOptions.length > 0 && (
        <FullScreenSelector
          label={t('theme_select.label')}
          placeholder={t('theme_select.placeholder')}
          selectedOption={themesAsOptions.find((theme) => theme.id === selectedThemeId)}
          onSelect={() => setActiveTabId('themeSelect')}
        />
      )}

      {classesAsOptions.length > 0 && (
        <FullScreenSelector
          label={t('class_select.label')}
          placeholder={t('class_select.placeholder')}
          selectedOption={classesAsOptions.find((cc) => cc.id === selectedClassId)}
          onSelect={() => setActiveTabId('classSelect')}
        />
      )}
      <br />
      {portraitPacksAsOptions.length > 0 && selectedClassId && (
        <PortraitSelector
          imageSrc={selectablePortraits.find((portrait) => portrait.id === selectedPortraitId)?.coverImg}
          onSelect={() => setActiveTabId('portraitSelect')}
        />
      )}
      <br />

      {selectedThemeId && selectedClassId && selectedPortraitId && (
        <TextInput
          value={name}
          label={t('name_label')}
          onChange={handleNameChange}
          error={!isCharacterNameAllowed && name ? t('name_invalid') : undefined}
        />
      )}

      {selectedThemeId && selectedClassId && selectedPortraitId && (
        <Select
          isSearchable={false}
          label={t('age_label')}
          options={AGE_OPTIONS}
          value={AGE_OPTIONS.find((option) => option.value === characterAge)}
          onChange={(newValue) => handleCharacterAgeSelection((newValue as Option).value as string)}
        />
      )}

      {characterAge && selectedPortraitId && (
        <>
          <PerkSelectionGroup>
            <Text
              fontWeight={500}
              color="opaqueWhite50"
              fontSize="smaller"
              letterSpacing="superWide"
              textTransform="uppercase"
            >
              {t('origin.label')}
            </Text>
            {selectedOriginPerk ? (
              <PerkSelector onClick={() => setActiveTabId('originSelect')}>
                <CharacterPerkContent selectedPerk={selectedOriginPerk} themeId={selectedThemeId} />
                <IconSwap />
              </PerkSelector>
            ) : (
              <PerkSelector onClick={() => setActiveTabId('originSelect')}>
                <Text> {t('origin.select_prompt')}</Text>
                <IconSwap />
              </PerkSelector>
            )}
          </PerkSelectionGroup>

          <PerkSelectionGroup>
            <Text
              fontWeight={500}
              color="opaqueWhite50"
              fontSize="smaller"
              letterSpacing="superWide"
              textTransform="uppercase"
            >
              {t('experience.label')}
            </Text>
            {selectedExperiencePerks && selectedExperiencePerks?.length >= 1 ? (
              <PerkSelector
                onClick={() => {
                  if (selectedExperiencePerks && selectedExperiencePerks?.length >= 1) {
                    const selectedPerkId = selectedExperiencePerks[0].id;
                    handleCurrentlyChangedPerkId(selectedPerkId);
                  }
                  setActiveTabId('experienceSelect');
                }}
              >
                <CharacterPerkContent selectedPerk={selectedExperiencePerks[0]} themeId={selectedThemeId} />
                <IconSwap />
              </PerkSelector>
            ) : (
              <PerkSelector onClick={() => setActiveTabId('experienceSelect')}>
                <Text> {t('experience.select_prompt')}</Text>
                <IconSwap />
              </PerkSelector>
            )}
            {(characterAge === 'adult' || characterAge === 'elder') &&
              (selectedExperiencePerks && selectedExperiencePerks?.length >= 2 ? (
                <PerkSelector
                  onClick={() => {
                    if (selectedExperiencePerks?.length >= 2) {
                      const selectedPerkId = selectedExperiencePerks[1].id;
                      handleCurrentlyChangedPerkId(selectedPerkId);
                    }
                    setActiveTabId('experienceSelect');
                  }}
                >
                  <CharacterPerkContent selectedPerk={selectedExperiencePerks[1]} themeId={selectedThemeId} />
                  <IconSwap />
                </PerkSelector>
              ) : (
                <PerkSelector onClick={() => setActiveTabId('experienceSelect')}>
                  <Text> {t('experience.select_prompt')}</Text>
                  <IconSwap />
                </PerkSelector>
              ))}
            {characterAge === 'elder' &&
              (selectedExperiencePerks && selectedExperiencePerks?.length === 3 ? (
                <PerkSelector
                  onClick={() => {
                    if (selectedExperiencePerks?.length === 3) {
                      const selectedPerkId = selectedExperiencePerks[2].id;
                      handleCurrentlyChangedPerkId(selectedPerkId);
                    }
                    setActiveTabId('experienceSelect');
                  }}
                >
                  <CharacterPerkContent selectedPerk={selectedExperiencePerks[2]} themeId={selectedThemeId} />
                  <IconSwap />
                </PerkSelector>
              ) : (
                <PerkSelector onClick={() => setActiveTabId('experienceSelect')}>
                  <Text> {t('experience.select_prompt')}</Text>
                  <IconSwap />
                </PerkSelector>
              ))}
          </PerkSelectionGroup>

          <PerkSelectionGroup>
            <Text
              fontWeight={500}
              color="opaqueWhite50"
              fontSize="smaller"
              letterSpacing="superWide"
              textTransform="uppercase"
            >
              {t('personality.label')}
            </Text>
            {selectedPersonalityPerks && selectedPersonalityPerks?.length >= 1 ? (
              <PerkSelector
                onClick={() => {
                  if (selectedPersonalityPerks && selectedPersonalityPerks?.length >= 1) {
                    const selectedPerkId = selectedPersonalityPerks[0].id;
                    handleCurrentlyChangedPerkId(selectedPerkId);
                  }
                  setActiveTabId('personalitySelect');
                }}
              >
                <CharacterPerkContent selectedPerk={selectedPersonalityPerks[0]} themeId={selectedThemeId} />
                <IconSwap />
              </PerkSelector>
            ) : (
              <PerkSelector onClick={() => setActiveTabId('personalitySelect')}>
                <Text> {t('personality.select_prompt')}</Text>
                <IconSwap />
              </PerkSelector>
            )}
            {selectedPersonalityPerks && selectedPersonalityPerks?.length === 2 ? (
              <PerkSelector
                onClick={() => {
                  if (selectedPersonalityPerks?.length === 2) {
                    const selectedPerkId = selectedPersonalityPerks[1].id;
                    handleCurrentlyChangedPerkId(selectedPerkId);
                  }
                  setActiveTabId('personalitySelect');
                }}
              >
                <CharacterPerkContent selectedPerk={selectedPersonalityPerks[1]} themeId={selectedThemeId} />
                <IconSwap />
              </PerkSelector>
            ) : (
              <PerkSelector onClick={() => setActiveTabId('personalitySelect')}>
                <Text> {t('personality.select_prompt')}</Text>
                <IconSwap />
              </PerkSelector>
            )}
          </PerkSelectionGroup>

          <PerkSelectionGroup>
            <Text
              fontWeight={500}
              color="opaqueWhite50"
              fontSize="smaller"
              letterSpacing="superWide"
              textTransform="uppercase"
            >
              {t('flaw.label')}
            </Text>
            {selectedFlawPerk ? (
              <PerkSelector onClick={() => setActiveTabId('flawSelect')}>
                <CharacterPerkContent selectedPerk={selectedFlawPerk} themeId={selectedThemeId} />
                <IconSwap />
              </PerkSelector>
            ) : (
              <PerkSelector onClick={() => setActiveTabId('flawSelect')}>
                <Text> {t('flaw.select_prompt')}</Text>
                <IconSwap />
              </PerkSelector>
            )}
          </PerkSelectionGroup>
        </>
      )}

      <SubmitButtonWrapper ref={submitButtonRef}>
        <ColorButton onClick={() => setActiveTabId('characterBuild')} isDisabled={!isSubmitAllowed}>
          {t('submit_button')}
        </ColorButton>
      </SubmitButtonWrapper>
    </Container>
  );
};

export default MainForm;
