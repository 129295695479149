import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Container,
  LogoWrapper,
  Logo,
  VersionWrapper,
  SocialMediaButtons,
  ContentWrapper,
  PoliciesWrapper,
} from 'components/generics/Form/AuthLayout/AuthLayout.styles';
import { getEnv } from 'utils/getEnv';
import { Text } from 'components/generics/Text/Text';
import LogoSource from 'assets/images/logo/logo-big.png';
import { useLoginView } from '../LoginView.hooks';

export const ProdLoginView = () => {
  const { t } = useTranslation('launcher', { keyPrefix: 'login_view' });

  const { loginViaSSO, availableProviders } = useLoginView();

  return (
    <Container isProd>
      <LogoWrapper>
        <Logo src={LogoSource} alt="" />
        <VersionWrapper>
          <Text as="span" color="secondary" fontWeight={600} fontSize="smaller" textTransform="uppercase">
            {t('early_access')}
          </Text>
          <Text as="span" fontSize="smaller" color="opaqueWhite50">
            v{getEnv('REACT_APP_VERSION')}
          </Text>
        </VersionWrapper>
      </LogoWrapper>
      <ContentWrapper>
        <Text color="opaqueWhite75" fontWeight={700}>
          {availableProviders.length === 1 ? t('discord_log_in_prompt') : t('social_media_log_in_prompt')}
        </Text>
        <SocialMediaButtons>
          {availableProviders.map(({ provider, Icon }) => (
            <button key={provider} type="button" onClick={() => loginViaSSO(provider)} aria-label={provider}>
              <Icon />
            </button>
          ))}
        </SocialMediaButtons>
        <PoliciesWrapper>
          <Text color="opaqueWhite50" fontSize="smaller" textAlign="center">
            {t('policies_text_1')}{' '}
            <a target="_blank" href="https://www.rollingdices.app/terms-of-service" rel="noreferrer">
              {t('policies_terms')}
            </a>{' '}
            {t('policies_text_2')}{' '}
            <a target="_blank" href="https://www.rollingdices.app/privacy-policy" rel="noreferrer">
              {t('policies_privacy')}
            </a>
            .
          </Text>
        </PoliciesWrapper>
      </ContentWrapper>
    </Container>
  );
};
