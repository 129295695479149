import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'components/Helmet/Helmet';
import { Text } from 'components/generics/Text/Text';
import { ColorButton } from 'components/generics/Button/ColorButton';
import { HeadingWrapper, Illustration, Wrapper } from './ErrorView.styles';
import IllustrationAsset from './assets/illustration.png';

export const ErrorView = () => {
  const { t } = useTranslation('launcher', { keyPrefix: 'error_view' });

  return (
    <>
      <Helmet>
        <title>{t('title')}</title>
      </Helmet>
      <Wrapper>
        <Text fontSize="sectionHeading" fontWeight={700} textAlign="center" textTransform="uppercase">
          {t('top_text')}
        </Text>
        <Illustration src={IllustrationAsset} alt="" />
        <HeadingWrapper>
          <Text fontSize="big" fontWeight={700} textAlign="left">
            {t('h1')}
          </Text>
          <Text
            as="h1"
            fontSize="large"
            fontWeight={700}
            textAlign="center"
            textTransform="uppercase"
            color="secondary"
          >
            {t('h2')}
          </Text>
          <Text fontSize="big" fontWeight={700} textAlign="right">
            {t('h3')}
          </Text>
        </HeadingWrapper>
        <div>
          <Text color="opaqueWhite75" textAlign="center">
            {t('p1')}
          </Text>
          <Text color="opaqueWhite75" textAlign="center">
            {t('p2')}
          </Text>
        </div>
        <Text color="opaqueWhite75" textAlign="center">
          {t('p3')}
        </Text>
        <ColorButton
          onClick={() => {
            window.location.reload();
          }}
        >
          {t('button')}
        </ColorButton>
      </Wrapper>
    </>
  );
};
