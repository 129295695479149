import React, { useState } from 'react';
import { ReactComponent as ShowPasswordIcon } from 'assets/images/svg/icon_show_pw.svg';
import { ReactComponent as HidePasswordIcon } from 'assets/images/svg/icon_hide_pw.svg';
import { TextInput, InputProps } from '../TextInput/TextInput';
import { InputWrapper, TogglePasswordButton } from './PasswordInput.styles';

export const PasswordInput = ({ ...props }: InputProps) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  return (
    <InputWrapper>
      <TextInput {...props} type={isPasswordVisible ? 'text' : 'password'} />
      <TogglePasswordButton
        tabIndex={-1}
        onClick={(e) => {
          e.preventDefault();
          setIsPasswordVisible(!isPasswordVisible);
        }}
      >
        {isPasswordVisible ? <HidePasswordIcon /> : <ShowPasswordIcon />}
      </TogglePasswordButton>
    </InputWrapper>
  );
};
