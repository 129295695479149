import React from 'react';
import { ButtonProps, ColorButtonContainer } from './ButtonShared';

export const ColorButton = ({
  color = 'primary',
  outline = false,
  onClick,
  children,
  type = 'button',
  isDisabled = false,
}: ButtonProps) => (
  <ColorButtonContainer color={color} outline={outline} onClick={onClick} type={type} disabled={isDisabled}>
    {children}
  </ColorButtonContainer>
);
