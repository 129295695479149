import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from 'components/generics/Text/Text';
import { MultiTabSection } from 'components/MultiTabSection/MultiTabSection';
import { TabButtons } from 'components/MultiTabSection/TabButtons';
import { ContentContainer } from '../../IngameHandbook.styles';
import { ManageHealthSubsection } from './subsections/ManageHealthSubsection';
import { ManageMoneySubsection } from './subsections/ManageMoneySubsection';
// import { GrantItemSubsection } from './subsections/GrantItemSubsection';

export const HandbookManagementSection = () => {
  const { t } = useTranslation('handbook', { keyPrefix: 'management_view' });
  const [activeTabId, setActiveTabId] = useState('');

  return (
    <ContentContainer>
      <Text fontSize="small" color="opaqueWhite75" fontWeight={500}>
        {t('paragraphs.p1')}
      </Text>

      <br />
      <Text fontWeight={500}> {t('subsections_prompt')}.</Text>

      <TabButtons
        tabs={[
          {
            id: 'manage_health',
            label: 'Manage Health',
            active: activeTabId === 'manage_health',
            onClick: () => setActiveTabId((prev) => (prev === 'manage_health' ? '' : 'manage_health')),
          },
          {
            id: 'manage_money',
            label: 'Manage Money',
            active: activeTabId === 'manage_money',
            onClick: () => setActiveTabId((prev) => (prev === 'manage_money' ? '' : 'manage_money')),
          },
          // {
          //   id: 'grant_item',
          //   label: 'Grant Item',
          //   active: activeTabId === 'grant_item',
          //   onClick: () => setActiveTabId((prev) => (prev === 'grant_item' ? '' : 'grant_item')),
          // },
        ]}
      />
      <MultiTabSection
        activeTabId={activeTabId}
        tabs={[
          {
            id: 'manage_health',
            component: <ManageHealthSubsection />,
          },
          {
            id: 'manage_money',
            component: <ManageMoneySubsection />,
          },
          // {
          //   id: 'grant_item',
          //   component: <GrantItemSubsection />,
          // },
        ]}
      />
    </ContentContainer>
  );
};
