import { GetCharacterPerksForThemeRow } from 'models/functions/GetCharacterPerksForTheme';

const INITIAL_BASE_MONEY = 5;

interface Props {
  originPerk?: GetCharacterPerksForThemeRow;
  experiencePerks?: GetCharacterPerksForThemeRow[];
}

export const calculateBaseMoney = ({ originPerk, experiencePerks }: Props) => {
  let baseMoney = INITIAL_BASE_MONEY;

  if (originPerk && originPerk.content?.base_money) {
    baseMoney += originPerk.content.base_money;
  }

  if (experiencePerks && experiencePerks.length) {
    experiencePerks.forEach((perk) => {
      if (perk.content?.base_money) {
        baseMoney += perk.content.base_money;
      }
    });
  }

  return baseMoney;
};
