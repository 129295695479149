import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from 'components/generics/Text/Text';
import { useSessionPlayersAPI } from 'hooks/sessionApi/useSessionPlayersAPI';
import { MultiTabSection } from 'components/MultiTabSection/MultiTabSection';
import { TabButtons } from 'components/MultiTabSection/TabButtons';
import { AboutSubsection } from './subsections/AboutSubsection';
import { StateSubsection } from './subsections/StateSubsection';
import { AttributesSubsection } from './subsections/AttributesSubsection';
import { InventorySubsection } from './subsections/InventorySubsection';

export const HandbookPartySection = () => {
  const { t } = useTranslation('handbook', { keyPrefix: 'party_view' });
  const { sessionPlayersQuery } = useSessionPlayersAPI();
  const sessionPlayers = sessionPlayersQuery.data?.data ?? [];

  const [activeTabId, setActiveTabId] = useState('');

  if (sessionPlayers.length === 0) {
    return null;
  }

  return (
    <>
      <Text fontSize="small" color="opaqueWhite75" fontWeight={500}>
        {t('paragraphs.p1')}
      </Text>
      <Text fontSize="small" color="opaqueWhite75" fontWeight={500}>
        {t('paragraphs.p2')}
      </Text>
      <br />
      <Text fontWeight={500}> {t('subsections_prompt')}</Text>
      <TabButtons
        tabs={[
          {
            id: 'about',
            label: t('subsections.about.label'),
            active: activeTabId === 'about',
            onClick: () => setActiveTabId((prev) => (prev === 'about' ? '' : 'about')),
          },
          {
            id: 'state',
            label: t('subsections.state.label'),
            active: activeTabId === 'state',
            onClick: () => setActiveTabId((prev) => (prev === 'state' ? '' : 'state')),
          },
          {
            id: 'attributes',
            label: t('subsections.attributes.label'),
            active: activeTabId === 'attributes',
            onClick: () => setActiveTabId((prev) => (prev === 'attributes' ? '' : 'attributes')),
          },
          {
            id: 'inventory',
            label: t('subsections.inventory.label'),
            active: activeTabId === 'inventory',
            onClick: () => setActiveTabId((prev) => (prev === 'inventory' ? '' : 'inventory')),
          },
        ]}
      />
      <MultiTabSection
        activeTabId={activeTabId}
        tabs={[
          {
            id: 'about',
            component: <AboutSubsection />,
          },
          {
            id: 'state',
            component: <StateSubsection />,
          },
          {
            id: 'attributes',
            component: <AttributesSubsection />,
          },
          {
            id: 'inventory',
            component: <InventorySubsection />,
          },
        ]}
      />
    </>
  );
};
