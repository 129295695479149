import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Text } from 'components/generics/Text/Text';
import { useGameplayNavigation } from 'hooks/useGameplayNavigation';
import { useIsGameMaster } from 'hooks/useIsGameMaster';
import { SESSION_VIEW_TYPE, useGameplaySelector } from 'stores/gameplay.slice';
import { ReactComponent as ChatIcon } from 'assets/images/svg/icon_chat.svg';
import { ReactComponent as RollDiceIcon } from 'assets/images/gameplay_menu/roll_dice_icon.svg';
import { ReactComponent as CallRollIcon } from 'assets/images/gameplay_menu/call_roll_icon.svg';
import { ReactComponent as StoryIcon } from 'assets/images/gameplay_menu/story_icon.svg';
import { ReactComponent as PartyIcon } from 'assets/images/gameplay_menu/party_icon.svg';
import { ReactComponent as CopyCodeIcon } from 'assets/images/gameplay_menu/copy_access_code_icon.svg';
import { ReactComponent as ManagementIcon } from 'assets/images/gameplay_menu/management_icon.svg';
import { copyToClipboard } from 'utils/copyToClipboard';
import { Item, Button, List, Wrapper } from './GameplaySideMenu.styles';

interface GameplaySideMenuProps {
  isOpen: boolean;
  toggleSideMenu: () => any;
}

const preventScroll = (isOpen: boolean) => {
  document.body.style.overflow = isOpen ? 'hidden' : 'initial';
};

export const GameplaySideMenu = ({ isOpen, toggleSideMenu }: GameplaySideMenuProps) => {
  const { t } = useTranslation('gameplay', { keyPrefix: 'side_menu' });
  const { isGameMaster } = useIsGameMaster();
  const { goToView } = useGameplayNavigation();
  const { activeSession } = useGameplaySelector();

  useEffect(() => preventScroll(isOpen), [isOpen]);

  const setViewAndToggleMenu = useCallback(
    (newView: SESSION_VIEW_TYPE) => {
      goToView(newView);
      toggleSideMenu();
    },
    [goToView, toggleSideMenu],
  );

  const copyAccessCodeToClipboard = () => {
    if (activeSession && activeSession.access_code) {
      copyToClipboard(activeSession.access_code);
      toast.success(t('access_code_copied_success'));
    } else {
      toast.error(t('access_code_copied_error'));
    }
  };

  return (
    <Wrapper isOpen={isOpen}>
      <List>
        <Item>
          <Button onClick={() => setViewAndToggleMenu(SESSION_VIEW_TYPE.SESSION_RECORDS)}>
            <ChatIcon />
            <Text fontWeight={700} fontSize="big">
              {t('session_log')}
            </Text>
          </Button>
        </Item>
        {isGameMaster && (
          <Item>
            <Button onClick={() => setViewAndToggleMenu(SESSION_VIEW_TYPE.DEMAND_ROLL)}>
              <CallRollIcon />
              <Text fontWeight={700} fontSize="big">
                {t('call_roll')}
              </Text>
            </Button>
          </Item>
        )}
        <Item>
          <Button onClick={() => setViewAndToggleMenu(SESSION_VIEW_TYPE.ROLL_DICE)}>
            <RollDiceIcon />
            <Text fontWeight={700} fontSize="big">
              {t('roll_the_dice')}
            </Text>
          </Button>
        </Item>
        <Item>
          <Button onClick={() => setViewAndToggleMenu(SESSION_VIEW_TYPE.PARTY)}>
            <PartyIcon />
            <Text fontWeight={700} fontSize="big">
              {t('party')}
            </Text>
          </Button>
        </Item>
        {isGameMaster && (
          <Item>
            <Button onClick={() => setViewAndToggleMenu(SESSION_VIEW_TYPE.STORY)}>
              <StoryIcon />
              <Text fontWeight={700} fontSize="big">
                {t('story')}
              </Text>
            </Button>
          </Item>
        )}
        {isGameMaster && (
          <Item>
            <Button onClick={() => setViewAndToggleMenu(SESSION_VIEW_TYPE.MANAGEMENT)}>
              <ManagementIcon />
              <Text fontWeight={700} fontSize="big">
                Management
              </Text>
            </Button>
          </Item>
        )}
        {isGameMaster && (
          <Item>
            <Button onClick={copyAccessCodeToClipboard}>
              <CopyCodeIcon />
              <Text fontWeight={700} fontSize="big">
                {t('copy_access_code')}
              </Text>
            </Button>
          </Item>
        )}
      </List>
    </Wrapper>
  );
};
