import React from 'react';
import { Text } from 'components/generics/Text/Text';
import { Container, InputBase, ErrorLabel } from './TextInput.styles';

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  value?: string | undefined;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => any;
  label?: string;
  name?: string;
  placeholder?: string;
  error?: string;
  type?: string;
  fixedWidth?: string;
}

export const TextInput = ({
  value,
  onChange,
  label,
  name,
  error,
  placeholder,
  type,
  fixedWidth,
  ...rest
}: InputProps) => (
  <Container fixedWidth={fixedWidth}>
    {label && (
      <Text
        fontWeight={500}
        color="opaqueWhite50"
        fontSize="smaller"
        letterSpacing="superWide"
        textTransform="uppercase"
      >
        {label}
      </Text>
    )}
    <InputBase
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      isError={error !== undefined}
      type={type}
      name={name}
      {...rest}
    />
    {error && <ErrorLabel>{error}</ErrorLabel>}
  </Container>
);
