/* eslint-disable max-len */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { Text } from 'components/generics/Text/Text';
import { useLocationsAPI } from 'hooks/sessionApi/useLocationsAPI';
import { StoryCard as StoryCardInterface } from 'models/db/resource_StoryCard';
import { SESSION_VIEW_TYPE, useGameplaySelector } from 'stores/gameplay.slice';
import { SupabaseService } from 'services/SupabaseService';
import { DB_TABLE_NAMES, QUERY_KEYS } from 'utils/consts';
import { useGameplayNavigation } from 'hooks/useGameplayNavigation';
import { ManagableCard } from 'components/ManagableCard/ManagableCard';
import { OmitAutoGenerated } from 'models/Api';
import { SessionRecord } from 'models/db/db_SessionRecord';
import { ResourceCard } from 'components/ResourceCard/ResourceCard';
import { CardContent, CardDescriptionRow, CardHeadingRow } from './StoryCard.styles';

interface StoryCardProps {
  storyCard: StoryCardInterface;
  isManagable?: boolean;
  isManaging?: boolean;
  toggleManaging?: (id?: string) => void;
}

export const StoryCard = ({ storyCard, isManagable, isManaging, toggleManaging }: StoryCardProps) => {
  const { t } = useTranslation('storyCards' as 'resource');
  const { t: tLocation } = useTranslation('locations' as 'resource');
  const { t: tLocationPack } = useTranslation('locationPacks' as 'resource');
  const { t: tGameplay } = useTranslation('gameplay', { keyPrefix: 'story_view' });
  const { activeSession } = useGameplaySelector();

  const { activeLocationQuery } = useLocationsAPI();
  const activeLocation = activeLocationQuery.data?.data?.[0];

  const queryClient = useQueryClient();
  const { goToView } = useGameplayNavigation();

  const handleDelete = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();

    await SupabaseService.from(DB_TABLE_NAMES.instanceStoryCard).delete().eq('id', storyCard.id);

    queryClient.invalidateQueries([QUERY_KEYS.gameplay.storyCards, activeSession?.id]);
  };

  const handlePutOnTable = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    if (!activeSession?.id) return;

    await SupabaseService.from(DB_TABLE_NAMES.dbSessionRecord).insert<OmitAutoGenerated<SessionRecord>>([
      {
        player_character_id: undefined,
        session_id: activeSession?.id,
        record_type: 'story_card',
        content: {
          action: 'add',
          type: storyCard?.type,
          location_image_url: activeLocation?.cover_img_url,
          t_key: `${storyCard.location_pack_t_key}.${storyCard.location_t_key}.${storyCard.t_key}`,
        },
      },
    ]);

    goToView(SESSION_VIEW_TYPE.SESSION_RECORDS);
  };

  const handleResolve = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    if (!activeSession?.id) return;

    await SupabaseService.from(DB_TABLE_NAMES.instanceStoryCard).delete().eq('id', storyCard.id);

    await SupabaseService.from(DB_TABLE_NAMES.dbSessionRecord).insert<OmitAutoGenerated<SessionRecord>>([
      {
        player_character_id: undefined,
        session_id: activeSession?.id,
        record_type: 'story_card',
        content: {
          action: 'delete',
          type: storyCard?.type,
          location_image_url: activeLocation?.cover_img_url,
          t_key: `${storyCard.location_pack_t_key}.${storyCard.location_t_key}.${storyCard.t_key}`,
        },
      },
    ]);

    queryClient.invalidateQueries(['storyCards', activeSession?.id]);
  };

  const renderCard = () => {
    if (!storyCard.type) return null;

    return (
      <ResourceCard heading={`${tGameplay('story_card')} • ${tGameplay(`story_cards_types.${storyCard.type}` as any)}`}>
        <CardContent>
          <CardHeadingRow>
            <Text
              fontSize="smaller"
              fontWeight={600}
              color="opaqueWhite75"
              textAlign="center"
              textTransform="uppercase"
            >
              {tLocationPack(`${activeSession?.theme_id}.${storyCard.location_pack_t_key}.name` as any)} •{' '}
              {tLocation(
                `${activeSession?.theme_id}.${storyCard.location_pack_t_key}.${storyCard.location_t_key}.title` as any,
              )}
            </Text>
            <Text fontWeight={700} textAlign="center" textTransform="uppercase">
              {t(
                `${activeSession?.theme_id}.${storyCard.location_pack_t_key}.${storyCard.location_t_key}.${storyCard.t_key}.title` as any,
              )}
            </Text>
          </CardHeadingRow>
          <CardDescriptionRow>
            <Text fontSize="smaller" color="opaqueWhite75" textAlign="center">
              {t(
                `${activeSession?.theme_id}.${storyCard.location_pack_t_key}.${storyCard.location_t_key}.${storyCard.t_key}.description` as any,
              )}
            </Text>
          </CardDescriptionRow>
        </CardContent>
      </ResourceCard>
    );
  };

  const managementOptions = [
    {
      label: tGameplay('actions.delete'),
      color: 'secondary',
      handleClick: handleDelete,
    },
    {
      label: tGameplay('actions.put_on_table'),
      color: 'highlightYellow',
      handleClick: handlePutOnTable,
    },
    {
      label: tGameplay('actions.resolve'),
      color: 'primary',
      handleClick: handleResolve,
    },
  ];

  return isManagable && toggleManaging ? (
    <ManagableCard
      isManaging={isManaging ?? false}
      toggleManaging={toggleManaging}
      managementOptions={managementOptions}
    >
      {renderCard()}
    </ManagableCard>
  ) : (
    renderCard()
  );
};
