import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { routes } from 'components/MainRouter/MainRouter';
import {
  SESSION_VIEW_TYPE,
  removeLastView,
  setCurrentView,
  useGameplaySelector,
  setCharacterIdToView,
} from 'stores/gameplay.slice';
import { closeModal, openModal } from 'stores/modal.slice';
import { useAppDispatch } from 'stores/store';

export const useGameplayNavigation = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { gameplayNavigation } = useGameplaySelector();
  const { t } = useTranslation('launcher', { keyPrefix: 'sessions_view' });

  const { currentView, previousViews } = gameplayNavigation;

  const goToView = (viewToRender: SESSION_VIEW_TYPE) => dispatch(setCurrentView({ newView: viewToRender }));

  const redirectToCharacter = (characterId: string) => {
    dispatch(setCharacterIdToView({ characterId }));
    goToView(SESSION_VIEW_TYPE.PARTY);
  };

  const goToPreviousView = async () => {
    if (currentView === SESSION_VIEW_TYPE.SESSION_RECORDS) {
      dispatch(
        openModal({
          content: t('leave_confirmation'),
          handleConfirm: () => {
            dispatch(closeModal());
            navigate(routes.sessions);
          },
          handleClose: () => dispatch(closeModal()),
          confirmText: t('leave_button'),
        }),
      );
    } else {
      await dispatch(setCurrentView({ newView: previousViews[previousViews.length - 1] }));
      dispatch(removeLastView());
    }
  };

  return { currentView, previousViews, goToView, goToPreviousView, redirectToCharacter } as const;
};
