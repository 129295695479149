import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 0.5rem;
`;

export const ContentContainer = styled.div`
  margin-top: 0.75rem;
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;
`;
