import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 0.5rem;
`;

export const ButtonGroup = styled.div`
  display: flex;
  flex-flow: row nowrap;
  gap: 1rem;
  margin-left: auto;
`;

export const Button = styled.button`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  transition-duration: ${({ theme }) => theme.transition.baseDuration};
  cursor: pointer;

  color: ${({ theme }) => theme.colors.opaqueWhite50};
  svg {
    height: 1.5rem;
    width: 1.5rem;
    fill: ${({ theme }) => theme.colors.opaqueWhite50};
  }

  &:hover {
    color: ${({ theme }) => theme.colors.white};
    svg {
      fill: ${({ theme }) => theme.colors.white};
    }
  }
`;
