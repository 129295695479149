import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from 'components/generics/Text/Text';
import { Helmet } from 'components/Helmet/Helmet';
import { ColorButton } from 'components/generics/Button/ColorButton';
import LauncherLayout from 'components/LauncherLayout/LauncherLayout';
import { menuItems } from 'components/LauncherNavMenu/LauncherNavMenu';
import { getEnv } from 'utils/getEnv';
import LogoSource from 'assets/images/logo/logo-big.png';
import {
  ContentWrapper,
  Item,
  List,
  ListBox,
  ListsWrapper,
  Logo,
  LogoWrapper,
  PageWrapper,
  VersionWrapper,
} from './HomeView.styles';

export const HomeView = () => {
  const { t } = useTranslation('launcher', { keyPrefix: 'home_view' });

  return (
    <>
      <Helmet>
        <title>{t('title')}</title>
      </Helmet>
      <LauncherLayout activeItem={menuItems.home}>
        <PageWrapper>
          <LogoWrapper>
            <Logo src={LogoSource} alt="" />
            <VersionWrapper>
              <Text as="span" color="secondary" fontWeight={600} fontSize="smaller" textTransform="uppercase">
                {t('early_access')}
              </Text>
              <Text as="span" fontSize="smaller" color="opaqueWhite50">
                v{getEnv('REACT_APP_VERSION')}
              </Text>
            </VersionWrapper>
          </LogoWrapper>
          <ContentWrapper>
            <Text as="h2" fontWeight={700} fontSize="big" textAlign="center">
              {t('heading')}
            </Text>
            <ListsWrapper>
              <ListBox>
                <Text fontSize="small" color="opaqueWhite75">
                  {t('list1.heading')}
                </Text>
                <List>
                  <Item>
                    <Text fontSize="small" color="opaqueWhite75">
                      {t('list1.p1')}
                    </Text>
                  </Item>
                  <Item>
                    <Text fontSize="small" color="opaqueWhite75">
                      {t('list1.p2')}
                    </Text>
                  </Item>
                </List>
                <Text fontSize="small" fontWeight={600} color="opaqueWhite75">
                  {t('list1.p3')}
                </Text>
              </ListBox>
              <center>
                <Text fontSize="small" fontWeight={500}>
                  {t('support_prompt')}
                </Text>
                <br />
                <ColorButton onClick={() => window.open('https://buycoffee.to/rollingdices', '_blank')}>
                  ❤️ {t('support_us_button')} ❤️
                </ColorButton>
              </center>
              <ListBox>
                <Text
                  fontSize="small"
                  color="highlightYellow"
                  fontWeight={600}
                  letterSpacing="wide"
                  textTransform="uppercase"
                >
                  {t('list2.heading')}
                </Text>
                <Text fontSize="small" color="opaqueWhite75">
                  {t('list2.subheading')}
                </Text>
                <List>
                  <Item>
                    <Text fontSize="small" color="opaqueWhite75">
                      {t('list2.p1')}
                    </Text>
                  </Item>
                  <Item>
                    <Text fontSize="small" color="opaqueWhite75">
                      {t('list2.p2')}
                    </Text>
                  </Item>
                  <Item>
                    <Text fontSize="small" color="opaqueWhite75">
                      {t('list2.p3')}
                    </Text>
                  </Item>
                </List>
              </ListBox>
            </ListsWrapper>
          </ContentWrapper>
        </PageWrapper>
      </LauncherLayout>
    </>
  );
};
