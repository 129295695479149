import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from 'components/generics/Text/Text';
import { ReactComponent as IconIsEquipped } from 'assets/images/svg/icon_checkmark_circle.svg';
import { Container, IsEquippedLabel, KeywordsWrapper } from './Card.styles';

interface CardProps {
  children: React.ReactNode;
  keywords?: string[];
  isEquipped?: boolean;
}

export const Card = ({ children, keywords, isEquipped }: CardProps) => {
  const { t } = useTranslation('gameplay', { keyPrefix: 'party_view.inventory_section' });

  return (
    <Container isEquipped={isEquipped}>
      {isEquipped && (
        <IsEquippedLabel>
          <Text fontWeight={700} color="primary">
            {t('equipped_label')}
          </Text>
          <IconIsEquipped />
        </IsEquippedLabel>
      )}
      {keywords && (
        <KeywordsWrapper>
          <Text fontSize="small" fontWeight={600} color="opaqueWhite50">
            {keywords?.join(' • ')}
          </Text>
        </KeywordsWrapper>
      )}
      {children}
    </Container>
  );
};
