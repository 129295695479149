import React from 'react';
import { BlandButton } from 'components/generics/Button/BlandButton';
import { Text } from 'components/generics/Text/Text';
import { CloseButton } from 'components/Modal/Modal.styles';
import { CardButton, CloseButtonWrapper, ManagingOverlay } from './ManagableCard.styles';

interface LauncherNavMenuProps {
  children: React.ReactNode;
  managementOptions: {
    label: string;
    color: string;
    handleClick: any;
  }[];
  isManaging: boolean;
  toggleManaging: () => any;
}

export const ManagableCard = ({ children, managementOptions, isManaging, toggleManaging }: LauncherNavMenuProps) => (
  <CardButton isManaging={isManaging} onClick={toggleManaging}>
    {isManaging && (
      <CloseButtonWrapper>
        <CloseButton />
      </CloseButtonWrapper>
    )}
    {isManaging && (
      <ManagingOverlay>
        {managementOptions.map((option) => (
          <BlandButton key={option.label} onClick={option.handleClick}>
            <Text color={option.color} fontSize="smaller">
              {option.label}
            </Text>
          </BlandButton>
        ))}
      </ManagingOverlay>
    )}
    {children}
  </CardButton>
);
