import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from 'components/generics/Text/Text';
import { GetCharacterPerksForThemeRow } from 'models/functions/GetCharacterPerksForTheme';

interface Props {
  selectedPerk: GetCharacterPerksForThemeRow;
  themeId: string;
}

export const CharacterPerkContent = ({ selectedPerk, themeId }: Props) => {
  const { t } = useTranslation('characterPerks' as 'resource');
  const { t: tThemeConfig } = useTranslation('themesIndex');

  const { type, content, t_key } = selectedPerk;

  return (
    <div style={{ width: '90%' }}>
      <Text fontWeight={600}>{t(`${themeId}.${type}.${t_key}.name` as any)}</Text>
      <Text fontSize="small" color="opaqueWhite75">
        {t(`${themeId}.${type}.${t_key}.description` as any)}
      </Text>
      <br />

      {content?.character_schema.attributes &&
        Object.keys(content.character_schema.attributes).map((attr) => (
          <Text key={attr} fontWeight={600}>{`${tThemeConfig(
            `${themeId}.character_attributes.${attr}.title` as any,
          )}: ${content?.character_schema.attributes[attr]}`}</Text>
        ))}
      {content?.character_schema.skills &&
        Object.keys(content.character_schema.skills).map((skill) => (
          <Text key={skill} fontWeight={600}>{`${tThemeConfig(`${themeId}.character_skills.${skill}.title` as any)}: ${
            content?.character_schema.skills[skill]
          }`}</Text>
        ))}
    </div>
  );
};
