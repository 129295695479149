/* eslint-disable no-param-reassign */
import { CaseReducer, PayloadAction, createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { User } from 'models/User';
import { ThunkStatus } from 'models/Thunk';
import { TokenService } from 'services/TokenService';
import { routes } from 'components/MainRouter/MainRouter';
import { getUserProfileThunk } from './user.thunks';
import { RootState } from './store';

export interface UserState {
  user: User | undefined;
  status: ThunkStatus;
  isError: boolean;
}

const initialState: UserState = {
  user: undefined,
  status: 'idle',
  isError: false,
};

const updateUserReducer: CaseReducer<UserState, PayloadAction<User>> = (state, { payload }) => ({
  ...state,
  user: { ...state.user, ...payload },
});

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logout: (state) => {
      state.isError = false;
      state.status = 'idle';
      state.user = undefined;
      TokenService.clearAllTokens();
      window.location.href = routes.login;
    },
    updateUser: updateUserReducer,
  },
  extraReducers: (builder) => {
    builder.addCase(getUserProfileThunk.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(getUserProfileThunk.fulfilled, (state, action) => {
      const fetchedUser = action.payload;
      state.user = fetchedUser;
      state.status = 'succeeded';

      if (!fetchedUser.did_onboard && window.location.pathname !== routes.onboarding) {
        window.location.href = routes.onboarding;
      }
    });
    builder.addCase(getUserProfileThunk.rejected, (state) => {
      state.user = undefined;
      state.status = 'failed';
    });
  },
});

export const { logout, updateUser } = userSlice.actions;

export const useUserSelector = () => useSelector((state: RootState) => state.user);

export default userSlice.reducer;
