import React, { useCallback, useEffect } from 'react';
import { useAppDispatch } from 'stores/store';
import { openModal, closeModal } from 'stores/modal.slice';
import { SessionOnboarding } from './SessionOnboarding';

export const useSessionOnboarding = () => {
  const dispatch = useAppDispatch();

  const finishOnboarding = useCallback(() => {
    localStorage.setItem('session_onboarding_completed', 'true');
    dispatch(closeModal());
  }, [dispatch]);

  useEffect(() => {
    const onboarding = localStorage.getItem('session_onboarding_completed');
    if (!onboarding || onboarding === 'false') {
      localStorage.setItem('session_onboarding_completed', 'false');
      dispatch(
        openModal({
          content: <SessionOnboarding />,
          handleConfirm: finishOnboarding,
          handleClose: finishOnboarding,
        }),
      );
    }
  }, [finishOnboarding, dispatch]);
};
