import { changeColorOpacity } from 'utils/styleUtils';
import { mainTheme } from 'assets/styles/theme';
import { CharacterSchema } from 'models/db/db_PlayerCharacter';

export type HealthStateOptions = 'healthy' | 'moderate' | 'injured' | 'badly' | 'critical' | 'eliminated';
export type WoundSeverity = 'none' | 'minor' | 'moderate' | 'critical';
export type BodyZone = 'head' | 'torso' | 'arms' | 'legs';

export const HEALTH_STATE_ROLL_MODIFIERS = {
  healthy: 1,
  moderate: 0.9,
  injured: 0.75,
  badly: 0.5,
  critical: 0.25,
  eliminated: 0,
};

export interface Health {
  head: {
    maxHp: number;
    currentHp: number;
  };
  torso: {
    maxHp: number;
    currentHp: number;
  };
  arms: {
    maxHp: number;
    currentHp: number;
  };
  legs: {
    maxHp: number;
    currentHp: number;
  };
}

export interface Wounds {
  head: WoundSeverity;
  torso: WoundSeverity;
  arms: WoundSeverity;
  legs: WoundSeverity;
}

// Player character possible health states and wounds
export const CHARACTER_HEALTH_STATE = {
  healthState: {
    healthy: 'healthy',
    moderate: 'moderate',
    injured: 'injured',
    badly: 'badly',
    critical: 'critical',
    eliminated: 'eliminated',
  },
  wounds: {
    none: 'none',
    minor: 'minor',
    moderate: 'moderate',
    critical: 'critical',
  },
} as const;

export interface Debuff {
  category?: string;
  value: number;
}

export interface Debuffs {
  healthState: {
    [key: string]: Debuff[];
  };
}

export interface CharacterAttributeItem {
  category: string;
  attribute: string;
}

export const getCharacterInitialHealth = (
  characterSchema: CharacterSchema,
  healthCalculationPattern: CharacterAttributeItem[],
): Health => {
  let rawTotalHp = 0;

  healthCalculationPattern.forEach(({ attribute }: CharacterAttributeItem) => {
    rawTotalHp += characterSchema.attributes[attribute] * 10;
  });

  const characterHealth: Health = {
    head: {
      maxHp: rawTotalHp * 0.4,
      currentHp: rawTotalHp * 0.4,
    },
    torso: {
      maxHp: rawTotalHp * 0.3,
      currentHp: rawTotalHp * 0.3,
    },
    arms: {
      maxHp: rawTotalHp * 0.1,
      currentHp: rawTotalHp * 0.1,
    },
    legs: {
      maxHp: rawTotalHp * 0.2,
      currentHp: rawTotalHp * 0.2,
    },
  };

  return characterHealth;
};

export const calculateCharacterHealthState = (health: Health): HealthStateOptions => {
  const { head, torso, arms, legs } = health;

  const totalMaxHp = head.maxHp + torso.maxHp + arms.maxHp + legs.maxHp;
  const totalCurrentHp = head.currentHp + torso.currentHp + arms.currentHp + legs.currentHp;

  const healthPercentage = (totalCurrentHp / totalMaxHp) * 100;

  if (healthPercentage === 100) {
    return CHARACTER_HEALTH_STATE.healthState.healthy;
  }

  if (healthPercentage >= 75) {
    return CHARACTER_HEALTH_STATE.healthState.moderate;
  }

  if (healthPercentage >= 50) {
    return CHARACTER_HEALTH_STATE.healthState.injured;
  }

  if (healthPercentage >= 25) {
    return CHARACTER_HEALTH_STATE.healthState.badly;
  }

  if (healthPercentage >= 20) {
    return CHARACTER_HEALTH_STATE.healthState.critical;
  }

  return CHARACTER_HEALTH_STATE.healthState.eliminated;
};

export const calculateCharacterWounds = (health: Partial<Health>): Wounds => {
  const wounds: Wounds = {
    head: CHARACTER_HEALTH_STATE.wounds.none,
    torso: CHARACTER_HEALTH_STATE.wounds.none,
    arms: CHARACTER_HEALTH_STATE.wounds.none,
    legs: CHARACTER_HEALTH_STATE.wounds.none,
  };

  Object.keys(health).forEach((key) => {
    const bodyPart = health[key as keyof typeof health];
    if (!bodyPart) return;
    const hpPercentage = (bodyPart.currentHp / bodyPart.maxHp) * 100;

    if (hpPercentage === 100) {
      wounds[key as keyof typeof wounds] = CHARACTER_HEALTH_STATE.wounds.none;
      return;
    }

    if (hpPercentage >= 75) {
      wounds[key as keyof typeof wounds] = CHARACTER_HEALTH_STATE.wounds.minor;
      return;
    }

    if (hpPercentage >= 50) {
      wounds[key as keyof typeof wounds] = CHARACTER_HEALTH_STATE.wounds.moderate;
      return;
    }

    wounds[key as keyof typeof wounds] = CHARACTER_HEALTH_STATE.wounds.critical;
  });

  return wounds;
};

export const mapWoundToTextColor = (wound: WoundSeverity): string => {
  if (wound === 'none') return 'healthyState';
  return `${wound}Wound`;
};

export const mapWoundToBackgroundColor = (wound: WoundSeverity): string => {
  if (wound === 'none') return 'transparent';
  const color = mainTheme.colors[`${wound}Wound` as keyof typeof mainTheme.colors];
  return changeColorOpacity(color, 0.15);
};

export const mapWoundToBodyColor = (wound: WoundSeverity): string => {
  const themeWoundKey = wound === 'none' ? 'healthyState' : `${wound}Wound`;
  const color = mainTheme.colors[themeWoundKey as keyof typeof mainTheme.colors];
  return color;
};
