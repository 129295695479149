import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from 'components/generics/Text/Text';
import { PortraitSelector } from 'components/PortraitSelector/PortraitSelector';
import { ColorButton } from 'components/generics/Button/ColorButton';
import { ReactComponent as IconSwap } from 'assets/images/svg/icon_swap_horizontal.svg';
import {
  AttributeContainer,
  AttributeTitle,
  Container,
  SubmitButtonWrapper,
  PerkSelectionGroup,
  PerkSelector,
} from '../CharacterCreatorView.styles';
import { useCharacterCreator } from '../CharacterCreator.hooks';
import { AttributeWithSkills } from '../components/AttributeWIthSkills';
import { ItemContent } from '../components/ItemContent';

interface CharacterBuildProps
  // TODO REFACTOR: Rework this type
  extends Pick<
    ReturnType<typeof useCharacterCreator>,
    'characterSchema' | 'themeApi' | 'isSubmitAllowed' | 'handleSubmit' | 'startingItemsApi'
  > {
  selectedPortraitSrc?: string;
  characterName?: string;
  classTkey?: string;
  setActiveTabId: (id: string) => any;
}
export const CharacterBuild = ({
  selectedPortraitSrc,
  themeApi,
  characterSchema,
  characterName,
  classTkey,
  handleSubmit,
  isSubmitAllowed,
  startingItemsApi,
  setActiveTabId,
}: CharacterBuildProps) => {
  const { t } = useTranslation('launcher', { keyPrefix: 'character_creator_view.build_subsection' });
  const { t: tThemeConfig } = useTranslation('themesIndex');

  const { selectedItemsIds, handleItemsSelection, selectedItems } = startingItemsApi;
  const { selectedThemeId: themeId } = themeApi;

  return (
    <Container>
      <Text fontSize="bigger" fontWeight={600} textAlign="center">
        {characterName}
      </Text>
      <Text
        color="opaqueWhite50"
        letterSpacing="superWide"
        fontWeight={500}
        textTransform="uppercase"
        textAlign="center"
      >
        {tThemeConfig(`${themeId}.character_classes.${classTkey}.name` as any)}
      </Text>
      <PortraitSelector imageSrc={selectedPortraitSrc} />
      <br />
      <Text
        color="opaqueWhite50"
        letterSpacing="superWide"
        fontWeight={500}
        textTransform="uppercase"
        textAlign="center"
      >
        {t('heading')}
      </Text>
      <br />
      <AttributeContainer>
        {Object.keys(characterSchema?.attributes as any).map(
          (attribute) =>
            themeApi.themeConfig?.attrIcons[attribute] !== undefined && (
              <AttributeWithSkills
                key={attribute}
                attributeTkey={tThemeConfig(`${themeId}.character_attributes.${attribute}.title` as any)}
                AttributeIcon={themeApi.themeConfig?.attrIcons[attribute]}
                attributeLevel={characterSchema?.attributes[attribute] ?? 0}
                skills={themeApi.themeConfig.skills[attribute].map((skill) => ({
                  skillTkey: tThemeConfig(`${themeId}.character_skills.${skill}.title` as any),
                  skillLevel: characterSchema?.skills[skill] ?? 0,
                }))}
              />
            ),
        )}
      </AttributeContainer>

      <br />
      <Text
        color="opaqueWhite50"
        letterSpacing="superWide"
        fontWeight={500}
        textTransform="uppercase"
        textAlign="center"
      >
        {t('starting_items')}
      </Text>

      <PerkSelectionGroup>
        {selectedItemsIds && selectedItemsIds?.length >= 1 ? (
          <PerkSelector
            onClick={() => {
              if (selectedItemsIds && selectedItemsIds?.length >= 1) {
                const selectedPerkId = selectedItemsIds[1];
                handleItemsSelection(selectedPerkId);
              }
              setActiveTabId('itemSelect');
            }}
          >
            <ItemContent selectedItem={selectedItems[0]} themeId={themeApi.selectedThemeId} />
            <IconSwap />
          </PerkSelector>
        ) : (
          <PerkSelector onClick={() => setActiveTabId('itemSelect')}>
            <Text>{t('select_item_prompt')}</Text>
            <IconSwap />
          </PerkSelector>
        )}
        {selectedItemsIds && selectedItemsIds?.length === 2 ? (
          <PerkSelector
            onClick={() => {
              if (selectedItemsIds?.length === 2) {
                const selectedPerkId = selectedItemsIds[1];
                handleItemsSelection(selectedPerkId);
              }
              setActiveTabId('itemSelect');
            }}
          >
            <ItemContent selectedItem={selectedItems[1]} themeId={themeApi.selectedThemeId} />
            <IconSwap />
          </PerkSelector>
        ) : (
          <PerkSelector onClick={() => setActiveTabId('itemSelect')}>
            <Text>{t('select_item_prompt')}</Text>
            <IconSwap />
          </PerkSelector>
        )}
      </PerkSelectionGroup>

      <SubmitButtonWrapper>
        <AttributeTitle>
          <ColorButton color="secondary" onClick={() => setActiveTabId('form')}>
            {t('back_to_form_button')}
          </ColorButton>
          <ColorButton onClick={handleSubmit} isDisabled={!isSubmitAllowed}>
            {t('finalize_button')}
          </ColorButton>
        </AttributeTitle>
      </SubmitButtonWrapper>
    </Container>
  );
};
