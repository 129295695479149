import styled from 'styled-components';
import { ReactComponent as ChevronDown } from 'assets/images/svg/chevron-down.svg';
import { pxToRem } from 'utils/styleUtils';

export const CollapsibleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;
`;

export const CollapsibleLabel = styled.button`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
`;

export const CollapsibleContent = styled.div<{ isExpanded?: boolean }>`
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;
  max-height: ${({ isExpanded }) => (isExpanded ? 'auto' : '0')};
  opacity: ${({ isExpanded }) => (isExpanded ? '1' : '0')};
  transition: all ${({ theme }) => theme.transition.baseDuration} ease-in-out;
  overflow: hidden;
`;

export const DropdownArrowIcon = styled(ChevronDown)<{ isExpanded?: boolean }>`
  width: ${pxToRem(28)};
  height: ${pxToRem(28)};
  stroke: ${({ theme }) => theme.colors.opaqueWhite25};
  transition: transform ${({ theme }) => theme.transition.baseDuration} ease-in-out;

  ${({ isExpanded }) =>
    isExpanded &&
    `
        transform: rotate(180deg);
    `}
`;
