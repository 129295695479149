import styled, { css } from 'styled-components';

export const SliderContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  margin: 1rem 0;

  user-select: none;
  * {
    user-select: none;
  }
`;

export const SliderSectionBar = styled.div<{ color: string }>`
  width: 20%;
  min-height: 0.5rem;

  background: ${({ color }) => color};
`;

export const SliderTouchpoints = styled.div`
  position: absolute;
  top: -0.5rem;
  width: 100%;
  height: 1.5rem;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
`;

export const SliderTouchpoint = styled.button<{ isSelected: boolean }>`
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  border: 0.2rem solid ${({ theme }) => theme.colors.opaqueWhite50};
  cursor: pointer;

  ${({ isSelected, theme }) =>
    isSelected &&
    css`
      width: 2rem;
      height: 2rem;
      background-color: ${theme.colors.opaqueBlack50};
      border-color: ${theme.colors.white};
      border-width: 0.4rem;
    `}
`;
