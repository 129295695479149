import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { Text } from 'components/generics/Text/Text';
import { Spinner } from 'components/generics/Spinner/Spinner';

import { LocationsService } from 'services/LocationsService';
import { ReactComponent as IconSwap } from 'assets/images/svg/icon_swap_horizontal.svg';
import { ColorButton } from 'components/generics/Button/ColorButton';
import { Location } from 'models/db/resource_Location';
import { LocationPack } from 'models/db/resource_LocationPack';
import { Select } from 'components/generics/Select/Select';
import {
  Container,
  ConfirmButtonWrapper,
  Selector,
  CoverImageVerticalLayout,
  OptionVerticalLayout,
  Overlay,
} from './LocationSelectOverlay.styles';

interface LocationSelectOverlayProps {
  themeId: string;
  selectedLocation?: Location;
  selectedLocationPack?: LocationPack;
  handleLocationSelection: (location?: Location) => any;
  handleLocationPackSelection: (locationPack?: LocationPack) => any;
  isOverlayActive: boolean;
  toggleOverlay: () => any;
  handleConfirmAction?: () => any;
  onlyStartingLocations?: boolean;
}

export const LocationSelectOverlay = ({
  themeId,
  selectedLocation,
  selectedLocationPack,
  handleLocationSelection,
  handleLocationPackSelection,
  isOverlayActive,
  toggleOverlay,
  handleConfirmAction,
  onlyStartingLocations = false,
}: LocationSelectOverlayProps) => {
  const [taggedForSelection, setTaggedForSelection] = useState<Location>();
  const { t } = useTranslation('launcher', { keyPrefix: 'create_new_session.starting_location_select' });
  const { t: tLocationPacks } = useTranslation('locationPacks' as 'resource');
  const { t: tLocation } = useTranslation('locations' as 'resource');

  const { data: locationPacksData, isLoading: isLocationPacksLoading } = useQuery(['getLocationPacks', themeId], () =>
    LocationsService.getLocationPacks(themeId),
  );
  const { data: startingLocationsData, isLoading: isStartingLocationsLoading } = useQuery(
    ['getStartingLocations', selectedLocationPack?.id],
    () => LocationsService.getStartingLocations(selectedLocationPack?.id ?? ''),
    { enabled: !!selectedLocationPack && !!onlyStartingLocations },
  );

  const { data: locationsData, isLoading: isLocationsLoading } = useQuery(
    ['getLocations', selectedLocationPack?.id],
    () => LocationsService.getLocations(selectedLocationPack?.id ?? ''),
    { enabled: !!selectedLocationPack && !onlyStartingLocations },
  );
  const locations = onlyStartingLocations ? startingLocationsData?.data : locationsData?.data;

  const isLoading = isLocationPacksLoading || (onlyStartingLocations ? isStartingLocationsLoading : isLocationsLoading);

  const tagForSelection = (location: Location) => {
    if (taggedForSelection?.id === location.id) {
      setTaggedForSelection(undefined);
      handleLocationSelection(undefined);
      return;
    }
    handleLocationSelection(location);
    setTaggedForSelection(location);
  };

  const confirmSelection = () => {
    toggleOverlay();
    if (handleConfirmAction) handleConfirmAction();
  };

  return isOverlayActive ? (
    <Overlay>
      <Text fontSize="bigger" fontWeight={500}>
        {t('overlay_heading')}
      </Text>
      {isLocationPacksLoading ? (
        <Spinner center />
      ) : (
        <>
          <Select
            label={t('pack_label')}
            defaultValue={
              selectedLocationPack
                ? {
                    value: selectedLocationPack,
                    label: tLocationPacks(`${themeId}.${selectedLocationPack?.t_key}.name` as any),
                  }
                : undefined
            }
            options={locationPacksData?.data.map((locationPack) => ({
              value: locationPack,
              label: tLocationPacks(`${themeId}.${locationPack.t_key}.name` as any),
            }))}
            isSearchable
            isClearable
            onChange={(option: any) => handleLocationPackSelection(option ? option.value : undefined)}
          />
          {selectedLocationPack && (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
              {isLoading ? (
                <Spinner center />
              ) : (
                locations?.map((location) => (
                  <OptionVerticalLayout
                    key={location.id}
                    onClick={() => tagForSelection(location)}
                    isSelected={taggedForSelection?.id === location.id}
                  >
                    <Text fontSize="small" fontWeight={600} color="opaqueWhite50">
                      {t('location_keyword')} •{' '}
                      {tLocationPacks(`${themeId}.${selectedLocationPack?.t_key}.name` as any)}
                    </Text>
                    <CoverImageVerticalLayout src={location.cover_img_url} loading="lazy" />
                    <Text fontSize="small" fontWeight={600}>
                      {tLocation(`${themeId}.${selectedLocationPack?.t_key}.${location.t_key}.title` as any)}
                    </Text>
                    <Text fontSize="smaller" textAlign="left">
                      {tLocation(`${themeId}.${selectedLocationPack?.t_key}.${location.t_key}.description` as any)}
                    </Text>
                  </OptionVerticalLayout>
                ))
              )}
            </>
          )}
        </>
      )}
      <ConfirmButtonWrapper>
        <ColorButton isDisabled={selectedLocationPack && !taggedForSelection} onClick={confirmSelection}>
          {t('confirm_button')}
        </ColorButton>
      </ConfirmButtonWrapper>
    </Overlay>
  ) : (
    <Container>
      <Text
        fontWeight={500}
        color="opaqueWhite50"
        fontSize="smaller"
        letterSpacing="superWide"
        textTransform="uppercase"
      >
        {t('label')}
      </Text>
      <Selector onClick={toggleOverlay}>
        {selectedLocation ? (
          `${tLocationPacks(`${themeId}.${selectedLocationPack?.t_key}.name` as any)} - ${tLocation(
            `${themeId}.${selectedLocationPack?.t_key}.${selectedLocation.t_key}.title` as any,
          )}`
        ) : (
          <Text color="opaqueWhite50">{t('placeholder')}</Text>
        )}
        <IconSwap />
      </Selector>
    </Container>
  );
};
