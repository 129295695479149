import React from 'react';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter } from 'utils/capitalizeFirstLetter';
import { Text } from 'components/generics/Text/Text';
import { HealthModel } from 'components/HealthModel/HealthModel';
import { Health, calculateCharacterHealthState, HEALTH_STATE_ROLL_MODIFIERS } from 'engine/health';
import { Card } from 'components/Card/Card';
import { TextGroup } from '../PartyView.styles';

interface StateSectionProps {
  characterHealth?: Health | null;
  showModel?: boolean;
  showStateCard?: boolean;
  showLabel?: boolean;
}

const StateSection = ({
  characterHealth,
  showModel = true,
  showStateCard = true,
  showLabel = true,
}: StateSectionProps) => {
  const { t } = useTranslation('gameplay');

  if (!characterHealth) return null;

  const calculatedHealthState = calculateCharacterHealthState(characterHealth);

  return (
    <TextGroup>
      {showLabel && (
        <Text
          fontWeight={500}
          color="opaqueWhite50"
          fontSize="smaller"
          letterSpacing="superWide"
          textTransform="uppercase"
        >
          {t('party_view.state_section.label')}
        </Text>
      )}
      {showStateCard && (
        <Card>
          <Text color={`${calculatedHealthState}State`} fontWeight={700} fontSize="big">
            {capitalizeFirstLetter(t(`character_status.health_state.${calculatedHealthState}` as any))}
          </Text>
          {calculatedHealthState !== 'healthy' && (
            <Text fontWeight={500}>
              {t('party_view.state_section.roll_multiplier')}: x{HEALTH_STATE_ROLL_MODIFIERS[calculatedHealthState]}
            </Text>
          )}
        </Card>
      )}
      {showModel && <HealthModel characterHealth={characterHealth} />}
    </TextGroup>
  );
};

export default StateSection;
