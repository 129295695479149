import styled from 'styled-components';
import { pxToRem } from 'utils/styleUtils';

export const Wrapper = styled.div`
  display: flex;
  margin: ${pxToRem(16)} 0;
  flex-direction: column;
  gap: ${pxToRem(16)};
`;

export const Group = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${pxToRem(8)};
`;

export const Centerer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${pxToRem(6)};
  align-items: center;
`;

export const ButtonsWrapper = styled.div`
  margin-top: ${pxToRem(16)};
  display: flex;
  flex-direction: column;
  gap: ${pxToRem(12)};
  align-items: center;

  & > button {
    width: ${pxToRem(140)};
  }
`;

export const CoinsIcon = styled.img`
  width: ${pxToRem(18)};
  height: ${pxToRem(18)};
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  gap: ${pxToRem(8)};
`;

export const MoneyChangeContainer = styled.div`
  display: flex;
  gap: ${pxToRem(12)};
  align-items: center;
  margin-right: ${pxToRem(18)};

  & input {
    font-weight: 700;
    text-align: center;
  }
`;

export const MoneyToggle = styled.button`
  width: ${pxToRem(36)};
  text-align: right;
  border: none;
`;
