import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { TextInput } from 'components/generics/Form/TextInput/TextInput';
import { updateUser, useUserSelector } from 'stores/user.slice';
import { Text } from 'components/generics/Text/Text';
import { ColorButton } from 'components/generics/Button/ColorButton';
import { Helmet } from 'components/Helmet/Helmet';
import { useMutation } from '@tanstack/react-query';
import { UpdateProfileExtrasBody, UsersService } from 'services/UsersService';
import { useAppDispatch } from 'stores/store';
import { User } from 'models/User';
import { useNavigate } from 'react-router-dom';
import { routes } from 'components/MainRouter/MainRouter';
import { Spinner } from 'components/generics/Spinner/Spinner';
import { Container, HeadingWrapper } from './OnboardingView.styles';

export const OnboardingView = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('launcher', { keyPrefix: 'onboarding_view' });
  const { t: tErrors } = useTranslation('launcher', { keyPrefix: 'form_errors' });

  const { user, status } = useUserSelector();
  const dispatch = useAppDispatch();

  const redirectIfOnboarded = () => {
    if (status === 'succeeded' && user && user.did_onboard) {
      navigate(routes.home);
    }
  };

  useEffect(redirectIfOnboarded, [status, user, navigate]);

  const displayNameValidationSchema = Yup.object().shape({
    displayName: Yup.string().required(tErrors('display_name_required')).max(30, tErrors('display_name_too_long')),
  });

  const displayNameFormMutation = useMutation({
    mutationFn: (body: UpdateProfileExtrasBody) =>
      UsersService.updateProfileExtras(user ? user.id : 'x', body).then((response) => {
        if (response.status === 204) {
          dispatch(updateUser(body as User));
          navigate(routes.home);
        } else {
          toast.error(tErrors('generic_error'));
        }
      }),
  });

  const displayNameForm = useFormik({
    initialValues: {
      displayName: user?.display_name ?? '',
    },
    validationSchema: displayNameValidationSchema,
    onSubmit: (values) =>
      displayNameFormMutation.mutate({
        display_name: values.displayName,
        did_onboard: true,
      }),
  });

  return (
    <>
      <Helmet>
        <title>Onboarding</title>
      </Helmet>
      <Container onSubmit={displayNameForm.handleSubmit}>
        {displayNameFormMutation.isLoading ? (
          <Spinner center />
        ) : (
          <>
            <HeadingWrapper>
              <Text fontSize="bigger" fontWeight={600}>
                {t('heading_1')}
              </Text>
              <Text>{t('heading_2')}</Text>
            </HeadingWrapper>
            <TextInput
              name="displayName"
              value={displayNameForm.values.displayName}
              onChange={displayNameForm.handleChange}
              onBlur={displayNameForm.handleBlur}
              label={t('input_label')}
              error={displayNameForm.touched.displayName ? displayNameForm.errors.displayName : undefined}
            />
            <ColorButton type="submit">{t('button')}</ColorButton>
          </>
        )}
      </Container>
    </>
  );
};
