import React from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useMutation } from '@tanstack/react-query';
import { ResetPasswordBody, UsersService } from 'services/UsersService';
import { Text } from 'components/generics/Text/Text';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'components/generics/Spinner/Spinner';
import { routes } from 'components/MainRouter/MainRouter';
import { Helmet } from 'components/Helmet/Helmet';
import { ColorButton } from 'components/generics/Button/ColorButton';

import { LOWERCASE_REGEX, NUMERIC_REGEX, SPECIAL_CHARACTER_REGEX, UPPERCASE_REGEX } from 'utils/regex';
import { Cta } from 'components/generics/Form/AuthLayout/AuthLayout.styles';
import { TokenService } from 'services/TokenService';
import { getEnv } from 'utils/getEnv';
import { getUserProfileThunk } from 'stores/user.thunks';
import { useOnlyDevelopment } from 'hooks/useOnlyDevelopment';
import { useAppDispatch } from 'stores/store';
import { PasswordInput } from 'components/generics/Form/PasswordInput/PasswordInput';
import { Form, Wrapper } from './ResetPasswordView.styles';

export const ResetPasswordView = () => {
  const { t } = useTranslation('launcher', { keyPrefix: 'reset_password_view' });
  const { t: tValidation } = useTranslation('launcher', { keyPrefix: 'form_errors' });
  const dispatch = useAppDispatch();

  useOnlyDevelopment();

  const formMutation = useMutation({
    mutationFn: (body: ResetPasswordBody) => {
      const externalOauthCredentials = localStorage.getItem(getEnv('REACT_APP_SB_AUTH_TOKEN_NAME'));

      if (externalOauthCredentials) {
        const { access_token: accessToken, refresh_token: refreshToken } =
          JSON.parse(externalOauthCredentials ?? '') ?? {};
        if (accessToken && refreshToken) {
          TokenService.accessToken.setToken(accessToken);
          TokenService.refreshToken.setToken(refreshToken);
          localStorage.removeItem(getEnv('REACT_APP_SB_AUTH_TOKEN_NAME'));
        }
        dispatch(getUserProfileThunk());
      }

      return UsersService.resetPassword(body);
    },
  });

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required(tValidation('field_required'))
      .matches(LOWERCASE_REGEX, tValidation('password_lowercase_missing'))
      .matches(UPPERCASE_REGEX, tValidation('password_uppercase_missing'))
      .matches(NUMERIC_REGEX, tValidation('password_number_missing'))
      .matches(SPECIAL_CHARACTER_REGEX, tValidation('password_special_character_missing'))
      .min(8, tValidation('password_too_short')),
    confirmPassword: Yup.string()
      .required(tValidation('field_required'))
      .oneOf([Yup.ref('password'), null], tValidation('passwords_not_match')),
  });

  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema,
    onSubmit: (values) => {
      formMutation.mutate({ password: values.password });
    },
  });

  return (
    <>
      <Helmet>
        <title>{t('title')}</title>
      </Helmet>
      <Wrapper>
        {formMutation.isSuccess ? (
          <>
            <Text>{t('success_heading')}</Text>
            <Text>{t('success_text')}</Text>
            <Cta to={routes.login}>{t('log_in_button')}</Cta>
          </>
        ) : (
          <Form onSubmit={handleSubmit}>
            <PasswordInput
              value={values.password}
              name="password"
              label={t('label_password')}
              type="password"
              onChange={handleChange}
              error={touched.password ? errors.password : ''}
            />
            <PasswordInput
              value={values.confirmPassword}
              name="confirmPassword"
              label={t('label_confirm_password')}
              type="password"
              onChange={handleChange}
              error={touched.confirmPassword ? errors.confirmPassword : ''}
            />
            {formMutation.isError && <Text color="secondary">{t('error_text')}</Text>}
            {formMutation.isLoading ? <Spinner /> : <ColorButton type="submit">{t('submit_button')}</ColorButton>}
          </Form>
        )}
      </Wrapper>
    </>
  );
};
