import styled from 'styled-components';
import { pxToRem } from 'utils/styleUtils';
import { BlandButton } from 'components/generics/Button/BlandButton';

export const AttributeContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;
`;

export const AttributeLabel = styled.button`
  display: flex;
  flex-flow: row nowrap;
  gap: 1rem;
  align-items: center;
  width: 100%;

  user-select: none;
  cursor: pointer;
`;

export const AttributeIconWrapper = styled.div`
  svg {
    width: ${pxToRem(48)};
    height: ${pxToRem(48)};
  }
`;

export const DropdownIconWrapper = styled.div`
  margin-left: auto;
`;

export const DicePoolContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 0.5rem;
  align-items: center;
`;

export const DicePoolItem = styled.div`
  display: flex;
  flex-flow: row nowrap;
  gap: 0.25rem;
  align-items: center;

  img {
    width: ${pxToRem(24)};
    height: ${pxToRem(24)};
  }
`;

export const ExpandableContent = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 0.5rem;
  width: 100%;
  padding-left: 1rem;
`;

export const SkillsList = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;
`;

export const CollapsedSkill = styled.button`
  display: flex;
  flex-flow: row nowrap;
  gap: 1rem;
  align-items: center;
  width: 100%;

  user-select: none;
  cursor: pointer;
`;

export const ExpandedSkill = styled.button`
  display: flex;
  padding: 0.5rem;
  flex-flow: column nowrap;
  gap: 0.5rem;
  text-align: left;
  cursor: pointer;
  user-select: none;

  background-color: ${({ theme }) => theme.colors.opaqueBlack25};
  border-radius: ${({ theme }) => theme.border.radiusBase};
`;
