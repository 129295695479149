import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from 'components/generics/Text/Text';
import { Select } from 'components/generics/Select/Select';
import { useGameplaySelector } from 'stores/gameplay.slice';
import { capitalizeFirstLetter } from 'utils/capitalizeFirstLetter';
import { HealthModel } from 'components/HealthModel/HealthModel';
import { SupabaseService } from 'services/SupabaseService';
import { DB_TABLE_NAMES } from 'utils/consts';
import { BodyZone, Health, WoundSeverity, calculateCharacterHealthState } from 'engine/health';
import { Card } from 'components/Card/Card';
import { ColorButton } from 'components/generics/Button/ColorButton';
import { BlandButton } from 'components/generics/Button/BlandButton';
import { SessionPlayer } from 'models/db/instance_SessionPlayer';
import { SessionRecord } from 'models/db/db_SessionRecord';
import { OmitAutoGenerated } from 'models/Api';
import { GetPlayersForSessionRow } from 'models/functions/GetPlayersForSession';
import { ButtonsWrapper, Centerer, Group } from '../../ManagementView.styles';

type Option = { value: string; label: string };

const calculateSeverityToHpPercentage = (severity: WoundSeverity) => {
  switch (severity) {
    case 'none':
      return 1;
    case 'minor':
      return 0.75;
    case 'moderate':
      return 0.5;
    case 'critical':
      return 0.2;
    default:
      return 1;
  }
};

interface HealthManagementProps {
  selectedCharacter: GetPlayersForSessionRow | undefined;
}

export const HealthManagement = ({ selectedCharacter }: HealthManagementProps) => {
  const { t } = useTranslation('gameplay');

  const { activeSession } = useGameplaySelector();
  const [isManagingWounds, setIsManagingWounds] = useState(false);
  const [selectedBodyZone, setSelectedBodyZone] = useState<BodyZone | null>(null);
  const [selectedSeverity, setSelectedSeverity] = useState<WoundSeverity | null>(null);

  const handleConfirm = async () => {
    const health = { ...selectedCharacter?.health };
    if (!health || !selectedBodyZone || !selectedSeverity || !activeSession) return;

    const bodyZone = health[selectedBodyZone];
    if (!bodyZone) return;

    const newHp = bodyZone.maxHp * calculateSeverityToHpPercentage(selectedSeverity);

    const newHealth = {
      ...health,
      [selectedBodyZone]: {
        ...bodyZone,
        currentHp: newHp,
      },
    } as Health;

    await SupabaseService.from(DB_TABLE_NAMES.instanceSessionPlayer)
      .update<Partial<SessionPlayer>>({ health: newHealth })
      .eq('id', selectedCharacter?.fingerprint);

    const oldHealthState = calculateCharacterHealthState(health as Health);
    const newHealthState = calculateCharacterHealthState(newHealth as Health);

    await SupabaseService.from(DB_TABLE_NAMES.dbSessionRecord).insert<OmitAutoGenerated<SessionRecord>>([
      {
        player_character_id: undefined,
        session_id: activeSession?.id,
        record_type: 'health_change',
        content: {
          character_id: selectedCharacter?.character_id ?? '',
          healthChange:
            oldHealthState !== newHealthState
              ? {
                  from: oldHealthState,
                  to: newHealthState,
                }
              : undefined,
          woundChange: {
            bodyZone: selectedBodyZone,
            from: bodyZone,
            to: newHealth[selectedBodyZone as keyof typeof newHealth],
          },
        },
      },
    ]);

    setIsManagingWounds(false);
    setSelectedBodyZone(null);
    setSelectedSeverity(null);
  };

  const bodyZonesOptions: Option[] = useMemo(
    () => [
      { value: 'head', label: t('character_status.body_parts.head') },
      { value: 'torso', label: t('character_status.body_parts.torso') },
      { value: 'arms', label: t('character_status.body_parts.arms') },
      { value: 'legs', label: t('character_status.body_parts.legs') },
    ],
    [t],
  );

  const severityOptions: Option[] = useMemo(
    () => [
      { value: 'none', label: t('character_status.wounds.none') },
      { value: 'minor', label: t('character_status.wounds.minor') },
      { value: 'moderate', label: t('character_status.wounds.moderate') },
      { value: 'critical', label: t('character_status.wounds.critical') },
    ],
    [t],
  );

  return (
    <>
      <Group>
        <Text color="opaqueWhite50">{t('management_view.manage_health.heading')}</Text>
        {selectedCharacter?.health && (
          <Card>
            <Text
              color={`${calculateCharacterHealthState(selectedCharacter?.health)}State`}
              fontWeight={700}
              fontSize="big"
            >
              {capitalizeFirstLetter(
                t(`character_status.health_state.${calculateCharacterHealthState(selectedCharacter.health)}` as any),
              )}
            </Text>
          </Card>
        )}
      </Group>
      {selectedCharacter?.health && <HealthModel characterHealth={selectedCharacter?.health} />}
      {isManagingWounds ? (
        <>
          <Select
            label={t('management_view.manage_health.body_zone_label')}
            options={bodyZonesOptions}
            onChange={(newValue) => setSelectedBodyZone((newValue as Option).value as BodyZone)}
            isSearchable={false}
          />
          <Select
            label={t('management_view.manage_health.severity_label')}
            options={severityOptions}
            onChange={(newValue) => setSelectedSeverity((newValue as Option).value as WoundSeverity)}
            isSearchable={false}
          />
          <ButtonsWrapper>
            <ColorButton onClick={handleConfirm} isDisabled={!selectedBodyZone || !selectedSeverity}>
              <Text fontSize="smaller" textTransform="uppercase">
                {t('management_view.confirm_button')}
              </Text>
            </ColorButton>
            <ColorButton onClick={() => setIsManagingWounds(false)} color="secondary">
              <Text fontSize="smaller" textTransform="uppercase">
                {t('management_view.cancel_button')}
              </Text>
            </ColorButton>
          </ButtonsWrapper>
        </>
      ) : (
        <Centerer>
          <BlandButton onClick={() => setIsManagingWounds(true)}>
            <Text fontSize="smaller">{t('management_view.manage_health.manage_wounds_button')}</Text>
          </BlandButton>
        </Centerer>
      )}
    </>
  );
};
