import styled from 'styled-components';
import { pxToRem } from 'utils/styleUtils';

export const SessionOnboardingContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  padding: 1.5rem;
  align-items: center;
  gap: 1.5rem;
`;

export const OnboardingSection = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
`;

export const MockMenuButton = styled.div`
  width: ${pxToRem(64)};
  height: ${pxToRem(64)};
  border: none;
  background: ${({ theme }) => theme.colors.opaqueWhite15};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;

  & svg {
    width: ${pxToRem(38)};
    height: ${pxToRem(38)};
  }
`;

export const MockSideButton = styled.div`
  width: 3rem;
  height: 3rem;
  z-index: 2000;

  border-radius: 100%;
  border: 0.1rem solid #4895ef;

  display: flex;
  align-items: center;
  justify-content: center;
  filter: drop-shadow(0 0.15rem 0.15rem rgba(0, 0, 0, 0.75));

  transition: transform 0.2s ease-in-out;

  svg {
    fill: ${({ theme }) => theme.colors.white};
    width: 1.5rem;
    height: 1.5rem;
  }
`;
