import React from 'react';
import { Collapsible } from 'components/Collapsible/Collapsible';
import { Text } from 'components/generics/Text/Text';
import { AttributeContainer, AttributeTitle, SkillsContainer } from '../CharacterCreatorView.styles';

interface Props {
  attributeTkey: string;
  AttributeIcon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  attributeLevel: number;
  skills: {
    skillTkey: string;
    skillLevel: number;
  }[];
}

export const AttributeWithSkills = ({ attributeTkey, AttributeIcon, attributeLevel, skills }: Props) => (
  <AttributeContainer>
    <Collapsible
      label={
        <AttributeTitle>
          <AttributeIcon />
          <Text fontWeight={600} fontSize="big">
            {attributeTkey}:
          </Text>
          <Text fontWeight={600} fontSize="bigger">
            {attributeLevel}
          </Text>
        </AttributeTitle>
      }
    >
      <SkillsContainer>
        {skills.map(({ skillTkey, skillLevel }) => (
          <AttributeTitle key={skillTkey}>
            <Text fontWeight={600} textTransform="uppercase" color={skillLevel <= 0 ? 'opaqueWhite25' : 'white'}>
              {skillTkey}:
            </Text>
            <Text fontWeight={600} fontSize="big" color={skillLevel <= 0 ? 'opaqueWhite25' : 'white'}>
              {skillLevel}
            </Text>
          </AttributeTitle>
        ))}
      </SkillsContainer>
    </Collapsible>
  </AttributeContainer>
);
