import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { dynamicRoutes, routes } from 'components/MainRouter/MainRouter';
import { GetUserSessionsReturn } from 'models/functions/GetUserSessions';
import { BlandButton } from 'components/generics/Button/BlandButton';
import { Text } from 'components/generics/Text/Text';
import { ButtonsWrapper, Divider, ListContainer, NoSessionsWrapper, SectionContainer } from './SessionList.styles';
// import { SessionListFilters } from './SessionListFilters';
import { SessionListItem } from './SessionListItem';

const SessionList = ({ sessions, currentUserId }: { sessions: GetUserSessionsReturn; currentUserId: string }) => {
  const navigate = useNavigate();
  const { t } = useTranslation('launcher', { keyPrefix: 'sessions_view' });

  return (
    <SectionContainer>
      {/* <SessionListFilters /> */}
      <ListContainer>
        {sessions.length === 0 && (
          <NoSessionsWrapper>
            <Text textAlign="center" color="opaqueWhite75">
              {t('no_sessions')}
            </Text>
          </NoSessionsWrapper>
        )}
        {sessions.map((session) => (
          <SessionListItem
            key={session.id}
            onClick={() => navigate(dynamicRoutes.session(session.id))}
            title={session.title}
            updatedAt={session.updated_at}
            isUserGM={currentUserId === session.owner_id}
            playersCount={session.playerscount}
            portrait={session.portrait}
            themeId={session.theme_id ?? ''}
            appVersion={session.app_version}
          />
        ))}
      </ListContainer>
      <ButtonsWrapper>
        <Divider />
        <BlandButton onClick={() => navigate(routes.createSession)}>
          <Text as="span" color="highlightYellow" fontWeight={700} fontSize="smaller">
            +
          </Text>
          <Text as="span" fontWeight={700} fontSize="smaller">
            {t('create_new_session')}
          </Text>
        </BlandButton>
        <BlandButton onClick={() => navigate(routes.joinSession)}>
          <Text as="span" color="primary" fontWeight={700} fontSize="smaller">
            +
          </Text>
          <Text as="span" fontWeight={700} fontSize="smaller">
            {t('join_session_by_code')}
          </Text>
        </BlandButton>
      </ButtonsWrapper>
    </SectionContainer>
  );
};
export default SessionList;
