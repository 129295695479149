import { routes } from 'components/MainRouter/MainRouter';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getEnv } from 'utils/getEnv';

export const useOnlyDevelopment = (redirectTo = routes.home) => {
  const navigate = useNavigate();
  const environment = getEnv('NODE_ENV');

  useEffect(() => {
    if (environment !== 'development') {
      navigate(redirectTo);
    }
  }, [environment, redirectTo, navigate]);
};
