import React from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox } from 'components/generics/Checkbox/Checkbox';
import { Text } from 'components/generics/Text/Text';
import { Item } from 'models/db/resource_Item';
import { CheckboxLabel, Container } from './CharacterSchemaCheckboxes.styles';

interface Props {
  themeId: string;
  characterEquippedItems: Omit<Item, 'created_at'>[];
  selectedInventoryModId: string;
  handleSelectInventoryMod: (inventoryModId: string) => any;
}

export const CharacterItemsCheckboxes = ({
  themeId,
  characterEquippedItems,
  selectedInventoryModId,
  handleSelectInventoryMod,
}: Props) => {
  const { t } = useTranslation('items' as 'resource');

  return (
    <Container>
      {characterEquippedItems.map((item) => (
        <Checkbox
          key={item.id}
          label={
            <CheckboxLabel>
              <Text fontWeight={600} textTransform="uppercase" letterSpacing="wide">
                {t(`${themeId}.${item.type}.${item.content?.subtype}.${item.t_key}.name` as any)}
              </Text>
              {item.content?.roll_modifier && (
                <Text fontWeight={600} textTransform="uppercase" letterSpacing="wide">
                  : + {item.content?.roll_modifier}
                </Text>
              )}
            </CheckboxLabel>
          }
          onClick={() => handleSelectInventoryMod(item.id)}
          isChecked={selectedInventoryModId === item.id}
        />
      ))}
    </Container>
  );
};
