import { useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { SupabaseService } from 'services/SupabaseService';
import { DB_TABLE_NAMES, QUERY_KEYS } from 'utils/consts';

export const useSessionLogListener = (sessionId: string) => {
  const queryClient = useQueryClient();

  useEffect(() => {
    // Session log channel
    const sessionRecordsChannel = SupabaseService.channel(QUERY_KEYS.channels.sessionRecords(sessionId));
    const sessionRecordsEventHandler = (payload: any) => {
      // Handle new session log record and update the query data
      queryClient.setQueryData([QUERY_KEYS.gameplay.sessionRecords, sessionId], (oldData: any) => ({
        data: [...oldData.data, payload.new],
      }));
    };
    sessionRecordsChannel
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: DB_TABLE_NAMES.dbSessionRecord,
          filter: `session_id=eq.${sessionId}`,
        },
        sessionRecordsEventHandler,
      )
      .subscribe();
  }, [sessionId, queryClient]);
};
