import styled, { css } from 'styled-components';
import { pxToRem } from 'utils/styleUtils';

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  background-color: #00000066;
  backdrop-filter: blur(${pxToRem(5)});
  z-index: 100;
`;

export const StyledModal = styled.div<{ fullscreen?: boolean }>`
  position: relative;
  padding: ${pxToRem(24)};
  padding-top: ${pxToRem(30)};
  background: linear-gradient(180deg, #001247 0%, #25034c 100%);
  box-shadow: ${`${pxToRem(2)} ${pxToRem(4)} ${pxToRem(6)} ${pxToRem(0)} #00000080`};
  border-radius: ${pxToRem(16)};

  ${({ fullscreen }) =>
    fullscreen &&
    css`
      background: ${({ theme }) => theme.colors.opaqueBlack75};
      width: 95vw;
      height: 95vh;
      border-radius: 0;
      display: flex;
    `}
`;

export const ContentWrapper = styled.div<{ fullscreen?: boolean }>`
  max-width: 70vw;
  max-height: 70vh;
  overflow-y: auto;

  ${({ fullscreen }) =>
    fullscreen &&
    css`
      max-width: 100%;
      max-height: 100%;
    `}
`;

export const CloseButtonWrapper = styled.div`
  position: absolute;
  top: ${pxToRem(12)};
  right: ${pxToRem(12)};
  width: ${pxToRem(14)};
`;

export const CloseButton = styled.button`
  width: ${pxToRem(14)};
  height: ${pxToRem(14)};
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.secondary};
  cursor: pointer;
  border: none;
`;

export const ConfirmButtonWrapper = styled.div`
  margin-top: 2rem;
`;
