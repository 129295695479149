import styled from 'styled-components';
import { pxToRem } from 'utils/styleUtils';

export const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;
`;

export const CheckboxLabel = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 0.5rem;
  align-items: center;
`;

export const AttributeCheckboxIcon = styled.div`
  svg {
    width: ${pxToRem(24)};
    height: ${pxToRem(24)};
  }
`;

export const Sublist = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;
  margin-left: 1rem;
`;
