import styled, { css } from 'styled-components';
import { pxToRem } from 'utils/styleUtils';

export const DiceButton = styled.button<{
  $width?: string;
  $height?: string;
  hasBackground?: boolean;
  isClickable?: boolean;
}>`
  width: ${({ $width }) => $width ?? pxToRem(88)};
  height: ${({ $height }) => $height ?? pxToRem(88)};
  border-radius: ${pxToRem(6)};
  background-color: ${({ hasBackground, theme }) => (hasBackground ? theme.colors.opaqueWhite10 : 'transparent')};
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;

  ${({ isClickable }) =>
    isClickable &&
    css`
      cursor: pointer;
    `}
`;

export const DiceIcon = styled.div<{ withLabel?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ withLabel }) =>
    withLabel &&
    css`
      padding: 0.75rem;
    `}

  & > svg {
    width: 100%;
    height: 100%;
    aspect-ratio: 1;
  }
`;

export const DiceTitle = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  filter: drop-shadow(0.2rem 0.2rem 0.2rem black);
`;
