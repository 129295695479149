import React, { useMemo, useState } from 'react';
import { v4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { Text } from 'components/generics/Text/Text';
import { ThemeConfig } from 'engine/themesConfig';
import { ATTRIBUTE_LEVEL_ROLL_SCHEMA, SKILL_LEVEL_ROLL_SCHEMA, getDiceImage } from 'engine/rolls';
import { DropdownArrowIcon } from 'components/Collapsible/Collapsible.styles';
import {
  AttributeContainer,
  AttributeIconWrapper,
  AttributeLabel,
  CollapsedSkill,
  DicePoolContainer,
  DicePoolItem,
  DropdownIconWrapper,
  ExpandableContent,
  ExpandedSkill,
  SkillsList,
} from './ExpandableAttribute.styles';

interface ExpandableAttributeProps {
  attributeTkey: string;
  attributeLevel: number;
  skills?: {
    [key: string]: number;
  };
  themeConfig: ThemeConfig;
  isExpanded: boolean;
  handleExpand: (attributeTkey: string) => any;
}

export const ExpandableAttribute = ({
  attributeTkey,
  attributeLevel,
  skills,
  themeConfig,
  isExpanded,
  handleExpand,
}: ExpandableAttributeProps) => {
  const { t } = useTranslation('gameplay', { keyPrefix: 'party_view.attributes_section' });
  const { t: tThemesIndex } = useTranslation('themesIndex');
  const [expandedSkill, setExpandedSkill] = useState<string | undefined>(undefined);

  const { attrIcons, skills: themeConfigSkills, themeId } = themeConfig;

  const assignedSkills = skills
    ? Object.keys(skills).filter((skill) => themeConfigSkills[attributeTkey].includes(skill))
    : [];

  const handleExpandSkill = (skillTkey: string) =>
    setExpandedSkill((prev) => (prev === skillTkey ? undefined : skillTkey));

  const AttributeLevelRollSchema = useMemo(
    () =>
      ATTRIBUTE_LEVEL_ROLL_SCHEMA[attributeLevel].map((baseDice) => ({
        baseDice,
        id: v4(),
      })),
    [attributeLevel],
  );

  return (
    <AttributeContainer>
      <AttributeLabel
        onClick={() => {
          setExpandedSkill(undefined);
          handleExpand(attributeTkey);
        }}
      >
        <AttributeIconWrapper>{React.createElement(attrIcons[attributeTkey])}</AttributeIconWrapper>
        <Text fontWeight={600} fontSize="big" textTransform="uppercase" letterSpacing="superWide">
          {tThemesIndex(`${themeId}.character_attributes.${attributeTkey}.title` as any)}
        </Text>
        {AttributeLevelRollSchema.map(({ baseDice, id }) => (
          <DicePoolItem key={id}>
            <img src={getDiceImage(baseDice)} alt={baseDice} />
            <Text>{baseDice}</Text>
          </DicePoolItem>
        ))}
        <DropdownIconWrapper>
          <DropdownArrowIcon isExpanded={isExpanded} />
        </DropdownIconWrapper>
      </AttributeLabel>
      {isExpanded && (
        <ExpandableContent>
          <Text fontSize="small" color="opaqueWhite50" textTransform="uppercase" letterSpacing="superWide">
            {t('attribute_level')} {attributeLevel}
          </Text>
          <Text color="opaqueWhite75">
            {tThemesIndex(`${themeId}.character_attributes.${attributeTkey}.description` as any)}
          </Text>
          <br />
          <Text fontSize="small" color="opaqueWhite50" textTransform="uppercase" letterSpacing="superWide">
            {t('use_cases')}
          </Text>
          <Text color="opaqueWhite75">
            {tThemesIndex(`${themeId}.character_attributes.${attributeTkey}.use_cases` as any)}
          </Text>
          {assignedSkills.length > 0 && skills && (
            <>
              <br />
              <Text
                fontWeight={500}
                color="opaqueWhite50"
                fontSize="smaller"
                letterSpacing="superWide"
                textTransform="uppercase"
              >
                {t('skills_label')}
              </Text>
              <SkillsList>
                {assignedSkills.map((skill: string) => {
                  const skillLevel: number = skills[skill];

                  if (skillLevel <= 0) {
                    return null;
                  }

                  return skill === expandedSkill ? (
                    <ExpandedSkill onClick={() => handleExpandSkill(skill)}>
                      <Text fontWeight={600} fontSize="big" textTransform="uppercase" letterSpacing="superWide">
                        {tThemesIndex(`${themeId}.character_skills.${skill}.title` as any)}
                      </Text>
                      <Text fontSize="small" color="opaqueWhite50" textTransform="uppercase" letterSpacing="superWide">
                        {t('skill_level')} {skills[skill]}
                      </Text>
                      <DicePoolContainer>
                        {SKILL_LEVEL_ROLL_SCHEMA[skillLevel].map((baseDice, index) => (
                          // eslint-disable-next-line react/no-array-index-key
                          <DicePoolItem key={`${baseDice}-${skill}-${index}`}>
                            <img src={getDiceImage(baseDice)} alt={baseDice} />
                            <Text>{baseDice}</Text>
                          </DicePoolItem>
                        ))}
                      </DicePoolContainer>

                      <Text color="opaqueWhite75">
                        {tThemesIndex(`${themeId}.character_skills.${skill}.description` as any)}
                      </Text>
                      <br />
                      <Text fontSize="small" color="opaqueWhite50" textTransform="uppercase" letterSpacing="superWide">
                        {t('use_cases')}
                      </Text>
                      <Text color="opaqueWhite75">
                        {tThemesIndex(`${themeId}.character_skills.${skill}.use_cases` as any)}
                      </Text>
                    </ExpandedSkill>
                  ) : (
                    <CollapsedSkill key={skill} onClick={() => handleExpandSkill(skill)}>
                      <Text fontWeight={600} textTransform="uppercase">
                        {tThemesIndex(`${themeId}.character_skills.${skill}.title` as any)}
                      </Text>
                      {SKILL_LEVEL_ROLL_SCHEMA[skillLevel].map((baseDice, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <DicePoolItem key={`${baseDice}-${skill}-${index}`}>
                          <img src={getDiceImage(baseDice)} alt={baseDice} />
                          <Text>{baseDice}</Text>
                        </DicePoolItem>
                      ))}
                    </CollapsedSkill>
                  );
                })}
              </SkillsList>
            </>
          )}
          <br />
        </ExpandableContent>
      )}
    </AttributeContainer>
  );
};
