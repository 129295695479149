import styled, { css } from 'styled-components';
import { pxToRem } from 'utils/styleUtils';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 0.2rem;
  overflow-y: auto;
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;
`;

export const OptionVerticalLayout = styled.button<{ isSelected?: boolean }>`
  display: flex;
  flex-flow: column nowrap;
  gap: 0.75rem;
  background-color: ${({ theme }) => theme.colors.opaqueBlack25};
  padding: 0.75rem;
  border-radius: ${({ theme }) => theme.border.radiusBase};
  cursor: pointer;

  ${({ isSelected }) =>
    isSelected &&
    css`
      outline: ${({ theme }) => `${theme.border.base} ${theme.colors.primary}`};
    `}
`;

export const CoverImageVerticalLayout = styled.img`
  width: 100%;
  border-radius: ${pxToRem(16)};
`;

export const OptionHorizontalLayout = styled(OptionVerticalLayout)`
  flex-flow: row nowrap;
  align-items: center;
  text-align: left;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const CoverImageHorizontalLayout = styled.img`
  min-width: ${pxToRem(100)};
  width: ${pxToRem(100)};
  height: ${pxToRem(100)};
  border-radius: ${pxToRem(16)};
`;

export const ConfirmButtonWrapper = styled.div`
  position: fixed;
  bottom: 6rem;
  left: 50%;
  transform: translateX(-50%);

  button {
    text-transform: uppercase;
  }
`;
