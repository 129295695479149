import React from 'react';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';
import { ATTRIBUTE_LEVEL_ROLL_SCHEMA, SKILL_LEVEL_ROLL_SCHEMA, getDiceImage } from 'engine/rolls';

import { Text } from 'components/generics/Text/Text';
import { Checkbox } from 'components/generics/Checkbox/Checkbox';
import { CharacterSchema } from 'models/db/db_PlayerCharacter';
import { ThemeConfig } from 'engine/themesConfig';

import { DicePoolItem } from 'views/Gameplay/PartyView/components/ExpandableAttribute/ExpandableAttribute.styles';
import { AttributeCheckboxIcon, CheckboxLabel, Container, Sublist } from './CharacterSchemaCheckboxes.styles';

interface Props {
  characterId: string;
  characterSchema: CharacterSchema;
  themeConfig: ThemeConfig;
  selectedAttributeId: string;
  selectedSkillId: string;
  handleSelectAttribute: (attributeId: string) => any;
  handleSelectSkill: (skillId: string) => any;
}

export const CharacterSchemaCheckboxes = ({
  characterId,
  characterSchema,
  themeConfig,
  selectedAttributeId,
  selectedSkillId,
  handleSelectAttribute,
  handleSelectSkill,
}: Props) => {
  const { t } = useTranslation('themesIndex');
  const { skills: themeSkills, attrIcons, themeId } = themeConfig;
  const { attributes, skills } = characterSchema;

  return (
    <Container>
      {Object.keys(attributes).map((attribute) => {
        const attributeLevel = attributes[attribute];
        const assignedSkills = Object.keys(skills).filter((skill) => themeSkills[attribute].includes(skill));

        return (
          <>
            <Checkbox
              key={`${characterId}-${attribute}`}
              label={
                <CheckboxLabel>
                  <AttributeCheckboxIcon>{React.createElement(attrIcons[attribute])}</AttributeCheckboxIcon>
                  <Text fontWeight={600} textTransform="uppercase" letterSpacing="wide">
                    {t(`${themeId}.character_attributes.${attribute}.title` as any)}
                  </Text>
                  {ATTRIBUTE_LEVEL_ROLL_SCHEMA[attributeLevel].map((baseDice, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <DicePoolItem key={`${characterId}-${attribute}-dice-${baseDice}-${index}`}>
                      <img src={getDiceImage(baseDice)} alt={baseDice} />
                      <Text>{baseDice}</Text>
                    </DicePoolItem>
                  ))}
                </CheckboxLabel>
              }
              onClick={() => handleSelectAttribute(attribute)}
              isChecked={selectedAttributeId === attribute}
            />
            {selectedAttributeId === attribute && assignedSkills.length > 0 && (
              <Sublist>
                {assignedSkills.map((skill) => {
                  const skillLevel = skills[skill];

                  if (skillLevel <= 0) return null;

                  return (
                    <Checkbox
                      key={`${characterId}-${skill}`}
                      label={
                        <CheckboxLabel>
                          <Text fontWeight={600} textTransform="uppercase" letterSpacing="wide">
                            {t(`${themeId}.character_skills.${skill}.title` as any)}
                          </Text>
                          {SKILL_LEVEL_ROLL_SCHEMA[skillLevel].map((baseDice, index) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <DicePoolItem key={`${characterId}-${attribute}-${skill}-dice-${baseDice}-${index}`}>
                              <img src={getDiceImage(baseDice)} alt={baseDice} />
                              <Text>{baseDice}</Text>
                            </DicePoolItem>
                          ))}
                        </CheckboxLabel>
                      }
                      onClick={() => handleSelectSkill(skill)}
                      isChecked={selectedSkillId === skill}
                    />
                  );
                })}
              </Sublist>
            )}
          </>
        );
      })}
    </Container>
  );
};
