import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { routes } from 'components/MainRouter/MainRouter';
import { getEnv } from 'utils/getEnv';
import { TokenService } from './TokenService';
import { SupabaseService } from './SupabaseService';

export const HttpService = axios.create({
  baseURL: `${getEnv('REACT_APP_API_URL')}`,
  headers: {
    apikey: `${process.env.REACT_APP_SB_API_KEY}`,
  },
});

const onRequest = (config: AxiosRequestConfig) => {
  if (config.headers) {
    const accessToken = TokenService.accessToken.getToken();
    if (accessToken) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
  }

  return config;
};

const onResponse = async (response: AxiosResponse) => {
  if (!TokenService.accessToken.shouldRefresh()) return response;

  const refreshToken = TokenService.refreshToken.getToken();
  if (refreshToken) {
    const {
      data: { session },
    } = await SupabaseService.auth.refreshSession({ refresh_token: refreshToken });

    const { access_token: accessToken, refresh_token: newRefreshToken } = session || {};
    if (accessToken && newRefreshToken) {
      TokenService.accessToken.setToken(accessToken);
      TokenService.refreshToken.setToken(newRefreshToken);
      localStorage.removeItem(getEnv('REACT_APP_SB_AUTH_TOKEN_NAME'));
    }
  }

  return response;
};

const onResponseError = async (error: AxiosError) => {
  if (error.response?.status === 401) {
    TokenService.clearAllTokens();
    const nextPath = window.location.pathname + window.location.search;
    window.location.href = `${routes.login}?next=${nextPath}`;
  }

  return Promise.reject(error);
};
HttpService.interceptors.request.use(onRequest);
HttpService.interceptors.response.use(onResponse, onResponseError);
