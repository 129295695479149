import styled from 'styled-components';
import { pxToRem } from 'utils/styleUtils';

export const Container = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  margin: 1.5rem 0;
  width: 100%;
  gap: 0.5rem;
`;

export const Line = styled.hr<{ opacity: 25 | 50 }>`
  width: 100%;
  margin-top: 0.6rem;
  border: none;
  border-top: ${pxToRem(1)} solid
    ${({ theme, opacity }) => theme.colors[opacity === 25 ? 'opaqueWhite25' : 'opaqueWhite50']};
`;
