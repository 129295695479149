import { useCallback } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { SupabaseService } from 'services/SupabaseService';
import { LoginBody, UsersService } from 'services/UsersService';
import { getEnv } from 'utils/getEnv';
import { TokenService } from 'services/TokenService';
import { SSOProvider } from 'models/Api';
import { routes } from 'components/MainRouter/MainRouter';
import { useAppDispatch } from 'stores/store';
import { getUserProfileThunk } from 'stores/user.thunks';
import { ReactComponent as GoogleIcon } from 'assets/images/svg/logo-google.svg';
import { ReactComponent as FacebookIcon } from 'assets/images/svg/logo-facebook.svg';
import { ReactComponent as DiscordIcon } from 'assets/images/svg/logo-discord.svg';

export const useLoginView = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation('launcher', { keyPrefix: 'form_errors' });

  const formMutation = useMutation({
    mutationFn: (body: LoginBody) =>
      UsersService.login(body).then((response) => {
        const { access_token: accessToken, refresh_token: refreshToken } = response.data ?? {};

        if (accessToken && refreshToken) {
          TokenService.accessToken.setToken(accessToken);
          TokenService.refreshToken.setToken(refreshToken);

          dispatch(getUserProfileThunk());

          const nextPath = searchParams.get('next');
          navigate(nextPath || routes.login);
        }
      }),
  });

  const validationSchema = Yup.object().shape({
    email: Yup.string().email(t('email_invalid')).required(t('field_required')),
    password: Yup.string().required(t('field_required')),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema,
    onSubmit: (values) => formMutation.mutate(values),
  });

  const loginViaSSO = useCallback(
    (method: SSOProvider) =>
      SupabaseService.auth.signInWithOAuth({
        provider: method,
        options: {
          redirectTo: `${getEnv('REACT_APP_BASE_URL')}/oauth/success${window.location.search}`,
        },
      }),
    [],
  );

  const environment = getEnv('NODE_ENV');
  const availableProviders: { provider: SSOProvider; Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>> }[] =
    environment === 'development'
      ? [
          { provider: 'google', Icon: GoogleIcon },
          { provider: 'facebook', Icon: FacebookIcon },
          { provider: 'discord', Icon: DiscordIcon },
        ]
      : [{ provider: 'discord', Icon: DiscordIcon }];

  return { formik, loginViaSSO, formMutation, availableProviders } as const;
};
