import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'components/Helmet/Helmet';
import { Select } from 'components/generics/Select/Select';
import { MultiTabSection } from 'components/MultiTabSection/MultiTabSection';
import { ReactComponent as FemaleIcon } from 'assets/images/svg/icon_female.svg';
import { ReactComponent as MaleIcon } from 'assets/images/svg/icon_male.svg';
import { TabButtons } from 'components/MultiTabSection/TabButtons';
import LauncherLayout from 'components/LauncherLayout/LauncherLayout';
import { FullScreenLoading } from 'components/FullScreenLoading/FullScreenLoading';
import { FullScreenSelect } from 'components/FullScreenSelect/FullScreenSelect';
import { PortraitSelect } from 'views/CharacterCreatorView/components/PortraitSelect/PortraitSelect';
import MainForm from './sections/MainForm';
import { useCharacterCreator } from './CharacterCreator.hooks';
import { SexSelection, SexSelectionButton } from './CharacterCreatorView.styles';
import { CharacterBuild } from './sections/CharacterBuild';

export const CharacterCreatorView = () => {
  const [activeTabId, setActiveTabId] = useState('form');

  const { t } = useTranslation('launcher', { keyPrefix: 'character_creator_view' });
  const { t: tPortraitPacks } = useTranslation('portraitPacks' as 'resource');

  const {
    themeApi,
    characterClassApi,
    portraitApi,
    characterNameApi,
    characterPerksApi,
    characterAgeApi,
    isSubmitAllowed,
    characterSchema,
    startingItemsApi,
    handleSubmit,
    isLoading,
    keywordsFilter,
    handleKeywordsFilter,
  } = useCharacterCreator();

  const keywordsFilteringOptions = useMemo(
    () => [
      {
        id: 'most_recommended',
        label: t('keywords_filtering.most_recommended'),
        active: keywordsFilter === 'most_recommended',
        onClick: () => handleKeywordsFilter('most_recommended'),
      },
      {
        id: 'all_recommended',
        label: t('keywords_filtering.all_recommended'),
        active: keywordsFilter === 'all_recommended',
        onClick: () => handleKeywordsFilter('all_recommended'),
      },
      {
        id: 'all',
        label: t('keywords_filtering.all'),
        active: keywordsFilter === 'all',
        onClick: () => handleKeywordsFilter('all'),
      },
    ],
    [keywordsFilter, handleKeywordsFilter, t],
  );

  return (
    <>
      <Helmet>
        <title>{t('title')}</title>
      </Helmet>
      <LauncherLayout>
        {isLoading && <FullScreenLoading />}
        <MultiTabSection
          activeTabId={activeTabId}
          tabs={[
            {
              id: 'form',
              component: (
                <MainForm
                  themeApi={themeApi}
                  characterClassApi={characterClassApi}
                  characterNameApi={characterNameApi}
                  characterPerksApi={characterPerksApi}
                  characterAgeApi={characterAgeApi}
                  setActiveTabId={setActiveTabId}
                  portraitApi={portraitApi}
                  isSubmitAllowed={isSubmitAllowed}
                />
              ),
            },
            {
              id: 'themeSelect',
              component: (
                <FullScreenSelect
                  onSelect={themeApi.handleThemeSelection}
                  toggleOverlay={() => setActiveTabId('form')}
                  options={themeApi.themesAsOptions}
                  optionLayout="vertical"
                  heading={t('class_select.heading')}
                  confirmButtonText={t('class_select.confirm_button')}
                  selectedOptionId={themeApi.selectedThemeId}
                />
              ),
            },
            {
              id: 'classSelect',
              component: (
                <FullScreenSelect
                  selectedOptionId={characterClassApi.selectedClassId}
                  onSelect={characterClassApi.handleClassSelection}
                  toggleOverlay={() => setActiveTabId('form')}
                  options={characterClassApi.classesAsOptions}
                  optionLayout="horizontal"
                  heading={t('class_select.heading')}
                  confirmButtonText={t('class_select.confirm_button')}
                />
              ),
            },
            {
              id: 'portraitSelect',
              component: (
                <PortraitSelect
                  selectedOptionId={portraitApi.selectedPortraitId}
                  onSelect={portraitApi.handlePortraitSelection}
                  options={portraitApi.selectablePortraits.filter(({ id }) => {
                    if (portraitApi.portraitsFilters.sex) {
                      return id.charAt(0) === portraitApi.portraitsFilters.sex;
                    }
                    return true;
                  })}
                  toggleOverlay={() => setActiveTabId('form')}
                  heading={t('portrait_select.heading')}
                  subheading={
                    <>
                      <Select
                        isSearchable={false}
                        label={t('portrait_select.portrait_pack_select_label')}
                        placeholder={t('portrait_select.portrait_pack_select_placeholder')}
                        options={portraitApi.portraitPacksAsOptions}
                        onChange={(option: any) => portraitApi.handlePortraitPackSelection(option.value)}
                        value={{
                          label: tPortraitPacks(portraitApi.selectedPortraitPackId as any),
                          value: portraitApi.selectedPortraitPackId,
                        }}
                      />
                      {portraitApi.selectablePortraits.length > 0 && (
                        <SexSelection>
                          <SexSelectionButton
                            isSelected={portraitApi.portraitsFilters.sex === 'f'}
                            onClick={() => portraitApi.handlePortraitsSexFilter('f')}
                          >
                            <FemaleIcon />
                          </SexSelectionButton>
                          <SexSelectionButton
                            isSelected={portraitApi.portraitsFilters.sex === 'm'}
                            onClick={() => portraitApi.handlePortraitsSexFilter('m')}
                          >
                            <MaleIcon />
                          </SexSelectionButton>
                        </SexSelection>
                      )}
                    </>
                  }
                  confirmButtonText={t('portrait_select.confirm_button')}
                />
              ),
            },
            {
              id: 'originSelect',
              component: (
                <FullScreenSelect
                  onSelect={characterPerksApi.handleOriginPerkSelection}
                  toggleOverlay={() => setActiveTabId('form')}
                  options={characterPerksApi.originPerksAsOptions}
                  optionLayout="horizontal"
                  heading={t('origin.label')}
                  confirmButtonText={t('class_select.confirm_button')}
                  selectedOptionId={characterPerksApi.selectedOriginPerkId}
                  customFilters={<TabButtons tabs={keywordsFilteringOptions} />}
                  themeId={themeApi.selectedThemeId ?? ''}
                />
              ),
            },
            {
              id: 'experienceSelect',
              component: (
                <FullScreenSelect
                  themeId={themeApi.selectedThemeId ?? ''}
                  onSelect={characterPerksApi.handleExperiencePerkSelection}
                  toggleOverlay={() => setActiveTabId('form')}
                  options={characterPerksApi.experiencePerksAsOptions}
                  optionLayout="horizontal"
                  heading={t('experience.label')}
                  confirmButtonText={t('class_select.confirm_button')}
                  selectedOptionId={
                    characterPerksApi.currentlyChangedPerkId &&
                    characterPerksApi.selectedExperiencePerkIds.includes(characterPerksApi.currentlyChangedPerkId)
                      ? characterPerksApi.currentlyChangedPerkId
                      : ''
                  }
                  disabledOptionsIds={characterPerksApi.selectedExperiencePerkIds.filter(
                    (perkId) => perkId !== characterPerksApi.currentlyChangedPerkId,
                  )}
                  customFilters={<TabButtons tabs={keywordsFilteringOptions} />}
                />
              ),
            },
            {
              id: 'personalitySelect',
              component: (
                <FullScreenSelect
                  onSelect={characterPerksApi.handlePersonalityPerkSelection}
                  toggleOverlay={() => setActiveTabId('form')}
                  options={characterPerksApi.personalityPerksAsOptions}
                  optionLayout="horizontal"
                  heading={t('personality.label')}
                  confirmButtonText={t('class_select.confirm_button')}
                  selectedOptionId={
                    characterPerksApi.currentlyChangedPerkId &&
                    characterPerksApi.selectedPersonalityPerkIds.includes(characterPerksApi.currentlyChangedPerkId)
                      ? characterPerksApi.currentlyChangedPerkId
                      : ''
                  }
                  disabledOptionsIds={characterPerksApi.selectedPersonalityPerkIds.filter(
                    (perkId) => perkId !== characterPerksApi.currentlyChangedPerkId,
                  )}
                />
              ),
            },
            {
              id: 'flawSelect',
              component: (
                <FullScreenSelect
                  onSelect={characterPerksApi.handleFlawPerkSelection}
                  toggleOverlay={() => setActiveTabId('form')}
                  options={characterPerksApi.flawPerksAsOptions}
                  optionLayout="horizontal"
                  heading={t('flaw.label')}
                  confirmButtonText={t('class_select.confirm_button')}
                  selectedOptionId={characterPerksApi.selectedFlawPerkId}
                />
              ),
            },
            {
              id: 'itemSelect',
              component: (
                <FullScreenSelect
                  onSelect={startingItemsApi.handleItemsSelection}
                  toggleOverlay={() => setActiveTabId('characterBuild')}
                  options={startingItemsApi.itemsAsOptions}
                  optionLayout="horizontal"
                  heading={t('build_subsection.starting_items')}
                  confirmButtonText={t('class_select.confirm_button')}
                  selectedOptionId=""
                  customFilters={<TabButtons tabs={startingItemsApi.itemsFilteringOptions} />}
                />
              ),
            },
            {
              id: 'characterBuild',
              component: (
                <CharacterBuild
                  selectedPortraitSrc={portraitApi.selectedPortraitSrc}
                  characterSchema={characterSchema}
                  characterName={characterNameApi.name}
                  classTkey={characterClassApi.selectedClassTkey}
                  themeApi={themeApi}
                  handleSubmit={handleSubmit}
                  isSubmitAllowed={isSubmitAllowed}
                  setActiveTabId={setActiveTabId}
                  startingItemsApi={startingItemsApi}
                />
              ),
            },
          ]}
        />
      </LauncherLayout>
    </>
  );
};
