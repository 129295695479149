import { NAVMENU_HEIGHT } from 'assets/styles/theme';
import styled, { css } from 'styled-components';
import { pxToRem } from 'utils/styleUtils';

export const MenuContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.opaqueBlack50};
  height: ${NAVMENU_HEIGHT};
  width: 100vw;
  max-width: ${({ theme }) => theme.breakpoints.mobile};

  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  align-items: center;
`;

export const MenuItem = styled.button<{ isActive?: boolean; disabled?: boolean }>`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  gap: 0.2rem;

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: initial;
    `}

  & svg {
    width: ${pxToRem(30)};
    height: ${pxToRem(30)};

    & path {
      fill: ${({ theme }) => theme.colors.opaqueWhite75};

      ${({ isActive }) =>
        isActive &&
        css`
          fill: ${({ theme }) => theme.colors.primary};
        `}

      ${({ disabled }) =>
        disabled &&
        css`
          fill: ${({ theme }) => theme.colors.opaqueWhite25};
        `}
    }
  }
`;

export const TextWrapper = styled.div`
  display: initial;

  @media screen and (max-width: 370px) {
    display: none;
  }
`;
