import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'stores/store';
import { logout } from 'stores/user.slice';
import LauncherLayout from 'components/LauncherLayout/LauncherLayout';
import { Text } from 'components/generics/Text/Text';
import { Helmet } from 'components/Helmet/Helmet';

import { BlandButton } from 'components/generics/Button/BlandButton';
import { menuItems } from 'components/LauncherNavMenu/LauncherNavMenu';
import { ReactComponent as LogoutIcon } from 'assets/images/svg/icon_logout.svg';
import { TextInput } from 'components/generics/Form/TextInput/TextInput';
import { PasswordInput } from 'components/generics/Form/PasswordInput/PasswordInput';
import { ColorButton } from 'components/generics/Button/ColorButton';
import { getEnv } from 'utils/getEnv';
import { capitalizeFirstLetter } from 'utils/capitalizeFirstLetter';
import { closeModal, openModal } from 'stores/modal.slice';
import {
  ENFlag,
  LanguageButtonsGroup,
  LogoutButtonWrapper,
  PLFlag,
  SubForm,
  UserSettingsViewContainer,
  DiscordIcon,
  FacebookIcon,
  GoogleIcon,
  SSOGroup,
  DeleteAccountButton,
  ContactMailLink,
} from './UserSettingsView.styles';
import { useUserSettingsView } from './UserSettingsView.hooks';

export const UserSettingsView = () => {
  const { t, i18n } = useTranslation('launcher', { keyPrefix: 'user_settings' });
  const dispatch = useAppDispatch();

  const { displayNameForm, changePasswordForm, isChangeDisplayNameBtnDisabled, allowPasswordChange, SSOProvider } =
    useUserSettingsView();

  const handleDeleteAccount = () => {
    dispatch(
      openModal({
        content: (
          <div>
            <Text fontWeight={700} fontSize="bigger" textTransform="uppercase">
              {t('delete_account_heading')}
            </Text>
            <br />
            <Text>{t('delete_account_text')}</Text>
            <br />
            <ContactMailLink href="mailto:contact@rollingdices.app">contact@rollingdices.app</ContactMailLink>
          </div>
        ),
        handleConfirm: () => dispatch(closeModal()),
        handleClose: () => dispatch(closeModal()),
        confirmText: t('delete_account_submit'),
      }),
    );
  };

  let SSOIcon;
  if (SSOProvider === 'google') {
    SSOIcon = <GoogleIcon />;
  } else if (SSOProvider === 'facebook') {
    SSOIcon = <FacebookIcon />;
  } else if (SSOProvider === 'discord') {
    SSOIcon = <DiscordIcon />;
  }

  return (
    <>
      <Helmet>
        <title>{t('title')}</title>
      </Helmet>
      <LauncherLayout activeItem={menuItems.profile}>
        <UserSettingsViewContainer>
          <Text as="h1" fontWeight={600} fontSize="bigger">
            {t('profile_heading')}
          </Text>
          <SubForm noValidate onSubmit={displayNameForm.handleSubmit}>
            <TextInput
              name="displayName"
              value={displayNameForm.values.displayName}
              onChange={displayNameForm.handleChange}
              onBlur={displayNameForm.handleBlur}
              label={t('display_name_label')}
              error={displayNameForm.touched.displayName ? displayNameForm.errors.displayName : undefined}
            />
            <ColorButton type="submit" isDisabled={isChangeDisplayNameBtnDisabled}>
              {t('display_name_submit')}
            </ColorButton>
          </SubForm>
          <Text
            fontWeight={500}
            color="opaqueWhite50"
            fontSize="small"
            letterSpacing="superWide"
            textTransform="uppercase"
          >
            {t('language_label')}
          </Text>
          <LanguageButtonsGroup>
            <BlandButton
              type="button"
              onClick={() => {
                localStorage.setItem('RD@userLanguage', 'pl');
                i18n.changeLanguage('pl');
              }}
            >
              <span>Polski</span>
              <PLFlag />
            </BlandButton>
            <BlandButton
              type="button"
              onClick={() => {
                localStorage.setItem('RD@userLanguage', 'en');
                i18n.changeLanguage('en');
              }}
            >
              <span>English</span>
              <ENFlag />
            </BlandButton>
          </LanguageButtonsGroup>
          <br />
          <Text
            fontWeight={500}
            color="opaqueWhite50"
            fontSize="small"
            letterSpacing="superWide"
            textTransform="uppercase"
          >
            {t('account_heading')}
          </Text>
          {SSOProvider && (
            <SSOGroup>
              <Text as="span" fontSize="small">
                {t('connected_with')}
              </Text>
              {SSOIcon}
              <Text as="span" fontSize="small">
                {capitalizeFirstLetter(SSOProvider)}
              </Text>
            </SSOGroup>
          )}
          <DeleteAccountButton type="button" onClick={handleDeleteAccount}>
            <Text as="span" fontSize="small" fontWeight={700} color="secondary">
              X
            </Text>
            <Text as="span" fontSize="small" fontWeight={600}>
              {t('delete_account_button')}
            </Text>
          </DeleteAccountButton>
          <br />
          {allowPasswordChange && (
            <>
              <Text as="h2" fontWeight={600} fontSize="bigger">
                {t('change_password_heading')}
              </Text>
              <SubForm noValidate onSubmit={changePasswordForm.handleSubmit}>
                <PasswordInput
                  name="newPassword"
                  value={changePasswordForm.values.newPassword}
                  onChange={changePasswordForm.handleChange}
                  error={changePasswordForm.touched.newPassword ? changePasswordForm.errors.newPassword : undefined}
                  onBlur={changePasswordForm.handleBlur}
                  label={t('new_password_label')}
                />
                <PasswordInput
                  name="repeatedNewPassword"
                  value={changePasswordForm.values.repeatedNewPassword}
                  onChange={changePasswordForm.handleChange}
                  onBlur={changePasswordForm.handleBlur}
                  error={
                    changePasswordForm.touched.repeatedNewPassword
                      ? changePasswordForm.errors.repeatedNewPassword
                      : undefined
                  }
                  label={t('confirm_password_label')}
                />
                <ColorButton
                  type="submit"
                  isDisabled={
                    !changePasswordForm.values.newPassword ||
                    !changePasswordForm.values.repeatedNewPassword ||
                    changePasswordForm.values.newPassword !== changePasswordForm.values.repeatedNewPassword
                  }
                >
                  {t('change_password_submit')}
                </ColorButton>
              </SubForm>
            </>
          )}
          <LogoutButtonWrapper>
            <BlandButton type="button" onClick={() => dispatch(logout())}>
              {t('logout_button')} <LogoutIcon />
            </BlandButton>
            <Text color="opaqueWhite50" fontWeight={500} textAlign="center">
              {`${t('current_version')}: ${getEnv('REACT_APP_VERSION')}`}
            </Text>
          </LogoutButtonWrapper>
        </UserSettingsViewContainer>
      </LauncherLayout>
    </>
  );
};
