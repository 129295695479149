import React from 'react';
import { Text } from 'components/generics/Text/Text';
import { ReactComponent as D4Source } from 'assets/images/dices/D4.svg';
import { ReactComponent as D6Source } from 'assets/images/dices/D6.svg';
import { ReactComponent as D8Source } from 'assets/images/dices/D8.svg';
import { ReactComponent as D10Source } from 'assets/images/dices/D10.svg';
import { ReactComponent as D12Source } from 'assets/images/dices/D12.svg';
import { ReactComponent as D20Source } from 'assets/images/dices/D20.svg';
import { DiceType } from 'engine/rolls';
import { DiceButton, DiceIcon, DiceTitle } from './Dice.styles';

interface DiceProps {
  type: DiceType;
  onClick?: () => any;
  width?: string;
  height?: string;
  hasBackground?: boolean;
  withLabel?: boolean;
}

export const Dice = ({ onClick, type, width, height, hasBackground, withLabel = false }: DiceProps) => {
  let imageToRender;
  switch (type) {
    case 'D4': {
      imageToRender = <D4Source />;
      break;
    }
    case 'D6': {
      imageToRender = <D6Source />;
      break;
    }
    case 'D8': {
      imageToRender = <D8Source />;
      break;
    }
    case 'D10': {
      imageToRender = <D10Source />;
      break;
    }
    case 'D12': {
      imageToRender = <D12Source />;
      break;
    }
    case 'D20': {
      imageToRender = <D20Source />;
      break;
    }
    default: {
      break;
    }
  }

  return (
    <DiceButton onClick={onClick} $width={width} $height={height} hasBackground={hasBackground} isClickable={!!onClick}>
      <DiceIcon withLabel={withLabel}>{imageToRender}</DiceIcon>
      {withLabel && (
        <DiceTitle>
          <Text fontSize="big" fontWeight={700}>
            {type}
          </Text>
        </DiceTitle>
      )}
    </DiceButton>
  );
};
