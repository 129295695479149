import React, { useEffect, useMemo, useState } from 'react';
import { Text } from 'components/generics/Text/Text';
import { useGameplaySelector } from 'stores/gameplay.slice';
import { useGameplayNavigation } from 'hooks/useGameplayNavigation';
import { SupabaseService } from 'services/SupabaseService';
import { useUserSelector } from 'stores/user.slice';
import { ReactComponent as IconGm } from 'assets/images/svg/icon_book.svg';
import { useSessionPlayersAPI } from 'hooks/sessionApi/useSessionPlayersAPI';
import {
  TopBar,
  Content,
  MenuButton,
  TitleWrapper,
  BackButton,
  Hamburger,
  HamburgerFirst,
  HamburgerSecond,
  HamburgerThird,
  PlayersWrapper,
  PlayerAvatar,
  GmAvatar,
  AvatarWrapper,
} from './GameplayTopBar.styles';

interface GameplayTopBarProps {
  toggleSideMenu: () => any;
  isSideMenuOpen?: boolean;
}

export const GameplayTopBar = ({ toggleSideMenu, isSideMenuOpen }: GameplayTopBarProps) => {
  const { user } = useUserSelector();
  const { activeSession } = useGameplaySelector();
  const { goToPreviousView, redirectToCharacter } = useGameplayNavigation();

  const [sessionOnlinePlayersIds, setSessionOnlinePlayersIds] = useState<string[]>([]);

  const { sessionPlayersQuery } = useSessionPlayersAPI();
  const sessionPlayers = useMemo(() => sessionPlayersQuery.data?.data ?? [], [sessionPlayersQuery.data]);

  const sortedPlayers = useMemo(
    () => sessionPlayers.sort((a) => (sessionOnlinePlayersIds?.includes(a.owner_id) ? -1 : 1)),
    [sessionOnlinePlayersIds, sessionPlayers],
  );

  useEffect(() => {
    const channel = SupabaseService.channel(`session_online_players:${activeSession?.id}`);

    channel
      .on('presence', { event: 'sync' }, () => {
        const state = channel.presenceState<{ userId: string; onlineAt: string }>();
        setSessionOnlinePlayersIds(Object.values(state).map((value) => value[0].userId));
      })
      .subscribe(async (status) => {
        if (status === 'SUBSCRIBED') {
          await channel.track({
            userId: user?.id,
            onlineAt: new Date().toISOString(),
          });
        }
      });

    return () => {
      if (channel) SupabaseService.removeChannel(channel);
    };
  }, [activeSession?.id, user?.id]);

  return (
    <TopBar>
      <Content>
        <BackButton onClick={goToPreviousView}>
          <Text fontWeight={700} fontSize="big">
            &lt;
          </Text>
        </BackButton>
        <TitleWrapper>
          <Text fontWeight={700} fontSize="big">
            {activeSession?.title}
          </Text>
          <PlayersWrapper>
            <GmAvatar
              isOnline={!!(activeSession?.owner_id && !!sessionOnlinePlayersIds?.includes(activeSession.owner_id))}
            >
              <IconGm />
            </GmAvatar>
            {sortedPlayers.map((player) => (
              <AvatarWrapper
                key={`player_character_${player.owner_id}`}
                onClick={() => redirectToCharacter(player.character_id)}
              >
                <PlayerAvatar
                  isOnline={!!sessionOnlinePlayersIds?.includes(player.owner_id)}
                  src={player?.portrait}
                  loading="lazy"
                />
              </AvatarWrapper>
            ))}
          </PlayersWrapper>
        </TitleWrapper>
      </Content>
      <MenuButton onClick={toggleSideMenu}>
        <Hamburger isOpen={!!isSideMenuOpen}>
          <HamburgerFirst isOpen={!!isSideMenuOpen} />
          <HamburgerSecond isOpen={!!isSideMenuOpen} />
          <HamburgerThird isOpen={!!isSideMenuOpen} />
        </Hamburger>
      </MenuButton>
    </TopBar>
  );
};
