import styled, { css } from 'styled-components';
import { pxToRem } from 'utils/styleUtils';
import { NavLink } from 'react-router-dom';

export const Container = styled.div<{ isProd?: boolean }>`
  width: 100vw;
  padding: 3rem;
  min-height: 100vh;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  gap: 4rem;

  ${({ isProd }) =>
    isProd &&
    css`
      justify-content: initial;
      padding-top: ${pxToRem(64)};
    `}
`;

export const ContentWrapper = styled.div`
  width: 95%;
  max-width: 35rem;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  gap: 1.25rem;

  form {
    all: inherit;
    width: 100%;
  }
`;

export const SocialMediaButtons = styled.div`
  width: 20%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  gap: 2rem;

  button {
    cursor: pointer;
    background-color: transparent;
    border: none;
  }

  svg {
    width: ${pxToRem(42)};
    height: ${pxToRem(42)};
    fill: ${({ theme }) => theme.colors.opaqueWhite50};
    transition-duration: ${({ theme }) => theme.transition.baseDuration};

    &:hover {
      fill: ${({ theme }) => theme.colors.white};
    }
  }
`;

export const ErrorBar = styled.div`
  margin-top: -${pxToRem(24)};
`;

export const Cta = styled(NavLink)`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  color: ${({ theme }) => theme.colors.white};
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
  font-weight: 500;
`;

export const LogoWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const VersionWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%) translateY(${pxToRem(-8)});
  display: flex;
  align-items: center;
  gap: ${pxToRem(4)};
`;

export const Logo = styled.img`
  display: block;
  width: 100%;
  max-width: ${pxToRem(360)};
`;

export const PoliciesWrapper = styled.div`
  padding-top: ${pxToRem(16)};

  & a {
    font: inherit;
    color: inherit;
  }
`;
