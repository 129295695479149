import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { RootState } from './store';

export interface ModalState {
  modalId?: string;
  isOpen: boolean;
  isFullScreen?: boolean;
  content?: React.ReactNode;
  handleClose?: () => any;
  handleConfirm?: () => any;
  confirmText?: string;
  isConfirmButtonDisabled?: boolean;
}

const initialState: ModalState = {
  isOpen: false,
  isFullScreen: false,
  content: undefined,
  handleClose: () => undefined,
  handleConfirm: () => undefined,
  isConfirmButtonDisabled: false,
};

const openModalReducer: CaseReducer<ModalState, PayloadAction<Omit<ModalState, 'isOpen'>>> = (state, { payload }) => ({
  ...payload,
  isOpen: true,
});

const closeModalReducer: CaseReducer<ModalState> = () => initialState;

const setIsConfirmButtonDisabledReducer: CaseReducer<ModalState, PayloadAction<boolean>> = (state, { payload }) => ({
  ...state,
  isConfirmButtonDisabled: payload,
});

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openModal: openModalReducer,
    closeModal: closeModalReducer,
    setIsConfirmButtonDisabled: setIsConfirmButtonDisabledReducer,
  },
});

export const { openModal, closeModal, setIsConfirmButtonDisabled } = modalSlice.actions;

export const useModalSelector = () => useSelector((state: RootState) => state.modal);

export default modalSlice.reducer;
