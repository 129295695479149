import React from 'react';
import { useTranslation } from 'react-i18next';
import { getEnv } from 'utils/getEnv';
import { useRedirectIfLogged } from 'hooks/useRedirectIfLogged';
import { Helmet } from 'components/Helmet/Helmet';
import { ProdLoginView } from './ProdLoginView/ProdLoginView';
import { DevLoginView } from './DevLoginView/DevLoginView';

export const LoginView = () => {
  const { t } = useTranslation('launcher', { keyPrefix: 'login_view' });

  useRedirectIfLogged();

  const environment = getEnv('NODE_ENV');

  return (
    <>
      <Helmet>
        <title>{t('title')}</title>
      </Helmet>
      {environment === 'development' ? <DevLoginView /> : <ProdLoginView />}
    </>
  );
};
