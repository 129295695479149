import React from 'react';
import { useTranslation } from 'react-i18next';
import { ItemObtainedSessionRecord } from 'models/db/db_SessionRecord';
import { useGameplaySelector } from 'stores/gameplay.slice';
import { useGameplayNavigation } from 'hooks/useGameplayNavigation';
import { useSessionPlayersAPI } from 'hooks/sessionApi/useSessionPlayersAPI';
import { Text } from 'components/generics/Text/Text';
import { LogCharacterAvatar, Record, RecordContentRow, RecordContentWrapper } from '../RecordsView.styles';

interface ItemObtainedRecordProps {
  isGm?: boolean;
  record: ItemObtainedSessionRecord;
}

export const ItemObtainedRecord = ({ isGm, record }: ItemObtainedRecordProps) => {
  const { activeSession } = useGameplaySelector();
  const { getters } = useSessionPlayersAPI();
  const { redirectToCharacter } = useGameplayNavigation();
  const { t } = useTranslation('gameplay', { keyPrefix: 'session_records_view' });
  const { t: tItem } = useTranslation('items' as 'resource');

  const character = getters.getPlayerCharacterById(record.content.character_id);

  return (
    <Record isGm={isGm}>
      <RecordContentWrapper>
        <RecordContentRow>
          {character && (
            <button type="button" onClick={() => redirectToCharacter(character?.character_id)}>
              <LogCharacterAvatar src={character?.portrait} loading="lazy" />
            </button>
          )}
          <Text color="highlightYellow" fontWeight={700} textTransform="uppercase">
            {t('item.obtained')}
          </Text>
        </RecordContentRow>
        <Text fontSize="small" fontWeight={700} textTransform="uppercase" letterSpacing="superWide">
          {character?.name}
        </Text>
        <Text fontSize="small" color={record.content.item_rarity === 'unique' ? 'rareItem' : 'white'} fontWeight={700}>
          {record.content.quantity && `${record.content.quantity}x`}{' '}
          {tItem(`${activeSession?.theme_id}.${record.content.item_type}.${record.content.item_t_key}.name` as any)}
        </Text>
      </RecordContentWrapper>
    </Record>
  );
};
