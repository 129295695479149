import { lib } from 'crypto-js';

const chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';

export function generateRandomCode(length = 8) {
  let result = '';

  for (let i = 0; i < length; i += 1) {
    const randomIndex = lib.WordArray.random(256).words[0] % chars.length;
    result += chars.charAt(randomIndex);
  }

  return result;
}
