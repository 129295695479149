import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 0.25rem;
  width: 100%;
`;

export const Selector = styled.button`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  border-radius: ${({ theme }) => theme.border.radiusBase};
  border: ${({ theme }) => `${theme.border.base} ${theme.colors.opaqueWhite25}`};
  background-color: ${({ theme }) => theme.colors.opaqueBlack25};
  color: ${({ theme }) => theme.colors.white};
  font: inherit;
  font-size: ${({ theme }) => theme.fontSizes.medium};
  padding: 0.8rem;
  cursor: pointer;

  svg {
    stroke: ${({ theme }) => theme.colors.opaqueWhite50};
  }

  &:hover {
    svg {
      stroke: ${({ theme }) => theme.colors.white};
    }
  }
`;
