import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from 'components/generics/Text/Text';
import { ListItemContainer, PlayerAvatar, CharacterDetails, VersionBox } from './CharactersList.styles';

interface CharactersListItemProps {
  name: string;
  theme_t_key: string;
  portrait?: string;
  class_t_key?: string;
  app_version?: string;
}

export const CharactersListItem = ({
  name,
  portrait,
  class_t_key,
  theme_t_key,
  app_version,
}: CharactersListItemProps) => {
  const { t } = useTranslation('themesIndex');

  return (
    <ListItemContainer>
      <PlayerAvatar src={portrait} loading="lazy" />
      <CharacterDetails>
        <Text fontSize="medium" letterSpacing="wide">
          {name}, {t(`${theme_t_key}.character_classes.${class_t_key}.name` as any)}
        </Text>
        <Text
          fontSize="smaller"
          fontWeight={500}
          letterSpacing="superWide"
          textTransform="uppercase"
          color="opaqueWhite50"
        >
          {t(`${theme_t_key}.name` as any)}
        </Text>
      </CharacterDetails>
      {app_version && (
        <VersionBox>
          <Text fontSize="smaller" color="opaqueWhite50">
            v{app_version}
          </Text>
        </VersionBox>
      )}
    </ListItemContainer>
  );
};
