import React from 'react';
import { CustomTheme } from 'assets/styles/theme';
import { Text, TextProps } from 'components/generics/Text/Text';

import { Box, InnerWrapper, Ring, Wrapper } from './Spinner.styles';

export interface SpinnerProps {
  size?: 'small' | 'medium';
  color?: keyof CustomTheme['colors'] | string;
  center?: boolean;
  text?: React.ReactNode;
  textProps?: TextProps;
}

export const Spinner = ({ text, textProps, size = 'medium', color, center }: SpinnerProps) => (
  <Wrapper center={center}>
    <InnerWrapper>
      <Ring color={color} size={size}>
        <Box />
        <Box />
        <Box />
        <Box />
      </Ring>
      {!!text && (
        <Text fontSize={size === 'small' ? 'small' : undefined} {...textProps}>
          {text}
        </Text>
      )}
    </InnerWrapper>
  </Wrapper>
);
