import React from 'react';
import LibSelect, { Props } from 'react-select';
import { mainTheme as theme } from 'assets/styles/theme';
import { reshade } from 'utils/styleUtils';
import { Container } from './Select.styles';
import { Text } from '../Text/Text';

interface SelectProps extends Props {
  label?: string;
}

export const Select = ({ label, ...rest }: SelectProps) => (
  <Container>
    {label && (
      <Text
        fontWeight={500}
        color="opaqueWhite50"
        fontSize="smaller"
        letterSpacing="superWide"
        textTransform="uppercase"
      >
        {label}
      </Text>
    )}
    <LibSelect
      styles={{
        control: (baseStyles) => ({
          ...baseStyles,
          padding: '0.3rem',
          background: theme.colors.opaqueBlack25,
          border: `${theme.border.base} ${theme.colors.opaqueWhite25}`,
          borderRadius: theme.border.radiusBase,
          outline: 'none',
          color: theme.colors.white,
          ':hover': {
            border: `${theme.border.base} ${theme.colors.opaqueWhite25}`,
          },
          ':focus': {
            borderColor: theme.colors.opaqueWhite50,
            outline: 'none',
          },
        }),
        indicatorSeparator: (baseStyles) => ({
          ...baseStyles,
          display: 'none',
        }),
        input: (baseStyles) => ({
          ...baseStyles,
          color: theme.colors.white,
        }),
        singleValue: (baseStyles) => ({
          ...baseStyles,
          color: theme.colors.white,
        }),
        placeholder: (baseStyles) => ({
          ...baseStyles,
          color: '#727273',
        }),
        menu: (baseStyles) => ({
          ...baseStyles,
          backgroundColor: '#0c1335',
          borderRadius: theme.border.radiusBase,
          overflow: 'hidden',
        }),
        option: (baseStyles, { isSelected }) => ({
          ...baseStyles,
          backgroundColor: isSelected ? theme.colors.primary : 'inherit',
          ':hover': {
            backgroundColor: isSelected ? reshade(theme.colors.primary, -10) : theme.colors.opaqueBlack50,
          },
        }),
      }}
      {...rest}
    />
  </Container>
);
