import { useState, useEffect } from 'react';
import { generateRandomCode } from 'utils/generateRandomCode';
import { ThemesService } from 'services/ThemesService';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { SupabaseService } from 'services/SupabaseService';
import { SessionService } from 'services/SessionService';
import { useUserSelector } from 'stores/user.slice';
import { dynamicRoutes } from 'components/MainRouter/MainRouter';
import { DB_TABLE_NAMES, QUERY_KEYS } from 'utils/consts';
import { LocationPack } from 'models/db/resource_LocationPack';
import { Location } from 'models/db/resource_Location';
import { OmitAutoGenerated } from 'models/Api';
import { Session } from 'models/db/db_Session';
import { SessionRecord } from 'models/db/db_SessionRecord';
import { getEnv } from 'utils/getEnv';

export function useCreateSessionView() {
  const { user } = useUserSelector();
  const queryClient = useQueryClient();
  const {
    data,
    isLoading: isThemesLoading,
    isError: isThemesError,
  } = useQuery(['getThemes'], () => ThemesService.getThemes());
  const navigate = useNavigate();
  const { t } = useTranslation('themesIndex');
  const [isLoading, setIsLoading] = useState(false);
  const [isGeneratingCode, setIsGeneratingCode] = useState(false);

  // Form state
  const [accessCode, setAccessCode] = useState('');
  const [selectedThemeId, setSelectedThemeId] = useState<string>('');
  const [sessionTitle, setSessionTitle] = useState('');

  // Locations
  const [selectedLocation, setSelectedLocation] = useState<Location>();
  const [selectedLocationPack, setSelectedLocationPack] = useState<LocationPack>();
  const handleLocationSelection = (location?: Location) => setSelectedLocation(location);
  const handleLocationPackSelection = (locationPack?: LocationPack) => setSelectedLocationPack(locationPack);

  // Form handlers
  const handleSessionTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => setSessionTitle(event.target.value);
  const handleThemeSelection = (themeId: string) => setSelectedThemeId(themeId);

  useEffect(() => {
    let newCode = generateRandomCode();

    setIsGeneratingCode(true);
    SessionService.checkAccessCode(newCode)
      .then((res) => res.data)
      .then((data) => {
        if (data.length > 0) {
          newCode = generateRandomCode();
        }
      })
      .finally(() => setIsGeneratingCode(false));
    setAccessCode(newCode);
  }, []);

  // Handle form submit
  const handleSubmit = async () => {
    if (!user) return;

    setIsLoading(true);

    const { data: newSessionDataNotTyped, error: newSessionError } = await SupabaseService.from(
      DB_TABLE_NAMES.dbSession,
    )
      .insert<OmitAutoGenerated<Session>>([
        {
          title: sessionTitle,
          access_code: accessCode,
          theme_id: selectedThemeId,
          owner_id: user?.id,
          current_location_id: selectedLocation?.id,
          app_version: getEnv('REACT_APP_VERSION'),
        },
      ])
      .select();

    const newSessionData = newSessionDataNotTyped as Session[];

    if (newSessionError || !newSessionData) {
      // TODO: Handle character creation error
      return;
    }

    const sessionId = newSessionData[0].id;

    if (selectedLocation) {
      await SupabaseService.from(DB_TABLE_NAMES.dbSessionRecord).insert<OmitAutoGenerated<SessionRecord>>([
        {
          player_character_id: undefined,
          session_id: sessionId,
          record_type: 'location_change',
          content: selectedLocation,
        },
      ]);
    }

    queryClient.refetchQueries({ queryKey: [QUERY_KEYS.launcher.sessionsList] });

    navigate(dynamicRoutes.session(sessionId));
  };

  const isButtonDisabled = !selectedThemeId || !sessionTitle || !accessCode;

  return {
    themes:
      data?.data.map((sessionTheme) => ({
        id: sessionTheme.id,
        name: t(`${sessionTheme.id}.name` as any),
        description: t(`${sessionTheme.id}.description` as any),
        coverImg: `${process.env.REACT_APP_API_URL}/storage/v1/object/public/images/theme_covers/${sessionTheme.id}`,
      })) ?? [],
    isError: isThemesError,
    isLoading: isLoading || isThemesLoading,
    selectedThemeId,
    handleThemeSelection,
    accessCode,
    sessionTitle,
    handleSessionTitleChange,
    handleSubmit,
    isGeneratingCode,
    selectedLocation,
    selectedLocationPack,
    handleLocationSelection,
    handleLocationPackSelection,
    isButtonDisabled,
  } as const;
}
