import styled, { css } from 'styled-components';

export const Container = styled.div<{ fixedWidth?: string }>`
  display: flex;
  flex-flow: column nowrap;
  gap: 0.25rem;
  width: 100%;

  ${({ fixedWidth }) =>
    fixedWidth &&
    css`
      width: ${fixedWidth};
    `}
`;

export const ErrorLabel = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.small};
  color: ${({ theme }) => theme.colors.secondary};
  margin-left: 0.25rem;
`;

export const InputBase = styled.input<{ isError?: boolean }>`
  border-radius: ${({ theme }) => theme.border.radiusBase};
  border: ${({ theme }) => `${theme.border.base} ${theme.colors.opaqueWhite25}`};
  background-color: ${({ theme }) => theme.colors.opaqueBlack25};
  color: ${({ theme }) => theme.colors.white};
  font: inherit;
  font-size: ${({ theme }) => theme.fontSizes.medium};
  padding: 0.8rem;

  &:focus {
    color: ${({ theme }) => theme.colors.white};
    border-color: ${({ theme }) => theme.colors.opaqueWhite50};
    outline: none;
  }

  ${({ isError }) =>
    isError &&
    css`
      border-color: ${({ theme }) => theme.colors.secondary};
    `}
`;
