import React from 'react';
import { useTranslation } from 'react-i18next';
import { StoryCardSessionRecord } from 'models/db/db_SessionRecord';
import { Text } from 'components/generics/Text/Text';
import { useGameplaySelector } from 'stores/gameplay.slice';
import { LocationImage, Record, RecordContentWrapper } from '../RecordsView.styles';

interface StoryCardRecordProps {
  isGm?: boolean;
  record: StoryCardSessionRecord;
}

export const StoryCardRecord = ({ isGm, record }: StoryCardRecordProps) => {
  const { t } = useTranslation('gameplay', { keyPrefix: 'session_records_view' });
  const { t: tStoryCard } = useTranslation('storyCards' as 'resource');
  const { activeSession } = useGameplaySelector();

  return (
    <Record isGm={isGm}>
      <RecordContentWrapper>
        <Text color="highlightYellow" fontWeight={700} textTransform="uppercase">
          {t(`story_card.${record.content?.type}_${record.content?.action}` as any)}
        </Text>
        <LocationImage src={record.content?.location_image_url} loading="lazy" />
        <Text fontSize="small" fontWeight={700}>
          {tStoryCard(`${activeSession?.theme_id}.${record.content.t_key}.title` as any)}
        </Text>
      </RecordContentWrapper>
    </Record>
  );
};
