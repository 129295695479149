import React from 'react';
import { ReactComponent as IconHandbook } from 'assets/images/svg/icon_book.svg';
import { useAppDispatch } from 'stores/store';
import { closeModal, openModal, useModalSelector } from 'stores/modal.slice';
import { Text } from 'components/generics/Text/Text';
import { IngameHandbook } from 'components/IngameHandbook/IngameHandbook';
import { ButtonBackground, StyledGameplaySideButton } from './GameplaySideButton.styles';

const HANDBOOK_MODAL_ID = 'handbook_modal';

export const GameplaySideButton = () => {
  const dispatch = useAppDispatch();
  const { isOpen, modalId } = useModalSelector();

  const toggleHandbook = () => {
    if (isOpen) {
      dispatch(closeModal());
    } else {
      dispatch(
        openModal({
          content: <IngameHandbook />,
          isFullScreen: true,
          modalId: HANDBOOK_MODAL_ID,
        }),
      );
    }
  };

  if (isOpen && modalId !== HANDBOOK_MODAL_ID) {
    return null;
  }

  return (
    <StyledGameplaySideButton onClick={() => toggleHandbook()} isToggled={isOpen}>
      <ButtonBackground />
      {isOpen ? <Text fontSize="bigger">X</Text> : <IconHandbook />}
    </StyledGameplaySideButton>
  );
};
