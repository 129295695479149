import { DB_TABLE_NAMES } from 'utils/consts';
import { Theme } from 'models/db/resource_Theme';
import {
  GetCharacterClassesForThemeParams,
  GetCharacterClassesForThemeReturn,
} from 'models/functions/GetCharacterClassesForTheme';
import {
  GetCharacterPerksForThemeParams,
  GetCharacterPerksForThemeReturn,
} from 'models/functions/GetCharacterPerksForTheme';
import { Item } from 'models/db/resource_Item';
import { PortraitPack } from 'models/db/resource_PortraitPack';
import { HttpService } from './HttpService';

export const ThemesService = {
  getThemes: () => HttpService.get<Theme[]>(`rest/v1/${DB_TABLE_NAMES.resourceTheme}?select=*`),
  getCharacterClassesForTheme: ({ t_id }: GetCharacterClassesForThemeParams) =>
    HttpService.get<GetCharacterClassesForThemeReturn>(`rest/v1/rpc/get_character_classes_for_theme?t_id=${t_id}`),
  getCharacterPerksForTheme: ({ t_id }: GetCharacterPerksForThemeParams) =>
    HttpService.get<GetCharacterPerksForThemeReturn>(`rest/v1/rpc/get_character_perks_for_theme?t_id=${t_id}`),
  getItemsForTheme: ({ t_id }: { t_id: Theme['id'] }) =>
    HttpService.get<Item[]>(`rest/v1/${DB_TABLE_NAMES.resourceItem}?select=*&theme_id=eq.${t_id}`),
  getPortraitPacksForTheme: ({ t_id }: { t_id: Theme['id'] }) =>
    HttpService.get<PortraitPack[]>(`rest/v1/${DB_TABLE_NAMES.resourcePortraitPack}?select=*&theme_id=eq.${t_id}`),
};
