import styled from 'styled-components';
import { pxToRem } from 'utils/styleUtils';

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${pxToRem(24)};
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${pxToRem(6)};
`;

export const LogoWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const VersionWrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translateX(-50%) translateY(${pxToRem(-8)});
  display: flex;
  align-items: center;
  gap: ${pxToRem(4)};
`;

export const Logo = styled.img`
  display: block;
  width: 100%;
  max-width: ${pxToRem(360)};
`;

export const ListsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${pxToRem(40)};
`;

export const ListBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${pxToRem(4)};
`;

export const List = styled.ul`
  color: ${({ theme }) => theme.colors.opaqueWhite75};
  padding-left: ${pxToRem(24)};
`;

export const Item = styled.li``;
