import { GetActiveStoryCardsParams, GetActiveStoryCardsReturn } from 'models/functions/GetActiveStoryCards';
import { GetRandomStoryCardParams, GetRandomStoryCardReturn } from 'models/functions/GetRandomStoryCard';
import { HttpService } from './HttpService';

export const StoryCardsService = {
  getActiveStoryCards: ({ s_id }: GetActiveStoryCardsParams) =>
    HttpService.get<GetActiveStoryCardsReturn>(`/rest/v1/rpc/get_active_story_cards?s_id=${s_id}`),
  getRandomStoryCard: ({ l_id, sc_type, prev_sc_id }: GetRandomStoryCardParams) =>
    HttpService.get<GetRandomStoryCardReturn>(
      `/rest/v1/rpc/get_random_story_card?l_id=${l_id}&sc_type=${sc_type}&prev_sc_id=${prev_sc_id}`,
    ),
};
