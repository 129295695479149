import React from 'react';
import { useTranslation } from 'react-i18next';

import { Text } from 'components/generics/Text/Text';
import { ReactComponent as IconHandbook } from 'assets/images/svg/icon_book.svg';
import {
  Hamburger,
  HamburgerFirst,
  HamburgerSecond,
  HamburgerThird,
} from 'components/GameplayTopBar/GameplayTopBar.styles';
import {
  SessionOnboardingContainer,
  MockMenuButton,
  MockSideButton,
  OnboardingSection,
} from './SessionOnboarding.styles';

export const SessionOnboarding = () => {
  const { t } = useTranslation('gameplay', { keyPrefix: 'session_onboarding' });

  return (
    <SessionOnboardingContainer>
      <Text fontSize="bigger" fontWeight={500} textTransform="uppercase" letterSpacing="superWide">
        {t('heading')}
      </Text>
      <br />
      <OnboardingSection>
        <MockMenuButton>
          <Hamburger isOpen={false}>
            <HamburgerFirst isOpen={false} />
            <HamburgerSecond isOpen={false} />
            <HamburgerThird isOpen={false} />
          </Hamburger>
        </MockMenuButton>
        <Text fontSize="big" fontWeight={500} textTransform="uppercase">
          {t('menu_section.label')}
        </Text>
        <Text fontSize="smaller" color="opaqueWhite50" textAlign="center">
          {t('menu_section.placement')}
        </Text>
        <Text textAlign="center"> {t('menu_section.description')}</Text>
      </OnboardingSection>
      <br />
      <OnboardingSection>
        <MockSideButton>
          <IconHandbook />
        </MockSideButton>
        <Text fontSize="big" fontWeight={500} textTransform="uppercase">
          {t('handbook_section.label')}
        </Text>
        <Text fontSize="smaller" color="opaqueWhite50" textAlign="center">
          {t('handbook_section.placement')}
        </Text>
        <Text textAlign="center">{t('handbook_section.description')}</Text>
      </OnboardingSection>
    </SessionOnboardingContainer>
  );
};
