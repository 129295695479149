import styled from 'styled-components';
import { ButtonShared } from './ButtonShared';

interface BlandButtonProps {
  noBorder?: boolean;
}

export const BlandButton = styled(ButtonShared)<BlandButtonProps>`
  background-color: transparent;
  color: ${({ theme }) => theme.colors.white};
  border: ${({ theme, noBorder }) => !noBorder && `${theme.border.base} rgba(255, 255, 255,.15)`};

  &:hover {
    border: ${({ theme, noBorder }) => !noBorder && `${theme.border.base} ${theme.colors.white}`};
  }
`;
