/* eslint-disable max-len */
import { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { GetCharacterPerksForThemeRow } from 'models/functions/GetCharacterPerksForTheme';
import { getThemeConfigForThemeId } from 'engine/themesConfig';
import { useDataProvider } from './useDataProvider';
import { useMainForm } from './useMainForm';

const KEYWORDS_FULL_MATCH = 2;

type SexFilter = 'f' | 'm' | 'all';

const mapPerkToOption = (
  perk: GetCharacterPerksForThemeRow,
  themeId: string,
  tPerks: (key: string) => any,
  recommendationKeywords: string[],
) => ({
  id: perk.id,
  key: perk.id,
  name: tPerks(`${themeId}.${perk.type}.${perk.t_key}.name`),
  description: tPerks(`${themeId}.${perk.type}.${perk.t_key}.description`),
  keywordsMatched:
    recommendationKeywords && perk.keywords
      ? perk.keywords.filter((value) => recommendationKeywords.includes(value))
      : [],
});

const sortPerksByKeywordsMatched = (perks: ReturnType<typeof mapPerkToOption>[]) =>
  [...perks].sort((a: any, b: any) => b.keywordsMatched.length - a.keywordsMatched.length);

export const useUIHelpers = (
  {
    themes,
    portraitPacks,
    characterClasses,
    characterPerks,
    items,
  }: Pick<
    ReturnType<typeof useDataProvider>,
    'themes' | 'portraitPacks' | 'characterClasses' | 'characterPerks' | 'items'
  >,
  {
    selectedThemeId,
    selectedClassId,
    selectedPortraitPackId,
  }: Pick<ReturnType<typeof useMainForm>, 'selectedThemeId' | 'selectedClassId' | 'selectedPortraitPackId'>,
) => {
  const { t } = useTranslation('themesIndex');
  const { t: tPerks } = useTranslation('characterPerks' as 'resource');
  const { t: tItems } = useTranslation('items' as 'resource');
  const { t: tPortraitPacks } = useTranslation('portraitPacks' as 'resource');

  // UI helpers
  const [selectablePortraits, setSelectablePortraits] = useState<{ id: string; coverImg: string }[]>([]);
  const [portraitsFilters, setPortraitsFilters] = useState<{
    sex: SexFilter;
  }>({
    sex: 'f',
  });
  const [keywordsFilter, setKeywordsFilter] = useState<'most_recommended' | 'all_recommended' | 'all'>(
    'most_recommended',
  );

  const themeConfig = useMemo(
    () => (selectedThemeId ? getThemeConfigForThemeId(selectedThemeId) : undefined),
    [selectedThemeId],
  );

  // Starting items filtering logic
  const [activeItemsFilter, setActiveItemsFilter] = useState<string>('all');
  const itemsFilteringOptions = useMemo(
    () =>
      themeConfig?.weaponTypes.map((weaponType) => ({
        id: weaponType,
        label: tItems(`${selectedThemeId}.weapon.${weaponType}.label` as any),
        active: activeItemsFilter === weaponType,
        onClick: () => setActiveItemsFilter((prev) => (prev === weaponType ? 'all' : weaponType)),
      })) ?? [],
    [themeConfig, activeItemsFilter, tItems, selectedThemeId],
  );

  const [currentlyChangedPerkId, setCurrentlyChangedPerkId] = useState<string | undefined>(undefined);

  // Keywords are used for perk recommendations
  const [characterKeywords, setCharacterKeywords] = useState({
    class: '',
    portraitPack: '',
  });

  const keywordsForRecommendations = useMemo(() => Object.values(characterKeywords), [characterKeywords]);

  const handlePortraitsSexFilter = (newValue: SexFilter) =>
    setPortraitsFilters({
      ...portraitsFilters,
      sex: newValue,
    });

  const handleCurrentlyChangedPerkId = (perkId: string) => setCurrentlyChangedPerkId(perkId);

  const handleKeywordsFilter = (newValue: string) => setKeywordsFilter(newValue as any);

  // Get keywords from class upon selection
  useEffect(() => {
    if (selectedClassId) {
      const selectedClass = characterClasses.find((characterClass) => characterClass.id === selectedClassId);

      if (selectedClass) {
        setCharacterKeywords((prev) => ({
          ...prev,
          class: selectedClass.t_key,
        }));
      }
    }
  }, [selectedClassId, characterClasses]);

  // Get keywords from portrait pack upon selection
  useEffect(() => {
    if (selectedPortraitPackId) {
      const selectedPortraitPack = portraitPacks.find((portraitPack) => portraitPack.id === selectedPortraitPackId);

      if (selectedPortraitPack) {
        setCharacterKeywords((prev) => ({
          ...prev,
          portraitPack: selectedPortraitPack.keywords[0],
        }));
      }
    }
  }, [selectedPortraitPackId, portraitPacks]);

  // Data mapping to options for Select Components
  const themesAsOptions = useMemo(
    () =>
      themes.map((sessionTheme) => ({
        id: sessionTheme.id,
        name: t(`${sessionTheme.id}.name` as any),
        description: t(`${sessionTheme.id}.description` as any),
        coverImg: `${process.env.REACT_APP_API_URL}/storage/v1/object/public/images/theme_covers/${sessionTheme.id}`,
      })) ?? [],
    [themes, t],
  );

  const classesAsOptions = useMemo(
    () =>
      characterClasses.map((characterClass) => ({
        id: characterClass.id,
        key: characterClass.t_key,
        name: t(`${characterClass.theme_id}.character_classes.${characterClass.t_key}.name` as any),
        description: t(`${characterClass.theme_id}.character_classes.${characterClass.t_key}.description` as any),
      })) ?? [],
    [characterClasses, t],
  );

  const itemsAsOptions = useMemo(
    () =>
      items
        .filter((item) => activeItemsFilter === 'all' || item.content?.subtype === activeItemsFilter)
        .map((item) => ({
          id: item.id,
          key: item.t_key,
          name: tItems(`${item.theme_id}.${item.type}.${item.content?.subtype}.${item.t_key}.name` as any),
          description: tItems(
            `${item.theme_id}.${item.type}.${item.content?.subtype}.${item.t_key}.description` as any,
          ),
        })) ?? [],
    [items, tItems, activeItemsFilter],
  );

  const originPerksAsOptions = useMemo(
    () =>
      sortPerksByKeywordsMatched(
        characterPerks
          .filter((perk) => perk.type === 'origin')
          .map((perk) => mapPerkToOption(perk, selectedThemeId, tPerks, keywordsForRecommendations)),
      ).filter((perk) => {
        if (keywordsFilter === 'most_recommended') {
          return perk.keywordsMatched.length === KEYWORDS_FULL_MATCH;
        }
        if (keywordsFilter === 'all_recommended') {
          return perk.keywordsMatched.length > 0;
        }
        return true;
      }),
    [characterPerks, selectedThemeId, tPerks, keywordsForRecommendations, keywordsFilter],
  );

  const experiencePerksAsOptions = useMemo(
    () =>
      sortPerksByKeywordsMatched(
        characterPerks
          .filter((perk) => perk.type === 'experience')
          .map((perk) => mapPerkToOption(perk, selectedThemeId, tPerks, keywordsForRecommendations)),
      ).filter((perk) => {
        if (keywordsFilter === 'most_recommended') {
          return perk.keywordsMatched.length === KEYWORDS_FULL_MATCH;
        }
        if (keywordsFilter === 'all_recommended') {
          return perk.keywordsMatched.length > 0;
        }
        return true;
      }),
    [characterPerks, selectedThemeId, tPerks, keywordsForRecommendations, keywordsFilter],
  );

  const personalityPerksAsOptions = useMemo(
    () =>
      characterPerks
        .filter((perk) => perk.type === 'personality')
        .map((perk) => mapPerkToOption(perk, selectedThemeId, tPerks, keywordsForRecommendations)),
    [characterPerks, selectedThemeId, tPerks, keywordsForRecommendations],
  );

  const flawPerksAsOptions = useMemo(
    () =>
      characterPerks
        .filter((perk) => perk.type === 'flaw')
        .map((perk) => mapPerkToOption(perk, selectedThemeId, tPerks, keywordsForRecommendations)),
    [characterPerks, selectedThemeId, tPerks, keywordsForRecommendations],
  );

  const portraitPacksAsOptions = useMemo(
    () =>
      portraitPacks?.map((portraitPack) => ({
        value: portraitPack.id,
        label: tPortraitPacks(portraitPack.id as any),
      })) ?? [],
    [portraitPacks, tPortraitPacks],
  );

  return {
    portraitPacksAsOptions,
    selectablePortraits,
    setSelectablePortraits,
    portraitsFilters,
    handlePortraitsSexFilter,
    themeConfig,
    keywordsForRecommendations,
    currentlyChangedPerkId,
    handleCurrentlyChangedPerkId,
    keywordsFilter,
    handleKeywordsFilter,
    itemsFilteringOptions,

    themesAsOptions,
    classesAsOptions,
    originPerksAsOptions,
    experiencePerksAsOptions,
    personalityPerksAsOptions,
    flawPerksAsOptions,
    itemsAsOptions,
  } as const;
};
