/* eslint-disable max-len */
import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { ThemesService } from 'services/ThemesService';
import { QUERY_KEYS } from 'utils/consts';

export const useDataProvider = (selectedThemeId: string) => {
  const {
    data: themes,
    isFetching: isThemesLoading,
    isError: isThemesError,
  } = useQuery([QUERY_KEYS.launcher.themes], () => ThemesService.getThemes());

  const {
    data: characterClasses,
    isFetching: isClassesLoading,
    isError: isClassesError,
  } = useQuery(
    [QUERY_KEYS.launcher.characterClassesForTheme, selectedThemeId],
    () => ThemesService.getCharacterClassesForTheme({ t_id: selectedThemeId }),
    {
      enabled: !!selectedThemeId,
    },
  );

  const {
    data: characterPerks,
    isFetching: isCharacterPerksLoading,
    isError: isCharacterPerksError,
  } = useQuery(
    [QUERY_KEYS.launcher.characterPerksForTheme, selectedThemeId],
    () => ThemesService.getCharacterPerksForTheme({ t_id: selectedThemeId ?? '' }),
    {
      enabled: !!selectedThemeId,
    },
  );

  const {
    data: items,
    isFetching: isItemsLoading,
    isError: isItemsError,
  } = useQuery(
    [QUERY_KEYS.launcher.itemsForTheme, selectedThemeId],
    () => ThemesService.getItemsForTheme({ t_id: selectedThemeId ?? '' }),
    {
      enabled: !!selectedThemeId,
    },
  );

  const {
    data: portraitPacks,
    isFetching: isPortraitPacksLoading,
    isError: isPortraitPacksError,
  } = useQuery(
    [QUERY_KEYS.launcher.portraitPacksForTheme, selectedThemeId],
    () => ThemesService.getPortraitPacksForTheme({ t_id: selectedThemeId ?? '' }),
    {
      enabled: !!selectedThemeId,
    },
  );

  useEffect(() => {
    if (isThemesError || isClassesError || isCharacterPerksError || isItemsError || isPortraitPacksError) {
      throw new Error('Error while fetching resources');
    }
  }, [isThemesError, isClassesError, isCharacterPerksError, isItemsError, isPortraitPacksError]);

  return {
    themes: themes?.data ?? [],
    characterClasses: characterClasses?.data ?? [],
    characterPerks: characterPerks?.data ?? [],
    items: items?.data ?? [],
    portraitPacks: portraitPacks?.data ?? [],
    isResourcesLoading:
      isThemesLoading || isClassesLoading || isCharacterPerksLoading || isItemsLoading || isPortraitPacksLoading,
  } as const;
};
