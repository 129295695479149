import styled, { css } from 'styled-components';
import { pxToRem } from 'utils/styleUtils';

export const TOPBAR_HEIGHT = 75;

export const TopBar = styled.div`
  height: ${TOPBAR_HEIGHT}px;
  width: 100%;
  max-width: ${({ theme }) => theme.breakpoints.mobile};
  padding: ${pxToRem(16)};
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.bgDarkNavy};
  z-index: 10;
  border-bottom: 1px solid ${({ theme }) => theme.colors.opaqueWhite25};

  &::after {
    content: '';
    display: block;
    height: 1px;
    width: ${`calc(100% - ${pxToRem(32)})`};
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: #ffffff26;
  }
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  gap: ${pxToRem(16)};
`;

export const BackButton = styled.button`
  background: transparent;
  border: none;
  font: inherit;
  padding: 0 ${pxToRem(6)};
  cursor: pointer;
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${pxToRem(8)};
`;

export const PlayersWrapper = styled.div`
  display: flex;
  gap: ${pxToRem(8)};
`;

export const AvatarWrapper = styled.button``;

export const PlayerAvatar = styled.img<{ isOnline: boolean }>`
  width: ${pxToRem(24)};
  height: ${pxToRem(32)};
  border-radius: ${({ theme }) => theme.border.radiusBase};
  border: ${pxToRem(2)} solid;
  border-color: ${({ isOnline }) => (isOnline ? '#41a828' : '#737373')};
  background-color: #ff0;
`;

export const GmAvatar = styled.div<{ isOnline: boolean }>`
  width: ${pxToRem(24)};
  height: ${pxToRem(32)};
  border-radius: ${({ theme }) => theme.border.radiusBase};
  display: flex;
  justify-content: center;
  align-items: center;
  border: ${pxToRem(2)} solid;
  border-color: ${({ isOnline }) => (isOnline ? '#41a828' : '#737373')};
  background-color: ${({ theme }) => theme.colors.highlightYellowOpaque};

  svg {
    width: ${pxToRem(14)};
    height: ${pxToRem(14)};
    fill: ${({ theme }) => theme.colors.highlightYellow};
  }
`;

export const MenuButton = styled.button`
  width: ${pxToRem(64)};
  height: ${pxToRem(64)};
  border: none;
  background: ${({ theme }) => theme.colors.opaqueWhite15};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;

  & svg {
    width: ${pxToRem(38)};
    height: ${pxToRem(38)};
  }
`;

export const Hamburger = styled.div<{ isOpen: boolean }>`
  width: ${pxToRem(32)};
  height: ${pxToRem(26)};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
`;

const hamburgerBarStyle = css`
  width: ${pxToRem(32)};
  height: ${pxToRem(4)};
  position: absolute;
  left: 0;
  border-radius: ${pxToRem(2)};
  background-color: ${({ theme }) => theme.colors.white};
  transition: 0.2s;
  transition-timing-function: linear;
`;

export const HamburgerFirst = styled.div<{ isOpen: boolean }>`
  ${hamburgerBarStyle};
  top: 0;

  ${({ isOpen }) =>
    isOpen &&
    css`
      top: 50%;
      transform: translateY(-50%) rotate(45deg);
    `}
`;

export const HamburgerSecond = styled.div<{ isOpen: boolean }>`
  ${hamburgerBarStyle};
  top: 50%;
  transform: translateY(-50%);

  ${({ isOpen }) =>
    isOpen &&
    css`
      left: -105%;
    `}
`;

export const HamburgerThird = styled.div<{ isOpen: boolean }>`
  ${hamburgerBarStyle};
  bottom: 0;

  ${({ isOpen }) =>
    isOpen &&
    css`
      top: 50%;
      transform: translateY(-50%) rotate(-45deg);
    `}
`;
