import React from 'react';
import { Text } from 'components/generics/Text/Text';
import { useIsGameMaster } from 'hooks/useIsGameMaster';
import { ReactComponent as IconGm } from 'assets/images/svg/icon_book.svg';
import { ReactComponent as IconSend } from 'assets/images/svg/send.svg';
import { ReactComponent as IconScrollDown } from 'assets/images/svg/icon_back_arrow.svg';
import { MessageInput } from 'components/MessageInput/MessageInput';
import { SessionRecord } from 'models/db/db_SessionRecord';
import { Spinner } from 'components/generics/Spinner/Spinner';
import {
  BottomBar,
  RecordAuthorImage,
  RecordsGroup,
  Wrapper,
  ContentWrapper,
  RecordsWrapper,
  GmAuthor,
  RecordAuthorButton,
  ScrollDownButton,
  SendButton,
  ShowScrollThreshold,
  BottomElement,
} from './RecordsView.styles';
import { useSessionRecords } from './RecordsView.hooks';
import { TypingIndicatorRecord } from './components/TypingIndicatorRecord';
import { MessageRecord } from './components/MessageRecord';
import { FreeRollRecord } from './components/FreeRollRecord';
import { ActionRollRecord } from './components/ActionRollRecord';
import { CallRollRecord } from './components/CallRollRecord';
import { LocationChangeRecord } from './components/LocationChangeRecord';
import { StoryCardRecord } from './components/StoryCardRecord';
import { InventoryRecord } from './components/ItemRecord';
import { HealthChangeRecord } from './components/HealthChangeRecord';
import { MoneyChangeRecord } from './components/MoneyChangeRecord';
import { ItemObtainedRecord } from './components/ItemObtainedRecord';

// Function to break session record into records groups based on the player character ID
function breakRecordIntoRecordsGroups(arr: SessionRecord[]): SessionRecord[][] {
  const result: SessionRecord[][] = [];

  if (arr.length === 0) {
    return result;
  }

  let currentSubarray: SessionRecord[] = [arr[0]];

  for (let i = 1; i < arr.length; i += 1) {
    const currentItem = arr[i];
    const previousItem = arr[i - 1];

    if (currentItem.player_character_id !== previousItem.player_character_id) {
      result.push(currentSubarray);
      currentSubarray = [currentItem];
    } else {
      currentSubarray.push(currentItem);
    }
  }

  result.push(currentSubarray);

  return result;
}

export const SessionRecordsView = () => {
  // Destructure the required state and functions from useSessionRecords hook
  const {
    message,
    sessionRecords,
    activePlayer,
    getSessionPlayerById,
    handleMessageChange,
    handleSubmit,
    toggleMessageInputFocus,
    redirectToCharacter,
    isFetchingMoreRecords,
    scrollableElementRef,
    bottomElementRef,
    topElementRef,
    isScrollDownButtonVisible,
    messageInputRef,
    scrollThresholdElementRef,
  } = useSessionRecords();

  const { isGameMaster } = useIsGameMaster();

  return (
    <Wrapper>
      <ContentWrapper ref={scrollableElementRef}>
        <div ref={topElementRef} />
        {isFetchingMoreRecords && <Spinner size="small" center />}
        {breakRecordIntoRecordsGroups(sessionRecords).map((recordsGroup) => {
          const author = getSessionPlayerById(recordsGroup[0].player_character_id ?? '');

          return (
            <RecordsGroup
              key={`session-records_group-${recordsGroup[0].player_character_id ?? 'gm'}_${
                recordsGroup[0].created_at
              }}`}
              isMe={
                isGameMaster
                  ? recordsGroup[0].player_character_id === null
                  : recordsGroup[0].player_character_id === activePlayer?.character_id
              }
            >
              {author && (
                <RecordAuthorButton type="button" onClick={() => redirectToCharacter(author.character_id)}>
                  <RecordAuthorImage src={author.portrait} loading="lazy" />
                </RecordAuthorButton>
              )}
              {!author && (
                <GmAuthor>
                  <IconGm />
                </GmAuthor>
              )}
              <RecordsWrapper>
                <Text fontSize="small" color="opaqueWhite50" fontWeight={600}>
                  {author?.name || 'GM'}
                </Text>
                {recordsGroup.map((record) => {
                  switch (record.record_type) {
                    case 'typing_indicator':
                      return <TypingIndicatorRecord key={record.id} isGm={!author} />;
                    case 'message':
                      return <MessageRecord key={record.id} isGm={!author} record={record} />;
                    case 'free_roll':
                      return <FreeRollRecord key={record.id} isGm={!author} record={record} />;
                    case 'action_roll':
                      return <ActionRollRecord key={record.id} record={record} />;
                    case 'call_roll':
                      return (
                        <CallRollRecord
                          key={record.id}
                          isGm={!author}
                          record={record}
                          playerCharacter={getSessionPlayerById(record.content.playerCharacterId)}
                        />
                      );
                    case 'location_change':
                      return <LocationChangeRecord key={record.id} isGm={!author} record={record} />;
                    case 'story_card':
                      return <StoryCardRecord key={record.id} isGm={!author} record={record} />;
                    case 'health_change':
                      return <HealthChangeRecord key={record.id} isGm={!author} record={record} />;
                    case 'money_change':
                      return <MoneyChangeRecord key={record.id} isGm={!author} record={record} />;
                    case 'item':
                      return <InventoryRecord key={record.id} isGm={!author} record={record} />;
                    case 'item_obtained':
                      return <ItemObtainedRecord key={record.id} isGm={!author} record={record} />;
                    default:
                      return null;
                  }
                })}
              </RecordsWrapper>
            </RecordsGroup>
          );
        })}
        <BottomElement ref={bottomElementRef}>
          <ShowScrollThreshold threshold={300} ref={scrollThresholdElementRef} />
        </BottomElement>
      </ContentWrapper>
      <BottomBar>
        {isScrollDownButtonVisible && (
          <ScrollDownButton type="button" onClick={() => bottomElementRef.current?.scrollIntoView()}>
            <IconScrollDown />
          </ScrollDownButton>
        )}
        <MessageInput
          value={message}
          onChange={handleMessageChange}
          toggleFocus={toggleMessageInputFocus}
          onEnterPress={handleSubmit}
        />
        <SendButton disabled={!message} type="button" onClick={handleSubmit}>
          <IconSend />
        </SendButton>
      </BottomBar>
      <div ref={messageInputRef} style={{ backgroundColor: 'red' }} />
    </Wrapper>
  );
};
