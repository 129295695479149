import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSessionPlayersAPI } from 'hooks/sessionApi/useSessionPlayersAPI';
import { useIsGameMaster } from 'hooks/useIsGameMaster';
import { setCharacterIdToView, useGameplaySelector } from 'stores/gameplay.slice';
import { Text } from 'components/generics/Text/Text';
import { ReactComponent as Arrow } from 'assets/images/svg/chevron-down.svg';
import { useAppDispatch } from 'stores/store';
import { MultiTabSection } from 'components/MultiTabSection/MultiTabSection';
import { GetPlayersForSessionRow } from 'models/functions/GetPlayersForSession';
import { TabButtons } from 'components/MultiTabSection/TabButtons';
import {
  Avatar,
  AvatarBox,
  Wrapper,
  CarouselButton,
  CharacterCarousel,
  HorizontalSeparator,
  UserDisplayNameWrapper,
} from './PartyView.styles';
import AboutSection from './sections/AboutSection';
import AttributesSection from './sections/AttributesSection';
import InventorySection from './sections/InventorySection';
import StateSection from './sections/StateSection';

enum Tabs {
  ABOUT = 'about',
  STATE = 'state',
  ATTRIBUTES = 'attributes',
  INVENTORY = 'inventory',
}

export const PartyView = () => {
  const { t } = useTranslation('gameplay', { keyPrefix: 'party_view' });
  const { t: tClass } = useTranslation('themesIndex');
  const [activeTabId, setActiveTabId] = useState(Tabs.ABOUT);

  const { activeSession, activePlayer, characterIdToView } = useGameplaySelector();
  const { sessionPlayersQuery, getters } = useSessionPlayersAPI();
  const sessionPlayers = useMemo(() => sessionPlayersQuery.data?.data ?? [], [sessionPlayersQuery.data]);
  const { isGameMaster } = useIsGameMaster();

  const [displayedCharacter, setDisplayedCharacter] = useState<GetPlayersForSessionRow | undefined>(undefined);
  const [playersOffset, setPlayersOffset] = useState(0);
  const dispatch = useAppDispatch();

  const shouldDisplayCarouselButton = sessionPlayers.length > 1;

  useEffect(() => {
    if (!isGameMaster && !characterIdToView && !displayedCharacter) {
      setDisplayedCharacter(getters.getActivePlayerCharacter());
    }
  }, [activePlayer, characterIdToView, getters, displayedCharacter, isGameMaster]);

  useEffect(() => {
    if (characterIdToView) {
      const characterOffset = sessionPlayers.findIndex((player) => player.character_id === characterIdToView);
      setPlayersOffset(characterOffset);
    }
  }, [characterIdToView, sessionPlayers]);

  useEffect(() => {
    if (sessionPlayers.length > 0) {
      setDisplayedCharacter(sessionPlayers[playersOffset]);
    }
  }, [playersOffset, sessionPlayers, dispatch, characterIdToView]);

  const handleNextPlayer = useCallback(() => {
    if (characterIdToView) {
      setCharacterIdToView({ characterId: undefined });
    }

    setPlayersOffset((prevOffset) => (prevOffset < sessionPlayers.length - 1 ? prevOffset + 1 : 0));
  }, [characterIdToView, sessionPlayers.length]);

  const handlePreviousPlayer = useCallback(() => {
    if (characterIdToView) {
      setCharacterIdToView({ characterId: undefined });
    }

    setPlayersOffset((prevOffset) => (prevOffset > 0 ? prevOffset - 1 : sessionPlayers.length - 1));
  }, [characterIdToView, sessionPlayers.length]);

  const renderCarouselButton = (previous?: boolean) => (
    <CarouselButton previous={previous} onClick={previous ? handlePreviousPlayer : handleNextPlayer}>
      <Arrow />
    </CarouselButton>
  );

  const renderAvatar = () => (
    <AvatarBox>
      {displayedCharacter?.owner_display_name && (
        <UserDisplayNameWrapper>
          <Text fontWeight={600}>{displayedCharacter?.owner_display_name}</Text>
          <Text fontSize="smaller" letterSpacing="superWide" textTransform="uppercase" color="opaqueWhite75">
            {t('display_name_suffix')}
          </Text>
        </UserDisplayNameWrapper>
      )}
      <Avatar src={displayedCharacter?.portrait} loading="lazy" />
      <Text fontSize="bigger" letterSpacing="wide" fontWeight={700} textAlign="center">
        {displayedCharacter?.name}
      </Text>
      <Text letterSpacing="superWide" textTransform="uppercase" color="opaqueWhite75" fontWeight={500}>
        {tClass(`${activeSession?.theme_id}.character_classes.${displayedCharacter?.class_key}.name` as any)}
      </Text>
    </AvatarBox>
  );

  const displayFullCharacterSheet = displayedCharacter?.character_id === activePlayer?.character_id || isGameMaster;

  return activePlayer || sessionPlayers.length > 0 ? (
    <Wrapper>
      <CharacterCarousel>
        {shouldDisplayCarouselButton && renderCarouselButton(true)}
        {renderAvatar()}
        {shouldDisplayCarouselButton && renderCarouselButton()}
      </CharacterCarousel>

      {displayFullCharacterSheet && (
        <TabButtons
          tabs={[
            {
              id: Tabs.ABOUT,
              label: t('about_section.label'),
              active: activeTabId === Tabs.ABOUT,
              onClick: () => setActiveTabId(Tabs.ABOUT),
            },
            {
              id: Tabs.STATE,
              label: t('state_section.label'),
              active: activeTabId === Tabs.STATE,
              onClick: () => setActiveTabId(Tabs.STATE),
            },
            {
              id: Tabs.ATTRIBUTES,
              label: t('attributes_section.label'),
              active: activeTabId === Tabs.ATTRIBUTES,
              onClick: () => setActiveTabId(Tabs.ATTRIBUTES),
            },
            {
              id: Tabs.INVENTORY,
              label: t('inventory_section.label'),
              active: activeTabId === Tabs.INVENTORY,
              onClick: () => setActiveTabId(Tabs.INVENTORY),
            },
          ]}
        />
      )}

      <HorizontalSeparator />

      {displayFullCharacterSheet ? (
        <MultiTabSection
          activeTabId={activeTabId}
          tabs={[
            {
              id: Tabs.ABOUT,
              component: (
                <AboutSection
                  characterPerks={displayedCharacter?.perks || []}
                  themeId={activeSession?.theme_id || ''}
                />
              ),
            },
            {
              id: Tabs.ATTRIBUTES,
              component: (
                <AttributesSection
                  characterSchema={displayedCharacter?.character_schema}
                  themeId={activeSession?.theme_id || ''}
                />
              ),
            },
            {
              id: Tabs.INVENTORY,
              component: (
                <InventorySection
                  characterItems={displayedCharacter?.items || []}
                  themeId={activeSession?.theme_id || ''}
                  isManagable={displayedCharacter?.character_id === activePlayer?.character_id}
                  money={displayedCharacter?.money || null}
                />
              ),
            },
            {
              id: Tabs.STATE,
              component: <StateSection characterHealth={displayedCharacter?.health} />,
            },
          ]}
        />
      ) : (
        <StateSection characterHealth={displayedCharacter?.health} />
      )}
    </Wrapper>
  ) : null;
};
