import React, { PropsWithChildren, useEffect } from 'react';
import { useIsLoggedIn } from 'hooks/useIsLoggedIn';
import { useAppDispatch } from 'stores/store';
import { useUserSelector } from 'stores/user.slice';
import { getUserProfileThunk } from 'stores/user.thunks';
import { ErrorView } from 'views/ErrorView/ErrorView';
import { Spinner } from 'components/generics/Spinner/Spinner';
import { SpinnerWrapper } from './AuthBoundary.styles';

export const AuthBoundary = ({ children }: PropsWithChildren) => {
  const user = useUserSelector();
  const dispatch = useAppDispatch();
  const isLoggedIn = useIsLoggedIn();

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(getUserProfileThunk());
    }
  }, [dispatch, isLoggedIn]);

  if (user.status === 'pending') {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );
  }

  if (user.status === 'failed') {
    return <ErrorView />;
  }

  return children as JSX.Element;
};
