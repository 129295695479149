import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from 'components/generics/Text/Text';

export const HandbookSessionLogSection = () => {
  const { t } = useTranslation('handbook', { keyPrefix: 'session_records_view' });

  return (
    <>
      <Text fontSize="small" color="opaqueWhite75" fontWeight={500}>
        {t('paragraphs.p1')}
      </Text>
      <Text fontSize="small" color="opaqueWhite75" fontWeight={500}>
        {t('paragraphs.p2')}
      </Text>
    </>
  );
};
