import React from 'react';
import { MultiTabSectionContainer } from './MultiTabSection.styles';

interface MultiTabSectionProps {
  tabs: {
    id: string;
    component: React.ReactNode;
  }[];
  activeTabId: string;
}

export const MultiTabSection = ({ tabs, activeTabId }: MultiTabSectionProps) => (
  <MultiTabSectionContainer>{tabs.find(({ id }) => id === activeTabId)?.component}</MultiTabSectionContainer>
);
