import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { routes } from 'components/MainRouter/MainRouter';
import { useIsLoggedIn } from './useIsLoggedIn';

export const useRedirectIfLogged = (to = routes.home) => {
  const navigate = useNavigate();
  const isLoggedIn = useIsLoggedIn();

  useEffect(() => {
    if (isLoggedIn) {
      navigate(to);
    }
  }, [navigate, isLoggedIn, to]);
};
