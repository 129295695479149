import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'components/Helmet/Helmet';
import { Text } from 'components/generics/Text/Text';
import { TextInput } from 'components/generics/Form/TextInput/TextInput';
import { Select } from 'components/generics/Select/Select';
import { ColorButton } from 'components/generics/Button/ColorButton';
import LauncherLayout from 'components/LauncherLayout/LauncherLayout';
import { useJoinSessionView } from './JoinSessionView.hooks';
import { Container, JoinButtonWrapper } from './JoinSessionView.styles';

export const JoinSessionView = () => {
  const { t } = useTranslation('launcher', { keyPrefix: 'join_session_view' });
  const {
    sessionCode,
    selectedCharacterId,
    compatibleCharacters,
    handleSessionCodeChange,
    handleSelectedCharacterChange,
    onJoinSession,
    onFindSession,
    sessionNotFoundError,
    ownSessionError,
    alreadyHaveCharacterError,
    noCompatibleCharactersError,
  } = useJoinSessionView();

  return (
    <>
      <Helmet>
        <title>{t('title')}</title>
      </Helmet>
      <LauncherLayout>
        <Container>
          <Text fontSize="bigger" fontWeight={500} letterSpacing="wide">
            {t('heading')}
          </Text>
          <TextInput
            value={sessionCode}
            onChange={handleSessionCodeChange}
            placeholder={t('access_code_input_placeholder')}
          />
          {compatibleCharacters.length > 0 && (
            <Select
              label="Compatible Characters"
              options={compatibleCharacters}
              onChange={handleSelectedCharacterChange}
            />
          )}
          {sessionNotFoundError && <div>{t('session_not_found')}</div>}
          {alreadyHaveCharacterError && <div>{t('already_have_character_error')}</div>}
          {ownSessionError && <div>{t('own_session_error')}</div>}
          {noCompatibleCharactersError && <div>No compatible characters found for this session theme</div>}
          {compatibleCharacters.length === 0 && (
            <JoinButtonWrapper>
              <ColorButton isDisabled={!sessionCode} onClick={onFindSession}>
                FIND
              </ColorButton>
            </JoinButtonWrapper>
          )}
          {compatibleCharacters.length > 0 && (
            <JoinButtonWrapper>
              <ColorButton isDisabled={!selectedCharacterId} onClick={onJoinSession}>
                JOIN
              </ColorButton>
            </JoinButtonWrapper>
          )}
        </Container>
      </LauncherLayout>
    </>
  );
};
