import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Protected } from 'components/Protected/Protected';

import { HomeView } from 'views/HomeView/HomeView';
import { RegisterView } from 'views/RegisterView/RegisterView';
import { LoginView } from 'views/LoginView/LoginView';
import { EmailConfirmedView } from 'views/EmailConfirmedView/EmailConfirmedView';
import { ForgotPasswordView } from 'views/ForgotPasswordView/ForgotPasswordView';
import { ResetPasswordView } from 'views/ResetPasswordView/ResetPasswordView';
import { OAuthSuccessView } from 'views/OAuthSuccessView/OAuthSuccessView';
import { NotFoundView } from 'views/NotFoundView/NotFoundView';
import { HandbookView } from 'views/HandbookView/HandbookView';
import { SessionsView } from 'views/SessionsView/SessionsView';
import { CharactersView } from 'views/CharactersView/CharactersView';
import { SessionView } from 'views/Gameplay/SessionView/SessionView';
import { CreateSessionView } from 'views/CreateSessionView/CreateSessionView';
import { JoinSessionView } from 'views/JoinSessionView/JoinSessionView';
import { CharacterCreatorView } from 'views/CharacterCreatorView/CharacterCreatorView';
import { UserSettingsView } from 'views/UserSettingsView/UserSettingsView';
import { OnboardingView } from 'views/OnboardingView/OnboardingView';

export const routes = {
  home: '/',
  register: '/register',
  login: '/login',
  emailConfirmed: '/email-confirmed',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password',
  OAuthSuccess: '/oauth/success',
  userSettings: '/settings',
  handbook: '/handbook',
  sessions: '/sessions',
  characters: '/characters',
  session: '/session/:sessionId',
  createSession: '/create-session',
  joinSession: '/join-session',
  createCharacter: '/create-character',
  onboarding: '/onboarding',
  notFound: '*',
} as const;

export const dynamicRoutes = {
  loginWithRedirect: (nextPath: string) => `${routes.login}?next=${nextPath}`,
  session: (sessionId: string) => `/session/${sessionId}`,
} as const;

export const MainRoutes = () => (
  <Routes>
    <Route
      path={routes.home}
      element={
        <Protected>
          <HomeView />
        </Protected>
      }
    />

    <Route path={routes.register} element={<RegisterView />} />
    <Route path={routes.login} element={<LoginView />} />
    <Route path={routes.emailConfirmed} element={<EmailConfirmedView />} />
    <Route path={routes.forgotPassword} element={<ForgotPasswordView />} />
    <Route path={routes.resetPassword} element={<ResetPasswordView />} />
    <Route path={routes.OAuthSuccess} element={<OAuthSuccessView />} />
    <Route path={routes.notFound} element={<NotFoundView />} />

    <Route
      path={routes.handbook}
      element={
        <Protected>
          <HandbookView />
        </Protected>
      }
    />
    <Route
      path={routes.sessions}
      element={
        <Protected>
          <SessionsView />
        </Protected>
      }
    />
    <Route
      path={routes.characters}
      element={
        <Protected>
          <CharactersView />
        </Protected>
      }
    />
    <Route
      path={routes.session}
      element={
        <Protected>
          <SessionView />
        </Protected>
      }
    />
    <Route
      path={routes.createSession}
      element={
        <Protected>
          <CreateSessionView />
        </Protected>
      }
    />
    <Route
      path={routes.joinSession}
      element={
        <Protected>
          <JoinSessionView />
        </Protected>
      }
    />
    <Route
      path={routes.createCharacter}
      element={
        <Protected>
          <CharacterCreatorView />
        </Protected>
      }
    />
    <Route
      path={routes.userSettings}
      element={
        <Protected>
          <UserSettingsView />
        </Protected>
      }
    />
    <Route
      path={routes.onboarding}
      element={
        <Protected>
          <OnboardingView />
        </Protected>
      }
    />
  </Routes>
);
