import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'components/Helmet/Helmet';
import { routes } from 'components/MainRouter/MainRouter';
import { Text } from 'components/generics/Text/Text';
import { useOnlyDevelopment } from 'hooks/useOnlyDevelopment';
import { Cta } from 'components/generics/Form/AuthLayout/AuthLayout.styles';
import { getEnv } from 'utils/getEnv';
import { Wrapper } from './EmailConfirmedView.styles';

export const EmailConfirmedView = () => {
  const { t } = useTranslation('launcher', { keyPrefix: 'email_confirmed_view' });

  useEffect(() => {
    localStorage.removeItem(getEnv('REACT_APP_SB_AUTH_TOKEN_NAME'));
  }, []);

  useOnlyDevelopment();

  return (
    <>
      <Helmet>
        <title>{t('title')}</title>
      </Helmet>
      <Wrapper>
        <Text fontWeight={700} fontSize="bigger" textAlign="center">
          {t('heading')}
        </Text>
        <Text textAlign="center">{t('text')}</Text>
        <Cta to={routes.login}>{t('log_in_button')}</Cta>
      </Wrapper>
    </>
  );
};
