import React from 'react';
import { useTranslation } from 'react-i18next';
import { useGameplaySelector } from 'stores/gameplay.slice';
import { TextInput } from 'components/generics/Form/TextInput/TextInput';
import ImageMoney from 'assets/images/coins.png';
import { Text } from 'components/generics/Text/Text';
import { ColorButton } from 'components/generics/Button/ColorButton';
import { pxToRem } from 'utils/styleUtils';
import { SupabaseService } from 'services/SupabaseService';
import { DB_TABLE_NAMES } from 'utils/consts';
import { SessionPlayer } from 'models/db/instance_SessionPlayer';
import { OmitAutoGenerated } from 'models/Api';
import { SessionRecord } from 'models/db/db_SessionRecord';
import { GetPlayersForSessionRow } from 'models/functions/GetPlayersForSession';
import {
  ButtonsWrapper,
  Centerer,
  CoinsIcon,
  MoneyChangeContainer,
  MoneyToggle,
  Row,
} from '../../ManagementView.styles';

interface MoneyManagementProps {
  selectedCharacter: GetPlayersForSessionRow | undefined;
}

export const MoneyManagement = ({ selectedCharacter }: MoneyManagementProps) => {
  const { t } = useTranslation('gameplay', { keyPrefix: 'management_view' });
  const { activeSession } = useGameplaySelector();
  const [money, setMoney] = React.useState<string>('50');
  const [isAdding, setIsAdding] = React.useState(true);

  const valueToAdd: number = isAdding ? Number(money) : -1 * Number(money);
  const newValue: number = selectedCharacter?.money !== undefined ? selectedCharacter.money + valueToAdd : 0;

  let inputError: string | undefined;
  if (Number(money) < 1 || Number(money) > 999) {
    inputError = t('manage_money.input_too_big_error');
  } else if (newValue < 0) {
    inputError = t('manage_money.input_player_doesnt_have_enough_money_error');
  }

  const handleConfirm = async () => {
    if (!activeSession) return;

    await SupabaseService.from(DB_TABLE_NAMES.instanceSessionPlayer)
      .update<Partial<SessionPlayer>>({ money: newValue })
      .eq('id', selectedCharacter?.fingerprint);

    await SupabaseService.from(DB_TABLE_NAMES.dbSessionRecord).insert<OmitAutoGenerated<SessionRecord>>([
      {
        player_character_id: undefined,
        session_id: activeSession?.id,
        record_type: 'money_change',
        content: {
          character_id: selectedCharacter?.character_id ?? '',
          change: valueToAdd,
          newValue,
        },
      },
    ]);
  };

  return (
    <>
      <Row>
        <CoinsIcon src={ImageMoney} loading="lazy" />
        <Text fontWeight={700}>{selectedCharacter?.money}</Text>
      </Row>
      <Centerer>
        <MoneyChangeContainer>
          <MoneyToggle onClick={() => setIsAdding((value) => !value)}>
            <Text fontWeight={700} fontSize="large">
              {isAdding ? '+' : '-'}
            </Text>
          </MoneyToggle>
          <TextInput
            fixedWidth={pxToRem(64)}
            type="text"
            value={money}
            onChange={(event) => {
              // Allow only numbers - number type input allowed other values such as minus sign
              if (event.target.value === '' || /^\d+$/.test(event.target.value)) {
                setMoney(event.target.value);
              }
            }}
            min={1}
            max={999}
            error={inputError ? '' : undefined}
          />
          <CoinsIcon src={ImageMoney} loading="lazy" />
        </MoneyChangeContainer>
        {inputError && (
          <Text color="secondary" fontSize="small">
            {inputError}
          </Text>
        )}
      </Centerer>
      {!inputError && selectedCharacter?.money && (
        <Row>
          <Text fontWeight={700}>{'= '}</Text>
          <CoinsIcon src={ImageMoney} loading="lazy" />
          <Text fontWeight={700}>{` ${newValue}`}</Text>
        </Row>
      )}
      <ButtonsWrapper>
        <ColorButton onClick={handleConfirm} isDisabled={!money || !!inputError}>
          <Text fontSize="smaller" textTransform="uppercase">
            {t('confirm_button')}
          </Text>
        </ColorButton>
      </ButtonsWrapper>
    </>
  );
};
