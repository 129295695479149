import styled from 'styled-components';
import { pxToRem } from 'utils/styleUtils';

export const Wrapper = styled.div`
  display: flex;
  margin: ${pxToRem(16)} 0;
  flex-direction: column;
  gap: ${pxToRem(16)};
`;

export const UserDisplayNameWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: ${pxToRem(4)};
  align-items: center;
  margin-bottom: 0.5rem;
`;

export const CharacterCarousel = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
  justify-content: center;
  flex-flow: row nowrap;
  margin-bottom: 0.8rem;
`;

export const AvatarBox = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${pxToRem(4)};
`;

export const Avatar = styled.img`
  width: ${pxToRem(200)};
  height: ${pxToRem(300)};
  border-radius: ${({ theme }) => theme.border.radiusBase};
  box-shadow: ${({ theme }) => `${pxToRem(4)} ${pxToRem(4)} ${pxToRem(4)} ${theme.colors.opaqueBlack50}`};
  margin-bottom: 1rem;
`;

export const TextGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  animation: expand 0.2s ease-in-out;

  @keyframes expand {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0);
    }
  }
`;

export const DescriptionCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${pxToRem(8)};

  animation: expand 0.2s ease-in-out;
`;

export const CarouselButton = styled.button<{ previous?: boolean }>`
  display: flex;
  cursor: pointer;

  svg {
    transform: ${({ previous }) => (previous ? 'rotate(90deg)' : 'rotate(-90deg)')};
    width: ${pxToRem(48)};
    height: ${pxToRem(48)};
    stroke: ${({ theme }) => theme.colors.white};
  }
`;

export const HorizontalSeparator = styled.div`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.opaqueWhite25};
`;

export const AttributeItem = styled.button`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
`;

export const AttributeImage = styled.img<{ zoomedVariant?: boolean }>`
  width: ${pxToRem(26)};
  height: ${pxToRem(26)};
  border-radius: ${pxToRem(8)};

  ${({ zoomedVariant }) =>
    zoomedVariant &&
    `width: ${pxToRem(86)};
    height: ${pxToRem(86)};
    `}
`;

export const ExpandedAttributeInnerWrapper = styled.div`
  display: flex;
  flex-direction: row nowrap;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;

  span {
    text-align: left;
  }
`;

export const ExpandedAttributeContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  gap: 0.5rem;
  padding: 0.5rem;
  border-radius: ${({ theme }) => theme.border.radiusBase};
  background-color: ${({ theme }) => theme.colors.opaqueBlack25};
  animation: expand 0.2s ease-in-out;

  @keyframes expand {
    0% {
      transform: translateX(-100%) scale(0);
    }
    100% {
      transform: translateX(0) scale(1);
    }
  }
`;

export const ModifiersWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 0.75rem;
`;
