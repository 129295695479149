import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'components/Helmet/Helmet';
import { Spinner } from 'components/generics/Spinner/Spinner';
import { menuItems } from 'components/LauncherNavMenu/LauncherNavMenu';
import LauncherLayout from 'components/LauncherLayout/LauncherLayout';
import { useCharactersView } from './CharactersView.hooks';
import { CharactersList } from './CharactersList/CharactersList';

export const CharactersView = () => {
  const { t } = useTranslation('launcher', { keyPrefix: 'characters_view' });
  const { characters, areCharactersLoading } = useCharactersView();

  return (
    <>
      <Helmet>
        <title>{t('title')}</title>
      </Helmet>
      <LauncherLayout activeItem={menuItems.characters}>
        {areCharactersLoading ? <Spinner /> : <CharactersList characters={characters} />}
      </LauncherLayout>
    </>
  );
};
