import { GetCharacterClassesForThemeRow } from 'models/functions/GetCharacterClassesForTheme';
import { GetCharacterPerksForThemeRow } from 'models/functions/GetCharacterPerksForTheme';
import { CharacterSchema } from 'models/db/db_PlayerCharacter';
import { mergeObjects } from './mergeObjects';

interface Props {
  selectedThemeId: string;
  characterAge: string;
  selectedClassId: string;
  selectedOriginPerkId: string;
  selectedExperiencePerkIds: string[];
  selectedPersonalityPerkIds: string[];
  selectedFlawPerkId: string;
  themeConfig: any;
  characterClasses: GetCharacterClassesForThemeRow[];
  characterPerks: GetCharacterPerksForThemeRow[];
}

export const calculateRecommendedBuild = async ({
  selectedThemeId,
  characterAge,
  selectedClassId,
  selectedOriginPerkId,
  selectedExperiencePerkIds,
  selectedPersonalityPerkIds,
  selectedFlawPerkId,
  themeConfig,
  characterClasses,
  characterPerks,
}: Props) => {
  if (selectedThemeId && characterAge) {
    const characterAgeSchema = themeConfig?.characterAgeSchema[characterAge];

    let mergedObj: CharacterSchema = {
      attributes: { ...characterAgeSchema },
      skills: {},
    };

    if (selectedClassId) {
      const selectedClassSchema = characterClasses.find(
        (characterClass: GetCharacterClassesForThemeRow) => characterClass.id === selectedClassId,
      )?.character_schema;
      if (selectedClassSchema !== undefined) {
        mergedObj = mergeObjects(mergedObj, selectedClassSchema);
      }

      if (selectedOriginPerkId) {
        const selectedOriginPerkSchema = characterPerks.find(
          (perk: GetCharacterPerksForThemeRow) => perk.id === selectedOriginPerkId,
        )?.content?.character_schema;

        if (selectedOriginPerkSchema !== undefined) {
          mergedObj = mergeObjects(mergedObj, selectedOriginPerkSchema);
        }
      }

      if (selectedExperiencePerkIds.length) {
        characterPerks
          .filter((perk: GetCharacterPerksForThemeRow) => selectedExperiencePerkIds.includes(perk.id))
          .forEach((perk: GetCharacterPerksForThemeRow) => {
            const selectedExperiencePerkSchema = perk.content?.character_schema;

            if (selectedExperiencePerkSchema) {
              mergedObj = mergeObjects(mergedObj, selectedExperiencePerkSchema);
            }
          });
      }

      if (selectedPersonalityPerkIds.length) {
        characterPerks
          .filter((perk: GetCharacterPerksForThemeRow) => selectedPersonalityPerkIds.includes(perk.id))
          .forEach((perk: GetCharacterPerksForThemeRow) => {
            const selectedPersonalityPerkSchema = perk.content?.character_schema;

            if (selectedPersonalityPerkSchema) {
              mergedObj = mergeObjects(mergedObj, selectedPersonalityPerkSchema);
            }
          });
      }

      if (selectedFlawPerkId) {
        const selectedFlawPerkSchema = characterPerks.find(
          (perk: GetCharacterPerksForThemeRow) => perk.id === selectedFlawPerkId,
        )?.content?.character_schema;

        if (selectedFlawPerkSchema !== undefined) {
          mergedObj = mergeObjects(mergedObj, selectedFlawPerkSchema);
        }
      }

      return mergedObj;
    }
  }

  return {
    attributes: {},
    skills: {},
  };
};
