import styled from 'styled-components';
import { pxToRem } from 'utils/styleUtils';

export const MSG_INPUT_HEIGHT = 48;

export const MessageContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 0.25rem;
  width: 100%;
`;

export const MessageEditable = styled.textarea`
  resize: none;
  border-radius: ${({ theme }) => theme.border.radiusBase};
  border: ${({ theme }) => `${theme.border.base} ${theme.colors.opaqueWhite25}`};
  background-color: ${({ theme }) => theme.colors.opaqueBlack25};
  color: ${({ theme }) => theme.colors.white};
  font: inherit;
  font-size: ${({ theme }) => theme.fontSizes.medium};
  padding: 0.5rem;
  height: ${pxToRem(MSG_INPUT_HEIGHT)};
  overflow: hidden;
  transition: height ${({ theme }) => theme.transition.baseDuration} ease-in-out;

  &:focus {
    color: ${({ theme }) => theme.colors.white};
    border-color: ${({ theme }) => theme.colors.opaqueWhite50};
    outline: none;
    height: 8rem;
    overflow-y: auto;
  }
`;
