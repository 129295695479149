import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;
  height: 100%;
`;

export const JoinButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: auto;

  button {
    text-transform: uppercase;
  }
`;
