import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'components/Helmet/Helmet';
import { Text } from 'components/generics/Text/Text';
import { TextInput } from 'components/generics/Form/TextInput/TextInput';
import { Spinner } from 'components/generics/Spinner/Spinner';
import { ColorButton } from 'components/generics/Button/ColorButton';
import { OverlaySelect } from 'components/OverlaySelect/OverlaySelect';
import LauncherLayout from 'components/LauncherLayout/LauncherLayout';
import { CopyableField } from 'components/CopyableField/CopyableField';
import { useCreateSessionView } from './CreateSessionView.hooks';
import { Container, InputsWrapper, SubmitButtonWrapper } from './CreateSessionView.styles';
import { LocationSelectOverlay } from './LocationSelectOverlay/LocationSelectOverlay';

export const CreateSessionView = () => {
  const [isOverlayActive, setIsOverlayActive] = useState(false);
  const [isChangingLocation, setIsChangingLocation] = useState(false);

  const {
    themes,
    accessCode,
    sessionTitle,
    selectedThemeId,
    handleThemeSelection,
    handleSessionTitleChange,
    handleSubmit,
    isError,
    isLoading,
    isGeneratingCode,
    selectedLocation,
    selectedLocationPack,
    handleLocationSelection,
    handleLocationPackSelection,
    isButtonDisabled,
  } = useCreateSessionView();

  const { t } = useTranslation('launcher', { keyPrefix: 'create_new_session' });

  const toggleOverlay = () => setIsOverlayActive((prev) => !prev);
  const toggleLocationOverlay = () => setIsChangingLocation((prev) => !prev);

  return (
    <>
      <Helmet>
        <title>{t('title')}</title>
      </Helmet>
      <LauncherLayout>
        {isLoading ? (
          <Spinner />
        ) : (
          <Container>
            {isChangingLocation ? (
              <LocationSelectOverlay
                themeId={selectedThemeId}
                isOverlayActive={isChangingLocation}
                toggleOverlay={toggleLocationOverlay}
                selectedLocation={selectedLocation}
                selectedLocationPack={selectedLocationPack}
                handleLocationSelection={handleLocationSelection}
                handleLocationPackSelection={handleLocationPackSelection}
                onlyStartingLocations
              />
            ) : (
              <>
                {!isOverlayActive && (
                  <Text fontSize="bigger" fontWeight={500} letterSpacing="wide">
                    {t('heading')}
                  </Text>
                )}
                <OverlaySelect
                  isOverlayActive={isOverlayActive}
                  toggleOverlay={toggleOverlay}
                  selectedOptionId={selectedThemeId}
                  onSelect={handleThemeSelection}
                  options={themes}
                  optionLayout="vertical"
                  heading={t('theme_select.overlay_heading')}
                  label={t('theme_select.label')}
                  placeholder={t('theme_select.placeholder')}
                  confirmButtonText={t('theme_select.confirm_button')}
                />
                {!!selectedThemeId && !isOverlayActive && (
                  <>
                    <InputsWrapper>
                      <TextInput
                        value={sessionTitle}
                        onChange={handleSessionTitleChange}
                        label={t('title_input.label')}
                        placeholder={t('title_input.placeholder')}
                      />
                      <LocationSelectOverlay
                        themeId={selectedThemeId}
                        isOverlayActive={isChangingLocation}
                        toggleOverlay={toggleLocationOverlay}
                        selectedLocation={selectedLocation}
                        selectedLocationPack={selectedLocationPack}
                        handleLocationSelection={handleLocationSelection}
                        handleLocationPackSelection={handleLocationPackSelection}
                      />
                    </InputsWrapper>
                    {!isGeneratingCode && <CopyableField label={t('access_code_label')} value={accessCode} />}
                    <SubmitButtonWrapper>
                      <ColorButton isDisabled={isButtonDisabled} onClick={handleSubmit}>
                        {t('submit_button')}
                      </ColorButton>
                    </SubmitButtonWrapper>
                  </>
                )}
              </>
            )}
          </Container>
        )}
      </LauncherLayout>
    </>
  );
};
