import React from 'react';
import { useTranslation } from 'react-i18next';
import { routes } from 'components/MainRouter/MainRouter';
import {
  Container,
  ContentWrapper,
  ErrorBar,
  Cta,
  SocialMediaButtons,
  PoliciesWrapper,
} from 'components/generics/Form/AuthLayout/AuthLayout.styles';
import { useOnlyDevelopment } from 'hooks/useOnlyDevelopment';
import { Spinner } from 'components/generics/Spinner/Spinner';
import { Text } from 'components/generics/Text/Text';
import { ColorButton } from 'components/generics/Button/ColorButton';
import { TextInput } from 'components/generics/Form/TextInput/TextInput';
import { PasswordInput } from 'components/generics/Form/PasswordInput/PasswordInput';
import { useLoginView } from '../LoginView.hooks';

export const DevLoginView = () => {
  const { t } = useTranslation('launcher', { keyPrefix: 'login_view' });
  const {
    formik: { values, touched, errors, handleChange, handleSubmit, handleBlur },
    formMutation,
    loginViaSSO,
    availableProviders,
  } = useLoginView();

  useOnlyDevelopment();

  return (
    <Container>
      <ContentWrapper>
        <Text color="opaqueWhite75" fontWeight={700}>
          {availableProviders.length === 1 ? t('discord_log_in_prompt') : t('social_media_log_in_prompt')}
        </Text>
        <SocialMediaButtons>
          {availableProviders.map(({ provider, Icon }) => (
            <button key={provider} type="button" onClick={() => loginViaSSO(provider)} aria-label={provider}>
              <Icon />
            </button>
          ))}
        </SocialMediaButtons>
        <PoliciesWrapper>
          <Text color="opaqueWhite50" fontSize="smaller" textAlign="center">
            {t('policies_text_1')}{' '}
            <a target="_blank" href="https://www.rollingdices.app/terms-of-service" rel="noreferrer">
              {t('policies_terms')}
            </a>{' '}
            {t('policies_text_2')}{' '}
            <a target="_blank" href="https://www.rollingdices.app/privacy-policy" rel="noreferrer">
              {t('policies_privacy')}
            </a>
            .
          </Text>
        </PoliciesWrapper>
      </ContentWrapper>
      <Text color="secondary" textAlign="center">
        BELOW DEVELOPMENT ONLY:
      </Text>
      <ContentWrapper>
        <form onSubmit={handleSubmit} noValidate>
          <TextInput
            value={values.email}
            name="email"
            label={t('label_email')}
            placeholder="your@email.com"
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.email ? errors.email : undefined}
          />
          <PasswordInput
            value={values.password}
            name="password"
            label={t('label_password')}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.password ? errors.password : undefined}
          />
          <Cta to={routes.forgotPassword}>{t('forgot_password')}</Cta>
          <br />
          {formMutation.isError && (
            <ErrorBar>
              <Text color="secondary">{t('error_message')}</Text>
            </ErrorBar>
          )}
          {formMutation.isLoading ? <Spinner /> : <ColorButton type="submit">{t('submit_button')}</ColorButton>}
        </form>
        <Text textAlign="center" color="opaqueWhite50">
          {t('sign_up_prompt')}
        </Text>
        <Cta to={routes.register} tabIndex={0}>
          {t('sign_up_cta')}
        </Cta>
      </ContentWrapper>
    </Container>
  );
};
