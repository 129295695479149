import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useGameplayNavigation } from 'hooks/useGameplayNavigation';
import { SESSION_VIEW_TYPE } from 'stores/gameplay.slice';
import { Helmet } from 'components/Helmet/Helmet';
import { Spinner } from 'components/generics/Spinner/Spinner';
import GameplayLayout from 'components/GameplayLayout/GameplayLayout';
import { useSessionOnboarding } from 'components/SessionOnboarding/SessionOnboarding.hooks';
import { useSessionView } from './SessionView.hooks';
import { CallRollView } from '../CallRollView/CallRollView';
import { RollDiceView } from '../RollDiceView/RollDiceView';
import { PartyView } from '../PartyView/PartyView';
import { StoryView } from '../StoryView/StoryView';
import { DrawStoryCardView } from '../DrawStoryCardView/DrawStoryCardView';
import { ManagementView } from '../ManagementView/ManagementView';
import { SessionRecordsView } from '../RecordsView/RecordsView';

export const SessionView = () => {
  const { sessionId } = useParams();
  const { sessionTitle, isSessionLoading } = useSessionView(sessionId);
  const { currentView } = useGameplayNavigation();

  const viewMap = useMemo(
    () => ({
      [SESSION_VIEW_TYPE.SESSION_RECORDS]: {
        component: SessionRecordsView,
      },
      [SESSION_VIEW_TYPE.DEMAND_ROLL]: {
        component: CallRollView,
      },
      [SESSION_VIEW_TYPE.ROLL_DICE]: {
        component: RollDiceView,
      },
      [SESSION_VIEW_TYPE.PARTY]: {
        component: PartyView,
      },
      [SESSION_VIEW_TYPE.STORY]: {
        component: StoryView,
      },
      [SESSION_VIEW_TYPE.DRAW_STORY_CARD]: {
        component: DrawStoryCardView,
      },
      [SESSION_VIEW_TYPE.MANAGEMENT]: {
        component: ManagementView,
      },
    }),
    [],
  );

  const { component: ViewComponent } = viewMap[currentView] || viewMap[SESSION_VIEW_TYPE.SESSION_RECORDS];

  useSessionOnboarding();

  return (
    <>
      <Helmet>
        <title>{sessionTitle}</title>
      </Helmet>
      <GameplayLayout>{isSessionLoading ? <Spinner /> : <ViewComponent />}</GameplayLayout>
    </>
  );
};
