import { useCallback, useMemo, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useGameplaySelector } from 'stores/gameplay.slice';
import { SupabaseService } from 'services/SupabaseService';
import { DB_TABLE_NAMES, QUERY_KEYS } from 'utils/consts';
import { Location } from 'models/db/resource_Location';
import { LocationPack } from 'models/db/resource_LocationPack';
import { Session } from 'models/db/db_Session';
import { SessionRecord } from 'models/db/db_SessionRecord';
import { OmitAutoGenerated } from 'models/Api';
import { useLocationsAPI } from 'hooks/sessionApi/useLocationsAPI';
import { useStoryCardsAPI } from 'hooks/sessionApi/useStoryCardsAPI';
import { StoryCardType } from 'models/db/resource_StoryCard';

export const useSessionStoryView = () => {
  const { activeSession } = useGameplaySelector();
  const queryClient = useQueryClient();

  const { activeLocationQuery, activeLocationPackQuery } = useLocationsAPI();
  const activeLocation = activeLocationQuery.data?.data?.[0];
  const activeLocationPack = activeLocationPackQuery.data?.data?.[0];

  const { storyCardsQuery } = useStoryCardsAPI();
  const storyCards = useMemo(() => storyCardsQuery.data?.data ?? [], [storyCardsQuery.data]);

  const [isChangingLocation, setIsChangingLocation] = useState(false);

  const [selectedLocation, setSelectedLocation] = useState<Location>();
  const [selectedLocationPack, setSelectedLocationPack] = useState<LocationPack>();
  const handleLocationSelection = (location?: Location) => setSelectedLocation(location);
  const handleLocationPackSelection = (locationPack?: LocationPack) => setSelectedLocationPack(locationPack);

  const [storyCardFilter, setStoryCardFilter] = useState<'all' | StoryCardType | undefined>();

  const filteredStoryCards = useMemo(() => {
    if (!storyCards) return [];
    if (!storyCardFilter) return storyCards;

    return storyCards.filter((card) => card.type === storyCardFilter);
  }, [storyCards, storyCardFilter]);

  const handleLocationChange = useCallback(async () => {
    if (!activeSession) return;
    if (!activeLocation && !selectedLocation) return;
    if (activeLocation?.id === selectedLocation?.id) return;

    await SupabaseService.from(DB_TABLE_NAMES.dbSession)
      .update<Partial<Session>>({ current_location_id: selectedLocation?.id ?? undefined })
      .eq('id', activeSession?.id);

    if (selectedLocation) {
      await SupabaseService.from(DB_TABLE_NAMES.dbSessionRecord).insert<OmitAutoGenerated<SessionRecord>>([
        {
          player_character_id: undefined,
          session_id: activeSession?.id,
          record_type: 'location_change',
          content: selectedLocation,
        },
      ]);

      queryClient.invalidateQueries([QUERY_KEYS.gameplay.activeLocation, activeSession.id]);
    }

    setSelectedLocationPack(undefined);
    setSelectedLocation(undefined);
  }, [activeLocation, activeSession, queryClient, selectedLocation]);

  return {
    isChangingLocation,
    activeSession,
    activeLocation,
    setIsChangingLocation,
    selectedLocation,
    selectedLocationPack,
    handleLocationChange,
    handleLocationSelection,
    handleLocationPackSelection,
    locationPack: activeLocationPack,
    storyCardFilter,
    setStoryCardFilter,
    filteredStoryCards,
    storyCards,
  } as const;
};
