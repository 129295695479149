import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { UsersService } from 'services/UsersService';

export const getUserProfileThunk = createAsyncThunk('user/getProfile', async (_, { rejectWithValue }) => {
  try {
    const { data: user } = await UsersService.getProfile();
    const { data: extras } = await UsersService.getProfileExtras(user.id);
    return {
      ...user,
      ...extras[0],
    };
  } catch (error) {
    return rejectWithValue((error as AxiosError).response?.data);
  }
});
