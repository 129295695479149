import React from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { TextInput } from 'components/generics/Form/TextInput/TextInput';
import { Text } from 'components/generics/Text/Text';
import { Spinner } from 'components/generics/Spinner/Spinner';
import { useOnlyDevelopment } from 'hooks/useOnlyDevelopment';
import { Helmet } from 'components/Helmet/Helmet';
import { ColorButton } from 'components/generics/Button/ColorButton';
import { SupabaseService } from 'services/SupabaseService';
import { getEnv } from 'utils/getEnv';
import { ErrorBar, Form, Wrapper } from './ForgotPasswordView.styles';

export const ForgotPasswordView = () => {
  const { t } = useTranslation('launcher', { keyPrefix: 'forgot_password_view' });
  const { t: tValidation } = useTranslation('launcher', { keyPrefix: 'form_errors' });

  useOnlyDevelopment();

  const validationSchema = Yup.object().shape({
    email: Yup.string().email(tValidation('email_invalid')).required(tValidation('field_required')),
  });

  const formMutation = useMutation({
    mutationFn: (email: string) =>
      SupabaseService.auth.resetPasswordForEmail(email, {
        redirectTo: `${getEnv('REACT_APP_BASE_URL')}/reset-password`,
      }),
  });

  const { values, touched, errors, handleChange, handleSubmit, handleBlur } = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema,
    onSubmit: (values) => formMutation.mutate(values.email),
  });

  return (
    <>
      <Helmet>
        <title>{t('title')}</title>
      </Helmet>
      <Wrapper>
        {formMutation.isSuccess ? (
          <>
            <Text>{t('success_heading')}</Text>
            <Text>{t('success_description')}</Text>
          </>
        ) : (
          <>
            <Text>{t('heading')}</Text>
            <Text>{t('description')}</Text>
            <Form onSubmit={handleSubmit}>
              <TextInput
                value={values.email}
                name="email"
                label={t('label_email')}
                placeholder="your@email.com"
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.email ? errors.email : undefined}
              />
              {formMutation.isError && (
                <ErrorBar>
                  <Text color="secondary">{t('error_text')}</Text>
                </ErrorBar>
              )}
              {formMutation.isLoading ? <Spinner /> : <ColorButton type="submit">{t('submit_button')}</ColorButton>}
            </Form>
          </>
        )}
      </Wrapper>
    </>
  );
};
