import { TokenService } from 'services/TokenService';

export const useIsLoggedIn = () => {
  const token = TokenService.accessToken.getToken();

  if (!token) return false;

  if (!TokenService.accessToken.isValid()) {
    TokenService.clearAllTokens();
    return false;
  }

  return true;
};
