import React from 'react';
import { useTranslation } from 'react-i18next';
import { HealthChangeSessionRecord } from 'models/db/db_SessionRecord';
import { Text } from 'components/generics/Text/Text';
import { useSessionPlayersAPI } from 'hooks/sessionApi/useSessionPlayersAPI';
import { calculateCharacterWounds, mapWoundToTextColor } from 'engine/health';
import { LogCharacterAvatar, Record, RecordContentRow, RecordContentWrapper } from '../RecordsView.styles';

interface HealthChangeRecordProps {
  isGm?: boolean;
  record: HealthChangeSessionRecord;
}

export const HealthChangeRecord = ({ isGm, record }: HealthChangeRecordProps) => {
  const { t } = useTranslation('gameplay');

  const { sessionPlayersQuery } = useSessionPlayersAPI();
  const sessionPlayers = sessionPlayersQuery.data?.data ?? [];

  const character = sessionPlayers.find((player) => player?.character_id === record.content.character_id);

  const oldWound = calculateCharacterWounds({
    [record.content.woundChange.bodyZone]: record.content.woundChange.from,
  })[record.content.woundChange.bodyZone];
  const newWound = calculateCharacterWounds({
    [record.content.woundChange.bodyZone]: record.content.woundChange.to,
  })[record.content.woundChange.bodyZone];

  return (
    <Record isGm={isGm}>
      <RecordContentWrapper>
        <RecordContentRow>
          <LogCharacterAvatar src={character?.portrait} loading="lazy" />
          <Text color="highlightYellow" fontWeight={700} textTransform="uppercase">
            {t('session_records_view.health_state_change')}
          </Text>
        </RecordContentRow>
        <Text fontSize="small" fontWeight={700} textTransform="uppercase" letterSpacing="superWide">
          {character?.name}
        </Text>
        {record.content.healthChange && (
          <Text fontWeight={700}>
            <Text fontSize="small" as="span" color={`${record.content.healthChange?.from}State`}>
              {t(`character_status.health_state.${record.content.healthChange?.from}` as any)}
            </Text>
            <Text fontSize="small" as="span">
              {' '}
              &gt;{' '}
            </Text>
            <Text fontSize="small" as="span" color={`${record.content.healthChange?.to}State`}>
              {t(`character_status.health_state.${record.content.healthChange?.to}` as any)}
            </Text>
          </Text>
        )}
        {oldWound && newWound && (
          <Text fontWeight={700}>
            <Text fontSize="small" as="span">
              {t(`character_status.body_parts.${record.content.woundChange.bodyZone}` as any)}:{' '}
            </Text>
            <Text fontSize="small" as="span" color={mapWoundToTextColor(oldWound)}>
              {t(`character_status.wounds.${oldWound}` as any)}
            </Text>
            <Text fontSize="small" as="span">
              {' '}
              &gt;{' '}
            </Text>
            <Text fontSize="small" as="span" color={mapWoundToTextColor(newWound)}>
              {t(`character_status.wounds.${newWound}` as any)}
            </Text>
          </Text>
        )}
      </RecordContentWrapper>
    </Record>
  );
};
