enum CONFIG_TYPES {
  NODE_ENV = 'NODE_ENV',
  REACT_APP_API_URL = 'REACT_APP_API_URL',
  REACT_APP_BASE_URL = 'REACT_APP_BASE_URL',
  REACT_APP_SB_API_KEY = 'REACT_APP_SB_API_KEY',
  REACT_APP_SB_AUTH_TOKEN_NAME = 'REACT_APP_SB_AUTH_TOKEN_NAME',
  REACT_APP_VERSION = 'REACT_APP_VERSION',
}

type KeyType = keyof typeof CONFIG_TYPES;

export const getEnv = (key: KeyType): string => {
  const value = process.env[key];
  if (!value) {
    throw new Error(`There is no env variable of key ${key} set.`);
  }

  return value;
};
