import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { MoneyChangeSessionRecord } from 'models/db/db_SessionRecord';
import { useSessionPlayersAPI } from 'hooks/sessionApi/useSessionPlayersAPI';
import { useGameplayNavigation } from 'hooks/useGameplayNavigation';
import { Text } from 'components/generics/Text/Text';
import { pxToRem } from 'utils/styleUtils';
import ImageMoney from 'assets/images/coins.png';
import { LogCharacterAvatar, Record, RecordContentRow, RecordContentWrapper } from '../RecordsView.styles';

export const CoinsIcon = styled.img`
  width: ${pxToRem(18)};
  height: ${pxToRem(18)};
`;

interface MoneyChangeRecordProps {
  isGm?: boolean;
  record: MoneyChangeSessionRecord;
}

export const MoneyChangeRecord = ({ isGm, record }: MoneyChangeRecordProps) => {
  const { t } = useTranslation('gameplay', { keyPrefix: 'session_records_view' });
  const { getters } = useSessionPlayersAPI();
  const { redirectToCharacter } = useGameplayNavigation();

  const character = getters.getPlayerCharacterById(record.content.character_id);

  return (
    <Record isGm={isGm}>
      <RecordContentWrapper>
        <RecordContentRow>
          {character && (
            <button type="button" onClick={() => redirectToCharacter(character?.character_id)}>
              <LogCharacterAvatar src={character?.portrait} loading="lazy" />
            </button>
          )}
          <Text color="highlightYellow" fontWeight={700} textTransform="uppercase">
            {record.content.change > 0 ? t('money.gained') : t('money.lost')}
          </Text>
        </RecordContentRow>
        <Text fontSize="small" fontWeight={700} textTransform="uppercase" letterSpacing="superWide">
          {character?.name}
        </Text>
        <RecordContentRow>
          <CoinsIcon src={ImageMoney} loading="lazy" />
          <Text fontSize="small" fontWeight={700}>
            {record.content.change > 0 ? `+${String(record.content.change)}` : String(record.content.change)}
          </Text>
        </RecordContentRow>
        <RecordContentRow>
          <Text fontSize="small" fontWeight={700}>
            {t('money.new_balance')}:
          </Text>
          <CoinsIcon src={ImageMoney} loading="lazy" />
          <Text fontSize="small" fontWeight={700}>
            {record.content.newValue}
          </Text>
        </RecordContentRow>
      </RecordContentWrapper>
    </Record>
  );
};
