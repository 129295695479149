import React, { useEffect } from 'react';
import { Portal } from 'components/Portal/Portal';
import { useModalSelector } from 'stores/modal.slice';
import { ColorButton } from 'components/generics/Button/ColorButton';
import {
  Backdrop,
  CloseButtonWrapper,
  ConfirmButtonWrapper,
  ContentWrapper,
  StyledModal,
  CloseButton,
} from './Modal.styles';

export const Modal = () => {
  const { isOpen, content, handleClose, handleConfirm, confirmText, isConfirmButtonDisabled, isFullScreen } =
    useModalSelector();

  const handleScroll = () => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'initial';
    }
  };

  useEffect(handleScroll, [isOpen]);

  if (!isOpen || !content) {
    return null;
  }

  return (
    <Portal>
      <Backdrop>
        <StyledModal fullscreen={isFullScreen ?? false}>
          {!!handleClose && (
            <CloseButtonWrapper>
              <CloseButton onClick={handleClose} />
            </CloseButtonWrapper>
          )}
          <ContentWrapper fullscreen={isFullScreen ?? false}>{content}</ContentWrapper>
        </StyledModal>
        {!!handleConfirm && (
          <ConfirmButtonWrapper>
            <ColorButton type="button" onClick={handleConfirm} isDisabled={isConfirmButtonDisabled}>
              {confirmText?.toUpperCase() ?? 'OK'}
            </ColorButton>
          </ConfirmButtonWrapper>
        )}
      </Backdrop>
    </Portal>
  );
};
