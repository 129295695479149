import styled from 'styled-components';
import { pxToRem } from 'utils/styleUtils';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  gap: ${pxToRem(12)};
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Form = styled.form`
  width: 90%;
  max-width: ${pxToRem(256)};
  display: flex;
  gap: ${pxToRem(12)};
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
