import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from 'components/generics/Text/Text';
import { capitalizeFirstLetter } from 'utils/capitalizeFirstLetter';
import {
  Health,
  calculateCharacterWounds,
  mapWoundToBackgroundColor,
  mapWoundToBodyColor,
  mapWoundToTextColor,
} from 'engine/health';
import {
  ArmsModel,
  HeadModel,
  LegsModel,
  Model,
  Section,
  SectionsWrapper,
  TorsoModel,
  Wrapper,
} from './HealthModel.styles';

interface HealthModelProps {
  characterHealth: Health;
  withDescriptions?: boolean;
}

export const HealthModel = ({ characterHealth, withDescriptions = true }: HealthModelProps) => {
  const { t } = useTranslation('gameplay', { keyPrefix: 'character_status' });
  const wounds = calculateCharacterWounds(characterHealth);

  return (
    <Wrapper>
      <Model>
        <HeadModel color={mapWoundToBodyColor(wounds.head)} />
        <TorsoModel color={mapWoundToBodyColor(wounds.torso)} />
        <ArmsModel color={mapWoundToBodyColor(wounds.arms)} />
        <LegsModel color={mapWoundToBodyColor(wounds.legs)} />
      </Model>

      {withDescriptions && (
        <SectionsWrapper>
          {Object.entries(wounds).map(([bodyPart, wound]) => (
            <Section key={`${bodyPart}_wound-section`} backgroundColor={mapWoundToBackgroundColor(wound)}>
              <Text fontSize="smaller" color="opaqueWhite50" fontWeight={700}>
                {capitalizeFirstLetter(t(`body_parts.${bodyPart}` as any))}
              </Text>
              <Text fontSize="small" color={mapWoundToTextColor(wound)} fontWeight={700}>
                {wound === 'none' ? t('wounds.none') : capitalizeFirstLetter(t(`wounds.${wound}` as any))}
              </Text>
            </Section>
          ))}
        </SectionsWrapper>
      )}
    </Wrapper>
  );
};
