import { cryptRandom } from 'utils/cryptRandom';
import D4Source from 'assets/images/dices/D4.svg';
import D6Source from 'assets/images/dices/D6.svg';
import D8Source from 'assets/images/dices/D8.svg';
import D10Source from 'assets/images/dices/D10.svg';
import D12Source from 'assets/images/dices/D12.svg';
import D20Source from 'assets/images/dices/D20.svg';
import { RollResult } from 'models/db/db_SessionRecord';

export const DICE_ROLL_SCHEMA = {
  D4: [1, 4],
  D6: [1, 6],
  D8: [1, 8],
  D10: [1, 10],
  D12: [1, 12],
  D20: [1, 20],
};

export type DiceType = keyof typeof DICE_ROLL_SCHEMA;

export const DIFFICULTY_LEVEL_ROLL_SCHEMA: {
  [key: number]: DiceType[];
} = {
  0: [],
  1: ['D4'],
  2: ['D6'],
  3: ['D8'],
  4: ['D10'],
  5: ['D12'],
  6: ['D4', 'D12'],
  7: ['D8', 'D12'],
  8: ['D8', 'D20'],
  9: ['D10', 'D20'],
  10: ['D12', 'D20'],
};

export const ATTRIBUTE_LEVEL_ROLL_SCHEMA: {
  [key: number]: DiceType[];
} = {
  0: [],
  1: ['D4'],
  2: ['D6'],
  3: ['D8'],
  4: ['D12'],
  5: ['D20'],
};

export const SKILL_LEVEL_ROLL_SCHEMA: {
  [key: number]: DiceType[];
} = {
  0: [],
  1: ['D4', 'D4'],
  2: ['D4', 'D6'],
  3: ['D12'],
  4: ['D6', 'D12'],
  5: ['D20', 'D4'],
};

export function rollDice(diceType: DiceType): number {
  const range = DICE_ROLL_SCHEMA[diceType];

  if (!range) {
    throw new Error('Invalid dice type.');
  }

  const [minValue, maxValue] = range;
  const randomValue = cryptRandom(minValue, maxValue);

  return minValue + randomValue;
}

// Utility function to organize roll results by dice type
export const organizeResultsByDiceType = (results: RollResult[]) => {
  const resultsByDiceType = {} as {
    [key in DiceType]: number[];
  };

  results.forEach((res) => {
    if (resultsByDiceType[res.diceType]) {
      resultsByDiceType[res.diceType].push(res.result);
    } else {
      resultsByDiceType[res.diceType] = [res.result];
    }
  });

  return resultsByDiceType;
};

// Utility function to get the dice image based on the dice type
export const getDiceImage = (type: DiceType) => {
  switch (type) {
    case 'D4': {
      return D4Source;
    }
    case 'D6': {
      return D6Source;
    }
    case 'D8': {
      return D8Source;
    }
    case 'D10': {
      return D10Source;
    }
    case 'D12': {
      return D12Source;
    }
    case 'D20': {
      return D20Source;
    }
    default: {
      return '';
    }
  }
};
