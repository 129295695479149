import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;
  height: 100%;
`;

export const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 1rem;
`;

export const SubmitButtonWrapper = styled.div`
  padding-top: 3rem;
  display: flex;
  justify-content: center;
  margin-top: auto;

  button {
    text-transform: uppercase;
  }
`;
