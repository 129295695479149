import jwtDecode from 'jwt-decode';
import { JWT } from 'models/Api';
import { getEnv } from 'utils/getEnv';

export const AUTH_TOKEN_NAME = 'accessToken';
export const REFRESH_TOKEN_NAME = 'refreshToken';
const VALIDATION_THRESHOLD_IN_SECONDS = 1800;

export const TokenService = {
  accessToken: {
    getToken: () => localStorage.getItem(AUTH_TOKEN_NAME),
    setToken: (token: string) => localStorage.setItem(AUTH_TOKEN_NAME, token),
    clearToken: () => localStorage.removeItem(AUTH_TOKEN_NAME),
    isValid: () => {
      const token = localStorage.getItem(AUTH_TOKEN_NAME);
      if (token) {
        const decodedToken: JWT = jwtDecode(token);
        if (new Date().getTime() < decodedToken.exp * 1000) {
          return true;
        }
      }
      return false;
    },
    shouldRefresh: () => {
      const token = localStorage.getItem(AUTH_TOKEN_NAME);
      if (token) {
        const decodedToken: JWT = jwtDecode(token);
        if (decodedToken.exp - VALIDATION_THRESHOLD_IN_SECONDS < new Date().getTime() / 1000) {
          return true;
        }
      }
      return false;
    },
  },
  refreshToken: {
    getToken: () => localStorage.getItem(REFRESH_TOKEN_NAME),
    setToken: (token: string) => localStorage.setItem(REFRESH_TOKEN_NAME, token),
    clearToken: () => localStorage.removeItem(REFRESH_TOKEN_NAME),
  },
  clearAllTokens: () => {
    localStorage.removeItem(AUTH_TOKEN_NAME);
    localStorage.removeItem(REFRESH_TOKEN_NAME);
    localStorage.removeItem(getEnv('REACT_APP_SB_AUTH_TOKEN_NAME'));
  },
};
