/* eslint-disable max-len */
import { ReactComponent as FantasyMentalIcon } from '../assets/images/thematic_assets/attribute_icons/fantasy_mental.svg';
import { ReactComponent as FantasyPhysicalIcon } from '../assets/images/thematic_assets/attribute_icons/fantasy_physical.svg';
import { ReactComponent as FantasyPsycheIcon } from '../assets/images/thematic_assets/attribute_icons/fantasy_psyche.svg';
import { ReactComponent as FantasyMagicIcon } from '../assets/images/thematic_assets/attribute_icons/fantasy_magic.svg';

export interface ThemeConfig {
  themeId: string;
  skills: {
    [key: string]: string[];
  };
  characterAgeSchema: {
    [key: string]: {
      [key: string]: number;
    };
  };
  healthCalculationPattern: string[];
  attrIcons: {
    [key: string]: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  };
  weaponTypes: string[];
}

export const THEME_HEROIC_FANTASY: ThemeConfig = {
  themeId: 'heroic_fantasy',
  skills: {
    physical: [
      'martial_arts',
      'shield_bearing',
      'one_handed_weapons',
      'two_handed_weapons',
      'ranged_weapons',
      'firearms',
      'stealth',
      'dexterity',
      'strength',
    ],
    mental: [
      'medicine',
      'thievery',
      'craft',
      'alchemy',
      'cooking',
      'herbalism',
      'animal_lore',
      'survival',
      'strategy',
      'polyglotism',
      'world_lore',
      'streetwise',
    ],
    psyche: [
      'charisma', // Charisma - Leadership, Persuasion, Bargaining
      'charm', // Charm - Seduction, Empathy, Emotional Intelligence, Flirting, Joking
      'intimidation', // Intimidation
      'intrigue', // Intrigue - Bluff, Acting, Lying
      'art', // Art - expressing oneself through art - Music, Painting, Sculpting, Writing, Poetry, Dancing
      'animal_handling', // Animal Handling - Riding, Training, Taming
      'willpower', // Willpower - Resisting temptation, resisting mind control, resisting fear
      'faith', // Faith - resisting mind control, resisting fear, resisting temptation
    ],
    magic: [
      'telekinesis',
      'offensive_magic',
      'defensive_magic',
      'illusion',
      'piromancy',
      'mind_control',
      'hydromancy',
      'cryomancy',
      'aeromancy',
      'geomancy',
      'chronomancy', // Chronomancy & Foresight - slowing down time, speeding up time, also foresight
      'necromancy', // Necromancy - raising the dead, communicating with the dead, etc
      'witchcraft', // Witchcraft & Dark Arts - curses, hexes, summoning demons, etc
      'nature_magic', // Nature Magic
      'conjuration',
      'enchantment',
      'shapeshifting',
    ],
  },
  characterAgeSchema: {
    teenager: {
      physical: 3,
      mental: 2,
      psyche: 1,
      magic: 1,
    },
    adult: {
      physical: 2,
      mental: 2,
      psyche: 2,
      magic: 1,
    },
    elder: {
      physical: 1,
      mental: 2,
      psyche: 3,
      magic: 1,
    },
  },
  healthCalculationPattern: ['physical', 'mental', 'psyche'],
  attrIcons: {
    physical: FantasyPhysicalIcon,
    mental: FantasyMentalIcon,
    psyche: FantasyPsycheIcon,
    magic: FantasyMagicIcon,
  },
  weaponTypes: [
    'one_handed_weapons',
    'two_handed_weapons',
    'ranged_weapons',
    'firearms',
    'melee_weapons',
    'weapons_special',
  ],
};

export const THEMES = {
  heroic_fantasy: THEME_HEROIC_FANTASY,
};

export function getThemeConfigForThemeId(themeId: string): ThemeConfig {
  switch (themeId) {
    case 'heroic_fantasy':
      return THEME_HEROIC_FANTASY;
    default:
      throw new Error(`Theme ${themeId} not found`);
  }
}
