import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from 'components/generics/Text/Text';
import { ContentContainer } from '../../../IngameHandbook.styles';

export const StoryCardsSubsection = () => {
  const { t } = useTranslation('handbook', { keyPrefix: 'story_view.subsections.story_cards' });

  return (
    <ContentContainer>
      <Text fontSize="small" color="opaqueWhite75" fontWeight={500}>
        {t('p1')}
      </Text>
      <Text fontSize="small" color="opaqueWhite75" fontWeight={500}>
        {t('p2')}
      </Text>
      <br />
      <Text fontSize="small" color="opaqueWhite75" fontWeight={500}>
        {t('p3')}
      </Text>

      <div>
        <Text fontSize="small" color="secondary" fontWeight={500}>
          {t('delete_action.label')}
        </Text>
        <Text fontSize="small" color="opaqueWhite75" fontWeight={500}>
          {t('delete_action.description')}
        </Text>
      </div>

      <div>
        <Text fontSize="small" color="highlightYellow" fontWeight={500}>
          {t('put_on_table_action.label')}
        </Text>
        <Text fontSize="small" color="opaqueWhite75" fontWeight={500}>
          {t('put_on_table_action.description')}
        </Text>
      </div>

      <div>
        <Text fontSize="small" color="primary" fontWeight={500}>
          {t('resolve_action.label')}
        </Text>
        <Text fontSize="small" color="opaqueWhite75" fontWeight={500}>
          {t('resolve_action.description')}
        </Text>
      </div>
    </ContentContainer>
  );
};
