import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { SupabaseService } from 'services/SupabaseService';
import { DB_TABLE_NAMES } from 'utils/consts';
import { Text } from 'components/generics/Text/Text';
import { useGameplaySelector } from 'stores/gameplay.slice';
import { PlayerInventoryItem } from 'models/functions/GetPlayersForSession';
import ImageMoney from 'assets/images/coins.png';
import { ManagableCard } from 'components/ManagableCard/ManagableCard';
import { Card } from 'components/Card/Card';
import { SessionRecord } from 'models/db/db_SessionRecord';
import { TabButtons } from 'components/MultiTabSection/TabButtons';
import { OmitAutoGenerated } from 'models/Api';
import { PlayerItem } from 'models/db/instance_PlayerItem';
import { ItemContent } from 'views/CharacterCreatorView/components/ItemContent';
import {
  TextGroup,
  DescriptionCardWrapper,
  HorizontalSeparator,
  AttributeItem,
  AttributeImage,
} from '../PartyView.styles';

type InventoryFilter = 'all' | 'weapon' | 'armor' | 'consumable';
interface InventorySectionProps {
  characterItems: PlayerInventoryItem[];
  themeId: string;
  isManagable: boolean;
  money: number | null;
}

const InventorySection = ({ characterItems, themeId, isManagable, money }: InventorySectionProps) => {
  const { t } = useTranslation('gameplay', { keyPrefix: 'party_view.inventory_section' });

  const { activePlayer, activeSession } = useGameplaySelector();
  const [currentFilter, setCurrentFilter] = useState<InventoryFilter>('all');
  const [currentlyManagedId, setCurrentlyManagedId] = useState<string | null>(null);

  const [equipmentSlots, setEquipmentSlots] = useState<string[]>([]);

  const sendEquipmentRecord = useCallback(
    async (
      itemTkey: string,
      actionType: 'remove' | 'equip' | 'unequip',
      itemType: string,
      removedQuantity?: number,
    ) => {
      if (!activeSession?.id) return;

      await SupabaseService.from(DB_TABLE_NAMES.dbSessionRecord).insert<OmitAutoGenerated<SessionRecord>>([
        {
          player_character_id: activePlayer?.character_id,
          session_id: activeSession?.id,
          record_type: 'item',
          content: {
            action: actionType,
            t_key: itemTkey,
            removed_quantity: removedQuantity,
            type: itemType,
          },
        },
      ]);
    },
    [activePlayer?.character_id, activeSession?.id],
  );

  const updateEquipmentSlots = useCallback((item: PlayerInventoryItem) => {
    if (item.is_equipable && item.is_equipped) {
      setEquipmentSlots((prev) => [...prev, `${item.type}.${item.content?.subtype ?? ''}`]);
    }
  }, []);

  useEffect(() => {
    if (characterItems) {
      setEquipmentSlots([]);
      characterItems.forEach(updateEquipmentSlots);
    }
  }, [characterItems, updateEquipmentSlots]);

  // Handlers
  const handleDelete = useCallback(
    async (itemId: string, itemTkey: string, itemType: string, removedQuantity: number) => {
      await SupabaseService.from(DB_TABLE_NAMES.instancePlayerItem).delete().eq('id', itemId);
      sendEquipmentRecord(itemTkey, 'remove', itemType, removedQuantity);
    },
    [sendEquipmentRecord],
  );

  const handleToggleEquippedStatus = useCallback(
    async (itemId: string, itemTkey: string, itemType: string, itemSubtype: string, currentEquipedStatus: boolean) => {
      if (currentEquipedStatus || (!equipmentSlots.includes(`${itemType}.${itemSubtype}`) && !currentEquipedStatus)) {
        const { data: playerItemDataNotTyped } = await SupabaseService.from(DB_TABLE_NAMES.instancePlayerItem)
          .update<Partial<PlayerItem>>({ is_equipped: !currentEquipedStatus })
          .eq('id', itemId)
          .select();

        const playerItemData = playerItemDataNotTyped as PlayerItem[];

        setEquipmentSlots((prev) => ({ ...prev, [itemType]: !currentEquipedStatus }));
        if (playerItemData) {
          sendEquipmentRecord(itemTkey, !currentEquipedStatus ? 'equip' : 'unequip', itemType);
        }
      } else {
        toast.error(t('same_type_item_error'));
      }
    },
    [equipmentSlots, sendEquipmentRecord, t],
  );

  const handleUseConsumable = useCallback(
    async (itemId: string, itemTkey: string, itemType: string, currentQuantity: number) => {
      if (currentQuantity > 1) {
        const { data: playerItemDataNotTyped } = await SupabaseService.from(DB_TABLE_NAMES.instancePlayerItem)
          .update<Partial<PlayerItem>>({ quantity: currentQuantity - 1 })
          .eq('id', itemId)
          .select();

        const playerItemData = playerItemDataNotTyped as PlayerItem[];

        if (playerItemData) {
          // TODO REFACTOR: Mixed up types
          // dispatch(updateActivePlayerItem(playerItemData[0] as PlayerInventoryItem));
          sendEquipmentRecord(itemTkey, 'remove', itemType, 1);
        }
      } else {
        handleDelete(itemId, itemTkey, itemType, 1);
      }
    },
    [handleDelete, sendEquipmentRecord],
  );

  const getManagementOptionsForItem = useCallback(
    (item: PlayerInventoryItem) => {
      const managementOptionsShared = [
        {
          label: t('inventory_management.remove_label'),
          color: 'secondary',
          handleClick: () => handleDelete(item.id, item.t_key, item.type, item.quantity || 1),
        },
      ];

      if (item.is_equipable) {
        return [
          ...managementOptionsShared,
          {
            label: t('inventory_management.equip_label'),
            color: 'primary',
            // TODO REFACTOR: Remove 'as any'
            handleClick: () =>
              handleToggleEquippedStatus(
                item.id,
                `${item.content?.subtype ?? ''}.${item.t_key}`,
                item.type,
                item.content?.subtype ?? '',
                item.is_equipped || false,
              ),
          },
        ];
      }

      return [
        ...managementOptionsShared,
        {
          label: t('inventory_management.use_label'),
          color: 'primary',
          handleClick: () => handleUseConsumable(item.id, item.t_key, item.type, item.quantity || 1),
        },
      ];
    },
    [handleDelete, handleToggleEquippedStatus, handleUseConsumable, t],
  );

  // Computation
  const filteredItems = useMemo(
    () => characterItems.filter((item) => (currentFilter === 'all' ? true : item.type.includes(currentFilter))),
    [characterItems, currentFilter],
  );

  // Render
  const renderCard = useCallback(
    (item: PlayerInventoryItem) => (
      <Card key={`eq-${isManagable ? 'managable' : 'non-managable'}-${item.t_key}`} isEquipped={item.is_equipped}>
        <ItemContent themeId={themeId} selectedItem={item} />
      </Card>
    ),
    [isManagable, themeId],
  );

  return (
    <TextGroup>
      {money && (
        <DescriptionCardWrapper>
          <Text
            fontWeight={500}
            color="opaqueWhite50"
            fontSize="smaller"
            letterSpacing="superWide"
            textTransform="uppercase"
          >
            {t('money_label')}
          </Text>
          <AttributeItem>
            <AttributeImage src={ImageMoney} loading="lazy" />
            <Text fontWeight={600}>{money}</Text>
          </AttributeItem>
        </DescriptionCardWrapper>
      )}
      <DescriptionCardWrapper>
        <Text
          fontWeight={500}
          color="opaqueWhite50"
          fontSize="smaller"
          letterSpacing="superWide"
          textTransform="uppercase"
        >
          {t('inventory_label')}
        </Text>
        <HorizontalSeparator />

        <TabButtons
          tabs={[
            {
              id: 'all',
              label: t('inventory_filters.all_items_label'),
              active: currentFilter === 'all',
              onClick: () => setCurrentFilter('all'),
            },
            {
              id: 'weapon',
              label: t('inventory_filters.weapons_label'),
              active: currentFilter === 'weapon',
              onClick: () => setCurrentFilter('weapon'),
            },
            {
              id: 'armor',
              label: t('inventory_filters.armor_label'),
              active: currentFilter === 'armor',
              onClick: () => setCurrentFilter('armor'),
            },
            {
              id: 'consumable',
              label: t('inventory_filters.consumables_label'),
              active: currentFilter === 'consumable',
              onClick: () => setCurrentFilter('consumable'),
            },
          ]}
        />

        <br />
        {filteredItems.map((item) =>
          isManagable ? (
            <ManagableCard
              key={item.t_key}
              managementOptions={getManagementOptionsForItem(item)}
              isManaging={currentlyManagedId === item.id}
              toggleManaging={() => setCurrentlyManagedId((prev) => (prev === item.id ? null : item.id))}
            >
              {renderCard(item)}
            </ManagableCard>
          ) : (
            renderCard(item)
          ),
        )}
      </DescriptionCardWrapper>
    </TextGroup>
  );
};

export default InventorySection;
