import React from 'react';
import { v4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { ActionRollSessionRecord } from 'models/db/db_SessionRecord';
import { useGameplaySelector } from 'stores/gameplay.slice';
import { Text } from 'components/generics/Text/Text';
import { DiceType, getDiceImage, organizeResultsByDiceType } from 'engine/rolls';
import { DiceMiniature, MiniatureHeader, Record, RecordRow, RecordRowsWrapper } from '../RecordsView.styles';

// Utility function to get the font color for the result based on the difficulty value
const getFontColorForResult = (result: number, difficultyValue: number) => {
  if (result > difficultyValue) {
    return 'tertiary';
  }
  if (result < difficultyValue) {
    return 'secondary';
  }

  return 'highlightYellow';
};

// Utility function to get the label for the result based on the difficulty value
const getLabelForResult = (result: number, difficultyValue: number) => {
  if (result > difficultyValue) {
    return 'success';
  }
  if (result < difficultyValue) {
    return 'failure';
  }

  return 'luck';
};

interface ActionRollRecordProps {
  record: ActionRollSessionRecord;
}

export const ActionRollRecord = ({ record }: ActionRollRecordProps) => {
  const { t } = useTranslation('gameplay');
  const { t: tThemesIndex } = useTranslation('themesIndex');
  const { activeSession } = useGameplaySelector();

  const resultsByDiceType = organizeResultsByDiceType(record.content.results);

  return (
    <Record>
      <MiniatureHeader>
        <Text fontWeight={700} textTransform="uppercase">
          {t('session_records_view.action_roll.label')}:{' '}
          {record.content.skill
            ? tThemesIndex(`${activeSession?.theme_id}.character_skills.${record.content.skill}.title` as any)
            : tThemesIndex(`${activeSession?.theme_id}.character_attributes.${record.content.attribute}.title` as any)}
        </Text>
      </MiniatureHeader>
      <Text fontSize="small" fontWeight={600} color={`difficulty${Math.round(record.content.difficultyLevel / 2)}`}>
        {t('session_records_view.difficulty')}:&nbsp;
        {record.content.difficultyValue}{' '}
        {`(${t(`call_roll_view.difficulty.ratings.${record.content.difficultyLevel - 1}.label` as any)})`}
      </Text>
      <RecordRowsWrapper>
        {Object.entries(resultsByDiceType).map(([diceType, results]) => (
          <RecordRow key={`action-roll-res-${diceType}-${v4()}`}>
            <Text fontSize="small">{diceType}</Text>
            <DiceMiniature src={getDiceImage(diceType as DiceType)} alt={diceType} loading="lazy" />
            <Text fontSize="small">{results.join(', ')}</Text>
          </RecordRow>
        ))}
      </RecordRowsWrapper>
      {!!record.content.modifier && (
        <Text fontSize="smaller" fontWeight={600}>
          Mod:&nbsp;
          {record.content.modifier > 0 ? '+' : '-'}
          {record.content.modifier}
        </Text>
      )}
      {!!record.content.multiplier && (
        <Text fontSize="smaller" fontWeight={600}>
          {t('session_records_view.action_roll.multiplier')}:&nbsp; x{record.content.multiplier}
        </Text>
      )}
      <br />
      <Text fontWeight={600}>
        {t('session_records_view.total')}: {record.content.resultsSum}
      </Text>
      <Text
        textTransform="uppercase"
        fontSize="big"
        fontWeight={700}
        color={getFontColorForResult(record.content.resultsSum, record.content.difficultyValue)}
      >
        {t(
          `session_records_view.action_roll.${getLabelForResult(
            record.content.resultsSum,
            record.content.difficultyValue,
          )}` as any,
        )}
      </Text>
    </Record>
  );
};
