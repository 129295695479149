import { User, UserExtras } from 'models/User';
import { getEnv } from 'utils/getEnv';
import { DB_TABLE_NAMES } from 'utils/consts';
import { HttpService } from './HttpService';

export interface LoginBody {
  email: string;
  password: string;
}

export interface RegisterBody extends LoginBody {
  data: {
    displayName: string;
  };
}

export interface ResetPasswordBody {
  password: string;
}

export interface UpdateProfileBody {
  email?: string;
  password?: string;
  data?: any;
}

export type UpdateProfileExtrasBody = Partial<Pick<UserExtras, 'did_onboard' | 'display_name'>>;

interface LoginResponse {
  access_token: string;
  expires_in: number;
  refresh_token: string;
  token_type: string;
  user: User;
}

type RegisterResponse = User;

type GetProfileResponse = User;

export const UsersService = {
  register: (body: RegisterBody) => HttpService.post<RegisterResponse>('auth/v1/signup', body),
  login: (body: LoginBody) => HttpService.post<LoginResponse>('/auth/v1/token?grant_type=password', body),
  getProfile: () => HttpService.get<GetProfileResponse>('/auth/v1/user'),
  getProfileExtras: (userId: string) =>
    HttpService.get<UserExtras[]>(`/rest/v1/${DB_TABLE_NAMES.dbProfile}?id=eq.${userId}`),
  verifyEmail: (emailToken: string) => HttpService.get(`/auth/email/verification/${emailToken}`),
  requestPasswordRecovery: (email: string) =>
    HttpService.post('/auth/v1/recover', { email, redirectTo: `${getEnv('REACT_APP_BASE_URL')}/reset-password` }),
  resetPassword: (body: ResetPasswordBody) => HttpService.put('/auth/v1/user', body),
  updateProfile: (body: UpdateProfileBody) => HttpService.put<User>(`/auth/v1/user`, body),
  updateProfileExtras: (userId: string, body: UpdateProfileExtrasBody) =>
    HttpService.patch<User>(`/rest/v1/${DB_TABLE_NAMES.dbProfile}?id=eq.${userId}`, body),
};
