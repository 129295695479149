import React, { useMemo, useState } from 'react';
import { getThemeConfigForThemeId } from 'engine/themesConfig';
import { CharacterSchema } from 'models/db/db_PlayerCharacter';
import { TextGroup } from '../PartyView.styles';
import { ExpandableAttribute } from '../components/ExpandableAttribute/ExpandableAttribute';

interface AttributesSectionProps {
  characterSchema?: CharacterSchema;
  themeId: string;
}

const AttributesSection = ({ characterSchema, themeId }: AttributesSectionProps) => {
  const themeConfig = useMemo(() => getThemeConfigForThemeId(themeId), [themeId]);

  const [expandedAttribute, setExpandedAttribute] = useState<string | undefined>(undefined);

  const handleExpandAttribute = (attributeTkey: string) => {
    setExpandedAttribute((prev) => (prev === attributeTkey ? undefined : attributeTkey));
  };

  return (
    <TextGroup>
      {Object.keys(characterSchema?.attributes ?? {}).map((attributeTkey) => (
        <ExpandableAttribute
          key={attributeTkey}
          attributeTkey={attributeTkey}
          attributeLevel={characterSchema?.attributes[attributeTkey] ?? 0}
          skills={characterSchema?.skills}
          themeConfig={themeConfig}
          isExpanded={expandedAttribute === attributeTkey}
          handleExpand={handleExpandAttribute}
        />
      ))}
    </TextGroup>
  );
};
export default AttributesSection;
