import { TOPBAR_HEIGHT } from 'components/GameplayTopBar/GameplayTopBar.styles';
import styled, { css } from 'styled-components';
import { pxToRem } from 'utils/styleUtils';

export const Wrapper = styled.aside<{ isOpen: boolean }>`
  position: fixed;
  top: ${pxToRem(TOPBAR_HEIGHT)};
  left: 100%;
  height: ${`calc(100vh - ${pxToRem(TOPBAR_HEIGHT)})`};
  width: 100%;
  max-width: ${({ theme }) => theme.breakpoints.mobile};
  transition: 0.5s;
  background: ${({ theme }) => `linear-gradient(${theme.colors.bgDarkNavy}, ${theme.colors.bgDarkViolet});`};
  z-index: 10;

  ${({ isOpen }) =>
    isOpen &&
    css`
      left: 50%;
      transform: translateX(-50%);
    `}
`;

export const List = styled.ul`
  margin-top: ${pxToRem(16)};
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: ${pxToRem(12)};
`;

export const Item = styled.li``;

export const Button = styled.button`
  text-decoration: none;
  display: flex;
  gap: ${pxToRem(12)};
  align-items: center;
  padding: ${pxToRem(6)} ${pxToRem(12)};
  cursor: pointer;

  & svg {
    fill: ${({ theme }) => theme.colors.white};
    width: ${pxToRem(38)};
    height: ${pxToRem(38)};
  }
`;
