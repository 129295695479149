import { NAVMENU_HEIGHT } from 'assets/styles/theme';
import styled from 'styled-components';
import { pxToRem } from 'utils/styleUtils';

export const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  overflow-y: hidden;
  min-height: 100%;
`;

export const ContentContainer = styled.div`
  padding: ${pxToRem(16)};
  width: 100%;
  max-width: ${({ theme }) => theme.breakpoints.mobile};
  height: calc(100svh - ${NAVMENU_HEIGHT});
  overflow-y: auto;
`;
