import styled from 'styled-components';
import { pxToRem } from 'utils/styleUtils';

export const Wrapper = styled.div`
  display: flex;
  margin: ${pxToRem(16)} 0;
  flex-direction: column;
  gap: ${pxToRem(16)};
`;

export const Group = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${pxToRem(8)};
`;

export const StoryCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${pxToRem(8)};
  padding: 1.5rem;
  border: ${pxToRem(1)} solid ${({ theme }) => theme.colors.opaqueWhite25};
  border-radius: ${({ theme }) => theme.border.radiusBase};

  ul {
    display: flex;
    flex-direction: column;
    gap: ${pxToRem(8)};
  }
`;
