import { useQuery } from '@tanstack/react-query';
import { SessionService } from 'services/SessionService';
import { useGameplaySelector } from 'stores/gameplay.slice';
import { QUERY_KEYS } from 'utils/consts';

export const usePendingActionsAPI = () => {
  const { activeSession } = useGameplaySelector();

  const sessionId = activeSession?.id;

  const pendingActionsQuery = useQuery(
    [QUERY_KEYS.gameplay.sessionPendingActions, sessionId],
    () => SessionService.getSessionPendingActions(sessionId ?? ''),
    {
      enabled: !!sessionId,
    },
  );

  return {
    pendingActionsQuery,
  } as const;
};
