import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from 'components/generics/Text/Text';
import { ContentContainer } from '../../../IngameHandbook.styles';

export const InventorySubsection = () => {
  const { t } = useTranslation('handbook', { keyPrefix: 'party_view.subsections.inventory' });

  return (
    <ContentContainer>
      <Text fontSize="small" color="opaqueWhite75" fontWeight={500}>
        {t('paragraphs.p1')}
      </Text>

      <div>
        <Text fontSize="small" color="opaqueWhite75" fontWeight={500}>
          {t('paragraphs.p2')}
        </Text>
        <Text fontSize="small" color="opaqueWhite75" fontWeight={500}>
          {t('paragraphs.p3')}
        </Text>
      </div>

      <div>
        <Text color="primary" fontWeight={500}>
          {t('use_action.label')}
        </Text>
        <Text fontSize="small" color="opaqueWhite75" fontWeight={500}>
          {t('use_action.description')}
        </Text>
      </div>

      <div>
        <Text color="primary" fontWeight={500}>
          {t('toggle_equipped.label')}
        </Text>
        <Text fontSize="small" color="opaqueWhite75" fontWeight={500}>
          {t('toggle_equipped.description')}
        </Text>
      </div>

      <div>
        <Text color="secondary" fontWeight={500}>
          {t('remove_action.label')}
        </Text>
        <Text fontSize="small" color="opaqueWhite75" fontWeight={500}>
          {t('remove_action.description')}
        </Text>
      </div>
    </ContentContainer>
  );
};
