import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Text } from 'components/generics/Text/Text';
import { ReactComponent as IconGm } from 'assets/images/svg/icon_open_book.svg';
import {
  ListItemContainer,
  GmAvatarWrapper,
  PlayerAvatar,
  SessionDetails,
  LastActivityDate,
  VersionBox,
} from './SessionList.styles';

interface SessionListItemProps {
  title: string;
  themeId: string;
  playersCount?: number;
  isUserGM?: boolean;
  updatedAt?: string;
  onClick?: () => any;
  portrait?: string;
  appVersion?: string;
}

export const SessionListItem = ({
  title,
  themeId,
  playersCount,
  isUserGM,
  updatedAt,
  onClick,
  portrait,
  appVersion,
}: SessionListItemProps) => {
  const { t } = useTranslation('launcher', { keyPrefix: 'sessions_view' });
  const { t: tThemesIndex } = useTranslation('themesIndex');

  return (
    <ListItemContainer onClick={onClick}>
      {isUserGM ? (
        <GmAvatarWrapper>
          <IconGm />
        </GmAvatarWrapper>
      ) : (
        <PlayerAvatar src={portrait} loading="lazy" />
      )}
      <SessionDetails>
        <Text fontSize="medium" letterSpacing="wide">
          {title}
        </Text>
        <Text
          fontSize="smaller"
          fontWeight={500}
          letterSpacing="superWide"
          textTransform="uppercase"
          color="opaqueWhite50"
        >
          {tThemesIndex(`${themeId}.name` as any)}
        </Text>
        <Text fontSize="small" letterSpacing="wide">{`${playersCount} ${
          playersCount !== 1 ? t('players_count') : t('single_player_in_session')
        }`}</Text>
      </SessionDetails>
      <LastActivityDate>
        <Text fontSize="smaller" color="opaqueWhite50">
          {moment(updatedAt).format('DD.MM')}
        </Text>
      </LastActivityDate>
      {appVersion && (
        <VersionBox>
          <Text fontSize="smaller" color="opaqueWhite50">
            v{appVersion}
          </Text>
        </VersionBox>
      )}
    </ListItemContainer>
  );
};
