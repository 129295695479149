import styled from 'styled-components';
import { pxToRem } from 'utils/styleUtils';

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${pxToRem(16)};
`;

export const CardHeadingRow = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CardDescriptionRow = styled.div``;
