export const DEFAULT_FONT_SIZE = 16;

/* 
    Get lighter or darker shade of the HEX color
    * @param  {string} color - The base color in Hex
    * @param  {number} amount - How much should the color be reshaded. <0 = darker shade, >0 = lighter shade
    * @return {string} The result color in HEX
*/
export function reshade(color: string, amount: number) {
  return `#${color.replace(/^#/, '').replace(/../g, (color) =>
    // eslint-disable-next-line prefer-template
    ('0' + Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).slice(-2),
  )}`;
}

/* 
    Get opacity of the HEX color
    * @param  {string} color - The base color in Hex
    * @param  {number} opacity - Desired opacity
    * @return {string} The result color in HEX
*/
export const changeColorOpacity = (color: string, opacity: number): string =>
  `${color}${Math.floor(opacity * 255)
    .toString(16)
    .padStart(2, '0')}`;

export const pxToRem = (value: number): string => `${(value / DEFAULT_FONT_SIZE).toString()}rem`;
