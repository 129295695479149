import React, { useEffect } from 'react';
import { Portal } from 'components/Portal/Portal';
import { Spinner } from 'components/generics/Spinner/Spinner';
import { Container } from './FullScreenLoading.styles';

const handleScrollBlock = () => {
  document.body.style.overflowY = 'hidden';
  return () => {
    document.body.style.overflowY = 'initial';
  };
};

export const FullScreenLoading = () => {
  useEffect(handleScrollBlock, []);

  return (
    <Portal>
      <Container>
        <Spinner />
      </Container>
    </Portal>
  );
};
