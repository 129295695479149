import React from 'react';
import { Text } from 'components/generics/Text/Text';
import { MessageSessionRecord } from 'models/db/db_SessionRecord';
import { Record } from '../RecordsView.styles';

interface MessageRecordProps {
  isGm?: boolean;
  record: MessageSessionRecord;
}

export const MessageRecord = ({ isGm, record }: MessageRecordProps) => (
  <Record isGm={isGm}>
    <Text fontSize="small">{record.content.message}</Text>
  </Record>
);
