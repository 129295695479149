import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from 'components/generics/Text/Text';
import { Container, ContentContainer } from '../../../IngameHandbook.styles';

export const AboutSubsection = () => {
  const { t } = useTranslation('handbook', { keyPrefix: 'party_view.subsections.about' });

  return (
    <ContentContainer>
      <Text fontSize="small" color="opaqueWhite75" fontWeight={500}>
        {t('paragraphs.p1')}
      </Text>
      <Container>
        <Text fontSize="small" color="opaqueWhite75" fontWeight={500}>
          {t('paragraphs.p2')}
        </Text>
        <Text fontSize="small" color="opaqueWhite75" fontWeight={500}>
          {t('paragraphs.p3')}
        </Text>
        <Text fontSize="small" color="opaqueWhite75" fontWeight={500}>
          {t('paragraphs.p4')}
        </Text>
        <Text fontSize="small" color="opaqueWhite75" fontWeight={500}>
          {t('paragraphs.p5')}
        </Text>
      </Container>
    </ContentContainer>
  );
};
