import React from 'react';
import { Text, TextProps } from 'components/generics/Text/Text';

import { Container, Line } from './Divider.styles';

interface DividerProps {
  text?: string;
  opacity?: 25 | 50;
  textProps?: TextProps;
}

const Divider = ({ text, opacity = 50, textProps }: DividerProps) => (
  <Container>
    <Line opacity={opacity} />
    {text && (
      <>
        <Text color={opacity === 25 ? 'opaqueWhite25' : 'opaqueWhite50'} {...textProps}>
          {text}
        </Text>
        <Line opacity={opacity} />
      </>
    )}
  </Container>
);

export default Divider;
