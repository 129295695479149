import styled from 'styled-components';
import { pxToRem } from 'utils/styleUtils';

export const InputWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const TogglePasswordButton = styled.div`
  position: absolute;
  bottom: ${pxToRem(8)};
  right: 1rem;
  border: none;
  background: none;
  cursor: pointer;

  svg {
    width: ${pxToRem(24)};
    fill: ${({ theme }) => theme.colors.opaqueWhite25};
  }

  &:hover {
    svg {
      fill: ${({ theme }) => theme.colors.opaqueWhite50};
    }
  }
`;
