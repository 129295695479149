import styled, { css } from 'styled-components';
import { pxToRem } from 'utils/styleUtils';

export const Wrapper = styled.div`
  display: flex;
  margin: ${pxToRem(16)} 0;
  flex-direction: column;
  gap: ${pxToRem(16)};
`;

export const LocationContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
`;

export const UnexpandedWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: -${pxToRem(3)};
`;

export const ExpandedWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0.75rem;

  text-align: justify;
`;

export const NameWrapper = styled.div`
  position: absolute;
  bottom: 0.5rem;
  left: 0.5rem;
  text-align: left;

  & > p {
    text-shadow: -1px -1px 0 #000, 0 -1px 0 #000, 1px -1px 0 #000, 1px 0 0 #000, 1px 1px 0 #000, 0 1px 0 #000,
      -1px 1px 0 #000, -1px 0 0 #000;
  }
`;

export const LocationImage = styled.img`
  width: 100%;
  max-height: 12rem;
  aspect-ratio: 16 / 9;
  object-fit: cover;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;

  & > button {
    width: 100%;
  }
`;

export const FiltersWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.8rem;
`;

export const FilterTab = styled.button<{ isSelected: boolean }>`
  color: ${({ theme }) => theme.colors.white};
  font-weight: 700;
  border: ${({ theme }) => `${pxToRem(2)} solid ${theme.colors.opaqueWhite25}`};
  border-radius: ${pxToRem(12)};
  padding: ${pxToRem(4)} ${pxToRem(8)};
  cursor: pointer;

  ${({ isSelected, theme }) =>
    isSelected &&
    css`
      border-color: ${theme.colors.white};
    `}
`;

export const NoLocationWrapper = styled.div`
  padding: 2rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
