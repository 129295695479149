import styled from 'styled-components';
import { pxToRem } from 'utils/styleUtils';

export const SectionContainer = styled.div`
  display: grid;
  grid-template-rows: 1fr min-content;
  /* grid-template-rows: min-content 1fr min-content; */
  height: 100%;
  gap: 1rem;
`;

export const FiltersContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-end;
`;

export const ListContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 16px;
  padding-bottom: ${pxToRem(16)};
  overflow-y: auto;
`;

export const ListItemContainer = styled.button`
  cursor: pointer;
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 1.5rem;
  padding: 1rem;
  border-radius: ${({ theme }) => theme.border.radiusBase};
  background-color: ${({ theme }) => theme.colors.opaqueBlack25};
`;

export const SessionDetails = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  gap: 0.25rem;
`;

export const GmAvatarWrapper = styled.div`
  width: ${pxToRem(50)};
  height: ${pxToRem(75)};
  border-radius: ${({ theme }) => theme.border.radiusBase};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.highlightYellowOpaque};

  svg {
    fill: ${({ theme }) => theme.colors.highlightYellow};
  }
`;

export const PlayerAvatar = styled.img`
  width: ${pxToRem(50)};
  height: ${pxToRem(75)};
  border-radius: ${({ theme }) => theme.border.radiusBase};
`;

export const LastActivityDate = styled.div`
  position: absolute;
  right: 0.35rem;
  top: 0.25rem;
`;

export const VersionBox = styled.div`
  position: absolute;
  right: 0.35rem;
  bottom: 0.25rem;
`;

export const Divider = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: ${`calc(100% - ${pxToRem(16)})`};
  height: ${pxToRem(1)};
  background-color: ${({ theme }) => theme.colors.opaqueWhite25};
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 ${pxToRem(8)};
  padding-top: ${pxToRem(12)};
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;
  gap: 0.5rem;

  & button {
    width: 50%;
  }

  & button:nth-child(2) {
    border-color: ${({ theme }) => theme.colors.highlightYellow};
  }

  & button:last-child {
    border-color: ${({ theme }) => theme.colors.primary};
  }
`;

export const NoSessionsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
`;
