import React from 'react';
import { Text } from 'components/generics/Text/Text';
import { Helmet } from 'components/Helmet/Helmet';
import { useTranslation } from 'react-i18next';
import { ColorButton } from 'components/generics/Button/ColorButton';
import { useNavigate } from 'react-router-dom';
import { Illustration, Wrapper } from './NotFoundView.styles';
import IllustrationAsset from './assets/illustration.png';

export const NotFoundView = () => {
  const { t } = useTranslation('launcher', { keyPrefix: 'not_found_view' });
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title>{t('title')}</title>
      </Helmet>
      <Wrapper>
        <Text as="h1" fontSize="sectionHeading" fontWeight={700} textAlign="center">
          404
        </Text>
        <Illustration src={IllustrationAsset} alt="" />
        <Text fontSize="large" fontWeight={700} textAlign="right">
          {t('heading')}
        </Text>
        <Text color="opaqueWhite75" textAlign="center">
          {t('p1')}
        </Text>
        <Text color="opaqueWhite75" textAlign="center">
          {t('p2')}
        </Text>
        <ColorButton
          onClick={() => {
            navigate(-1);
          }}
        >
          {t('button')}
        </ColorButton>
      </Wrapper>
    </>
  );
};
