import { useGameplayNavigation } from 'hooks/useGameplayNavigation';
import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import { SupabaseService } from 'services/SupabaseService';
import { useGameplaySelector } from 'stores/gameplay.slice';
import { useSessionRecordsAPI } from 'hooks/sessionApi/useSessionRecordsAPI';
import { useSessionPlayersAPI } from 'hooks/sessionApi/useSessionPlayersAPI';
import { useUserSelector } from 'stores/user.slice';
import { SessionRecord } from 'models/db/db_SessionRecord';
import { OmitAutoGenerated } from 'models/Api';
import { DB_TABLE_NAMES } from 'utils/consts';
import { useIsGameMaster } from 'hooks/useIsGameMaster';
import { useIsVisible } from 'hooks/useIsVisible';

export const useSessionRecords = () => {
  const { activeSession, activePlayer } = useGameplaySelector();
  const { isGameMaster } = useIsGameMaster();
  const { redirectToCharacter } = useGameplayNavigation();
  const { user } = useUserSelector();

  const scrollableElementRef = useRef<HTMLDivElement | null>(null);
  const bottomElementRef = useRef<HTMLDivElement | null>(null);
  const scrollThresholdElementRef = useRef<HTMLDivElement | null>(null);
  const messageInputRef = useRef<HTMLDivElement | null>(null);
  const [isFirstFetching, setIsFirstFetching] = useState(true);

  const {
    sessionRecordsQuery,
    isFetchingMoreRecords,
    canFetchMoreRecords,
    getters: { fetchMoreSessionRecords },
  } = useSessionRecordsAPI({
    handleOnSuccess: () => {
      setTimeout(() => {
        if (scrollableElementRef.current) scrollableElementRef.current.scroll(0, 10000000);
        if (messageInputRef.current) messageInputRef.current.scrollIntoView();
      }, 0);
      setTimeout(() => {
        setIsFirstFetching(false);
      }, 1000);
    },
  });

  const sessionRecords = useMemo(() => sessionRecordsQuery.data?.data ?? [], [sessionRecordsQuery.data]);
  const { sessionPlayersQuery } = useSessionPlayersAPI();
  const sessionPlayers = useMemo(() => sessionPlayersQuery.data?.data ?? [], [sessionPlayersQuery.data]);

  const [message, setMessage] = useState('');
  const [isMessageInputFocused, setIsMessageInputFocused] = useState(false);

  const isScrollDownButtonVisible = !useIsVisible(scrollThresholdElementRef);

  const triggerInfinityScroll = useCallback(async () => {
    if (isFetchingMoreRecords || !canFetchMoreRecords) return;

    if (!scrollableElementRef.current) return;
    const { scrollHeight: scrollHeightBefore } = scrollableElementRef.current;

    await fetchMoreSessionRecords(sessionRecords.length);

    // setTimeout 0 to wait for the DOM to update
    setTimeout(() => {
      if (!scrollableElementRef.current) return;
      const { scrollHeight: scrollHeightAfter } = scrollableElementRef.current;

      const oldPosition = scrollHeightAfter - scrollHeightBefore;

      scrollableElementRef.current.scroll(0, oldPosition);
    }, 0);
  }, [fetchMoreSessionRecords, isFetchingMoreRecords, sessionRecords.length, canFetchMoreRecords]);

  const intObserver = useRef<IntersectionObserver | null>(null);
  const topElementRef: any = useCallback(
    (element: Element) => {
      if (isFetchingMoreRecords || isFirstFetching) return;

      if (intObserver.current) intObserver.current.disconnect();

      intObserver.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && canFetchMoreRecords) {
          triggerInfinityScroll();
        }
      });

      if (element) {
        intObserver.current.observe(element);
      }
    },
    [canFetchMoreRecords, isFetchingMoreRecords, isFirstFetching, triggerInfinityScroll],
  );

  const toggleMessageInputFocus = () => {
    setIsMessageInputFocused((prev) => !prev);
    // There is a 200ms animation
    setTimeout(() => {
      if (messageInputRef.current) messageInputRef.current.scrollIntoView();
      if (scrollableElementRef.current) scrollableElementRef.current.scroll(0, 10000000);
    }, 200);
  };

  // Handler for updating the message state
  const handleMessageChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(event.target.value);
  };

  // Handler for submitting the message
  const handleSubmit = async () => {
    if (!message || !activeSession) return;

    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }

    await SupabaseService.from(DB_TABLE_NAMES.dbSessionRecord).insert<OmitAutoGenerated<SessionRecord>>([
      {
        player_character_id: !isGameMaster ? activePlayer?.character_id : undefined,
        session_id: activeSession?.id,
        record_type: 'message',
        content: {
          message,
        },
      },
    ]);

    setMessage('');

    if (scrollableElementRef.current) scrollableElementRef.current.scroll(0, 10000000);
  };

  const [typingUsersIds, setTypingUsersIds] = useState<string[]>([]);
  const isTyping = isMessageInputFocused && message.length > 0;

  useEffect(() => {
    const typingIndicatorsChannel = SupabaseService.channel(`session_typing_indicators:${activeSession?.id}`);
    typingIndicatorsChannel
      .on('broadcast', { event: 'typing_enable' }, ({ payload }) => {
        if (!typingUsersIds.includes(payload.userId)) {
          setTypingUsersIds((prev) => [...prev, payload.userId]);
        }
      })
      .on('broadcast', { event: 'typing_disable' }, ({ payload }) => {
        if (typingUsersIds.includes(payload.userId)) {
          setTypingUsersIds((prev) => prev.filter((id) => id !== payload.userId));
        }
      })
      .subscribe((status) => {
        if (status === 'SUBSCRIBED') {
          typingIndicatorsChannel.send({
            type: 'broadcast',
            event: isTyping ? 'typing_enable' : 'typing_disable',
            payload: { userId: user?.id },
          });
        }
      });

    return () => {
      if (typingIndicatorsChannel) SupabaseService.removeChannel(typingIndicatorsChannel);
    };
  }, [activeSession?.id, isTyping, typingUsersIds, user?.id]);

  const sessionRecordsWithTypingIndicators = useMemo(() => {
    const typingIndicatorRecords: SessionRecord[] = typingUsersIds.map((userId) => ({
      id: `${userId}-typing-indicator`,
      created_at: new Date().toISOString(),
      player_character_id: sessionPlayers.find((player) => player.owner_id === userId)?.character_id ?? undefined,
      session_id: activeSession?.id ?? '',
      record_type: 'typing_indicator',
      content: undefined,
    }));

    return [...sessionRecords, ...typingIndicatorRecords];
  }, [activeSession?.id, sessionPlayers, sessionRecords, typingUsersIds]);

  // Function to get a session player by ID
  const getSessionPlayerById = (id: string) => sessionPlayers.find((player) => player?.character_id === id);

  return {
    message,
    sessionRecords: sessionRecordsWithTypingIndicators,
    activeSession,
    handleMessageChange,
    handleSubmit,
    activePlayer,
    getSessionPlayerById,
    isMessageInputFocused,
    toggleMessageInputFocus,
    redirectToCharacter,
    isFetchingMoreRecords,
    canFetchMoreRecords,
    triggerInfinityScroll,
    scrollableElementRef,
    bottomElementRef,
    topElementRef,
    isScrollDownButtonVisible,
    messageInputRef,
    scrollThresholdElementRef,
  } as const;
};
