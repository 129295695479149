import { useEffect } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { QUERY_KEYS } from 'utils/consts';
import { SessionService } from 'services/SessionService';
import { SupabaseService } from 'services/SupabaseService';
import { useAppDispatch } from 'stores/store';
import { setActiveSession, useGameplaySelector, resetGameplayStore } from 'stores/gameplay.slice';
import { useSessionLogListener } from './hooks/useSessionLogListener';
import { useSessionPlayersListener } from './hooks/useSessionPlayersListener';
import { useSessionPendingActionsListener } from './hooks/useSessionPendingActionsListener';

export const useSessionView = (sessionId: string | undefined) => {
  const { activeSession } = useGameplaySelector();
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  if (!sessionId) throw new Error('Session ID is not defined');

  const sessionQuery = useQuery(
    [QUERY_KEYS.gameplay.sessionData, sessionId],
    () => SessionService.getSessionData({ s_id: sessionId }),
    {
      enabled: !!sessionId,
    },
  );

  const session = sessionQuery.data;

  useEffect(() => {
    // Update the active session when session data is available
    if (session?.data && !activeSession) {
      const sessionData = session.data[0];
      dispatch(setActiveSession({ session: sessionData }));
    }

    return () => {
      if (activeSession) {
        SupabaseService.removeAllChannels();
        dispatch(resetGameplayStore());

        queryClient.removeQueries([QUERY_KEYS.gameplay.sessionPlayers, activeSession.id]);
        queryClient.removeQueries([QUERY_KEYS.gameplay.sessionRecords, activeSession.id]);
        queryClient.removeQueries([QUERY_KEYS.gameplay.activeLocation, activeSession.id]);
        queryClient.removeQueries([QUERY_KEYS.gameplay.activeLocationPack, activeSession.id]);
        queryClient.removeQueries([QUERY_KEYS.gameplay.sessionPendingActions, activeSession.id]);
        queryClient.removeQueries([QUERY_KEYS.gameplay.storyCards, activeSession.id]);
      }
    };
  }, [activeSession, session, dispatch, queryClient]);

  // SETUP LISTENERS
  useSessionLogListener(sessionId);
  useSessionPlayersListener(sessionId);
  useSessionPendingActionsListener(sessionId);

  return {
    sessionTitle: session?.data[0].title,
    isSessionLoading: sessionQuery.isLoading,
  };
};
