import * as Yup from 'yup';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { UpdateProfileBody, UpdateProfileExtrasBody, UsersService } from 'services/UsersService';
import { LOWERCASE_REGEX, NUMERIC_REGEX, SPECIAL_CHARACTER_REGEX, UPPERCASE_REGEX } from 'utils/regex';
import { updateUser, useUserSelector } from 'stores/user.slice';
import { User } from 'models/User';
import { useAppDispatch } from 'stores/store';

export const useUserSettingsView = () => {
  const { user } = useUserSelector();
  const dispatch = useAppDispatch();

  const { t: tErrors } = useTranslation('launcher', { keyPrefix: 'form_errors' });

  // Display name subform
  const displayNameValidationSchema = Yup.object().shape({
    displayName: Yup.string()
      .required(tErrors('display_name_required'))
      .max(30, tErrors('display_name_too_long'))
      .not([user?.display_name]),
  });

  const displayNameFormMutation = useMutation({
    mutationFn: (body: UpdateProfileExtrasBody) =>
      UsersService.updateProfileExtras(user ? user.id : 'x', body).then((response) => {
        if (response.status === 204) {
          dispatch(updateUser(body as User));
          toast.success(tErrors('display_name_updated_successfully'));
        } else {
          toast.error(tErrors('generic_error'));
        }
      }),
  });

  const displayNameForm = useFormik({
    initialValues: {
      displayName: user?.display_name,
    },
    validationSchema: displayNameValidationSchema,
    onSubmit: (values) =>
      displayNameFormMutation.mutate({
        display_name: values.displayName,
      }),
  });

  // Change password subform
  const changePasswordFormMutation = useMutation({
    mutationFn: (body: UpdateProfileBody) =>
      UsersService.updateProfile(body).then((response) => {
        if (response.status === 200) {
          toast.success(tErrors('password_updated_successfully'));
        } else {
          toast.error(tErrors('generic_error'));
        }
      }),
  });

  const changePasswordValidationSchema = Yup.object().shape({
    newPassword: Yup.string()
      .required(tErrors('new_password_required'))
      .min(8, tErrors('password_too_short'))
      .matches(LOWERCASE_REGEX, tErrors('password_lowercase_missing'))
      .matches(UPPERCASE_REGEX, tErrors('password_uppercase_missing'))
      .matches(NUMERIC_REGEX, tErrors('password_number_missing'))
      .matches(SPECIAL_CHARACTER_REGEX, tErrors('password_special_character_missing')),
    repeatedNewPassword: Yup.string()
      .required(tErrors('repeated_password_required'))
      .oneOf([Yup.ref('newPassword'), null], tErrors('passwords_not_match')),
  });

  const changePasswordForm = useFormik({
    initialValues: {
      newPassword: '',
      repeatedNewPassword: '',
    },
    validationSchema: changePasswordValidationSchema,
    onSubmit: (values) => changePasswordFormMutation.mutate({ password: values.newPassword }),
  });

  const isChangeDisplayNameBtnDisabled = user?.display_name === displayNameForm.values.displayName;

  const allowPasswordChange = user?.app_metadata.provider === 'email';

  const SSOProvider = user?.app_metadata.provider;

  return {
    displayNameForm,
    changePasswordForm,
    isChangeDisplayNameBtnDisabled,
    allowPasswordChange,
    SSOProvider,
  } as const;
};
