import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { GetSessionByIdRow } from 'models/functions/GetSessionById';
import { RootState } from './store';

export const enum SESSION_VIEW_TYPE {
  SESSION_RECORDS = 'SESSION_RECORDS',
  DEMAND_ROLL = 'CALL_ROLL',
  ROLL_DICE = 'ROLL_DICE',
  PARTY = 'PARTY',
  STORY = 'STORY',
  DRAW_STORY_CARD = 'DRAW_STORY_CARD',
  MANAGEMENT = 'MANAGEMENT',
}

export interface ActivePlayerState {
  user_id: string;
  character_id: string;
  session_fingerprint: string;
}

export interface GameplayState {
  activeSession: GetSessionByIdRow | undefined;
  activePlayer: ActivePlayerState | undefined;
  gameplayNavigation: {
    currentView: SESSION_VIEW_TYPE;
    previousViews: SESSION_VIEW_TYPE[];
  };
  characterIdToView: string | undefined;
  rollIdToSelect: string | undefined;
}

const initialState: GameplayState = {
  activeSession: undefined,
  activePlayer: undefined,
  gameplayNavigation: {
    previousViews: [],
    currentView: SESSION_VIEW_TYPE.SESSION_RECORDS,
  },
  characterIdToView: undefined,
  rollIdToSelect: undefined,
};

const resetGameplayStoreReducer: CaseReducer<GameplayState> = () => initialState;

const setActiveSessionReducer: CaseReducer<GameplayState, PayloadAction<{ session: GetSessionByIdRow }>> = (
  state,
  { payload },
) => ({
  ...state,
  activeSession: payload.session,
});

const setActivePlayerReducer: CaseReducer<GameplayState, PayloadAction<ActivePlayerState>> = (state, { payload }) => ({
  ...state,
  activePlayer: payload,
});

const setCurrentViewReducer: CaseReducer<GameplayState, PayloadAction<{ newView: SESSION_VIEW_TYPE }>> = (
  state,
  { payload },
) => ({
  ...state,
  gameplayNavigation:
    payload.newView !== state.gameplayNavigation.currentView
      ? {
          ...state.gameplayNavigation,
          previousViews:
            payload.newView === SESSION_VIEW_TYPE.SESSION_RECORDS
              ? []
              : [...state.gameplayNavigation.previousViews, state.gameplayNavigation.currentView],
          currentView: payload.newView,
        }
      : state.gameplayNavigation,
});

const removeLastViewReducer: CaseReducer<GameplayState> = (state) => ({
  ...state,
  gameplayNavigation: {
    ...state.gameplayNavigation,
    previousViews: state.gameplayNavigation.previousViews.slice(0, -2),
  },
});

const setCharacterIdToViewReducer: CaseReducer<GameplayState, PayloadAction<{ characterId: string | undefined }>> = (
  state,
  { payload },
) => ({
  ...state,
  characterIdToView: payload.characterId,
});

const setRollIdToSelectReducer: CaseReducer<GameplayState, PayloadAction<{ rollId: string | undefined }>> = (
  state,
  { payload },
) => ({
  ...state,
  rollIdToSelect: payload.rollId,
});

export const gameplaySlice = createSlice({
  name: 'gameplay',
  initialState,
  reducers: {
    setActiveSession: setActiveSessionReducer,
    resetGameplayStore: resetGameplayStoreReducer,
    setActivePlayer: setActivePlayerReducer,
    setCurrentView: setCurrentViewReducer,
    removeLastView: removeLastViewReducer,
    setCharacterIdToView: setCharacterIdToViewReducer,
    setRollIdToSelect: setRollIdToSelectReducer,
  },
});

export const {
  setActiveSession,
  resetGameplayStore,
  setActivePlayer,
  setCurrentView,
  removeLastView,
  setCharacterIdToView,
  setRollIdToSelect,
} = gameplaySlice.actions;

export const useGameplaySelector = () => useSelector((state: RootState) => state.gameplay);

export default gameplaySlice.reducer;
