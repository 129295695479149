import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useGameplayNavigation } from 'hooks/useGameplayNavigation';
import { Text } from 'components/generics/Text/Text';
import { SESSION_VIEW_TYPE } from 'stores/gameplay.slice';
import { HandbookSessionLogSection } from './sections/HandbookSessionLogSection';
import { Container, ContentContainer } from './IngameHandbook.styles';
import { HandbookPartySection } from './sections/HandbookPartySection/HandbookSessionPartySection';
import { HandbookRollDiceSection } from './sections/HandbookRollDiceSection/HandbookRollDiceSection';
import { HandbookStorySection } from './sections/HandbookStorySection/HandbookStorySection';
import { HandbookCallRollSection } from './sections/HandbookCallRollSection';
import { HandbookManagementSection } from './sections/HandbookManagementSection/HandbookManagementSection';

export const IngameHandbook = () => {
  const { t } = useTranslation('handbook');
  const { currentView } = useGameplayNavigation();

  const renderSection = useCallback(() => {
    switch (currentView) {
      case SESSION_VIEW_TYPE.SESSION_RECORDS:
        return <HandbookSessionLogSection />;
      case SESSION_VIEW_TYPE.MANAGEMENT:
        return <HandbookManagementSection />;
      case SESSION_VIEW_TYPE.DEMAND_ROLL:
        return <HandbookCallRollSection />;
      case SESSION_VIEW_TYPE.STORY:
      case SESSION_VIEW_TYPE.DRAW_STORY_CARD:
        return <HandbookStorySection />;
      case SESSION_VIEW_TYPE.PARTY:
        return <HandbookPartySection />;
      case SESSION_VIEW_TYPE.ROLL_DICE:
        return <HandbookRollDiceSection />;
      default:
        return <HandbookSessionLogSection />;
    }
  }, [currentView]);

  return (
    <Container>
      <Text
        fontWeight={500}
        color="opaqueWhite50"
        fontSize="smaller"
        letterSpacing="superWide"
        textTransform="uppercase"
      >
        {t('handbook_heading')}
      </Text>
      <Text fontSize="big" fontWeight={500} letterSpacing="wide" textTransform="uppercase">
        {t(`${currentView.toLowerCase()}_view.section_heading` as any)}
      </Text>
      <ContentContainer>{renderSection()}</ContentContainer>
    </Container>
  );
};
