import React, { useState } from 'react';
import { Text } from 'components/generics/Text/Text';
import { ColorButton } from 'components/generics/Button/ColorButton';
import { ReactComponent as IconSwap } from 'assets/images/svg/icon_swap_horizontal.svg';
import {
  Container,
  ConfirmButtonWrapper,
  Selector,
  CoverImageVerticalLayout,
  CoverImageHorizontalLayout,
  OptionVerticalLayout,
  OptionHorizontalLayout,
  Overlay,
} from './OverlaySelect.styles';

interface OverlaySelectProps {
  selectedOptionId: string;
  onSelect: (optionId: string) => any;
  options: {
    id: string;
    name: string;
    description: string;
    coverImg: string;
  }[];
  optionLayout: 'horizontal' | 'vertical';
  label?: string;
  placeholder: string;
  heading?: string;
  confirmButtonText: string;
  isOverlayActive: boolean;
  toggleOverlay: () => any;
}

export const OverlaySelect = ({
  selectedOptionId,
  onSelect,
  options,
  optionLayout,
  isOverlayActive,
  toggleOverlay,
  heading,
  label,
  placeholder,
  confirmButtonText,
}: OverlaySelectProps) => {
  const [taggedForSelectionId, setTaggedForSelectionId] = useState<string>('');

  const tagForSelection = (optionId: string) =>
    setTaggedForSelectionId((current) => (current === optionId ? '' : optionId));

  const confirmSelection = () => {
    onSelect(taggedForSelectionId);
    toggleOverlay();
  };

  return isOverlayActive ? (
    <Overlay>
      {heading && (
        <Text fontSize="bigger" fontWeight={500}>
          {heading}
        </Text>
      )}
      {optionLayout === 'vertical' &&
        options.map((option) => (
          <OptionVerticalLayout
            key={option.id}
            onClick={() => tagForSelection(option.id)}
            isSelected={taggedForSelectionId === option.id}
          >
            <Text fontSize="big" fontWeight={500}>
              {option.name}
            </Text>
            <CoverImageVerticalLayout src={option.coverImg} loading="lazy" />
            <Text fontSize="smaller" textAlign="left">
              {option.description}
            </Text>
          </OptionVerticalLayout>
        ))}
      {optionLayout === 'horizontal' &&
        options.map((option) => (
          <OptionHorizontalLayout
            key={option.id}
            onClick={() => tagForSelection(option.id)}
            isSelected={taggedForSelectionId === option.id}
          >
            <CoverImageHorizontalLayout src={option.coverImg} loading="lazy" />
            <div>
              <Text fontSize="medium" fontWeight={500}>
                {option.name}
              </Text>
              <Text fontSize="smaller" fontWeight={300} textAlign="left">
                {option.description}
              </Text>
            </div>
          </OptionHorizontalLayout>
        ))}
      <ConfirmButtonWrapper>
        <ColorButton isDisabled={!taggedForSelectionId} onClick={confirmSelection}>
          {confirmButtonText}
        </ColorButton>
      </ConfirmButtonWrapper>
    </Overlay>
  ) : (
    <Container>
      <Text
        fontWeight={500}
        color="opaqueWhite50"
        fontSize="smaller"
        letterSpacing="superWide"
        textTransform="uppercase"
      >
        {label}
      </Text>
      <Selector onClick={toggleOverlay}>
        {selectedOptionId ? (
          options.find((o) => o.id === selectedOptionId)?.name
        ) : (
          <Text color="opaqueWhite50">{placeholder}</Text>
        )}
        <IconSwap />
      </Selector>
    </Container>
  );
};
