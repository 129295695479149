import React from 'react';
import { ItemSessionRecord } from 'models/db/db_SessionRecord';
import { useTranslation } from 'react-i18next';
import { Text } from 'components/generics/Text/Text';
import { useGameplaySelector } from 'stores/gameplay.slice';
import { Record, RecordContentWrapper } from '../RecordsView.styles';

interface ItemRecordProps {
  isGm?: boolean;
  record: ItemSessionRecord;
}

export const InventoryRecord = ({ isGm, record }: ItemRecordProps) => {
  const { t } = useTranslation('gameplay', { keyPrefix: 'session_records_view' });
  const { t: tItem } = useTranslation('items' as 'resource');
  const { activeSession } = useGameplaySelector();

  return (
    <Record isGm={isGm}>
      <RecordContentWrapper>
        <Text fontWeight={700} textTransform="uppercase">
          {t(`item.${record.content?.action}` as any)}
        </Text>
        <Text fontSize="small" fontWeight={700}>
          {record.content.removed_quantity && `${record.content.removed_quantity}x`}{' '}
          {tItem(`${activeSession?.theme_id}.${record.content.type}.${record.content.t_key}.name` as any)}
        </Text>
      </RecordContentWrapper>
    </Record>
  );
};
