import i18next from 'i18next';
import { initReactI18next, TFunction } from 'react-i18next';
import HttpBackend from 'i18next-http-backend';
import ChainedBackend from 'i18next-chained-backend';
import resourcesToBackend from 'i18next-resources-to-backend';

import launcherEN from 'translations/en/launcher.json';
import launcherPL from 'translations/pl/launcher.json';

import gameplayEN from 'translations/en/gameplay.json';
import gameplayPL from 'translations/pl/gameplay.json';

import handbookEN from 'translations/en/handbook.json';
import handbookPL from 'translations/pl/handbook.json';

import themesIndexEN from 'translations/en/themes_index.json';
import themesIndexPL from 'translations/pl/themes_index.json';

import resourceEN_DO_NOT_REMOVE from 'translations/en/resource.json';
import resourcePL_DO_NOT_REMOVE from 'translations/pl/resource.json';
import { getEnv } from './utils/getEnv';

export const translationResources = {
  en: {
    launcher: launcherEN,
    gameplay: gameplayEN,
    handbook: handbookEN,
    themesIndex: themesIndexEN,
    resource: resourceEN_DO_NOT_REMOVE,
  },
  pl: {
    launcher: launcherPL,
    gameplay: gameplayPL,
    handbook: handbookPL,
    themesIndex: themesIndexPL,
    resource: resourcePL_DO_NOT_REMOVE,
  },
};

export type TranslationResources =
  | keyof typeof translationResources['en']['gameplay']
  | keyof typeof translationResources['en']['handbook']
  | keyof typeof translationResources['en']['launcher']
  | keyof typeof translationResources['en']['themesIndex']
  | keyof typeof translationResources['en']['resource'];

export type TKey = Parameters<TFunction<'gameplay' | 'launcher' | 'handbook' | 'themesIndex' | 'resource'>>[0];

i18next
  .use(initReactI18next)
  .use(ChainedBackend)
  .init({
    lng: localStorage.getItem('RD@userlanguage') || navigator.language,
    fallbackLng: 'en',
    supportedLngs: ['en', 'pl'],
    ns: ['characterAbilities', 'characterPerks', 'items', 'locationPacks', 'locations', 'storyCards', 'portraitPacks'],
    backend: {
      backends: [resourcesToBackend(translationResources), HttpBackend],
      backendOptions: [
        undefined,
        {
          loadPath: `${getEnv(
            'REACT_APP_API_URL',
          )}/storage/v1/object/public/translations/{{lng}}/resources/{{ns}}.json`,
        },
      ],
    },
  });

export default i18next;
