/* eslint-disable max-len */
import { useState, useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { useUserSelector } from 'stores/user.slice';
import { SupabaseService } from 'services/SupabaseService';
import { CharacterSchema } from 'models/db/db_PlayerCharacter';
import { DB_TABLE_NAMES, QUERY_KEYS } from 'utils/consts';
import { routes } from 'components/MainRouter/MainRouter';
import { calculateRecommendedBuild } from './utils/calculateRecommendedBuild';
import { calculateBaseMoney } from './utils/calculateBaseMoney';
import { calculateBaseHp } from './utils/calculateBaseHp';
import { useDataProvider } from './hooks/useDataProvider';
import { useMainForm } from './hooks/useMainForm';
import { useUIHelpers } from './hooks/useUIHelpers';

export const useCharacterCreator = () => {
  const queryClient = useQueryClient();

  const {
    name,
    setName,
    isCharacterNameAllowed,
    characterAge,
    selectedThemeId,
    selectedClassId,
    selectedPortraitPackId,
    selectedPortraitId,
    selectedOriginPerkId,
    selectedExperiencePerkIds,
    selectedPersonalityPerkIds,
    selectedFlawPerkId,
    handleCharacterAgeSelection,
    handleThemeSelection,
    handleClassSelection,
    handlePortraitPackSelection,
    handlePortraitSelection,
    handleOriginPerkSelection,
    handleExperiencePerkSelection,
    handlePersonalityPerkSelection,
    handleFlawPerkSelection,
  } = useMainForm();

  const { themes, characterClasses, characterPerks, items, portraitPacks, isResourcesLoading } =
    useDataProvider(selectedThemeId);

  const {
    portraitPacksAsOptions,
    selectablePortraits,
    setSelectablePortraits,
    portraitsFilters,
    handlePortraitsSexFilter,
    themeConfig,
    themesAsOptions,
    classesAsOptions,
    originPerksAsOptions,
    experiencePerksAsOptions,
    personalityPerksAsOptions,
    flawPerksAsOptions,
    itemsAsOptions,
    currentlyChangedPerkId,
    handleCurrentlyChangedPerkId,
    keywordsFilter,
    handleKeywordsFilter,
    itemsFilteringOptions,
  } = useUIHelpers(
    { themes, portraitPacks, characterClasses, characterPerks, items },
    { selectedThemeId, selectedClassId, selectedPortraitPackId },
  );

  const [isLoading, setIsLoading] = useState(false);

  // Navigation and localization
  const navigate = useNavigate();
  const { user } = useUserSelector();

  // Character Build data
  const [characterSchema, setCharacterSchema] = useState<CharacterSchema | undefined>(undefined);
  const [selectedItemsIds, setSelectedItemsIds] = useState<string[]>([]);

  const handleItemsSelection = (itemId: string) =>
    setSelectedItemsIds((prevIds) =>
      prevIds.includes(itemId) ? prevIds.filter((id) => id !== itemId) : [...prevIds, itemId],
    );

  // Portraits provider
  useEffect(() => {
    const fetchPortraitsForTheme = async (themeKey: string) => {
      const { data: portraitsData, error: portraitsError } = await SupabaseService.storage
        .from('images')
        .list(`portraits/${themeKey}/${selectedPortraitPackId}`, {
          sortBy: { column: 'name', order: 'asc' },
          search: `${portraitsFilters.sex}_`,
        });

      setSelectablePortraits(
        portraitsData?.map((portrait) => ({
          id: portrait.name,
          coverImg: `${
            process.env.REACT_APP_API_URL
          }/storage/v1/object/public/images/portraits/${themeKey}/${selectedPortraitPackId}/${[portrait.name]}`,
        })) ?? [],
      );
    };

    if (selectedThemeId && selectedPortraitPackId) {
      fetchPortraitsForTheme(selectedThemeId);
    }
  }, [selectedThemeId, selectedPortraitPackId, portraitsFilters, setSelectablePortraits]);

  // Calculate character schema when possible
  useEffect(() => {
    const updateCharacterBuild = async () => {
      const mergedObj = await calculateRecommendedBuild({
        selectedThemeId,
        characterAge,
        selectedClassId,
        selectedOriginPerkId,
        selectedExperiencePerkIds,
        selectedPersonalityPerkIds,
        selectedFlawPerkId,
        themeConfig,
        characterClasses: characterClasses ?? [],
        characterPerks: characterPerks ?? [],
      });

      setCharacterSchema(mergedObj);
    };

    if (
      selectedThemeId &&
      selectedClassId &&
      characterAge &&
      selectedOriginPerkId &&
      selectedFlawPerkId &&
      selectedExperiencePerkIds.length &&
      selectedPersonalityPerkIds.length
    ) {
      updateCharacterBuild();
    }
  }, [
    selectedThemeId,
    selectedClassId,
    characterAge,
    selectedOriginPerkId,
    selectedExperiencePerkIds,
    selectedPersonalityPerkIds,
    selectedFlawPerkId,
    characterClasses,
    characterPerks,
    themeConfig,
  ]);

  const isSubmitAllowed = !!(
    name &&
    characterAge &&
    selectedThemeId &&
    selectedClassId &&
    selectedPortraitId &&
    selectedOriginPerkId &&
    selectedExperiencePerkIds.length &&
    selectedPersonalityPerkIds.length &&
    selectedFlawPerkId &&
    isCharacterNameAllowed
  );

  const handleSubmit = async () => {
    if (isSubmitAllowed) {
      setIsLoading(true);

      const baseMoney = calculateBaseMoney({
        originPerk: characterPerks.find((perk) => perk.id === selectedOriginPerkId),
        experiencePerks: characterPerks.filter((perk) => selectedExperiencePerkIds.includes(perk.id)),
      });

      const baseHp = calculateBaseHp({
        themeId: selectedThemeId,
        characterAttributes: characterSchema?.attributes ?? {},
      });

      const { data: characterData, error: characterError } = await SupabaseService.from(
        DB_TABLE_NAMES.dbPlayerCharacter,
      )
        .insert([
          {
            name,
            class_id: selectedClassId,
            owner_id: user?.id,
            portrait: `${process.env.REACT_APP_API_URL}/storage/v1/object/public/images/portraits/${selectedThemeId}/${selectedPortraitPackId}/${selectedPortraitId}`,
            app_version: process.env.REACT_APP_VERSION,
            base_money: baseMoney,
            base_health: baseHp,
            base_schema: characterSchema,
          },
        ])
        .select('id');

      if (characterData) {
        queryClient.refetchQueries([QUERY_KEYS.launcher.charactersList]);

        const characterPerks = [
          selectedOriginPerkId,
          selectedFlawPerkId,
          ...selectedExperiencePerkIds,
          ...selectedPersonalityPerkIds,
        ].map((perkId) => ({
          character_id: characterData[0].id,
          perk_id: perkId,
        }));

        const characterItems = selectedItemsIds.map((itemId) => ({
          character_id: characterData[0].id,
          item_id: itemId,
        }));

        await SupabaseService.from(DB_TABLE_NAMES.dbPlayerCharacterPerk).insert(characterPerks);
        await SupabaseService.from(DB_TABLE_NAMES.dbPlayerCharacterItem).insert(characterItems);

        navigate(routes.characters);
      }

      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (currentlyChangedPerkId) {
      if (selectedExperiencePerkIds.includes(currentlyChangedPerkId)) {
        handleExperiencePerkSelection(currentlyChangedPerkId);
      }

      if (selectedPersonalityPerkIds.includes(currentlyChangedPerkId)) {
        handlePersonalityPerkSelection(currentlyChangedPerkId);
      }
    }
  }, [
    currentlyChangedPerkId,
    selectedExperiencePerkIds,
    selectedPersonalityPerkIds,
    handleExperiencePerkSelection,
    handlePersonalityPerkSelection,
  ]);

  return {
    themeApi: {
      themesAsOptions,
      handleThemeSelection,
      selectedThemeId,
      themeConfig,
    },
    characterClassApi: {
      classesAsOptions,
      selectedClassId,
      handleClassSelection,
      selectedClassTkey: `${characterClasses.find((characterClass) => characterClass.id === selectedClassId)?.t_key}`,
    },
    portraitApi: {
      portraitPacksAsOptions,
      selectedPortraitPackId,
      selectablePortraits,
      selectedPortraitId,
      handlePortraitPackSelection,
      handlePortraitSelection,
      portraitsFilters,
      handlePortraitsSexFilter,
      selectedPortraitSrc: selectablePortraits.find((portrait) => portrait.id === selectedPortraitId)?.coverImg,
    },
    characterNameApi: {
      name,
      handleNameChange: (e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value),
      isCharacterNameAllowed,
    },
    characterAgeApi: {
      characterAge,
      handleCharacterAgeSelection,
    },
    characterPerksApi: {
      originPerksAsOptions,
      experiencePerksAsOptions,
      personalityPerksAsOptions,
      flawPerksAsOptions,
      selectedOriginPerkId,
      selectedExperiencePerkIds,
      selectedPersonalityPerkIds,
      selectedFlawPerkId,
      selectedOriginPerk: characterPerks.find((perk) => perk.id === selectedOriginPerkId),
      selectedExperiencePerks: characterPerks.filter((perk) => selectedExperiencePerkIds.includes(perk.id)),
      selectedPersonalityPerks: characterPerks.filter((perk) => selectedPersonalityPerkIds.includes(perk.id)),
      selectedFlawPerk: characterPerks.find((perk) => perk.id === selectedFlawPerkId),
      handleOriginPerkSelection,
      handleExperiencePerkSelection: (perkId: string) => {
        handleExperiencePerkSelection(perkId);
        handleCurrentlyChangedPerkId('');
      },
      handlePersonalityPerkSelection: (perkId: string) => {
        handlePersonalityPerkSelection(perkId);
        handleCurrentlyChangedPerkId('');
      },
      handleFlawPerkSelection,
      currentlyChangedPerkId,
      handleCurrentlyChangedPerkId,
    },
    characterSchema,
    startingItemsApi: {
      itemsAsOptions,
      selectedItems: items.filter((item) => selectedItemsIds.includes(item.id)) ?? [],
      selectedItemsIds,
      handleItemsSelection,
      itemsFilteringOptions,
    },
    keywordsFilter,
    handleKeywordsFilter,
    isSubmitAllowed,
    handleSubmit,
    isLoading: isLoading || isResourcesLoading,
  };
};
