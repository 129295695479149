import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from 'components/generics/Text/Text';
import { ColorButton } from 'components/generics/Button/ColorButton';
import {
  Container,
  OptionHorizontalLayout,
  OptionVerticalLayout,
  ConfirmButtonWrapper,
  CoverImageHorizontalLayout,
  CoverImageVerticalLayout,
} from './FullScreenSelect.styles';

interface FullScreenSelectProps {
  themeId?: string;
  onSelect: (optionId: string) => any;
  options: {
    id: string;
    name: string;
    description: string;
    coverImg?: string;
    keywordsMatched?: string[];
  }[];
  optionLayout: 'horizontal' | 'vertical';
  heading?: string;
  confirmButtonText: string;
  toggleOverlay: () => any;
  customFilters?: React.ReactNode;
  selectedOptionId: string;
  disabledOptionsIds?: string[];
}

export const FullScreenSelect = ({
  themeId,
  onSelect,
  options,
  optionLayout,
  toggleOverlay,
  heading,
  confirmButtonText,
  customFilters,
  selectedOptionId,
  disabledOptionsIds,
}: FullScreenSelectProps) => {
  const { t } = useTranslation('launcher', { keyPrefix: 'fullscreen_select' });
  const { t: tThemesIndex } = useTranslation('themesIndex');

  const [taggedForSelectionId, setTaggedForSelectionId] = useState<string>('');

  const tagForSelection = (optionId: string) =>
    setTaggedForSelectionId((current) => (current === optionId ? '' : optionId));

  const confirmSelection = () => {
    onSelect(taggedForSelectionId);
    toggleOverlay();
  };

  useEffect(() => {
    if (selectedOptionId) {
      setTaggedForSelectionId(selectedOptionId);
    }
  }, [selectedOptionId]);

  return (
    <Container>
      {heading && (
        <Text fontSize="bigger" fontWeight={500}>
          {heading}
        </Text>
      )}
      {customFilters ?? null}
      {optionLayout === 'vertical' &&
        options.map((option) => (
          <OptionVerticalLayout
            key={option.id}
            onClick={() => tagForSelection(option.id)}
            isSelected={taggedForSelectionId === option.id}
          >
            <Text fontSize="big" fontWeight={500}>
              {option.name}
            </Text>
            {option.coverImg && <CoverImageVerticalLayout src={option.coverImg} loading="lazy" />}
            <Text fontSize="small" textAlign="left">
              {option.description}
            </Text>
          </OptionVerticalLayout>
        ))}
      {optionLayout === 'horizontal' &&
        options.map((option) => (
          <OptionHorizontalLayout
            key={option.id}
            onClick={() => tagForSelection(option.id)}
            isSelected={taggedForSelectionId === option.id}
            disabled={disabledOptionsIds?.includes(option.id)}
          >
            {option.coverImg && <CoverImageHorizontalLayout src={option.coverImg} loading="lazy" />}
            <div>
              <Text fontSize="medium" fontWeight={500}>
                {option.name}
              </Text>
              {!!option.keywordsMatched && option.keywordsMatched.length > 0 && themeId && (
                <Text
                  fontSize="smaller"
                  fontWeight={600}
                  textTransform="uppercase"
                  letterSpacing="wide"
                  color="highlightYellow"
                >
                  {t('recommended_label')}{' '}
                  {`(${option.keywordsMatched
                    ?.map((keyword) => tThemesIndex(`${themeId}.keywords.${keyword}` as any))
                    .join(', ')})`}
                </Text>
              )}
              <Text fontSize="small" fontWeight={300} textAlign="left">
                {option.description}
              </Text>
            </div>
          </OptionHorizontalLayout>
        ))}
      <ConfirmButtonWrapper>
        <ColorButton isDisabled={!taggedForSelectionId} onClick={confirmSelection}>
          {confirmButtonText}
        </ColorButton>
      </ConfirmButtonWrapper>
    </Container>
  );
};
