import { useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { SupabaseService } from 'services/SupabaseService';
import { DB_TABLE_NAMES, QUERY_KEYS } from 'utils/consts';

export const useSessionPendingActionsListener = (sessionId: string) => {
  const queryClient = useQueryClient();

  useEffect(() => {
    if (sessionId) {
      // Pending rolls channel
      const pendingRollsChannel = SupabaseService.channel(QUERY_KEYS.channels.pendingActions(sessionId));
      const pendingRollsEventHandler = () => {
        queryClient.invalidateQueries([QUERY_KEYS.gameplay.sessionPendingActions, sessionId]);
      };

      pendingRollsChannel
        .on(
          'postgres_changes',
          {
            event: '*',
            schema: 'public',
            table: DB_TABLE_NAMES.instancePendingAction,
            filter: `session_id=eq.${sessionId}`,
          },
          pendingRollsEventHandler,
        )
        .subscribe();
    }
  }, [sessionId, queryClient]);
};
