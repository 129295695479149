import styled from 'styled-components';
import { pxToRem } from 'utils/styleUtils';

export const Wrapper = styled.main`
  width: 100%;
  min-height: 100vh;
  padding: 0 ${pxToRem(24)};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`;

export const Illustration = styled.img`
  display: block;
  width: ${pxToRem(260)};
`;
