import React, { useMemo } from 'react';
import { v4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { useGameplayNavigation } from 'hooks/useGameplayNavigation';
import { SESSION_VIEW_TYPE, setRollIdToSelect } from 'stores/gameplay.slice';
import { ColorButton } from 'components/generics/Button/ColorButton';
import { Select } from 'components/generics/Select/Select';
import { Text } from 'components/generics/Text/Text';
import { pxToRem } from 'utils/styleUtils';
import { useAppDispatch } from 'stores/store';
import { DICE_ROLL_SCHEMA, DiceType } from 'engine/rolls';
import { Dice } from 'components/Dice/Dice';
import { useRollDice, calculateDiceCount } from './RollDiceView.hooks';
import {
  Board,
  DicesWrapper,
  ResultDiceWrapper,
  RollSchemeWrapper,
  SubmitButtonWrapper,
  TopBar,
  Wrapper,
} from './RollDiceView.styles';

export const RollDiceView = () => {
  const { goToView } = useGameplayNavigation();
  const { t } = useTranslation('gameplay', { keyPrefix: 'roll_dice_view' });
  const { t: tDifficulty } = useTranslation('gameplay', { keyPrefix: 'call_roll_view.difficulty' });
  const dispatch = useAppDispatch();

  const {
    dicesOnBoard,
    addDice,
    removeDice,
    rollDices,
    rollResults,
    dicesAreCast,
    resultsSum,
    calledRolls,
    pendingRolls,
    selectedRoll,
    selectedRollMeta,
    handleRollSelect,
    selectDiceFromSelectedRollSchema,
  } = useRollDice();

  const goToSessionRecord = () => {
    dispatch(setRollIdToSelect({ rollId: undefined }));
    goToView(SESSION_VIEW_TYPE.SESSION_RECORDS);
  };

  const checkIfRollBtnDisabled = () => {
    if (dicesOnBoard.length === 0 || !selectedRoll) return true;

    if (selectedRoll.rollScheme) {
      return (
        calculateDiceCount(selectedRoll.rollScheme) !==
        calculateDiceCount(dicesOnBoard.map((dice) => DICE_ROLL_SCHEMA[dice.type]))
      );
    }

    return false;
  };

  const renderResults = () => {
    const rollType = selectedRollMeta?.rollType ?? 'free_roll';
    const mod = selectedRollMeta?.player_res_modifier ?? null;
    const mp = selectedRollMeta?.player_health_multiplier ?? null;
    const difficultyValue = selectedRollMeta?.difficulty_value ?? null;
    const difficultyLevel = selectedRollMeta?.difficulty_level ?? null;

    return (
      <>
        <br />
        <Text
          textAlign="center"
          fontSize="bigger"
          fontWeight={600}
          color="opaqueWhite75"
          textTransform="uppercase"
          letterSpacing="superWide"
        >
          {t(`roll_types.${rollType}` as any)}
        </Text>
        <Text fontSize="large" textAlign="center" fontWeight={600} textTransform="uppercase">
          {t('roll_results.roll_results_heading')}
        </Text>
        {!!difficultyValue && !!difficultyLevel && (
          <>
            <Text textAlign="center" fontWeight={600} textTransform="uppercase" fontSize="small">
              {t('roll_results.against')}
            </Text>
            <Text
              textAlign="center"
              fontSize="bigger"
              fontWeight={600}
              color={`difficulty${Math.round(difficultyLevel / 2)}`}
            >
              {tDifficulty(`ratings.${difficultyLevel - 1}.label` as any)}: {difficultyValue}
            </Text>
          </>
        )}
        {!!difficultyLevel && !difficultyValue && (
          <Text
            textAlign="center"
            fontSize="big"
            fontWeight={600}
            color={`difficulty${Math.round(difficultyLevel / 2)}`}
          >{`${difficultyLevel}/10 - ${tDifficulty(`ratings.${difficultyLevel - 1}.label` as any)}`}</Text>
        )}
        <Board>
          {rollResults.map((result) => (
            <ResultDiceWrapper key={result.id}>
              <Dice type={result.diceType} width={pxToRem(48)} height={pxToRem(48)} />
              <Text as="p" textAlign="center" fontSize="bigger" fontWeight={600}>
                {result.result}
              </Text>
            </ResultDiceWrapper>
          ))}
        </Board>
        {(!!mod || !!mp) && rollType === 'action_roll' && (
          <Text color="opaqueWhite50" fontWeight={400} fontSize="big" textAlign="center">
            {t('roll_results.base_result')}:{' '}
            {rollResults.reduce((acc: number, res: { diceType: DiceType; result: number }) => acc + res.result, 0)}
          </Text>
        )}
        {!!mod && rollType === 'action_roll' && (
          <Text fontWeight={600} textAlign="center">
            MOD: + {mod}
          </Text>
        )}
        {!!mp && rollType === 'action_roll' && (
          <Text fontWeight={600} textAlign="center">
            {t('roll_results.health_multiplier')}: x{mp}
          </Text>
        )}
        <Text textAlign="center" fontSize="large" fontWeight={600}>
          {t('roll_results.final_result')}: {resultsSum}
        </Text>
        {rollType === 'action_roll' && !!difficultyValue && resultsSum > difficultyValue && (
          <Text
            fontWeight={600}
            fontSize="sectionHeading"
            letterSpacing="superWide"
            textTransform="uppercase"
            textAlign="center"
            color="tertiary"
          >
            {t('roll_results.success')}
          </Text>
        )}
        {rollType === 'action_roll' && !!difficultyValue && resultsSum === difficultyValue && (
          <Text
            fontWeight={600}
            fontSize="sectionHeading"
            letterSpacing="superWide"
            textTransform="uppercase"
            textAlign="center"
            color="highlightYellow"
          >
            {t('roll_results.twist')}
          </Text>
        )}
        {rollType === 'action_roll' && !!difficultyValue && resultsSum < difficultyValue && (
          <Text
            fontWeight={600}
            fontSize="sectionHeading"
            letterSpacing="superWide"
            textTransform="uppercase"
            textAlign="center"
            color="secondary"
          >
            {t('roll_results.failure')}
          </Text>
        )}
        <SubmitButtonWrapper>
          <ColorButton onClick={goToSessionRecord}>{t('close_results').toUpperCase()}</ColorButton>
        </SubmitButtonWrapper>
      </>
    );
  };

  const selectedRollSchema = useMemo(
    () =>
      selectedRoll?.rollScheme?.length
        ? selectedRoll.rollScheme.map(([diceCount, diceType]) => ({
            diceCount,
            diceType,
            id: v4(),
          }))
        : null,
    [selectedRoll],
  );

  const renderRollDice = () => (
    <>
      <TopBar>
        <Select
          isSearchable={false}
          value={selectedRoll}
          onChange={handleRollSelect}
          label={t('roll_heading')}
          options={[...calledRolls, ...pendingRolls, { value: 'free_roll', label: t('roll_types.free_roll') }]}
        />

        {selectedRoll?.rollScheme?.length && (
          <Text fontSize="small" textTransform="uppercase" letterSpacing="superWide" color="opaqueWhite75">
            {t('roll_scheme')}
          </Text>
        )}

        <RollSchemeWrapper onClick={selectDiceFromSelectedRollSchema}>
          {selectedRollSchema?.map(({ diceCount, diceType, id }) => (
            <Text fontSize="small" fontWeight={600} key={id}>
              <Dice type={`D${diceType}` as DiceType} width={pxToRem(18)} height={pxToRem(28)} />
              {diceCount} x{' '}
            </Text>
          ))}
        </RollSchemeWrapper>
      </TopBar>
      <Board>
        {dicesOnBoard.map((dice) => (
          <Dice
            key={dice.id}
            type={dice.type}
            onClick={() => removeDice(dice.id)}
            width={pxToRem(48)}
            height={pxToRem(48)}
          />
        ))}
      </Board>
      <DicesWrapper>
        {['D4', 'D6', 'D8', 'D10', 'D12', 'D20'].map((type) => (
          <Dice
            key={`dice-btn-${type}`}
            type={type as DiceType}
            onClick={() => addDice(type as DiceType)}
            hasBackground
            withLabel
          />
        ))}
      </DicesWrapper>
      <SubmitButtonWrapper>
        <ColorButton isDisabled={checkIfRollBtnDisabled()} onClick={rollDices}>
          {t('roll_dice_button').toUpperCase()}
        </ColorButton>
      </SubmitButtonWrapper>
    </>
  );

  return <Wrapper>{dicesAreCast ? renderResults() : renderRollDice()}</Wrapper>;
};
