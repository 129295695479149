import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from 'components/generics/Text/Text';
import StateSection from 'views/Gameplay/PartyView/sections/StateSection';
import { Container, ContentContainer } from '../../../IngameHandbook.styles';

const EXAMPLE_CHARACTER_STATE = {
  head: {
    currentHp: 100,
    maxHp: 100,
  },
  torso: {
    currentHp: 75,
    maxHp: 100,
  },
  arms: {
    currentHp: 50,
    maxHp: 100,
  },
  legs: {
    currentHp: 25,
    maxHp: 100,
  },
};

export const StateSubsection = () => {
  const { t } = useTranslation('handbook', { keyPrefix: 'party_view.subsections.state' });

  return (
    <ContentContainer>
      <Text fontSize="small" color="opaqueWhite75" fontWeight={500}>
        {t('paragraphs.p1')}
      </Text>
      <Text fontSize="small" color="opaqueWhite75" fontWeight={500}>
        {t('paragraphs.p2')}
      </Text>
      <Text fontSize="small" color="opaqueWhite75" fontWeight={500}>
        {t('paragraphs.p3')}
      </Text>
      <StateSection showLabel={false} showStateCard={false} characterHealth={EXAMPLE_CHARACTER_STATE} />
      <br />
      <Text fontSize="small" color="opaqueWhite75" fontWeight={500}>
        {t('paragraphs.p4')}
      </Text>
      <Container>
        <div>
          <Text color="healthyState" fontWeight={500}>
            {t('health_states.healthy.label')}
          </Text>
          <Text fontSize="small" color="opaqueWhite75" fontWeight={500}>
            {t('health_states.healthy.description')}
          </Text>
        </div>

        <div>
          <Text color="moderateState" fontWeight={500}>
            {t('health_states.slightly_injured.label')}
          </Text>
          <Text fontSize="small" color="opaqueWhite75" fontWeight={500}>
            {t('health_states.slightly_injured.description')}
          </Text>
        </div>

        <div>
          <Text color="injuredState" fontWeight={500}>
            {t('health_states.injured.label')}
          </Text>
          <Text fontSize="small" color="opaqueWhite75" fontWeight={500}>
            {t('health_states.injured.description')}
          </Text>
        </div>

        <div>
          <Text color="badlyState" fontWeight={500}>
            {t('health_states.badly_injured.label')}
          </Text>
          <Text fontSize="small" color="opaqueWhite75" fontWeight={500}>
            {t('health_states.badly_injured.description')}
          </Text>
        </div>

        <div>
          <Text color="criticalState" fontWeight={500}>
            {t('health_states.critically_injured.label')}
          </Text>
          <Text fontSize="small" color="opaqueWhite75" fontWeight={500}>
            {t('health_states.critically_injured.description')}
          </Text>
        </div>

        <div>
          <Text color="eliminatedState" fontWeight={500}>
            {t('health_states.eliminated.label')}
          </Text>
          <Text fontSize="small" color="opaqueWhite75" fontWeight={500}>
            {t('health_states.eliminated.description')}
          </Text>
        </div>
      </Container>
    </ContentContainer>
  );
};
