import React, { useRef } from 'react';
import Draggable, { DraggableData, DraggableEventHandler } from 'react-draggable';
import { mainTheme } from 'assets/styles/theme';
import { SliderContainer, SliderSectionBar, SliderTouchpoint, SliderTouchpoints } from './DifficultySlider.styles';

const SLIDER_SECTIONS = [
  {
    id: 'slider-bar-0',
    color: mainTheme.colors.difficulty1,
  },
  {
    id: 'slider-bar-1',
    color: mainTheme.colors.difficulty2,
  },
  {
    id: 'slider-bar-2',
    color: mainTheme.colors.difficulty3,
  },
  {
    id: 'slider-bar-3',
    color: mainTheme.colors.difficulty4,
  },
  {
    id: 'slider-bar-4',
    color: mainTheme.colors.difficulty5,
  },
];

const DIFFICULTY_LEVELS = [
  {
    id: 1,
  },
  {
    id: 2,
  },
  {
    id: 3,
  },
  {
    id: 4,
  },
  {
    id: 5,
  },
  {
    id: 6,
  },
  {
    id: 7,
  },
  {
    id: 8,
  },
  {
    id: 9,
  },
  {
    id: 10,
  },
];

const MIN_DIFFICULTY_LEVEL = 1;
const MAX_DIFFICULTY_LEVEL = 10;

interface Props {
  selectedDifficultyLevel: number;
  handleSelectedDifficultyLevel: (level: number) => any;
}

export const DifficultySlider = ({ selectedDifficultyLevel, handleSelectedDifficultyLevel }: Props) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  const eventHandler = (e: MouseEvent | TouchEvent, data: DraggableData) => {
    const wrapperWidth = wrapperRef.current?.clientWidth ?? window.innerWidth;
    const step = wrapperWidth / DIFFICULTY_LEVELS.length;

    const steps = Math.round(data.x / step);

    if (selectedDifficultyLevel + steps >= MAX_DIFFICULTY_LEVEL) {
      handleSelectedDifficultyLevel(MAX_DIFFICULTY_LEVEL);
      return;
    }

    if (selectedDifficultyLevel + steps <= MIN_DIFFICULTY_LEVEL) {
      handleSelectedDifficultyLevel(MIN_DIFFICULTY_LEVEL);
      return;
    }

    handleSelectedDifficultyLevel(selectedDifficultyLevel + steps);
  };

  return (
    <SliderContainer ref={wrapperRef}>
      {SLIDER_SECTIONS.map((section) => (
        <SliderSectionBar key={section.id} color={section.color} />
      ))}
      <SliderTouchpoints>
        {DIFFICULTY_LEVELS.map((level) =>
          selectedDifficultyLevel === level.id ? (
            <Draggable
              axis="x"
              onStop={eventHandler as DraggableEventHandler}
              key={`difficulty-level-touchpoint-${level.id}`}
            >
              <SliderTouchpoint
                key={`difficulty-level-touchpoint-${level.id}`}
                onClick={() => handleSelectedDifficultyLevel(level.id)}
                isSelected={selectedDifficultyLevel === level.id}
              />
            </Draggable>
          ) : (
            <SliderTouchpoint
              key={`difficulty-level-touchpoint-${level.id}`}
              onClick={() => handleSelectedDifficultyLevel(level.id)}
              isSelected={false}
            />
          ),
        )}
      </SliderTouchpoints>
    </SliderContainer>
  );
};
