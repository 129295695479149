import React, { useCallback, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { GameplayTopBar } from 'components/GameplayTopBar/GameplayTopBar';
import { GameplaySideMenu } from 'components/GameplaySideMenu/GameplaySideMenu';
import { GameplaySideButton } from 'components/GameplaySideButton/GameplaySideButton';
import { Container, ContentContainer } from './GameplayLayout.styles';

interface GameplayLayoutProps {
  children: React.ReactNode;
  noTopBar?: boolean;
  noSideMenu?: boolean;
}

const GameplayLayout = ({ children, noTopBar, noSideMenu }: GameplayLayoutProps) => {
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);

  const toggleSideMenu = useCallback(() => {
    setIsSideMenuOpen((isOpen) => !isOpen);
  }, []);

  return (
    <Container>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      {!noTopBar && <GameplayTopBar toggleSideMenu={toggleSideMenu} isSideMenuOpen={isSideMenuOpen} />}
      {!noSideMenu && <GameplaySideMenu isOpen={isSideMenuOpen} toggleSideMenu={toggleSideMenu} />}
      <GameplaySideButton />
      <ContentContainer>{children}</ContentContainer>
    </Container>
  );
};
export default GameplayLayout;
