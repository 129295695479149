import styled, { css } from 'styled-components';
import { pxToRem } from 'utils/styleUtils';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  margin: ${pxToRem(16)} 0;
  flex-flow: column nowrap;
  gap: ${pxToRem(16)};
`;

export const PlayerSelectionBox = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 1rem;
  align-items: center;
  justify-content: center;
`;

export const SelectablePlayer = styled.button<{ isSelected: boolean }>`
  position: relative;
  border-radius: ${({ theme }) => theme.border.radiusBase};
  user-select: none;
  cursor: pointer;
  transition-duration: ${({ theme }) => theme.transition.baseDuration};
  border: ${pxToRem(4)} solid transparent;

  img {
    width: ${pxToRem(100)};
    height: ${pxToRem(150)};
    border-radius: ${({ theme }) => theme.border.radiusBase};
  }

  ${({ isSelected, theme }) =>
    isSelected &&
    css`
      border-color: ${theme.colors.primary};
    `}
`;

export const SelectablePlayerName = styled.div`
  position: absolute;
  bottom: 0.05rem;
  left: 0.1rem; ;
`;

export const SubmitButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: ${pxToRem(16)} 0;
`;
