import { useQuery } from '@tanstack/react-query';
import { StoryCardsService } from 'services/StoryCardsService';
import { useGameplaySelector } from 'stores/gameplay.slice';
import { QUERY_KEYS } from 'utils/consts';

export const useStoryCardsAPI = () => {
  const { activeSession } = useGameplaySelector();

  const sessionId = activeSession?.id;

  const storyCardsQuery = useQuery(
    [QUERY_KEYS.gameplay.storyCards, sessionId],
    () => StoryCardsService.getActiveStoryCards({ s_id: sessionId ?? '' }),
    {
      enabled: !!sessionId,
    },
  );

  return {
    storyCardsQuery,
  } as const;
};
