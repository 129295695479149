import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { routes } from 'components/MainRouter/MainRouter';
import { Text } from 'components/generics/Text/Text';
import { ReactComponent as HomeSessions } from 'assets/images/svg/icon_home.svg';
import { ReactComponent as IconSessions } from 'assets/images/svg/icon_session.svg';
import { ReactComponent as IconCharacters } from 'assets/images/svg/icon_players.svg';
import { ReactComponent as IconProfile } from 'assets/images/svg/icon_settings.svg';
import { MenuContainer, MenuItem, TextWrapper } from './LauncherNavMenu.styles';

export const menuItems = {
  home: 'home',
  sessions: 'sessions',
  characters: 'characters',
  profile: 'profile',
} as const;

interface LauncherNavMenuProps {
  activeItem?: keyof typeof menuItems;
}

export const LauncherNavMenu = ({ activeItem }: LauncherNavMenuProps) => {
  const { t } = useTranslation('launcher', { keyPrefix: 'navmenu' });
  const navigate = useNavigate();

  return (
    <MenuContainer>
      <MenuItem isActive={activeItem === menuItems.home} onClick={() => navigate(routes.home)}>
        <HomeSessions />
        <TextWrapper>
          <Text color={activeItem === menuItems.home ? 'primary' : 'opaqueWhite50'} fontSize="small">
            {t('home')}
          </Text>
        </TextWrapper>
      </MenuItem>

      <MenuItem isActive={activeItem === menuItems.sessions} onClick={() => navigate(routes.sessions)}>
        <IconSessions />
        <TextWrapper>
          <Text color={activeItem === menuItems.sessions ? 'primary' : 'opaqueWhite50'} fontSize="small">
            {t('sessions')}
          </Text>
        </TextWrapper>
      </MenuItem>

      <MenuItem isActive={activeItem === menuItems.characters} onClick={() => navigate(routes.characters)}>
        <IconCharacters />
        <TextWrapper>
          <Text color={activeItem === menuItems.characters ? 'primary' : 'opaqueWhite50'} fontSize="small">
            {t('characters')}
          </Text>
        </TextWrapper>
      </MenuItem>

      <MenuItem isActive={activeItem === menuItems.profile} onClick={() => navigate(routes.userSettings)}>
        <IconProfile />
        <TextWrapper>
          <Text color={activeItem === menuItems.profile ? 'primary' : 'opaqueWhite50'} fontSize="small">
            {t('profile')}
          </Text>
        </TextWrapper>
      </MenuItem>
    </MenuContainer>
  );
};
