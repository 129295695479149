import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from 'components/generics/Text/Text';
import { Select } from 'components/generics/Select/Select';
import { ColorButton } from 'components/generics/Button/ColorButton';
import { StoryCardType } from 'models/db/resource_StoryCard';
import { Randomizer } from 'components/Randomizer/Randomizer';
import { StoryCard } from './components/StoryCard/StoryCard';
import { Group, Wrapper } from './DrawStoryCardView.styles';
import { useDrawStoryCardView } from './DrawStoryCardView.hooks';

export const DrawStoryCardView = () => {
  const { fetchedStoryCard, getRandomStoryCard, handleAddToStory, typeOptions, setSelectedType } =
    useDrawStoryCardView();

  const { t } = useTranslation('gameplay', { keyPrefix: 'draw_story_card_view' });

  return (
    <Wrapper>
      <Text as="h2" fontWeight={600} fontSize="big">
        {t('heading')}
      </Text>
      <Group>
        <Select
          isSearchable={false}
          label={t('category_label')}
          options={typeOptions}
          onChange={({ value }: any) => setSelectedType(value as StoryCardType)}
        />
        <Group>{fetchedStoryCard && <StoryCard storyCard={fetchedStoryCard} />}</Group>
        {fetchedStoryCard && <Randomizer label="" value=" " onReroll={getRandomStoryCard} />}
      </Group>
      <br />
      <center>
        <ColorButton isDisabled={!fetchedStoryCard} onClick={handleAddToStory}>
          {t('submit_button')}
        </ColorButton>
      </center>
    </Wrapper>
  );
};
