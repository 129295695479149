import { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

export const Portal = ({ children }: PropsWithChildren) => {
  const ref = useRef<Element | null>(null);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    ref.current = document.querySelector('#portal');
    setIsMounted(true);
  }, []);

  if (ref.current !== null && isMounted) {
    return createPortal(children, ref.current);
  }

  return null;
};
