import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from 'components/generics/Text/Text';
import { Card } from 'components/Card/Card';
import { CharacterPerk } from 'models/db/resource_CharacterPerk';
import { TextGroup, DescriptionCardWrapper } from '../PartyView.styles';

interface AboutSectionProps {
  characterPerks: Omit<CharacterPerk, 'content'>[];
  themeId: string;
}

export const ExpandableDescriptionCard = ({
  description,
  themeId,
  displayLabel = false,
}: {
  description: Omit<CharacterPerk, 'content'>;
  themeId: string;
  displayLabel?: boolean;
}) => {
  const { t } = useTranslation('gameplay', { keyPrefix: 'party_view.about_section' });
  const { t: tCharacterPerks } = useTranslation('characterPerks' as 'resource');

  return (
    <DescriptionCardWrapper key={description.t_key}>
      {displayLabel && (
        <Text
          fontWeight={500}
          color="opaqueWhite50"
          fontSize="smaller"
          letterSpacing="superWide"
          textTransform="uppercase"
        >
          {t(`${description.type}_label` as any)}
        </Text>
      )}
      <Card>
        <Text fontWeight={600}>
          {tCharacterPerks(`${themeId}.${description.type}.${description.t_key}.name` as any)}
        </Text>
        <Text fontSize="small" color="opaqueWhite50">
          {tCharacterPerks(`${themeId}.${description.type}.${description.t_key}.description` as any)}
        </Text>
      </Card>
    </DescriptionCardWrapper>
  );
};

const AboutSection = ({ characterPerks, themeId }: AboutSectionProps) => {
  const descriptionsOrder = ['origin', 'experience', 'personality', 'flaw'];

  const characterPerksSorted = [...characterPerks].sort(
    (a, b) => descriptionsOrder.indexOf(a.type) - descriptionsOrder.indexOf(b.type),
  );

  return (
    <TextGroup>
      {characterPerksSorted.map((description, index) => (
        <ExpandableDescriptionCard
          key={description.id}
          description={description}
          themeId={themeId}
          displayLabel={index === 0 || (index > 0 && characterPerksSorted[index - 1].type !== description.type)}
        />
      ))}
    </TextGroup>
  );
};

export default AboutSection;
