import { useQuery } from '@tanstack/react-query';
import { LocationsService } from 'services/LocationsService';
import { useGameplaySelector } from 'stores/gameplay.slice';
import { QUERY_KEYS } from 'utils/consts';

export const useLocationsAPI = () => {
  const { activeSession } = useGameplaySelector();
  const sessionId = activeSession?.id;

  const activeLocationQuery = useQuery(
    [QUERY_KEYS.gameplay.activeLocation, sessionId],
    () => LocationsService.getActiveLocation({ s_id: sessionId ?? '' }),
    {
      enabled: !!sessionId,
    },
  );

  const activeLocation = activeLocationQuery.data?.data[0];

  const activeLocationPackQuery = useQuery(
    [QUERY_KEYS.gameplay.activeLocationPack, activeLocation?.location_pack_id],
    () => LocationsService.getLocationPack(activeLocation?.location_pack_id ?? ''),
    { enabled: !!activeLocation },
  );

  return {
    activeLocationQuery,
    activeLocationPackQuery,
  } as const;
};
