import { DEFAULT_FONT_SIZE, pxToRem } from 'utils/styleUtils';

export const mainTheme = {
  colors: {
    primary: '#4895EF',
    secondary: '#FF5757',
    tertiary: '#61DC00',
    white: '#FFFFFF',
    black: '#000000',
    bgDarkNavy: '#122B48',
    bgDarkViolet: '#1F041E',
    opaqueWhite5: 'rgba(255, 255, 255, 0.05)',
    opaqueWhite10: 'rgba(255, 255, 255, 0.1)',
    opaqueWhite15: 'rgba(255, 255, 255, 0.15)',
    opaqueWhite25: 'rgba(255, 255, 255, 0.25)',
    opaqueWhite50: 'rgba(255, 255, 255, 0.5)',
    opaqueWhite75: 'rgba(255, 255, 255, 0.75)',
    opaqueBlack25: 'rgba(0, 0, 0, 0.25)',
    opaqueBlack50: 'rgba(0, 0, 0, 0.50)',
    opaqueBlack75: 'rgba(0, 0, 0, 0.75)',
    highlightYellow: '#FAFF00',
    highlightYellowOpaque: 'rgba(250, 255, 0, 0.15)',

    // Difficulty related
    difficulty1: '#6DB5DD',
    difficulty2: '#A1D75C',
    difficulty3: '#E7EA76',
    difficulty4: '#FF8845',
    difficulty5: '#FF4545',

    // Status related
    minorWound: '#FDECB0',
    moderateWound: '#E88464',
    criticalWound: '#FF3D00',
    healthyState: '#7CC662',
    moderateState: '#FDECB0',
    injuredState: '#E88464',
    badlyState: '#FF8A00',
    criticalState: '#FF3D00',
    eliminatedState: 'rgba(255, 65, 128, 0.75)',

    // Inventory related
    rareItem: '#D84BB9',
  },
  fontSizes: {
    tiny: pxToRem(10),
    smaller: pxToRem(12),
    small: pxToRem(14),
    medium: pxToRem(DEFAULT_FONT_SIZE),
    big: pxToRem(20),
    bigger: pxToRem(24),
    large: pxToRem(36),
    sectionHeading: pxToRem(48),
  },
  fonts: {
    main: 'Quicksand, Tahoma, Verdana, sans-serif',
  },
  border: {
    radiusBase: pxToRem(8),
    base: `${pxToRem(2)} solid`,
  },
  transition: {
    baseDuration: '200ms',
  },
  letterSpacing: {
    default: 0,
    wide: '0.05rem',
    superWide: '0.15rem',
  },
  breakpoints: {
    mobile: '480px',
  },
} as const;

export type FontWeight = 300 | 400 | 500 | 600 | 700;

export type CustomTheme = typeof mainTheme;

export const NAVMENU_HEIGHT = '5rem';
