import styled, { css, keyframes } from 'styled-components';
import { pxToRem } from 'utils/styleUtils';
import { SpinnerProps } from './Spinner';

export const Wrapper = styled.div<{ center?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${({ center }) =>
    center &&
    css`
      align-items: center;
    `}
`;

export const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${pxToRem(12)};
`;

const animateRing = keyframes`
0% {
  transform: rotate(0deg);
}
100% {
  transform: rotate(360deg);
}
`;

export const Box = styled.div`
  box-sizing: border-box;
  display: block;
  position: absolute;
  margin: ${pxToRem(8)};
  border-radius: 50%;
  animation: ${animateRing} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
`;

export const Ring = styled.div<Omit<SpinnerProps, 'text'>>`
  display: inline-block;
  position: relative;
  width: ${({ size }) => pxToRem(size === 'small' ? 40 : 80)};
  height: ${({ size }) => pxToRem(size === 'small' ? 40 : 80)};

  & ${Box}:nth-child(1) {
    animation-delay: -0.45s;
  }

  & ${Box}:nth-child(2) {
    animation-delay: -0.3s;
  }

  & ${Box}:nth-child(3) {
    animation-delay: -0.15s;
  }

  & ${Box} {
    border: ${({ theme }) => `${pxToRem(8)} solid ${theme.colors.primary}`};
    border-color: ${({ theme }) => `${theme.colors.primary} transparent transparent transparent`};

    width: ${({ size }) => pxToRem(size === 'small' ? 32 : 64)};
    height: ${({ size }) => pxToRem(size === 'small' ? 32 : 64)};
    border-width: ${({ size }) => pxToRem(size === 'small' ? 4 : 8)};
  }

  ${({ color, theme, size }) =>
    color &&
    css`
      & ${Box} {
        border: ${pxToRem(8)} solid ${color in theme.colors ? theme.colors[color as keyof typeof theme.colors] : color};
        border-color: ${color in theme.colors ? theme.colors[color as keyof typeof theme.colors] : color} transparent
          transparent transparent;
        border-width: ${pxToRem(size === 'small' ? 4 : 8)};
      }
    `}
`;
