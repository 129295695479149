import styled, { css } from 'styled-components';
import { NAVMENU_HEIGHT } from 'assets/styles/theme';
import { pxToRem } from 'utils/styleUtils';

export const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;
  height: 100%;

  input {
    margin-bottom: 1.5rem;
  }
`;

export const SexSelection = styled.div`
  display: flex;
  gap: 2rem;
  justify-content: center;
`;

export const SexSelectionButton = styled.button.attrs({ type: 'button' })<{ isSelected?: boolean }>`
  svg {
    cursor: pointer;
    transition-duration: ${({ theme }) => theme.transition.baseDuration};
    width: ${pxToRem(32)};
    height: ${pxToRem(32)};
    fill: ${({ theme }) => theme.colors.opaqueWhite25};

    &:hover {
      fill: ${({ theme }) => theme.colors.white};
    }
  }

  ${({ isSelected }) =>
    isSelected &&
    css`
      svg {
        fill: ${({ theme }) => theme.colors.white};
      }
    `}
`;

export const SubmitButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: ${NAVMENU_HEIGHT};
  margin-top: 2rem;

  button {
    text-transform: uppercase;
  }
`;

export const PerkSelectionGroup = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 0.5rem;
  margin: 0.75rem 0;
`;

export const PerkSelector = styled.button`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 1rem;
  border: 1px solid ${({ theme }) => theme.colors.opaqueWhite25};
  border-radius: ${({ theme }) => theme.border.radiusBase};
  transition-duration: 200ms;
  text-align: left;

  svg {
    stroke: ${({ theme }) => theme.colors.opaqueWhite50};
    width: ${pxToRem(32)};
    height: ${pxToRem(32)};
  }

  &:hover {
    border-color: ${({ theme }) => theme.colors.white};
    svg {
      stroke: ${({ theme }) => theme.colors.white};
    }
  }
`;

export const AttributeContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;
`;

export const SkillsContainer = styled(AttributeContainer)`
  padding-left: 1.5rem;
`;

export const AttributeTitle = styled.div`
  display: flex;
  flex-flow: row nowrap;
  gap: 1rem;
  align-items: center;

  svg {
    width: ${pxToRem(42)};
    height: ${pxToRem(42)};
  }
`;
