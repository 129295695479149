import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';
import { getDiceImage } from 'engine/rolls';

import { Text } from 'components/generics/Text/Text';
import { Checkbox } from 'components/generics/Checkbox/Checkbox';
import { ColorButton } from 'components/generics/Button/ColorButton';
import { useThemeConfig } from 'hooks/useThemeConfig';

import {
  DicePoolContainer,
  DicePoolItem,
} from '../PartyView/components/ExpandableAttribute/ExpandableAttribute.styles';

import {
  PlayerSelectionBox,
  SelectablePlayer,
  SelectablePlayerName,
  Wrapper,
  SubmitButtonWrapper,
} from './CallRollView.styles';

import { useCallRoll } from './CallRollView.hooks';
import { CharacterSchemaCheckboxes } from './components/CheckboxLists/CharacterSchemaCheckboxes';
import { CharacterItemsCheckboxes } from './components/CheckboxLists/CharacterItemsCheckboxes';
import { CheckboxLabel } from './components/CheckboxLists/CharacterSchemaCheckboxes.styles';
import { DifficultySlider } from './components/DifficultySlider/DifficultySlider';

const getSuccessChanceDescription = (value: number) => {
  if (value < -50) return 'minimal';
  if (value < -25) return 'low';
  if (value < 25) return 'moderate';
  if (value < 100) return 'high';
  if (value >= 100) return 'very_high';
};

export const CallRollView = () => {
  const { t } = useTranslation('gameplay', { keyPrefix: 'call_roll_view' });
  const { sessionPlayers, selectedPlayerAPI, rollBaseAPI, rollExtrasAPI, rollState, isSubmitAllowed, handleCallRoll } =
    useCallRoll();
  const { themeConfig } = useThemeConfig();

  const { selectedPlayer, selectedPlayerId, handleSelectPlayer } = selectedPlayerAPI;
  const selectedPlayerEquippedItems = useMemo(
    () => selectedPlayer?.items.filter((item) => item.is_equipped),
    [selectedPlayer],
  );

  const actionRollSchema = useMemo(
    () => rollState.actionRollSchema.map((dice) => ({ dice, id: v4() })),
    [rollState.actionRollSchema],
  );

  const difficultyRollSchema = useMemo(
    () => rollState.difficultyRollSchema.map((dice) => ({ dice, id: v4() })),
    [rollState.difficultyRollSchema],
  );

  return (
    <Wrapper>
      <Text
        fontWeight={500}
        color="opaqueWhite50"
        fontSize="smaller"
        letterSpacing="superWide"
        textTransform="uppercase"
      >
        {t('player_heading')}
      </Text>
      <PlayerSelectionBox>
        {sessionPlayers.map((player) => (
          <SelectablePlayer
            key={player.character_id}
            isSelected={selectedPlayerId === player.character_id}
            onClick={() => handleSelectPlayer(player.character_id)}
          >
            <img src={player.portrait} alt={player.name} />
            <SelectablePlayerName>
              <Text fontWeight={600} fontSize="smaller" textTransform="uppercase" letterSpacing="wide">
                {player.name}
              </Text>
            </SelectablePlayerName>
          </SelectablePlayer>
        ))}
      </PlayerSelectionBox>
      <br />

      {selectedPlayer && (
        <>
          <Text
            fontWeight={500}
            color="opaqueWhite50"
            fontSize="smaller"
            letterSpacing="superWide"
            textTransform="uppercase"
          >
            {t('attributes_checklist_heading')}
          </Text>
          <CharacterSchemaCheckboxes
            characterId={selectedPlayerId}
            characterSchema={selectedPlayer?.character_schema}
            themeConfig={themeConfig}
            selectedAttributeId={rollBaseAPI.selectedAttributeId}
            selectedSkillId={rollBaseAPI.selectedSkillId}
            handleSelectAttribute={rollBaseAPI.handleSelectAttribute}
            handleSelectSkill={rollBaseAPI.handleSelectSkill}
          />
        </>
      )}
      {selectedPlayerEquippedItems && selectedPlayerEquippedItems?.length > 0 && (
        <>
          <br />
          <Text
            fontWeight={500}
            color="opaqueWhite50"
            fontSize="smaller"
            letterSpacing="superWide"
            textTransform="uppercase"
          >
            {t('inventory_mods_heading')}
          </Text>
          <CharacterItemsCheckboxes
            characterEquippedItems={selectedPlayerEquippedItems}
            selectedInventoryModId={rollExtrasAPI.selectedInventoryModId}
            handleSelectInventoryMod={rollExtrasAPI.handleSelectInventoryMod}
            themeId={themeConfig.themeId}
          />
        </>
      )}
      {selectedPlayerId && (
        <>
          <br />
          <Text
            fontWeight={500}
            color="opaqueWhite50"
            fontSize="smaller"
            letterSpacing="superWide"
            textTransform="uppercase"
          >
            {t('extras.heading')}
          </Text>
          <Checkbox
            isChecked={rollExtrasAPI.rpBonusGranted}
            onClick={rollExtrasAPI.handleToggleRpBonusGranted}
            label={
              <CheckboxLabel>
                <Text fontWeight={600} textTransform="uppercase" letterSpacing="wide">
                  {t('extras.rp_bonus')}
                </Text>
                <DicePoolItem>
                  <img src={getDiceImage('D6')} alt="D6" />
                  <Text>D6</Text>
                </DicePoolItem>
              </CheckboxLabel>
            }
          />
          <br />
          <Text
            fontWeight={500}
            color="opaqueWhite50"
            fontSize="smaller"
            letterSpacing="superWide"
            textTransform="uppercase"
          >
            {t('difficulty.heading')}
          </Text>
          <DifficultySlider
            selectedDifficultyLevel={rollBaseAPI.selectedDifficultyLevel}
            handleSelectedDifficultyLevel={rollBaseAPI.handleSelectDifficultyLevel}
          />
        </>
      )}
      {rollBaseAPI.selectedDifficultyLevel > 0 && (
        <>
          <DicePoolContainer>
            <Text fontWeight={600} fontSize="big">
              {`${rollBaseAPI.selectedDifficultyLevel}/10`}
            </Text>
            <Text
              fontWeight={600}
              fontSize="big"
              color={`difficulty${Math.round(rollBaseAPI.selectedDifficultyLevel / 2)}`}
            >
              {t(`difficulty.ratings.${rollBaseAPI.selectedDifficultyLevel - 1}.label` as any)}
            </Text>
          </DicePoolContainer>
          <Text>{t(`difficulty.ratings.${rollBaseAPI.selectedDifficultyLevel - 1}.description` as any)}</Text>
        </>
      )}

      {rollState.rollMeta && (
        <>
          <br />
          <Text fontWeight={500} letterSpacing="superWide" textTransform="uppercase">
            {t('summary_section.heading')}
          </Text>
          <Text
            fontWeight={500}
            color="opaqueWhite50"
            fontSize="smaller"
            letterSpacing="superWide"
            textTransform="uppercase"
          >
            {t('summary_section.action_roll_heading')}
          </Text>
          <DicePoolContainer>
            {actionRollSchema.map(({ dice, id }) => (
              <DicePoolItem key={id}>
                <img src={getDiceImage(dice as any)} alt="dice" />
                <Text>{dice}</Text>
              </DicePoolItem>
            ))}
            {rollState.inventoryMod > 0 && <Text fontWeight={600}>{`+ ${rollState.inventoryMod}`}</Text>}
          </DicePoolContainer>
          <Text
            fontWeight={500}
            color="opaqueWhite50"
            fontSize="smaller"
            letterSpacing="superWide"
            textTransform="uppercase"
          >
            {t('summary_section.difficulty_roll_heading')}
          </Text>
          <DicePoolContainer>
            {difficultyRollSchema.map(({ dice, id }) => (
              <DicePoolItem key={id}>
                <img src={getDiceImage(dice as any)} alt="dice" />
                <Text>{dice}</Text>
              </DicePoolItem>
            ))}
          </DicePoolContainer>
          <Text
            fontWeight={500}
            color="opaqueWhite50"
            fontSize="smaller"
            letterSpacing="superWide"
            textTransform="uppercase"
          >
            {t('summary_section.chance_of_success.heading')}
          </Text>
          {rollState.rollMeta?.percentageOfDiffMax !== undefined && (
            <Text>
              {t(
                `summary_section.chance_of_success.${getSuccessChanceDescription(
                  rollState.rollMeta?.percentageOfDiffMax,
                )}` as any,
              )}
            </Text>
          )}
        </>
      )}
      <br />
      <SubmitButtonWrapper>
        <ColorButton isDisabled={!isSubmitAllowed} onClick={handleCallRoll}>
          {t('call_roll_button')}
        </ColorButton>
      </SubmitButtonWrapper>
    </Wrapper>
  );
};
