import React from 'react';
import ReactDOM from 'react-dom/client';
import { AppWrapper } from 'components/AppWrapper/AppWrapper';
import reportWebVitals from 'utils/reportWebVitals';
import './i18n';
import './assets/fonts/Quicksand-VariableFont_wght.ttf';
import 'react-toastify/dist/ReactToastify.css';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <AppWrapper />
  </React.StrictMode>,
);

reportWebVitals();
