import styled, { css } from 'styled-components';

export const StyledGameplaySideButton = styled.button<{
  isToggled?: boolean;
}>`
  width: 3rem;
  height: 3rem;
  z-index: 2000;

  position: fixed;
  right: -1.2rem;
  top: 10%;
  border-radius: 100%;
  border: 0.1rem solid #4895ef;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
  filter: drop-shadow(0 0.15rem 0.15rem rgba(0, 0, 0, 0.75));

  transition: transform 0.2s ease-in-out;

  svg {
    fill: ${({ theme }) => theme.colors.white};
    width: 1.5rem;
    height: 1.5rem;
  }

  ${({ isToggled }) =>
    isToggled &&
    css`
      transform: translateX(-100%) translateY(-100%);
    `}
`;

export const ButtonBackground = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(#4895ef, #fff);
  opacity: 0.25;
  border-radius: 100%;
`;
