import styled, { css } from 'styled-components';

export const Container = styled.div<{ isEquipped?: boolean }>`
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  padding: 0.75rem;
  border-radius: ${({ theme }) => theme.border.radiusBase};
  border: 1px solid ${({ theme }) => theme.colors.opaqueWhite50};

  ${({ isEquipped }) =>
    isEquipped &&
    css`
      border: 2px solid ${({ theme }) => theme.colors.primary};
    `}
`;

export const IsEquippedLabel = styled.div`
  position: absolute;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 0.5rem;
  bottom: 0;
  right: -0.2rem;

  svg {
    fill: ${({ theme }) => theme.colors.primary};
  }
`;

export const KeywordsWrapper = styled.div`
  margin-bottom: 0.25rem;
`;
