import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from 'components/generics/Text/Text';
import { ReactComponent as IconCopy } from 'assets/images/svg/icon_copy.svg';
import { copyToClipboard as copyToClipboardUtil } from 'utils/copyToClipboard';
import { Container, Field } from './CopyableField.styles';

interface CopyableFieldProps {
  label: string;
  value: string;
}

export const CopyableField = ({ label, value }: CopyableFieldProps) => {
  const [copiedNotification, setCopiedNotification] = useState(false);
  const { t } = useTranslation('launcher', { keyPrefix: 'copyable_field' });

  const copyToClipboard = () => {
    setCopiedNotification(true);
    setTimeout(() => setCopiedNotification(false), 1000);
    copyToClipboardUtil(value);
  };

  return (
    <Container>
      <Text
        fontWeight={500}
        color="opaqueWhite50"
        fontSize="smaller"
        letterSpacing="superWide"
        textTransform="uppercase"
      >
        {label}
      </Text>
      <Field onClick={copyToClipboard}>
        {copiedNotification ? t('copied_to_clipboard') : value}
        <IconCopy />
      </Field>
    </Container>
  );
};
