import { DB_TABLE_NAMES } from 'utils/consts';
import { GetUserSessionsParams, GetUserSessionsReturn } from 'models/functions/GetUserSessions';
import { GetSessionByIdParams, GetSessionByIdReturn } from 'models/functions/GetSessionById';
import { SessionRecord } from 'models/db/db_SessionRecord';
import { PendingAction } from 'models/db/instance_PendingAction';
import { Session } from 'models/db/db_Session';
import { HttpService } from './HttpService';

export const SessionService = {
  getUserSessions: ({ querying_user_id }: GetUserSessionsParams) =>
    HttpService.get<GetUserSessionsReturn>(`rest/v1/rpc/get_user_sessions?querying_user_id=${querying_user_id}`),
  getSessionData: ({ s_id }: GetSessionByIdParams) =>
    HttpService.get<GetSessionByIdReturn>(`rest/v1/rpc/get_session_by_id?s_id=${s_id}`),
  getSessionRecords: (sessionId: string, from: number, to: number) =>
    HttpService.get<SessionRecord[]>(
      `rest/v1/${DB_TABLE_NAMES.dbSessionRecord}?session_id=eq.${sessionId}&order=created_at.desc`,
      {
        headers: {
          Range: `${from.toString()}-${to.toString()}`,
        },
      },
    ).then((response) => ({ ...response, data: response.data.reverse() })),
  getSessionPendingActions: (sessionId: string) =>
    HttpService.get<PendingAction[]>(
      `rest/v1/${DB_TABLE_NAMES.instancePendingAction}?session_id=eq.${sessionId}&order=created_at.asc`,
    ),
  checkAccessCode: (accessCode: string) =>
    HttpService.get<Session[]>(`rest/v1/${DB_TABLE_NAMES.dbSession}?access_code=eq.${accessCode}`),
};
