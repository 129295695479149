import { SessionService } from 'services/SessionService';
import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useUserSelector } from 'stores/user.slice';
import { QUERY_KEYS } from 'utils/consts';

export function useSessionsView() {
  const { user } = useUserSelector();

  const {
    data: sessions,
    isLoading: areSessionsLoading,
    isError: isSessionsError,
    refetch,
  } = useQuery(
    [QUERY_KEYS.launcher.sessionsList],
    () => SessionService.getUserSessions({ querying_user_id: user?.id ?? '' }),
    {
      enabled: !!user,
    },
  );

  useEffect(() => {
    if (isSessionsError && user?.id) {
      refetch();
    }
  }, [isSessionsError, user?.id, refetch]);

  return {
    sessions: sessions?.data ?? [],
    areSessionsLoading,
    isSessionsError,
    currentUserId: user?.id ?? '',
  } as const;
}
