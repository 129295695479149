import { DB_TABLE_NAMES } from 'utils/consts';
import { LocationPack } from 'models/db/resource_LocationPack';
import { Location } from 'models/db/resource_Location';
import { GetActiveLocationParams, GetActiveLocationReturn } from 'models/functions/GetActiveLocation';
import { HttpService } from './HttpService';

export const LocationsService = {
  getLocationPacks: (themeId: string) =>
    HttpService.get<LocationPack[]>(`rest/v1/${DB_TABLE_NAMES.resourceLocationPack}?theme_id=eq.${themeId}`),
  getLocations: (locationPackId: string) =>
    HttpService.get<Location[]>(`rest/v1/${DB_TABLE_NAMES.resourceLocation}?location_pack_id=eq.${locationPackId}`),
  getStartingLocations: (locationPackId: string) =>
    HttpService.get<Location[]>(
      `rest/v1/${DB_TABLE_NAMES.resourceLocation}?location_pack_id=eq.${locationPackId}&is_starting_location=eq.true`,
    ),
  getActiveLocation: ({ s_id }: GetActiveLocationParams) =>
    HttpService.get<GetActiveLocationReturn>(`/rest/v1/rpc/get_active_location?s_id=${s_id}`),
  getLocationPack: (id: string) =>
    HttpService.get<LocationPack[]>(`rest/v1/${DB_TABLE_NAMES.resourceLocationPack}?id=eq.${id}`),
};
