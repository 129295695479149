import styled from 'styled-components';
import { pxToRem } from 'utils/styleUtils';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const TopBar = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${pxToRem(6)};
  margin-top: 0.75rem;
`;

export const RollSchemeWrapper = styled.button`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  gap: 1rem;
  user-select: none;
  cursor: pointer;
`;

export const Board = styled.div`
  width: 100%;
  margin: ${pxToRem(32)} 0;
  min-height: ${pxToRem(50)};
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: center;
  gap: ${pxToRem(16)};
`;

export const ResultDiceWrapper = styled.div`
  position: relative;

  p {
    bottom: -0.7rem;
    left: 40%;
    position: absolute;
    text-shadow: 0.05rem 0.05rem 0.2rem black, 0.05rem 0.05rem 0.1rem black;
    filter: drop-shadow(0.05rem 0.05rem 0.2rem black);
  }
`;

export const DicesWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, max-content);
  grid-template-rows: repeat(2, max-content);
  justify-content: center;
  justify-items: space-between;
  align-items: center;
  grid-gap: ${pxToRem(16)};
`;

export const SubmitButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
