import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as GoogleIcon } from 'assets/images/svg/logo-google.svg';
import { ReactComponent as FacebookIcon } from 'assets/images/svg/logo-facebook.svg';
import { routes } from 'components/MainRouter/MainRouter';
import {
  Container,
  ContentWrapper,
  ErrorBar,
  SocialMediaButtons,
  Cta,
} from 'components/generics/Form/AuthLayout/AuthLayout.styles';
import { Spinner } from 'components/generics/Spinner/Spinner';
import { Helmet } from 'components/Helmet/Helmet';
import { useRedirectIfLogged } from 'hooks/useRedirectIfLogged';
import { useOnlyDevelopment } from 'hooks/useOnlyDevelopment';
import { Text } from 'components/generics/Text/Text';
import { ColorButton } from 'components/generics/Button/ColorButton';
import Divider from 'components/Divider/Divider';
import { TextInput } from 'components/generics/Form/TextInput/TextInput';
import { PasswordInput } from 'components/generics/Form/PasswordInput/PasswordInput';
import { REGISTER_FORM_STEP, useRegisterView } from './RegisterView.hooks';

export const RegisterView = () => {
  const { t } = useTranslation('launcher', { keyPrefix: 'register_view' });
  const {
    formik: { values, touched, errors, handleChange, handleSubmit, handleBlur },
    formMutation,
    registerViaSSO,
    formStep,
  } = useRegisterView();

  useRedirectIfLogged();
  useOnlyDevelopment();

  return (
    <>
      <Helmet>
        <title>{t('title')}</title>
      </Helmet>
      <Container>
        <ContentWrapper>
          {formStep === REGISTER_FORM_STEP.SUCCESS ? (
            <>
              {/* // TODO: Replace when typography component is made and this screen is designed */}
              <h1>{t('success_heading')}</h1>
              <Text color="opaqueWhite50">{t('success_text')}</Text>
              <Cta to={routes.login} tabIndex={0}>
                {t('log_in_cta')}
              </Cta>
            </>
          ) : (
            <>
              <Text color="secondary" textAlign="center">
                DEVELOPMENT ONLY
              </Text>
              <Text color="opaqueWhite50">{t('social_media_sign_up_prompt')}</Text>
              <SocialMediaButtons>
                <button type="button" onClick={() => registerViaSSO('google')}>
                  <GoogleIcon />
                </button>
                <button type="button" onClick={() => registerViaSSO('facebook')}>
                  <FacebookIcon />
                </button>
              </SocialMediaButtons>
              <Divider text={t('divider_label')} />
              <form onSubmit={handleSubmit} noValidate>
                <TextInput
                  value={values.displayName}
                  name="displayName"
                  label={t('label_displayname')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.displayName ? errors.displayName : undefined}
                />
                <TextInput
                  value={values.email}
                  name="email"
                  label={t('label_email')}
                  placeholder="your@email.com"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.email ? errors.email : undefined}
                />
                <PasswordInput
                  value={values.password}
                  name="password"
                  label={t('label_password')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.password ? errors.password : undefined}
                />
                <PasswordInput
                  value={values.confirmPassword}
                  name="confirmPassword"
                  label={t('label_confirm_password')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.confirmPassword ? errors.confirmPassword : undefined}
                />
                <br />
                {formMutation.isError && (
                  <ErrorBar>
                    <Text color="secondary">{t('error_message')}</Text>
                  </ErrorBar>
                )}
                {formMutation.isLoading ? <Spinner /> : <ColorButton type="submit">{t('submit_button')}</ColorButton>}
              </form>
              <Text textAlign="center" color="opaqueWhite50">
                {t('log_in_prompt')}
              </Text>
              <Cta to={routes.login} tabIndex={0}>
                {t('log_in_cta')}
              </Cta>
            </>
          )}
        </ContentWrapper>
      </Container>
    </>
  );
};
