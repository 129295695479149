import styled from 'styled-components';
import { pxToRem } from 'utils/styleUtils';
import { ReactComponent as IconSwap } from 'assets/images/svg/icon_swap_horizontal.svg';

export const Selector = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  width: fit-content;
  align-self: center;

  svg {
    stroke: ${({ theme }) => theme.colors.opaqueWhite50};
  }

  &:hover {
    svg {
      stroke: ${({ theme }) => theme.colors.white};
    }
  }
`;

export const SelectorPromptIcon = styled(IconSwap)`
  position: absolute;
  bottom: 5%;
  right: 5%;
  z-index: 100;
`;

export const SelectedPortrait = styled.img<{ isSelected: boolean }>`
  width: ${pxToRem(200)};
  height: ${pxToRem(300)};
  position: relative;
  border-radius: ${({ theme }) => theme.border.radiusBase};
  filter: drop-shadow(${pxToRem(4)} ${pxToRem(4)} ${pxToRem(4)} rgba(0, 0, 0, 0.5));
  border: ${({ isSelected, theme }) => !isSelected && `${pxToRem(3)} solid ${theme.colors.white}`};
`;

export const PortraitHintWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
