import { useState, useMemo } from 'react';

const CHARACTER_NAME_MIN_LENGTH = 0;
const CHARACTER_NAME_MAX_LENGTH = 21;

const checkIfCharacterNameIsValid = (input: string): boolean => {
  const regex = /^[A-Za-z]+$/;

  return regex.test(input) && input.length > CHARACTER_NAME_MIN_LENGTH && input.length < CHARACTER_NAME_MAX_LENGTH;
};

export const useMainForm = () => {
  // Form State
  const [name, setName] = useState('');
  const [characterAge, setCharacterAge] = useState('');
  const [selectedThemeId, setSelectedThemeId] = useState('');
  const [selectedClassId, setSelectedClassId] = useState('');
  const [selectedPortraitPackId, setSelectedPortraitPackId] = useState<string>('');
  const [selectedPortraitId, setSelectedPortraitId] = useState('');
  const [selectedOriginPerkId, setSelectedOriginPerkId] = useState('');
  const [selectedExperiencePerkIds, setSelectedExperiencePerkIds] = useState<string[]>([]);
  const [selectedPersonalityPerkIds, setSelectedPersonalityPerkIds] = useState<string[]>([]);
  const [selectedFlawPerkId, setSelectedFlawPerkId] = useState('');

  const isCharacterNameAllowed = useMemo(() => checkIfCharacterNameIsValid(name), [name]);

  // Form state handlers
  const handleCharacterAgeSelection = (newAge: string) => {
    setCharacterAge(newAge);
    setSelectedExperiencePerkIds([]);
  };
  const handleThemeSelection = (themeId: string) => setSelectedThemeId(themeId);
  const handleClassSelection = (classId: string) => setSelectedClassId(classId);
  const handlePortraitPackSelection = (portraitPackId: string) => setSelectedPortraitPackId(portraitPackId);
  const handlePortraitSelection = (portraitId: string) => setSelectedPortraitId(portraitId);

  const handleOriginPerkSelection = (perkId: string) => setSelectedOriginPerkId(perkId);
  const handleExperiencePerkSelection = (perkId: string) =>
    setSelectedExperiencePerkIds((prevIds) =>
      prevIds.includes(perkId) ? prevIds.filter((id) => id !== perkId) : [...prevIds, perkId],
    );
  const handlePersonalityPerkSelection = (perkId: string) =>
    setSelectedPersonalityPerkIds((prevIds) =>
      prevIds.includes(perkId) ? prevIds.filter((id) => id !== perkId) : [...prevIds, perkId],
    );
  const handleFlawPerkSelection = (perkId: string) => setSelectedFlawPerkId(perkId);

  return {
    name,
    setName,
    isCharacterNameAllowed,
    characterAge,
    selectedThemeId,
    selectedClassId,
    selectedPortraitPackId,
    selectedPortraitId,
    selectedOriginPerkId,
    selectedExperiencePerkIds,
    selectedPersonalityPerkIds,
    selectedFlawPerkId,
    handleCharacterAgeSelection,
    handleThemeSelection,
    handleClassSelection,
    handlePortraitPackSelection,
    handlePortraitSelection,
    handleOriginPerkSelection,
    handleExperiencePerkSelection,
    handlePersonalityPerkSelection,
    handleFlawPerkSelection,
  } as const;
};
