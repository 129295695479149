import styled, { css } from 'styled-components';
import { reshade } from 'utils/styleUtils';

export const ButtonShared = styled.button`
  max-width: 100%;
  width: fit-content;
  height: fit-content;
  padding: 0.75rem 1.25rem;
  display: flex;
  gap: 0.5rem;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  font: inherit;
  font-family: inherit;
  font-size: 1.15rem;
  font-weight: 700;
  background-color: transparent;
  border: none;
  border-radius: ${({ theme }) => theme.border.radiusBase};
  transition-duration: ${({ theme }) => theme.transition.baseDuration};
  cursor: pointer;
`;

export interface ButtonProps {
  color?: 'primary' | 'secondary' | 'tertiary';
  outline?: boolean;
  children?: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => any;
  type?: 'button' | 'submit' | 'reset';
  isDisabled?: boolean;
}

export const ColorButtonContainer = styled(ButtonShared)<ButtonProps>`
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme, color }) => color && theme.colors[color]};

  &:hover {
    background-color: ${({ theme, color }) => color && reshade(theme.colors[color], 30)};
  }

  ${({ outline, color }) =>
    outline &&
    css`
      color: ${({ theme }) => color && theme.colors[color]};
      background-color: transparent;
      border: ${({ theme }) => color && `${theme.border.base} ${theme.colors[color]}`};

      &:hover {
        color: ${({ theme }) => theme.colors.white};
      }
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: #949494;
      color: #00000080;
      cursor: not-allowed;

      &:hover {
        background-color: #949494;
      }
    `}
`;
