import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';
import { useQueryClient } from '@tanstack/react-query';
import { SESSION_VIEW_TYPE, useGameplaySelector } from 'stores/gameplay.slice';
import { StoryCardsService } from 'services/StoryCardsService';
import { SupabaseService } from 'services/SupabaseService';
import { DB_TABLE_NAMES, QUERY_KEYS } from 'utils/consts';
import { useGameplayNavigation } from 'hooks/useGameplayNavigation';
import { StoryCardType } from 'models/db/resource_StoryCard';
import { GetRandomStoryCardRow } from 'models/functions/GetRandomStoryCard';
import { OmitAutoGenerated } from 'models/Api';
import { StoryCard } from 'models/db/instance_StoryCard';
import { useLocationsAPI } from 'hooks/sessionApi/useLocationsAPI';

export const useDrawStoryCardView = () => {
  const { t } = useTranslation('gameplay', { keyPrefix: 'story_view' });
  const queryClient = useQueryClient();
  const { activeSession } = useGameplaySelector();
  const { goToView } = useGameplayNavigation();

  const { activeLocationQuery } = useLocationsAPI();
  const activeLocation = activeLocationQuery.data?.data?.[0];

  const [selectedType, setSelectedType] = useState<StoryCardType | undefined>(undefined);
  const [fetchedStoryCard, setFetchedStoryCard] = useState<GetRandomStoryCardRow | undefined>(undefined);

  const typeOptions = useMemo(() => {
    const types: { value: StoryCardType | 'all'; label: string }[] = [
      { value: 'event', label: t('story_cards_options.events') },
      { value: 'task', label: t('story_cards_options.tasks') },
      { value: 'encounter', label: t('story_cards_options.encounters') },
      { value: 'threat', label: t('story_cards_options.threats') },
    ];

    return types.map((type) => ({
      value: type.value,
      label: type.label,
    }));
  }, [t]);

  const getRandomStoryCard = () => {
    if (!activeLocation?.id) return;
    if (!selectedType) return;

    StoryCardsService.getRandomStoryCard({
      l_id: activeLocation?.id,
      sc_type: selectedType,
      prev_sc_id: fetchedStoryCard?.id || v4(),
    }).then((res) => {
      setFetchedStoryCard(res.data[0]);
    });
  };

  const handleAddToStory = async () => {
    if (!fetchedStoryCard) return;

    await SupabaseService.from(DB_TABLE_NAMES.instanceStoryCard).insert<OmitAutoGenerated<StoryCard>>([
      {
        session_id: activeSession?.id,
        story_card_id: fetchedStoryCard?.id,
      },
    ]);

    queryClient.refetchQueries([QUERY_KEYS.gameplay.storyCards, activeSession?.id]);

    goToView(SESSION_VIEW_TYPE.STORY);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(getRandomStoryCard, [activeLocation?.id, selectedType]);

  return {
    fetchedStoryCard,
    getRandomStoryCard,
    setSelectedType,
    typeOptions,
    handleAddToStory,
  } as const;
};
