import { PlayersService } from 'services/PlayersService';
import { useQuery } from '@tanstack/react-query';
import { setActivePlayer, useGameplaySelector } from 'stores/gameplay.slice';
import { useCallback, useEffect } from 'react';
import { useAppSelector } from 'stores/store';
import { useDispatch } from 'react-redux';
import { QUERY_KEYS } from 'utils/consts';
import { useIsGameMaster } from 'hooks/useIsGameMaster';

export const useSessionPlayersAPI = () => {
  const dispatch = useDispatch();
  const { user } = useAppSelector((state) => state.user);
  const { isGameMaster } = useIsGameMaster();
  const { activeSession, activePlayer } = useGameplaySelector();

  const sessionId = activeSession?.id;

  const sessionPlayersQuery = useQuery(
    [QUERY_KEYS.gameplay.sessionPlayers, sessionId],
    () => PlayersService.getPlayersForSession({ s_id: sessionId ?? '' }),
    {
      enabled: !!sessionId,
    },
  );

  // GETTERS
  const getActivePlayerCharacter = useCallback(() => {
    const sessionPlayers = sessionPlayersQuery.data?.data ?? [];

    if (sessionPlayers.length > 0 && activePlayer && !isGameMaster) {
      const player = sessionPlayers.find((player) => player.owner_id === user?.id);

      return player;
    }
  }, [activePlayer, sessionPlayersQuery.data?.data, user?.id, isGameMaster]);

  const getPlayerCharacterById = useCallback(
    (characterId: string) => {
      const sessionPlayers = sessionPlayersQuery.data?.data ?? [];

      if (sessionPlayers.length > 0) {
        const player = sessionPlayers.find((player) => player.character_id === characterId);

        return player;
      }
    },
    [sessionPlayersQuery.data?.data],
  );

  useEffect(() => {
    const sessionPlayers = sessionPlayersQuery.data?.data ?? [];

    if (sessionPlayers.length > 0 && !activePlayer) {
      const player = sessionPlayers.find((player) => player.owner_id === user?.id);

      if (player) {
        dispatch(
          setActivePlayer({
            user_id: player.owner_id,
            character_id: player.character_id,
            session_fingerprint: player.fingerprint,
          }),
        );
      } else {
        dispatch(
          setActivePlayer({
            user_id: user?.id ?? '',
            character_id: '',
            session_fingerprint: '',
          }),
        );
      }
    }
  }, [activePlayer, dispatch, sessionPlayersQuery.data?.data, user?.id]);

  return {
    sessionPlayersQuery,
    getters: {
      getActivePlayerCharacter,
      getPlayerCharacterById,
    },
  } as const;
};
