import styled, { css } from 'styled-components';
import { pxToRem } from 'utils/styleUtils';

export const Container = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;
  overflow: hidden;
`;

export const OptionsList = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 1rem;
  justify-content: center;
  margin-bottom: 5rem;
`;

export const Option = styled.button<{ isSelected?: boolean; previewPositionX?: () => number }>`
  width: ${pxToRem(100)};
  height: ${pxToRem(150)};
  border-radius: ${({ theme }) => theme.border.radiusBase};

  cursor: pointer;
  transition-duration: ${({ theme }) => theme.transition.baseDuration};

  ${({ isSelected, previewPositionX }) =>
    isSelected &&
    previewPositionX &&
    css`
      outline: ${pxToRem(3)} solid ${({ theme }) => theme.colors.primary};
      transform: scale(2) translate(${previewPositionX}rem, 0);
      z-index: 500;
    `}

  img {
    width: 100%;
    height: 100%;
    border-radius: ${({ theme }) => theme.border.radiusBase};
  }
`;

export const ConfirmButtonWrapper = styled.div`
  position: fixed;
  bottom: 6rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10001;

  button {
    text-transform: uppercase;
  }
`;

export const SubheadingWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 1rem;
  justify-content: center;
`;
