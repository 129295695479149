import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SESSION_VIEW_TYPE, useGameplaySelector } from 'stores/gameplay.slice';
import { Select } from 'components/generics/Select/Select';
import { ColorButton } from 'components/generics/Button/ColorButton';
import { useGameplayNavigation } from 'hooks/useGameplayNavigation';
import { SupabaseService } from 'services/SupabaseService';
import { DB_TABLE_NAMES } from 'utils/consts';
import { TextInput } from 'components/generics/Form/TextInput/TextInput';
import { Text } from 'components/generics/Text/Text';
import { pxToRem } from 'utils/styleUtils';
import { OmitAutoGenerated } from 'models/Api';
import { PlayerItem } from 'models/db/instance_PlayerItem';
import { SessionRecord } from 'models/db/db_SessionRecord';
import { Item, ItemRarity, ItemType } from 'models/db/resource_Item';
import { GetPlayersForSessionRow } from 'models/functions/GetPlayersForSession';
import { getThemeConfigForThemeId } from 'engine/themesConfig';
import { Option } from '../../ManagementView';
import { Wrapper, Centerer, MoneyChangeContainer, ButtonsWrapper } from '../../ManagementView.styles';

interface ItemManagementProps {
  selectedCharacter: GetPlayersForSessionRow | undefined;
}

export const ItemManagement = ({ selectedCharacter }: ItemManagementProps) => {
  const { t } = useTranslation('gameplay', { keyPrefix: 'management_view' });
  const { t: tItemMeta } = useTranslation('gameplay', { keyPrefix: 'character_inventory' });

  const { activeSession } = useGameplaySelector();
  const [selectedItemType, setSelectedItemType] = React.useState<ItemType | undefined>(undefined);
  const [selectedItemRarity, setSelectedItemRarity] = React.useState<ItemRarity | undefined>(undefined);
  const { goToView } = useGameplayNavigation();

  const themeConfig = useMemo(() => getThemeConfigForThemeId(activeSession?.theme_id ?? ''), [activeSession?.theme_id]);

  const [selectableItems, setSelectableItems] = React.useState<Item[]>([]);
  const [selectedItemId, setSelectedItemId] = React.useState<string>('');

  const [quantity, setQuantity] = React.useState<string>('1');

  const handleSelectItem = (instance_id: string) => setSelectedItemId(instance_id);

  let inputError: string | undefined;
  if (Number(quantity) < 1 || Number(quantity) > 999) {
    inputError = 'Value must be between 1 and 99';
  }

  useEffect(() => {
    const fetchItems = async () => {
      const { data: resourceItemDataNotTyped } = await SupabaseService.from(DB_TABLE_NAMES.resourceItem)
        .select('*')
        .eq('theme_id', activeSession?.theme_id)
        .eq('type', selectedItemType)
        .eq('rarity', selectedItemRarity);

      const resourceItemData = resourceItemDataNotTyped as Item[];

      setSelectableItems(resourceItemData);
    };

    if (activeSession?.theme_id && selectedItemType && selectedItemRarity) {
      fetchItems();
    }
  }, [activeSession?.theme_id, selectedItemType, selectedItemRarity]);

  const handleConfirm = async () => {
    if (activeSession?.id && selectedItemId && selectedItemType && !inputError && quantity) {
      await SupabaseService.from(DB_TABLE_NAMES.instancePlayerItem).insert<OmitAutoGenerated<PlayerItem>>([
        {
          session_id: activeSession?.id,
          session_player_id: selectedCharacter?.fingerprint,
          item_id: selectedItemId,
          quantity: Number(quantity),
        },
      ]);

      await SupabaseService.from(DB_TABLE_NAMES.dbSessionRecord).insert<OmitAutoGenerated<SessionRecord>>([
        {
          player_character_id: undefined,
          session_id: activeSession?.id,
          record_type: 'item_obtained',
          content: {
            character_id: selectedCharacter?.character_id ?? '',
            item_type: selectedItemType,
            item_t_key: selectableItems.find((item) => item.id === selectedItemId)?.t_key ?? '',
            item_rarity: selectedItemRarity,
            quantity: Number(quantity),
          },
        },
      ]);
    }

    goToView(SESSION_VIEW_TYPE.SESSION_RECORDS);
  };

  const itemTypeOptions = [{ value: 'weapon', label: tItemMeta('types.weapon') }];

  const itemRarityOptions = [
    { value: 'plain', label: tItemMeta('rarity.plain') },
    { value: 'unique', label: tItemMeta('rarity.unique') },
  ];

  return (
    <Wrapper>
      <Select
        label={t('grant_item.item_type_label')}
        options={itemTypeOptions}
        onChange={(newValue) => setSelectedItemType((newValue as Option).value as ItemType)}
        isSearchable={false}
      />
      <Select
        label={t('grant_item.rarity_label')}
        options={itemRarityOptions}
        onChange={(newValue) => setSelectedItemRarity((newValue as Option).value as ItemRarity)}
        isSearchable={false}
      />
      {selectedItemId && (
        <Centerer>
          <MoneyChangeContainer>
            <TextInput
              fixedWidth={pxToRem(64)}
              type="text"
              value={quantity}
              onChange={(event) => {
                // Allow only numbers - number type input allowed other values such as minus sign
                if (event.target.value === '' || /^\d+$/.test(event.target.value)) {
                  setQuantity(event.target.value);
                }
              }}
              min={1}
              max={99}
              error={inputError ? '' : undefined}
            />
          </MoneyChangeContainer>
          {inputError && (
            <Text color="secondary" fontSize="small">
              {inputError}
            </Text>
          )}
        </Centerer>
      )}
      <ButtonsWrapper>
        <ColorButton
          onClick={handleConfirm}
          isDisabled={!selectedItemId || !selectedItemType || !quantity || !!inputError}
        >
          <Text fontSize="smaller" textTransform="uppercase">
            {t('confirm_button')}
          </Text>
        </ColorButton>
      </ButtonsWrapper>
    </Wrapper>
  );
};
