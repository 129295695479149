/* eslint-disable camelcase */
import { useEffect } from 'react';
import { routes } from 'components/MainRouter/MainRouter';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { TokenService } from 'services/TokenService';
import { useRedirectIfLogged } from 'hooks/useRedirectIfLogged';
import { useAppDispatch } from 'stores/store';
import { getUserProfileThunk } from 'stores/user.thunks';
import { getEnv } from 'utils/getEnv';

export const OAuthSuccessView = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();

  useRedirectIfLogged();

  useEffect(() => {
    const getExternalAuthToken = () =>
      setTimeout(() => {
        const externalOauthCredentials = localStorage.getItem(getEnv('REACT_APP_SB_AUTH_TOKEN_NAME'));

        if (!externalOauthCredentials) {
          throw new Error('No credentials found');
        }

        const { access_token: accessToken, refresh_token: refreshToken } =
          JSON.parse(externalOauthCredentials ?? '') ?? {};
        if (accessToken && refreshToken) {
          TokenService.accessToken.setToken(accessToken);
          TokenService.refreshToken.setToken(refreshToken);
          localStorage.removeItem(getEnv('REACT_APP_SB_AUTH_TOKEN_NAME'));
        }

        dispatch(getUserProfileThunk());

        navigate(searchParams.get('next') ?? routes.home);
      }, 1000);

    getExternalAuthToken();
  }, [navigate, dispatch, searchParams]);

  return null;
};
