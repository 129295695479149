import React from 'react';
import { useGameplayNavigation } from 'hooks/useGameplayNavigation';
import { CallRollSessionRecord } from 'models/db/db_SessionRecord';
import { useTranslation } from 'react-i18next';
import { usePendingActionsAPI } from 'hooks/sessionApi/usePendingActionsAPI';
import { SESSION_VIEW_TYPE, setRollIdToSelect, useGameplaySelector } from 'stores/gameplay.slice';
import { useAppDispatch } from 'stores/store';
import { useUserSelector } from 'stores/user.slice';
import { Text } from 'components/generics/Text/Text';
import { v4 } from 'uuid';
import { DiceType, getDiceImage, organizeResultsByDiceType } from 'engine/rolls';
import { GetPlayersForSessionRow } from 'models/functions/GetPlayersForSession';
import {
  DiceMiniature,
  LogCharacterAvatar,
  MiniatureHeader,
  Record,
  RecordRow,
  RecordRowsWrapper,
} from '../RecordsView.styles';

interface CallRollRecordProps {
  isGm: boolean;
  record: CallRollSessionRecord;
  playerCharacter: GetPlayersForSessionRow | undefined;
}

export const CallRollRecord = ({ isGm, record, playerCharacter }: CallRollRecordProps) => {
  const { t } = useTranslation('gameplay');
  const { t: tThemesIndex } = useTranslation('themesIndex');

  const dispatch = useAppDispatch();
  const { user } = useUserSelector();
  const { goToView, redirectToCharacter } = useGameplayNavigation();
  const { activeSession } = useGameplaySelector();

  const { pendingActionsQuery } = usePendingActionsAPI();
  const pendingActions = pendingActionsQuery.data?.data ?? [];

  const wasAlreadyRolled =
    pendingActions.find((action) => action.content.roll_identifier === record.content.rollId) === undefined;

  const isHighlightedToRoll = user?.id === playerCharacter?.owner_id && !wasAlreadyRolled;

  const resultsByDiceType = organizeResultsByDiceType(record.content.results);

  const redirectToRoll = (rollId: string) => {
    dispatch(setRollIdToSelect({ rollId }));
    goToView(SESSION_VIEW_TYPE.ROLL_DICE);
  };

  return (
    <Record
      isGm={isGm}
      isHighlighted={isHighlightedToRoll}
      as={isHighlightedToRoll ? 'button' : 'div'}
      onClick={isHighlightedToRoll ? () => redirectToRoll(record.content.rollId) : undefined}
    >
      <MiniatureHeader>
        {playerCharacter?.character_id && playerCharacter.portrait && (
          <button type="button" onClick={() => redirectToCharacter(playerCharacter?.character_id)}>
            <LogCharacterAvatar src={playerCharacter?.portrait} loading="lazy" />
          </button>
        )}
        <Text color={isGm ? 'highlightYellow' : 'white'} fontWeight={700} textTransform="uppercase">
          {t('session_records_view.call_roll')}
        </Text>
      </MiniatureHeader>
      <Text fontSize="small" fontWeight={700} textTransform="uppercase" letterSpacing="superWide">
        {playerCharacter?.name}
      </Text>
      <Text fontSize="small" fontWeight={600} color="opaqueWhite75">
        {tThemesIndex(`${activeSession?.theme_id}.character_attributes.${record.content.attribute}.title` as any)}
        {record.content.skill &&
          ` > ${tThemesIndex(`${activeSession?.theme_id}.character_skills.${record.content.skill}.title` as any)}`}
      </Text>
      <Text
        fontSize="small"
        fontWeight={600}
        color={
          record.content.difficultyLevel === 0
            ? 'opaqueWhite50'
            : `difficulty${Math.round(record.content.difficultyLevel / 2)}`
        }
      >
        {t('session_records_view.difficulty')}:{' '}
        {t(`call_roll_view.difficulty.ratings.${record.content.difficultyLevel - 1}.label` as any)}
      </Text>
      <RecordRowsWrapper>
        {Object.entries(resultsByDiceType).map(([diceType, results]) => (
          <RecordRow key={`call-roll-res-${diceType}-${v4()}`}>
            <Text fontSize="small">{diceType}</Text>
            <DiceMiniature src={getDiceImage(diceType as DiceType)} alt={diceType} loading="lazy" />
            <Text fontSize="small">{results.join(', ')}</Text>
          </RecordRow>
        ))}
      </RecordRowsWrapper>
      <br />
      <Text fontWeight={600}>
        {t('session_records_view.total')}: {record.content.resultsSum}
      </Text>
    </Record>
  );
};
