import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from 'components/generics/Text/Text';
import { ContentContainer } from '../../../IngameHandbook.styles';

export const CurrentLocationSubsection = () => {
  const { t } = useTranslation('handbook', { keyPrefix: 'story_view.subsections.locations' });

  return (
    <ContentContainer>
      <Text fontSize="small" color="opaqueWhite75" fontWeight={500}>
        {t('p1')}
      </Text>
      <Text fontSize="small" color="opaqueWhite75" fontWeight={500}>
        {t('p2')}
      </Text>
      <Text fontSize="small" color="opaqueWhite75" fontWeight={500}>
        {t('p3')}
      </Text>
      <Text fontSize="small" color="opaqueWhite75" fontWeight={500}>
        {t('p4')}
      </Text>
      <br />
    </ContentContainer>
  );
};
