import { CharactersService } from 'services/CharactersService';
import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useUserSelector } from 'stores/user.slice';
import { QUERY_KEYS } from 'utils/consts';

export function useCharactersView() {
  const { user } = useUserSelector();

  const {
    data: characters,
    isLoading: areCharactersLoading,
    isError: isCharactersError,
    refetch,
  } = useQuery(
    [QUERY_KEYS.launcher.charactersList],
    () => CharactersService.getUserCharacters({ u_id: user?.id ?? '' }),
    {
      enabled: !!user,
    },
  );

  useEffect(() => {
    if (isCharactersError && user?.id) {
      refetch();
    }
  }, [isCharactersError, user?.id, refetch]);

  return {
    characters: characters?.data ?? [],
    areCharactersLoading,
    isCharactersError,
  } as const;
}
