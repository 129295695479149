import React from 'react';
import { Container, CheckboxButton } from './Checkbox.styles';

interface CheckboxProps {
  label: React.ReactNode;
  onClick: () => any;
  isChecked: boolean;
}

export const Checkbox = ({ label, onClick, isChecked }: CheckboxProps) => (
  <Container>
    <CheckboxButton onClick={onClick} isChecked={isChecked} />
    {label}
  </Container>
);
