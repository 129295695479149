import { useEffect, useRef } from 'react';

export const useEventListener = (eventName: keyof WindowEventMap, handler: (event?: any) => any, element = window) => {
  const savedHandler = useRef<(event?: any) => any>();

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    const isSupported = element && element.addEventListener;
    if (!isSupported) return;

    const eventListener = (event: Event) => (savedHandler.current ? savedHandler.current(event) : undefined);

    element.addEventListener(eventName, eventListener);

    return () => {
      element.removeEventListener(eventName, eventListener);
    };
  }, [eventName, element]);
};
