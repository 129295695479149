import React, { useCallback, useState } from 'react';
import { CollapsibleContainer, CollapsibleContent, CollapsibleLabel, DropdownArrowIcon } from './Collapsible.styles';

interface CollapsibleProps {
  label: React.ReactNode;
  children: React.ReactNode;
  expandedByDefault?: boolean;
}

export const Collapsible = ({ label, children, expandedByDefault }: CollapsibleProps) => {
  const [isExpanded, setIsExpanded] = useState(false || expandedByDefault);

  const toggle = useCallback(() => setIsExpanded((prev) => !prev), []);

  return (
    <CollapsibleContainer>
      <CollapsibleLabel onClick={toggle}>
        {label} <DropdownArrowIcon isExpanded={isExpanded} />
      </CollapsibleLabel>
      <CollapsibleContent isExpanded={isExpanded}>{children}</CollapsibleContent>
    </CollapsibleContainer>
  );
};
