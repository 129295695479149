import { useGameplaySelector } from 'stores/gameplay.slice';
import { useAppSelector } from 'stores/store';

export const useIsGameMaster = () => {
  const { user } = useAppSelector((state) => state.user);
  const { activeSession } = useGameplaySelector();

  return {
    isGameMaster: user?.id === activeSession?.owner_id ?? false,
  } as const;
};
