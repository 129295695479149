import React from 'react';
import { Text } from 'components/generics/Text/Text';
import { ReactComponent as IconSwap } from 'assets/images/svg/icon_swap_horizontal.svg';
import { Container, Selector } from './FullScreenSelector.styles';

interface FullScreenSelectorProps {
  onSelect: () => any;
  selectedOption?: {
    id: string;
    name: string;
  };
  label?: string;
  placeholder: string;
}

export const FullScreenSelector = ({ selectedOption, onSelect, label, placeholder }: FullScreenSelectorProps) => (
  <Container>
    <Text fontWeight={500} color="opaqueWhite50" fontSize="smaller" letterSpacing="superWide" textTransform="uppercase">
      {label}
    </Text>
    <Selector onClick={onSelect}>
      {selectedOption ? selectedOption?.name : <Text color="opaqueWhite50">{placeholder}</Text>}
      <IconSwap />
    </Selector>
  </Container>
);
