import { getThemeConfigForThemeId } from 'engine/themesConfig';

interface Props {
  themeId: string;
  characterAttributes: {
    [key: string]: number;
  };
}

export const calculateBaseHp = ({ themeId, characterAttributes }: Props) => {
  const themeConfig = getThemeConfigForThemeId(themeId);

  let rawTotalHp = 0;

  themeConfig.healthCalculationPattern.forEach((attr) => {
    rawTotalHp += characterAttributes[attr] * 50;
  });

  const baseHp = {
    head: rawTotalHp * 0.1,
    torso: rawTotalHp * 0.4,
    arms: rawTotalHp * 0.2,
    legs: rawTotalHp * 0.3,
  };

  return baseHp;
};
