import React, { PropsWithChildren } from 'react';
import { CustomTheme, FontWeight } from 'assets/styles/theme';
import { TextComponent } from './Text.styles';

export type HeadingElement = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

export type TextElement = HeadingElement | 'p' | 'span';

export interface TextProps {
  as?: TextElement;
  textAlign?: 'start' | 'end' | 'left' | 'right' | 'center' | 'justify' | 'match-parent';
  fontSize?: keyof CustomTheme['fontSizes'];
  fontWeight?: FontWeight;
  color?: keyof CustomTheme['colors'] | string;
  textTransform?: 'none' | 'capitalize' | 'uppercase' | 'lowercase' | 'full-width';
  letterSpacing?: keyof CustomTheme['letterSpacing'];
  textDecoration?: 'none' | 'underline' | 'line-through';
}

export const Text = ({ children, as = 'p', color = 'white', ...rest }: PropsWithChildren<TextProps>) => (
  <TextComponent as={as} color={color} {...rest}>
    {children}
  </TextComponent>
);
