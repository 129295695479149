import styled, { css } from 'styled-components';
import { pxToRem } from 'utils/styleUtils';
import { ReactComponent as HeadAsset } from 'assets/images/health_model/head.svg';
import { ReactComponent as TorsoAsset } from 'assets/images/health_model/torso.svg';
import { ReactComponent as ArmsAsset } from 'assets/images/health_model/arms.svg';
import { ReactComponent as LegsAsset } from 'assets/images/health_model/legs.svg';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
`;

export const SectionsWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: ${pxToRem(12)};
`;

export const Section = styled.div<{ backgroundColor?: string }>`
  display: flex;
  flex-direction: column;
  padding: ${pxToRem(6)} ${pxToRem(8)};
  background-color: ${({ backgroundColor }) => backgroundColor || 'transparent'};
  border: ${pxToRem(1)} solid ${({ theme }) => theme.colors.opaqueWhite50};
  border-radius: ${pxToRem(8)};
`;

export const Model = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: ${pxToRem(190)};
  width: ${pxToRem(78)};
`;

interface BodyModelProps {
  color: string;
}

const sharedModelStyles = (color: string) => css`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  & path,
  ellipse {
    fill: ${color};
  }
`;

export const HeadModel = styled(HeadAsset)<BodyModelProps>`
  ${({ color }) => sharedModelStyles(color)}
  top: 0;

  width: ${pxToRem(20)};
  height: ${pxToRem(30)};
`;

export const TorsoModel = styled(TorsoAsset)<BodyModelProps>`
  ${({ color }) => sharedModelStyles(color)}
  top: ${pxToRem(26)};

  width: ${pxToRem(40)};
  height: ${pxToRem(80)};
`;

export const ArmsModel = styled(ArmsAsset)<BodyModelProps>`
  ${({ color }) => sharedModelStyles(color)}
  top: ${pxToRem(32)};

  width: ${pxToRem(81)};
  height: ${pxToRem(79)};
`;

export const LegsModel = styled(LegsAsset)<BodyModelProps>`
  ${({ color }) => sharedModelStyles(color)}
  top: ${pxToRem(76)};

  width: ${pxToRem(48)};
  height: ${pxToRem(115)};
`;
