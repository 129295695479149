import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { GetUserCharactersReturn } from 'models/functions/GetUserCharacters';
import { BlandButton } from 'components/generics/Button/BlandButton';
import { Text } from 'components/generics/Text/Text';
import { routes } from 'components/MainRouter/MainRouter';
import { CharactersListItem } from './CharactersListItem';
import { ButtonsWrapper, Divider, ListContainer, NoCharactersWrapper, SectionContainer } from './CharactersList.styles';

export const CharactersList = ({ characters }: { characters: GetUserCharactersReturn }) => {
  const navigate = useNavigate();
  const { t } = useTranslation('launcher', { keyPrefix: 'characters_view' });

  return (
    <SectionContainer>
      <ListContainer>
        {characters.length === 0 && (
          <NoCharactersWrapper>
            <Text textAlign="center" color="opaqueWhite75">
              {t('no_characters')}
            </Text>
          </NoCharactersWrapper>
        )}
        {characters.map((character) => (
          <CharactersListItem
            key={character.id}
            name={character.name}
            portrait={character.portrait}
            class_t_key={character.class_t_key}
            theme_t_key={character.theme_t_key}
            app_version={character.app_version}
          />
        ))}
      </ListContainer>
      <ButtonsWrapper>
        <Divider />
        <BlandButton onClick={() => navigate(routes.createCharacter)}>
          <Text as="span" color="primary" fontWeight={700} fontSize="smaller">
            +
          </Text>
          <Text as="span" fontWeight={700} fontSize="smaller">
            {t('create_new_character')}
          </Text>
        </BlandButton>
      </ButtonsWrapper>
    </SectionContainer>
  );
};
