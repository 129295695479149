import { useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { SupabaseService } from 'services/SupabaseService';
import { DB_TABLE_NAMES, QUERY_KEYS } from 'utils/consts';

export const useSessionPlayersListener = (sessionId: string) => {
  const queryClient = useQueryClient();

  useEffect(() => {
    // Session players channel
    const sessionPlayersChannel = SupabaseService.channel(QUERY_KEYS.channels.sessionPlayers(sessionId));
    const sessionPlayersEventHandler = () => {
      // Can add further optimization here by leveraging optimistic query data updates
      // might reduce server load
      // for now invalidate the query is good enough
      queryClient.invalidateQueries([QUERY_KEYS.gameplay.sessionPlayers, sessionId]);
    };

    sessionPlayersChannel
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: DB_TABLE_NAMES.instanceSessionPlayer,
          filter: `session_id=eq.${sessionId}`,
        },
        sessionPlayersEventHandler,
      )
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: DB_TABLE_NAMES.instancePlayerItem,
          filter: `session_id=eq.${sessionId}`,
        },
        sessionPlayersEventHandler,
      )
      .subscribe();
  }, [sessionId, queryClient]);
};
