import styled from 'styled-components';
import { BlandButton } from 'components/generics/Button/BlandButton';

export const TabButtonsGroup = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 0.5rem;

  button {
    padding: 0.25rem 0.5rem;
    font-size: ${({ theme }) => theme.fontSizes.small};
  }
`;

export const TabButton = styled(BlandButton)<{ active?: boolean }>`
  color: ${({ theme, active }) => (active ? theme.colors.white : theme.colors.opaqueWhite50)};
  border-color: ${({ theme, active }) => (active ? theme.colors.white : theme.colors.opaqueWhite50)};
`;
