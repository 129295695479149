import styled from 'styled-components';
import { pxToRem } from 'utils/styleUtils';

export const CardWrapper = styled.div<{ as: 'button' | 'div' }>`
  display: flex;
  flex-direction: column;
  border: ${({ theme }) => `${pxToRem(3)} solid ${theme.colors.black}`};
  border-radius: ${pxToRem(8)};
  box-shadow: ${({ theme }) => `0 ${pxToRem(4)} ${pxToRem(4)} ${theme.colors.opaqueBlack50}`};
  background: ${({ theme }) => theme.colors.opaqueBlack50};
  cursor: ${({ as }) => (as === 'button' ? 'pointer' : 'initial')};
  overflow: hidden;
`;

export const CardHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${pxToRem(4)};
  background-color: ${({ theme }) => theme.colors.black};
  padding-bottom: ${pxToRem(3)};
`;

export const CardContent = styled.div<{ noPadding?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: ${({ noPadding }) => (noPadding ? 'initial' : `${pxToRem(12)} ${pxToRem(16)}`)};
`;
