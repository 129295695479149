import { CharacterSchema } from 'models/db/db_PlayerCharacter';

// Maximum and minimum level for skills(!)
// Attributes share maximum level with skills
// Minimum level of the attribute is 1 though
// For the time being we don't have any perks that would decrease attribute level
// So it's safe to assume that attribute will never be lower than 1
// But it's something worth keeping in mind in case we introduce decreasing perks in the future
const MAXIMUM_LEVEL = 5;
const MINIMUM_LEVEL = 0;

export const mergeObjects = (baseObj: CharacterSchema, immutableObj: CharacterSchema): CharacterSchema => {
  const mergedObj = {
    attributes: { ...baseObj.attributes },
    skills: { ...baseObj.skills },
  };

  const mergeProperty = (property: keyof CharacterSchema) => {
    if (immutableObj[property]) {
      Object.keys(immutableObj[property]).forEach((key) => {
        if (mergedObj[property][key] === undefined) {
          mergedObj[property][key] = immutableObj[property][key];
        } else {
          mergedObj[property][key] += immutableObj[property][key];
        }

        if (mergedObj[property][key] < MINIMUM_LEVEL) {
          mergedObj[property][key] = MINIMUM_LEVEL;
        }

        if (mergedObj[property][key] > MAXIMUM_LEVEL) {
          mergedObj[property][key] = MAXIMUM_LEVEL;
        }
      });
    }
  };

  mergeProperty('attributes');
  mergeProperty('skills');

  return mergedObj;
};
