import { TOPBAR_HEIGHT } from 'components/GameplayTopBar/GameplayTopBar.styles';
import styled from 'styled-components';
import { pxToRem } from 'utils/styleUtils';

export const Container = styled.div`
  height: 100svh;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
`;

export const ContentContainer = styled.main`
  width: 100%;
  max-width: ${({ theme }) => theme.breakpoints.mobile};
  padding: 0 ${pxToRem(8)};
  height: calc(100svh - ${pxToRem(TOPBAR_HEIGHT)});
  overflow-y: auto;
`;
