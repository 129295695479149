import { TOPBAR_HEIGHT } from 'components/GameplayTopBar/GameplayTopBar.styles';
import styled, { css } from 'styled-components';
import { pxToRem } from 'utils/styleUtils';

export const Wrapper = styled.div`
  min-height: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
`;

export const ContentWrapper = styled.div`
  padding: ${pxToRem(6)} 0;
  display: flex;
  flex-direction: column;
  gap: ${pxToRem(16)};
  overflow-y: auto;
  width: 100%;
  height: calc(100svh - ${pxToRem(TOPBAR_HEIGHT + 62)});
  position: relative;

  * {
    word-break: break-word;
  }
`;

export const BottomBar = styled.div`
  width: 100%;
  position: relative;
  max-width: ${({ theme }) => theme.breakpoints.mobile};
  padding: ${pxToRem(6)};
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  align-items: flex-end;
`;

export const SendButton = styled.button`
  width: 2.5rem;
  margin-bottom: 0.3rem;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
    svg {
      fill: ${({ theme }) => theme.colors.opaqueWhite50};
    }
  }

  svg {
    width: ${pxToRem(24)};
    height: ${pxToRem(24)};
    fill: ${({ theme }) => theme.colors.primary};
  }
`;

interface RecordProps {
  isMe?: boolean;
  isGm?: boolean;
  isHighlighted?: boolean;
}

export const Record = styled.div<RecordProps>`
  padding: ${pxToRem(12)};
  position: relative;
  background-color: ${({ theme }) => theme.colors.opaqueWhite15};
  border-radius: ${pxToRem(8)};
  white-space: pre-line;

  ${({ isGm }) =>
    isGm &&
    css`
      background-color: ${({ theme }) => theme.colors.highlightYellowOpaque};
    `}

  ${({ isHighlighted }) =>
    isHighlighted &&
    css`
      border: 2px solid ${({ theme }) => theme.colors.highlightYellow};
      text-align: start;
      cursor: pointer;
    `}
`;

export const RecordRowsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0;
  gap: 0.5rem;
`;

export const RecordRow = styled.div`
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  gap: 0.2rem;
`;

export const RecordItem = styled.div`
  display: flex;
  align-items: center;
`;

export const DiceMiniature = styled.img`
  width: ${pxToRem(18)};
  height: ${pxToRem(18)};
`;

export const MiniatureHeader = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
  width: 75%;
`;

export const AbilityMiniature = styled.img`
  width: ${pxToRem(48)};
  height: ${pxToRem(48)};
  border-radius: ${pxToRem(8)};
`;

export const RecordContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const RecordContentRow = styled.div`
  display: flex;
  align-items: center;
  gap: ${pxToRem(6)};
`;

export const LogCharacterAvatar = styled.img`
  width: ${pxToRem(36)};
  height: ${pxToRem(54)};
  border-radius: ${pxToRem(8)};
  box-shadow: ${({ theme }) => `${pxToRem(4)} ${pxToRem(4)} ${pxToRem(4)} 0 ${theme.colors.opaqueBlack50}`};
`;

export const LocationImage = styled.img`
  width: 100%;
  max-height: 10rem;
  aspect-ratio: 16 / 9;
  object-fit: cover;
  border-radius: ${pxToRem(16)};
`;

export const TextRecordWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0;
  gap: 0.5rem;
`;

export const TextRecordRow = styled.div`
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  gap: 0.2rem;
`;

export const TextRecordItem = styled.div`
  display: flex;
  align-items: center;
`;

export const RecordsGroup = styled.div<RecordProps>`
  display: flex;
  gap: ${pxToRem(4)};
  align-items: flex-end;

  ${({ isMe }) =>
    isMe &&
    css`
      flex-direction: row-reverse;
    `}
`;

export const RecordAuthorButton = styled.button`
  display: flex;
  align-items: flex-end;
  border: none;
  outline: none;
`;

export const RecordAuthorImage = styled.img`
  width: ${pxToRem(36)};
  height: ${pxToRem(54)};
  border-radius: ${({ theme }) => theme.border.radiusBase};
`;

export const GmAuthor = styled.div`
  width: ${pxToRem(36)};
  height: ${pxToRem(54)};
  border-radius: ${({ theme }) => theme.border.radiusBase};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.highlightYellowOpaque};

  svg {
    width: ${pxToRem(24)};
    height: ${pxToRem(24)};
    fill: ${({ theme }) => theme.colors.highlightYellow};
  }
`;

export const RecordsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${pxToRem(4)};
  width: 70%;
`;

export const BottomElement = styled.div`
  position: relative;
`;

export const ShowScrollThreshold = styled.div<{ threshold: number }>`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: ${({ threshold }) => `${threshold}px`};
`;

export const ScrollDownButton = styled.button`
  width: ${pxToRem(48)};
  height: ${pxToRem(48)};
  border-radius: 50%;
  background-color: #f00;
  position: absolute;
  top: -${pxToRem(74)};
  left: 50%;
  transform: translateX(-50%);
  background-color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;

  & svg {
    width: ${pxToRem(24)};
    height: ${pxToRem(24)};
    transform: rotate(-90deg) translateX(-2px);
  }
`;
