import React from 'react';
import { useTranslation } from 'react-i18next';
import LauncherLayout from 'components/LauncherLayout/LauncherLayout';
import { Helmet } from 'components/Helmet/Helmet';
import { Spinner } from 'components/generics/Spinner/Spinner';
import { menuItems } from 'components/LauncherNavMenu/LauncherNavMenu';
import { useSessionsView } from './SessionsView.hooks';
import SessionList from './SessionList/SessionList';

export const SessionsView = () => {
  const { t } = useTranslation('launcher', { keyPrefix: 'sessions_view' });
  const { sessions, areSessionsLoading, currentUserId } = useSessionsView();

  return (
    <>
      <Helmet>
        <title>{t('title')}</title>
      </Helmet>
      <LauncherLayout activeItem={menuItems.sessions}>
        {areSessionsLoading ? <Spinner /> : <SessionList sessions={sessions} currentUserId={currentUserId} />}
      </LauncherLayout>
    </>
  );
};
