import styled, { css } from 'styled-components';
import { pxToRem } from 'utils/styleUtils';
import { ReactComponent as PLFlagAsset } from 'assets/images/flags/pl.svg';
import { ReactComponent as ENFlagAsset } from 'assets/images/flags/en.svg';
import { ReactComponent as GoogleAsset } from 'assets/images/svg/logo-google.svg';
import { ReactComponent as FacebookAsset } from 'assets/images/svg/logo-facebook.svg';
import { ReactComponent as DiscordAsset } from 'assets/images/svg/logo-discord.svg';

export const UserSettingsViewContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;
  flex: 1;
  height: 100%;
`;

export const SubForm = styled.form`
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;
  margin-bottom: 2rem;

  button {
    margin-left: auto;
  }
`;

export const LogoutButtonWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: end;
  flex: 1;
  gap: 0.5rem;
  padding-bottom: 3rem;
`;

export const LanguageButtonsGroup = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 1rem;

  button {
    padding: 0.5rem;
    font-size: 1rem;
    display: flex;
    align-items: center;
  }
`;

const sharedFlagStyles = css`
  height: ${pxToRem(16)};
  width: ${pxToRem(24)};
  border-radius: ${pxToRem(2)};
`;

export const PLFlag = styled(PLFlagAsset)`
  ${sharedFlagStyles}
`;

export const ENFlag = styled(ENFlagAsset)`
  ${sharedFlagStyles}
`;

export const SSOGroup = styled.div`
  display: flex;
  gap: ${pxToRem(6)};
  align-items: center;
`;

const sharedSSOStyles = css`
  height: ${pxToRem(20)};
  width: ${pxToRem(20)};
`;

export const GoogleIcon = styled(GoogleAsset)`
  ${sharedSSOStyles}
`;

export const FacebookIcon = styled(FacebookAsset)`
  ${sharedSSOStyles}
`;

export const DiscordIcon = styled(DiscordAsset)`
  ${sharedSSOStyles}
`;

export const DeleteAccountButton = styled.button`
  font: inherit;
  display: flex;
  align-items: center;
  gap: 0.3rem;
  border-radius: 2rem;
  align-self: start;
  padding: 0.2rem 0.5rem;
  border: ${({ theme }) => `${pxToRem(2)} solid ${theme.colors.secondary}`};
  cursor: pointer;
`;

export const ContactMailLink = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.highlightYellow};
  font-weight: bold;
  text-align: center;
`;
