import styled, { css } from 'styled-components';
import { pxToRem } from 'utils/styleUtils';

export const Container = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 1rem;
`;

export const CheckboxButton = styled.button<{ isChecked: boolean }>`
  border-radius: ${({ theme }) => theme.border.radiusBase};
  border: ${pxToRem(1)} solid ${({ theme }) => theme.colors.opaqueWhite25};
  background-color: ${({ theme }) => theme.colors.opaqueBlack25};
  height: 2rem;
  width: 2rem;
  cursor: pointer;

  ${({ isChecked }) =>
    isChecked &&
    css`
      background-color: ${({ theme }) => theme.colors.opaqueWhite50};
    `}
`;
