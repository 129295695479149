import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Record } from '../RecordsView.styles';

const blink = keyframes`
  0% {
    opacity: .1;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: .1;
  }
`;

export const TypingIndicator = styled.div`
  position: relative;
  padding-bottom: 1rem;

  & span {
    content: '';
    animation: ${blink} 1.5s infinite;
    animation-fill-mode: both;
    height: 0.5rem;
    width: 0.5rem;
    background: ${({ theme }) => theme.colors.white};
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;

    &:nth-child(2) {
      animation-delay: 0.2s;
      margin-left: 0.75rem;
    }

    &:nth-child(3) {
      animation-delay: 0.4s;
      margin-left: 1.5rem;
    }
  }
`;

interface TypingIndicatorRecordProps {
  isGm?: boolean;
}

export const TypingIndicatorRecord = ({ isGm }: TypingIndicatorRecordProps) => (
  <Record isGm={isGm}>
    <TypingIndicator>
      <span />
      <span />
      <span />
    </TypingIndicator>
  </Record>
);
