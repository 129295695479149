import styled from 'styled-components';

export const Container = styled.form`
  margin: 0 auto;
  max-width: 35rem;
  padding: 2rem;
  min-height: 100vh;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  gap: 4rem;
`;

export const HeadingWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;
