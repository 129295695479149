import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from 'components/generics/Select/Select';
import { useSessionPlayersAPI } from 'hooks/sessionApi/useSessionPlayersAPI';
import { Wrapper } from './ManagementView.styles';
import { HealthManagement } from './components/HealthManagement/HealthManagement';
import { MoneyManagement } from './components/MoneyManagement/MoneyManagement';
import { ItemManagement } from './components/ItemManagement/ItemManagement';

export type Option = { value: string; label: string };
type Action = 'health' | 'money' | 'grant_item' | undefined;

export const ManagementView = () => {
  const { t } = useTranslation('gameplay', { keyPrefix: 'management_view' });
  const { sessionPlayersQuery } = useSessionPlayersAPI();
  const sessionPlayers = useMemo(() => sessionPlayersQuery.data?.data ?? [], [sessionPlayersQuery.data]);

  const [selectedCharacterId, setSelectedCharacterId] = useState<string | undefined>(undefined);
  const [selectedAction, setSelectedAction] = useState<Action>(undefined);

  const actionsOptions: Option[] = useMemo(
    () => [
      { value: 'health', label: t('actions.manage_health') },
      { value: 'money', label: t('actions.manage_money') },
      // { value: 'grant_item', label: t('actions.grant_item') },
    ],
    [t],
  );

  const playersOptions: Option[] = useMemo(
    () =>
      sessionPlayers.map((player) => ({
        value: player.character_id,
        label: player.name,
      })),
    [sessionPlayers],
  );

  const selectedCharacter = useMemo(
    () => sessionPlayers.find((player) => player.character_id === selectedCharacterId),
    [sessionPlayers, selectedCharacterId],
  );

  let managementBody;
  if (selectedCharacterId) {
    switch (selectedAction) {
      case 'health':
        managementBody = <HealthManagement selectedCharacter={selectedCharacter} />;
        break;
      case 'money':
        managementBody = <MoneyManagement selectedCharacter={selectedCharacter} />;
        break;
      case 'grant_item':
        managementBody = <ItemManagement selectedCharacter={selectedCharacter} />;
        break;
      default:
        break;
    }
  }

  return (
    <Wrapper>
      <Select
        label={t('player_label')}
        options={playersOptions}
        noOptionsMessage={() => t('no_players_prompt')}
        onChange={(newValue) => setSelectedCharacterId((newValue as Option).value)}
        isSearchable={false}
      />
      <Select
        label={t('action_label')}
        options={actionsOptions}
        onChange={(newValue) => setSelectedAction((newValue as Option).value as Action)}
        isSearchable={false}
      />

      {managementBody}
    </Wrapper>
  );
};
