import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from 'components/generics/Text/Text';
import { ContentContainer, Container } from '../IngameHandbook.styles';

export const HandbookCallRollSection = () => {
  const { t } = useTranslation('handbook', { keyPrefix: 'call_roll_view' });

  return (
    <ContentContainer>
      <Text fontSize="small" color="opaqueWhite75" fontWeight={500}>
        {t('sections.section1.p1')}
      </Text>
      <Text fontSize="small" color="opaqueWhite75" fontWeight={500}>
        {t('sections.section1.p2')}
      </Text>
      <Text fontSize="small" color="opaqueWhite75" fontWeight={500}>
        {t('sections.section1.p3')}
      </Text>
      <Text fontSize="small" color="opaqueWhite75" fontWeight={500}>
        {t('sections.section1.p4')}
      </Text>
      <br />
      <Container>
        <Text fontWeight={500}>{t('sections.section2.heading')}</Text>
        <Text fontSize="small" color="opaqueWhite75" fontWeight={500}>
          {t('sections.section2.p1')}
        </Text>
        <Text fontSize="small" color="opaqueWhite75" fontWeight={500}>
          {t('sections.section2.p2')}
        </Text>
      </Container>
      <br />
      <Container>
        <Text fontWeight={500}> {t('sections.section3.heading')}</Text>
        <Text fontSize="small" color="opaqueWhite75" fontWeight={500}>
          {t('sections.section3.p1')}
        </Text>
        <Text fontSize="small" color="opaqueWhite75" fontWeight={500}>
          {t('sections.section3.p2')}
        </Text>
        <Text fontSize="small" color="opaqueWhite75" fontWeight={500}>
          {t('sections.section3.p3')}
        </Text>
      </Container>
      <br />
      <Container>
        <Text fontWeight={500}>{t('sections.section4.heading')}</Text>
        <Text fontSize="small" color="opaqueWhite75" fontWeight={500}>
          {t('sections.section4.p1')}
        </Text>
        <Text fontSize="small" color="opaqueWhite75" fontWeight={500}>
          {t('sections.section4.p2')}
        </Text>
        <Text fontSize="small" color="opaqueWhite75" fontWeight={500}>
          {t('sections.section4.p3')}
        </Text>
      </Container>
    </ContentContainer>
  );
};
