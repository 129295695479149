import React from 'react';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';
import { Text } from 'components/generics/Text/Text';
import { FreeRollSessionRecord } from 'models/db/db_SessionRecord';
import { DiceType, getDiceImage, organizeResultsByDiceType } from 'engine/rolls';
import { DiceMiniature, Record, RecordRow, RecordRowsWrapper } from '../RecordsView.styles';

interface FreeRollRecordProps {
  isGm?: boolean;
  record: FreeRollSessionRecord;
}

export const FreeRollRecord = ({ isGm, record }: FreeRollRecordProps) => {
  const { t } = useTranslation('gameplay', { keyPrefix: 'session_records_view' });

  const resultsByDiceType = organizeResultsByDiceType(record.content.results);

  return (
    <Record isGm={isGm}>
      <Text fontWeight={700} textTransform="uppercase">
        {t('free_roll')}
      </Text>
      <RecordRowsWrapper>
        {Object.entries(resultsByDiceType).map((res) => (
          <RecordRow key={`free-roll-res-${res[0]}-${v4()}`}>
            <Text fontSize="small">{res[0]}</Text>
            <DiceMiniature src={getDiceImage(res[0] as DiceType)} alt={res[0]} loading="lazy" />
            <Text fontSize="small">{res[1].join(', ')}</Text>
          </RecordRow>
        ))}
      </RecordRowsWrapper>
      <Text fontSize="small" fontWeight={600}>
        {t('total')}: {record.content.results.reduce((acc, curr) => acc + curr.result, 0)}
      </Text>
    </Record>
  );
};
